import { AppModule } from "../../module-wrapper";
import { HowToUseControllerModule } from "./howToUse.controller";
import { HowToUseServiceModule } from "./howToUse.service";

// angular.module("howToUse.module", ["howToUse.controller", "howToUse.service"]);

export const HowToUseModule = new AppModule({
  name: "howToUse.module",
  modules: [HowToUseControllerModule, HowToUseServiceModule]
});
