import { AppModule } from "../../module-wrapper";
import { TranslateServiceModule } from "./translate.service";
import { TranslateControllerModule } from "./translate.controller";

// angular
//     .module('translate.module', [
//         'translate.service',
//         'translate.controller'
//     ]);

export const TranslateModule = new AppModule({
  name: "translate.module",
  modules: [TranslateServiceModule, TranslateControllerModule]
});
