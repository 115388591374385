export let colors = {
  // ====== Standard colors ======
  default: {
    agro: {
      hex: "#ffcb05",
      rgb: "rgb(255,203,5)",
    },
    pecu: {
      hex: "#cc7348",
      rgb: "rgb(204,115,72)",
    },
  },

  // ====== Indicators ======
  indicator: {
    // Hex: manual: #98e46a
    // RGB: manual: rgb(152,228,106)
    green: {
      hex: "#98e46a",
      rgb: "rgb(152,228,106)",
    },

    // Hex: manual: #fded50
    // RGB: manual: rgb(253,237,80)
    yellow: {
      hex: "#FFFB9E",
      rgb: "rgb(255,251,158)",
    },

    // Hex: manual: #faae4c
    // RGB: manual: rgb(250,174,76)
    orange: {
      hex: "#faae4c",
      rgb: "rgb(250,174,76)",
    },

    // Hex: manual: #ed4e48
    // RGB: manual: rgb(237,78,72)
    red: {
      hex: "#ed4e48",
      rgb: "rgb(237,78,72)",
    },
  },

  // ====== Biomes ======
  biomes: {
    amazonia: {
      hex: "#156330",
      rgb: "rgb(21,99,48)",
    },
    cerrado: {
      hex: "#8d2c17",
      rgb: "rgb(141,44,23)",
    },
    caatinga: {
      hex: "#bcab46",
      rgb: "rgb(188,171,70)",
    },
    pantanal: {
      hex: "#817eaa",
      rgb: "rgb(129,126,170)",
    },
    mataAtlantica: {
      hex: "#4ba35a",
      rgb: "rgb(75,163,90)",
    },
    pampa: {
      hex: "#cea2b4",
      rgb: "rgb(206,162,180)",
    },
  },

  // ====== States ======
  states: {
    acre: {
      hex: "#e4a061",
      rgb: "rgb(228,160,97)",
    },
    amapa: {
      hex: "#b77630",
      rgb: "rgb(183,118,48)",
    },
    amazonas: {
      hex: "#502899",
      rgb: "rgb(80,40,153)",
    },
    bahia: {
      hex: "#cd493a",
      rgb: "rgb(205,73,58)",
    },
    goias: {
      hex: "#d0bb3a",
      rgb: "rgb(208,187,58)",
    },
    maranhao: {
      hex: "#d175a8",
      rgb: "rgb(209,117,168)",
    },
    matoGrosso: {
      hex: "#a8bba0",
      rgb: "rgb(168,187,160)",
    },
    matoGrossoSul: {
      hex: "#8776e3",
      rgb: "rgb(135,118,227)",
    },
    minasGerais: {
      hex: "#f7756e",
      rgb: "rgb(247,117,110)",
    },
    para: {
      hex: "#bc44ae",
      rgb: "rgb(188,68,174)",
    },
    piaui: {
      hex: "#9f90ad",
      rgb: "rgb(159,144,173)",
    },
    rondonia: {
      hex: "#778059",
      rgb: "rgb(119,128,89)",
    },
    roraima: {
      hex: "#4c775e",
      rgb: "rgb(76,119,94)",
    },
    saoPaulo: {
      hex: "#464646",
      rgb: "rgb(70,70,70)",
    },
    tocantins: {
      hex: "#bdbdbd",
      rgb: "rgb(189,189,189)",
    },
  },
  // ====== Report ======
  report: {
    // Radar graph

    // Hex: system: "#4d2ca2" | manual: "#2b0079"
    // RGB: system: rgb(77,44,162) | manual: rgb(43,0,121)
    radarLinhaPontos: {
      hex: "#4d2ca2",
      rgb: "rgb(77,44,162)",
    },

    // Hex: system: "#3c61ad" | manual: "#375cc5"
    // RGB: system: rgb(60,97,173) | manual: rgb(55,92,197)
    radarIndicador: {
      hex: "#3c61ad",
      rgb: "rgb(60,97,173)",
    },

    // Hex: system: "#9de8ff" | manual: "#88eaff"
    // RGB: system: rgb(157,232,255) | manual: rgb(136,234,255)
    radarLinhaGrade: {
      hex: "#9de8ff",
      rgb: "rgb(157,232,255)",
    },

    // Comparative Graph
    barrasComparativo: {
      hex: "#5d73cf",
      rgb: "rgb(93,115,207)",
    },

    // Striped Legend
    legendaRachurada: {
      hex: "#8e8e8e",
      rgb: "rgb(142,142,142)",
    },
  },
};
