import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function AuthLoginService($resource) {
  return function(url) {
    return $resource(
      url,
      {},
      {
        login: {
          method: "POST",
          isArray: false
        },
        logout: {
          method: "DELETE",
          isArray: false
        },
        get: {
          method: "GET",
          isArray: false
        },
        recoveryPassword: {
          method: "POST",
          isArray: false
        }
      }
    );
  };
}

/* @ngInject */
function AuthService($localStorage, $state, AuthLoginService, AppService, $injector) {
  function isAuthenticated() {
    return Boolean(getToken());
  }

  function logout() {
    delete $localStorage.token;
    delete $localStorage.username;
    delete $localStorage.name;
    delete $localStorage.profile;
  }

  function setProfile(profile) {
    $localStorage.profile = profile;
  }

  function getProfile() {
    return $localStorage.profile;
  }

  function setToken(token) {
    $localStorage.token = "token " + token;
  }

  function getToken() {
    return $localStorage.token;
  }

  function setMsgErrorDefault(msgErrorDefault) {
    $localStorage.msgErrorDefault = msgErrorDefault;
  }

  function getMsgErrorDefault() {
    return $localStorage.msgErrorDefault;
  }

  function setUsername(username) {
    $localStorage.username = username;
  }

  function getUsername() {
    return $localStorage.username;
  }

  function setName(name) {
    $localStorage.name = name;
  }

  function getName() {
    return $localStorage.name;
  }

  function authorizationState(state) {
    var profiles = getProfile();
    var pass = false;
    var services;

    angular.forEach(profiles, function(profile) {
      if (profile.services) {
        services = profile.services;
      }

      angular.forEach(services, function(service) {
        if (service.status && service.nameService == state) {
          pass = true;
        }
      });
    });

    if (!pass) {
      $state.go($state.$current.name);
    }
  }

  function sendInitialAuthLog() {
    var LogService = $injector.get("LogService");
    LogService.sendAuthLog(true, true);
  }

  function checkToken(send_log) {
    var url = AppService.getUrlApi() + "/session";
    AuthLoginService(url)
      .get()
      .$promise.then(
        function(response) {
          var data = response;
          if (!data.success) {
            logout();
          }
          if (send_log) {
            sendInitialAuthLog();
          }
        },
        function() {
          logout();
          if (send_log) {
            sendInitialAuthLog();
          }
        }
      );
  }

  return {
    logout: logout,
    isAuthenticated: isAuthenticated,
    setProfile: setProfile,
    getProfile: getProfile,
    setToken: setToken,
    getToken: getToken,
    setUsername: setUsername,
    getUsername: getUsername,
    setName: setName,
    getName: getName,
    setMsgErrorDefault: setMsgErrorDefault,
    getMsgErrorDefault: getMsgErrorDefault,
    authorizationState: authorizationState,
    checkToken: checkToken
  };
}

export const AuthServiceModule = new AppModule({
  name: "auth.service",
  factories: { AuthService, AuthLoginService }
});

// angular
//     .module('auth.service', [])
//     .factory('AuthService', AuthService)
//     .factory('AuthLoginService', AuthLoginService);
