import angular from "angular";
import { AppModule } from "../module-wrapper";
import { ContactModule } from "./contact/contact.module";
import { HowToUseModule } from "./howToUse/howToUse.module";
import { LayersModule } from "./layer/layers.module";
import { MainControllerModule } from "./main.controller";
import { MainDirectivesModule } from "./main.directives";
import { MainServiceModule } from "./main.service";
import { MapModule } from "./map/map.module";
import { ReportModule } from "./report/report.module";
import { StrategyModule } from "./strategy/strategy.module";

/* @ngInject */
function run($rootScope, $state, $window, AuthService) {
  var stateReload;
  var messageError = [] as { message; type }[];

  $rootScope.$on("$stateChangeStart", function (event, toState, fromState) {
    // console.log("state change start", event, toState, fromState);
    if (toState.authenticate && !AuthService.isAuthenticated()) {
      $state.transitionTo("map");
      event.preventDefault();
    } else if (
      toState.name === "login" &&
      fromState.name !== "logout" &&
      AuthService.isAuthenticated()
    ) {
      $state.transitionTo(fromState.name);
      event.preventDefault();
    }
    stateReload = toState.name;
  });
  $rootScope.$on("$stateChangeSuccess", function (event, toState) {
    // console.log("state change success", event, toState);
    $window.document.title = toState.title;
    if ($state.current.name === "login" && AuthService.isAuthenticated()) {
      $state.transitionTo("map");
      event.preventDefault();
    }
  });
  // $rootScope.$on("$stateChangeCancel", function(...args) {
  //   console.log("state cancel", ...args);
  // });
  // $rootScope.$on("$stateChangeError", function(...args) {
  //   console.log("state error", ...args);
  // });
  $rootScope.$on("$viewContentLoading", function () {
    if (stateReload === "login" && AuthService.isAuthenticated()) {
      $window.location.reload();
    }
  });
  $rootScope.$on("$viewContentLoaded", function () {
    document.getElementById("main")!.removeAttribute("hidden");
    if (document.getElementById("map") !== null) {
      document.getElementById("map")!.removeAttribute("hidden");
    }
  });
  messageError.push({ message: "Problema de conexão com API!", type: "warning" });
  AuthService.setMsgErrorDefault(messageError);
}

/* @ngInject */
function config($urlRouterProvider, $locationProvider) {
  $urlRouterProvider.otherwise(location.pathname);
  $locationProvider.hashPrefix("");
}

/* @ngInject */
function authHttpRequestInterceptor($localStorage, $injector) {
  return {
    request: function (config) {
      if (config.params == undefined) {
        config.params = {};
      }
      var AppService = $injector.get("AppService");
      if (config.url.contains(AppService.getUrlApi())) {
        var StrategyService = $injector.get("StrategyService");
        config.headers["Authorization"] = $localStorage.token;

        var MainService = $injector.get("MainService");
        config.params["language"] = MainService.getLanguageSystem().locale;

        var biomas = StrategyService.getRegionsActive();
        if (angular.isDefined(biomas) && biomas.length !== 0) {
          config.params["biomas"] = biomas.join(",");
        }

        var unitPlan = StrategyService.getUnitPlanningActive();
        if (angular.isDefined(unitPlan) && !angular.equals({}, unitPlan)) {
          config.params["tipo"] = unitPlan.name;
        }
      }
      return config;
    },
  };
}

/* @ngInject */
function configHttpProvider($httpProvider) {
  $httpProvider.interceptors.push("authHttpRequestInterceptor");
}

/* @ngInject */
function configTranslateProvider($translateProvider) {
  $translateProvider.useLoader("customLoader", {});
  $translateProvider.useSanitizeValueStrategy("sceParameters");
}

/* @ngInject */
function configDynamicLocaleProvider(tmhDynamicLocaleProvider) {
  tmhDynamicLocaleProvider.localeLocationPattern(
    "/assets/angular-i18n/angular-locale_{{locale}}.js"
  );
}

/* @ngInject */
function CustomLoaderPropertiesI18n($q, MainService, AppService) {
  return function (options) {
    var urls = [
      "/app/modules/map/language/map.language.json",
      "/app/modules/layer/language/layers.language.json",
      "/app/modules/report/language/report.language.json",
      "/app/modules/report/reportCharts/language/reportCharts.language.json",
      "/app/modules/report/reportPdf/language/reportPdf.language.json",
      "/app/modules/strategy/language/strategy.language.json",
      "/app/core/modal/language/privacyPolicy/privacyPolicy.language.json",
      "/app/core/modal/language/termsUse/" + AppService.getLayout() + "/termsUse.language.json",
      "/app/core/modal/language/termsUseComplete/termsUseComplete.language.json",
      "app/core/modal/language/disclaimer/" + AppService.getLayout() + "/disclaimer.language.json",
      "/app/core/language/core.language.json",
      "/app/core/user/language/user.language.json",
      "/app/modules/howToUse/language/howtouse.language.json",
      "app/modules/tour/languages/tour.language.json",
    ];
    var deferred = $q.defer();
    var promise = MainService.getDictProperties(urls);
    promise.then(
      function (response) {
        var data = response;
        MainService.setDictI18n(data);
        var dict_translate_areas = AppService.getTranslateAreas(
          MainService.getLanguageSystem().locale
        );
        MainService.pushDictI18n(dict_translate_areas, true);
        deferred.resolve(data);
      },
      function (error) {}
    );
    return deferred.promise;
  };
}

export const MainModule = new AppModule({
  name: "main.module",
  modules: [
    MainControllerModule,
    MainDirectivesModule,
    MainServiceModule,
    ContactModule,
    HowToUseModule,
    LayersModule,
    MapModule,
    ReportModule,
    StrategyModule,
  ],
  runner: run,
  factories: { authHttpRequestInterceptor, customLoader: CustomLoaderPropertiesI18n },
  configurators: [config, configHttpProvider, configTranslateProvider, configDynamicLocaleProvider],
});

// angular
//     .module('main.module', [
//         'main.controller',
//         'main.directives',
//         'main.service',
//         'contact.module',
//         'howToUse.module',
//         'layers.module',
//         'map.module',
//         'report.module',
//         'strategy.module'
//     ])
//     .factory("authHttpRequestInterceptor", authHttpRequestInterceptor)
//     .factory("customLoader", CustomLoaderPropertiesI18n)
//     .run(run)
//     .config(config)
//     .config(configHttpProvider)
//     .config(configTranslateProvider)
//     .config(configDynamicLocaleProvider);
// })();
