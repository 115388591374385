import { AppModule } from "../../module-wrapper";

/* @ngInject */
function HowToUseService(
  this: any,
  StrategyService,
  $rootScope,
  AppService,
  MainService,
  MapService,
  $timeout
) {
  var ctrl = this;

  ctrl.listStepsHowToCreateStrategies = [
    {
      index: 1,
      default: true,
    },
    {
      index: 2,
      default: true,
    },
    {
      index: 3,
      default: false,
    },
    {
      index: 4,
      default: false,
    },
    {
      index: 5,
      default: false,
    },
    {
      index: 6,
      default: false,
    },
    {
      index: 7,
      default: false,
    },
  ];

  ctrl.simulateGeoSearchBR = {
    api: "microrregioes",
    code: 29001,
    firstInGroup: true,
    groupLabel: "microrregioes",
    hifen: "-",
    name: "BARREIRAS",
    uf: "BA",
  };

  ctrl.simulateGeoSearchAR = {
    api: "estados",
    code: 70,
    firstInGroup: true,
    groupLabel: "estados",
    hifen: "-",
    name: "Provincia de San Juan",
    uf: "San Juan",
  };

  ctrl.simulateGeoSearchPY = {
    api: "municipios",
    code: 1604,
    firstInGroup: true,
    groupLabel: "municipios",
    hifen: "-",
    name: "FILADELFIA",
    uf: "BOQUERÓN",
  };

  ctrl.listStepsHowToUseTools = [
    {
      index: 1,
      label: "comousar.centralizarnaareaselecionada",
      labelGroup: "comousar.ferramentasdezoom",
      icon: "flaticon-focus",
      default: true,
    },
    {
      index: 2,
      label: "comousar.diminuaozoom",
      labelGroup: "comousar.ferramentasdezoom",
      icon: "flaticon-minus",
      default: false,
    },
    {
      index: 3,
      label: "comousar.aumenteozoom",
      labelGroup: "comousar.ferramentasdezoom",
      icon: "flaticon-add-plus-button",
      default: false,
    },
    {
      index: 4,
      label: "comousar.buscageografica",
      labelGroup: "comousar.ferramentasdezoom",
      icon: "icon-ferramenta-zoom",
      default: false,
    },
    {
      index: 5,
      label: "comousar.escolhaentre2opcoesdetexturaparaavizualizacaodomapa",
      labelGroup: "comousar.selecaodatexturadomapa",
      icon: "maps-style",
      default: false,
    },
  ];

  function getListStepsHowToCreateStrategies() {
    return ctrl.listStepsHowToCreateStrategies;
  }

  function getListStepsHowToUseTools() {
    return ctrl.listStepsHowToUseTools;
  }

  function setInitialStepHowToCreateStrategies() {
    var filterStep = ctrl.listStepsHowToCreateStrategies.filter(function (step) {
      step.classDisabled = false;
      return step.index === 1;
    });
    filterStep.forEach(function (step) {
      $rootScope.tab = 2;
    });
  }

  function resetSteps(list) {
    list.forEach(function (step) {
      step.default = false;
    });
  }

  function setStepHowToCreateStrategies(step) {
    var layout = AppService.getLayout();
    var system = AppService.getLayersNameUnits();
    var unitPlan = StrategyService.getUnitPlanningActive();
    var hasFeature = MapService.hasFeatureInSelectionAreaByRegionsSelected(unitPlan);
    var ar = "municipios_argentina_chaco";
    var br = "municipios_brasileiros_cerrado_amazonia";
    var py = "municipios_paraguai_chaco";

    if (system.municipios == br) {
      setStepHowToCreateStrategiesBrasil(step.index, layout, hasFeature);
    } else if (layout == "soja" && system.municipios == ar) {
      setStepHowToCreateStrategiesArgentinaSoja(step.index, hasFeature);
    } else if (layout == "pecuaria" || system.municipios == py) {
      setStepHowToCreateStrategiesParaguaiPecuaria(step.index, hasFeature);
    }
  }

  function setStepHowToUseTools(step) {
    resetSteps(ctrl.listStepsHowToUseTools);
    step.default = true;
  }

  function getHowToUseImage() {
    var layout = AppService.getLayout();
    var language = MainService.getLocaleLanguageSystem();
    var pathLocation;

    switch (layout) {
      case "soja":
        pathLocation = "assets/images/HowToUse/soja/" + language;
        return pathLocation;
      case "pecuaria":
        pathLocation = "assets/images/HowToUse/pecuaria/" + language;
        return pathLocation;
    }
  }

  function getArrowIcons() {
    var layout = AppService.getLayout();
    var pathLocation;

    switch (layout) {
      case "soja":
        pathLocation = "assets/icons/soja";
        return pathLocation;
      case "pecuaria":
        pathLocation = "assets/icons/pecuaria";
        return pathLocation;
    }
  }

  function setStepHowToCreateStrategiesBrasil(index, layout, hasFeature) {
    var isEconomicIndicatorActive = $("#checkbox-indicators").hasClass("ng-not-empty");
    var isSocioambientalIndicatorActive = $(".25, .68").hasClass("ng-not-empty");

    switch (index) {
      case 1:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#criarumpoligononomapa").triggerHandler("click");
        }, 0);
        break;

      case 2:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;

      case 3:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#inputGeographicSearch")
            .val(ctrl.simulateGeoSearchBR.name + "-" + ctrl.simulateGeoSearchBR.uf)
            .focus();
          if (!hasFeature) {
            $rootScope.$broadcast("howToUseSimulation", { myParam: ctrl.simulateGeoSearchBR });
          }
        }, 0);
        break;

      case 4:
        if (!hasFeature) {
          $timeout(function () {
            $rootScope.$broadcast("cleanMapSimulation");
            setStepHowToCreateStrategies({ index: 3 });
          }, 0);
          $timeout(function () {
            StrategyService.setCollapseSession(2, true);
            if (layout == "soja") {
              $("#group_areas_aptas").triggerHandler("click");
            } else {
              $("#group_pecuaria").triggerHandler("click");
            }
          }, 3000);

          $timeout(function () {
            $("#checkbox-indicators").trigger("click");
          }, 4800);
        } else {
          StrategyService.setCollapseSession(2, true);
          if (!isEconomicIndicatorActive) {
            $timeout(function () {
              if (layout == "soja") {
                $("#group_areas_aptas").triggerHandler("click");
              } else {
                $("#group_pecuaria").triggerHandler("click");
              }
            }, 0);

            $timeout(function () {
              $("#checkbox-indicators").trigger("click");
            }, 500);
          }
        }

        break;

      case 5:
        $timeout(function () {
          $("#relacaoinversa").trigger("click");
        }, 0);
        break;

      case 6:
        if (!hasFeature) {
          $timeout(function () {
            setStepHowToCreateStrategies({ index: 4 });
          }, 0);
          $timeout(function () {
            StrategyService.setCollapseSession(3, true);
            if (layout == "soja") {
              $("#group_areas_especiais").triggerHandler("click");
            } else {
              $("#group_cobertura_vegetal").triggerHandler("click");
            }
          }, 4600);

          $timeout(function () {
            if (layout == "soja") {
              $(".25").trigger("click");
            } else {
              $(".68").trigger("click");
            }
          }, 5300);
        } else {
          StrategyService.setCollapseSession(3, true);
          if (!isSocioambientalIndicatorActive) {
            $timeout(function () {
              if (layout == "soja") {
                $("#group_areas_especiais").triggerHandler("click");
              } else {
                $("#group_cobertura_vegetal").triggerHandler("click");
              }
            }, 0);

            $timeout(function () {
              if (layout == "soja") {
                $(".25").trigger("click");
              } else {
                $(".68").trigger("click");
              }
            }, 500);
          }
        }
        break;

      case 7:
        $timeout(function () {
          StrategyService.setCollapseSession(4, true);
          $("#btn-strategy").triggerHandler("click");
        }, 0);
        break;

      case 8:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;
    }
  }

  function setStepHowToCreateStrategiesArgentinaSoja(index, hasFeature) {
    var isEconomicIndicatorActive = $("#checkbox-indicators").hasClass("ng-not-empty");
    var isSocioambientalIndicatorActive = $(".13").hasClass("ng-not-empty");

    switch (index) {
      case 1:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#criarumpoligononomapa").triggerHandler("click");
        }, 0);
        break;

      case 2:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;

      case 3:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#inputGeographicSearch")
            .val(ctrl.simulateGeoSearchAR.name + "-" + ctrl.simulateGeoSearchAR.uf)
            .focus();
          if (!hasFeature) {
            $rootScope.$broadcast("howToUseSimulation", { myParam: ctrl.simulateGeoSearchAR });
          }
        }, 0);
        break;

      case 4:
        if (!hasFeature) {
          $timeout(function () {
            $rootScope.$broadcast("cleanMapSimulation");
            setStepHowToCreateStrategies({ index: 3 });
          }, 0);

          $timeout(function () {
            StrategyService.setCollapseSession(2, true);
            $("#group_areas_especiais").triggerHandler("click");
          }, 1500);

          $timeout(function () {
            $("#checkbox-indicators").trigger("click");
          }, 1800);
        } else {
          StrategyService.setCollapseSession(2, true);
          if (!isEconomicIndicatorActive) {
            $timeout(function () {
              $("#group_areas_especiais").triggerHandler("click");
            }, 0);

            $timeout(function () {
              $("#checkbox-indicators").trigger("click");
            }, 500);
          }
        }
        break;

      case 5:
        $timeout(function () {
          $("#relacaoinversa").trigger("click");
        }, 0);
        break;

      case 6:
        if (!hasFeature) {
          $timeout(function () {
            setStepHowToCreateStrategies({ index: 4 });
          }, 0);

          $timeout(function () {
            StrategyService.setCollapseSession(3, true);
            $("#group_ambiental").triggerHandler("click");
          }, 2000);

          $timeout(function () {
            $(".19").trigger("click");
          }, 2300);
        } else {
          StrategyService.setCollapseSession(3, true);
          if (!isSocioambientalIndicatorActive) {
            $timeout(function () {
              $("#group_ambiental").triggerHandler("click");
            }, 0);

            $timeout(function () {
              $(".19").trigger("click");
            }, 500);
          }
        }
        break;

      case 7:
        $timeout(function () {
          StrategyService.setCollapseSession(4, true);
          $("#btn-strategy").triggerHandler("click");
        }, 0);
        break;

      case 8:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;
    }
  }

  function setStepHowToCreateStrategiesParaguaiPecuaria(index, hasFeature) {
    var isEconomicIndicatorActive = $("#checkbox-indicators").hasClass("ng-not-empty");
    var isSocioambientalIndicatorActive = $(".7").hasClass("ng-not-empty");

    switch (index) {
      case 1:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#criarumpoligononomapa").triggerHandler("click");
        }, 0);
        break;

      case 2:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;

      case 3:
        $timeout(function () {
          $("#grades").triggerHandler("click");
          $("#inputGeographicSearch")
            .val(ctrl.simulateGeoSearchPY.name + "-" + ctrl.simulateGeoSearchPY.uf)
            .focus();
          if (!hasFeature) {
            $rootScope.$broadcast("howToUseSimulation", { myParam: ctrl.simulateGeoSearchPY });
          }
        }, 0);
        break;

      case 4:
        if (!hasFeature) {
          $timeout(function () {
            $rootScope.$broadcast("cleanMapSimulation");
            setStepHowToCreateStrategies({ index: 3 });
          }, 0);

          $timeout(function () {
            StrategyService.setCollapseSession(2, true);
            $("#group_areas_aptas").triggerHandler("click");
          }, 3000);

          $timeout(function () {
            $("#checkbox-indicators").trigger("click");
          }, 3500);
        } else {
          StrategyService.setCollapseSession(2, true);
          if (!isEconomicIndicatorActive) {
            $timeout(function () {
              $("#group_areas_aptas").triggerHandler("click");
            }, 0);

            $timeout(function () {
              $("#checkbox-indicators").trigger("click");
            }, 500);
          }
        }
        break;

      case 5:
        $timeout(function () {
          $("#relacaoinversa").trigger("click");
        }, 0);
        break;

      case 6:
        if (!hasFeature) {
          $timeout(function () {
            setStepHowToCreateStrategies({ index: 4 });
          }, 0);

          $timeout(function () {
            StrategyService.setCollapseSession(3, true);
            $("#group_areas_especiais").triggerHandler("click");
          }, 4000);

          $timeout(function () {
            $(".7").trigger("click");
          }, 4500);
        } else {
          StrategyService.setCollapseSession(3, true);
          if (!isSocioambientalIndicatorActive) {
            $timeout(function () {
              $("#group_areas_especiais").triggerHandler("click");
            }, 0);

            $timeout(function () {
              $(".7").trigger("click");
            }, 500);
          }
        }

        break;

      case 7:
        $timeout(function () {
          StrategyService.setCollapseSession(4, true);
          $("#btn-strategy").triggerHandler("click");
        }, 0);
        break;

      case 8:
        $timeout(function () {
          $("#criarpontonomapa").triggerHandler("click");
        }, 0);
        break;
    }
  }

  return {
    getListStepsHowToCreateStrategies: getListStepsHowToCreateStrategies,
    getListStepsHowToUseTools: getListStepsHowToUseTools,
    setInitialStepHowToCreateStrategies: setInitialStepHowToCreateStrategies,
    setStepHowToCreateStrategies: setStepHowToCreateStrategies,
    setStepHowToUseTools: setStepHowToUseTools,
    getHowToUseImage: getHowToUseImage,
    getArrowIcons: getArrowIcons,
    setStepHowToCreateStrategiesBrasil: setStepHowToCreateStrategiesBrasil,
    setStepHowToCreateStrategiesArgentinaSoja: setStepHowToCreateStrategiesArgentinaSoja,
    setStepHowToCreateStrategiesParaguaiPecuaria: setStepHowToCreateStrategiesParaguaiPecuaria,
  };
}

// angular
//     .module('howToUse.service', [])
//     .factory('HowToUseService', HowToUseService);

export const HowToUseServiceModule = new AppModule({
  name: "howToUse.service",
  factories: { HowToUseService },
});
