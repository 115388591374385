import { AppModule } from "../../../module-wrapper";
import angular from "angular";
import { TranslateService } from "../../../core/translate/translate.service";

/* @ngInject */
function GetRegionIndicatorService($resource, AppService) {
  var url = AppService.getUrlApi() + "/";

  return $resource(
    url,
    { code: "@code", region: "@region" },
    {
      get: {
        url: url + ":region/:code/indicators",
        method: "GET",
        isArray: true
      }
    }
  );
}

/* @ngInject */
function GetAxisService($resource, AppService) {
  var url = AppService.getUrlApi() + "/axes";

  return $resource(url, {
    get: {
      method: "GET",
      isArray: true
    }
  });
}

/* @ngInject */
function GetIndicatorsGroupsService($resource, AppService) {
  var url = AppService.getUrlApi() + "/indicators-group";

  return $resource(url, {
    get: {
      method: "GET",
      isArray: true
    }
  });
}

/* @ngInject */
function NewGetIndicatorService($resource, AppService) {
  var url = AppService.getUrlApi() + "/indicators";

  return $resource(url, {
    get: {
      method: "GET",
      isArray: true
    }
  });
}

/* @ngInject */
function AxisService(this: any, GetAxisService) {
  var ctrl = this;
  ctrl.axes = [];

  function loadAxes() {
    GetAxisService.get().$promise.then(function(response) {
      if (response.success) {
        ctrl.axes = response.data;
      }
    });
  }

  function getAxes() {
    return ctrl.axes;
  }

  function getIndicatorsByAxisName(axis_name, indicators) {
    var i = 0;
    var len = indicators.length;
    var axis_indicators = [] as any[];
    for (; i < len; i++) {
      var indicator = indicators[i];
      if (indicator.axis_name == axis_name) {
        axis_indicators.push(indicator);
      }
    }
    return axis_indicators;
  }

  return {
    getIndicatorsByAxisName: getIndicatorsByAxisName,
    getAxes: getAxes,
    loadAxes: loadAxes
  };
}

/* @ngInject */
function IndicatorsGroupsService(this: any, GetIndicatorsGroupsService) {
  var ctrl = this;
  ctrl.indicators_groups = [];

  function loadIndicatorsGroups() {
    GetIndicatorsGroupsService.get().$promise.then(function(response) {
      if (response.success) {
        ctrl.indicators_groups = response.data;
      }
    });
  }

  function getIndicatorsGroups() {
    return ctrl.indicators_groups;
  }

  function getIndicatorsGroupsByAxisName(axis_name) {
    var indicators_groups_by_axis = getIndicatorsGroups();
    return indicators_groups_by_axis.filter(function(indicator) {
      return indicator.axis_name == axis_name;
    });
  }

  return {
    getIndicatorsGroups: getIndicatorsGroups,
    getIndicatorsGroupsByAxisName: getIndicatorsGroupsByAxisName,
    loadIndicatorsGroups: loadIndicatorsGroups
  };
}

/* @ngInject */
function IndicatorService(
  this: any,
  StrategyService,
  MainService,
  $timeout,
  $filter,
  NewGetIndicatorService,
  $q,
  TranslateService: TranslateService
) {
  var ctrl = this;
  ctrl.indicators = [];

  function setIndicadores(indicadores) {
    ctrl.indicadores = indicadores;
  }

  function getIndicadores() {
    return ctrl.indicadores;
  }

  function loadIndicators() {
    var deferred = $q.defer();
    NewGetIndicatorService.get().$promise.then(function(response) {
      if (response.success) {
        ctrl.indicators = response.data;
        ctrl.indicators.forEach(function(indicator) {
          indicator.initial_relation = indicator.inverse_relationship;
          indicator.weight = 1;
        });
      }
      deferred.resolve();
    });
    return deferred.promise;
  }

  function getIndicatorByName(name, indicators?, boolean?, boolean_only_false?) {
    var indicators = getIndicators(indicators);
    var i = 0;
    var len = indicators.length;
    for (; i < len; i++) {
      if (indicators[i].name == name) {
        return boolean ? true : indicators[i];
      }
    }
    return boolean || boolean_only_false ? false : {};
  }

  function getIndicatorsActiveByAxisName(axis_name, indicators) {
    if (angular.isUndefined(indicators)) {
      indicators = getIndicators(indicators);
    }
    return getIndicatorsByAxisName(axis_name, getIndicatorsActive(indicators));
  }

  function getIndicatorsActiveByGroupName(group_name, indicators) {
    if (angular.isUndefined(indicators)) {
      indicators = getIndicators(indicators);
    }
    return getIndicatorsByGroupName(group_name, getIndicatorsActive(indicators));
  }

  function getIndicatorsByVisible(visible, indicators) {
    var indicators = getIndicators(indicators);

    return indicators.filter(function(indicator) {
      if (angular.isUndefined(indicator.visible)) {
        indicator = getIndicatorByName(indicator.name);
      }
      return indicator.visible == visible;
    });
  }

  function setVisibleAllIndicators(visible, indicators) {
    var indicators = getIndicators(indicators);
    indicators = getIndicatorsByVisible(!visible, indicators);
    indicators.forEach(function(indicator) {
      indicator.visible = visible;
    });
  }

  function getIndicatorsByAxisName(axis_name, indicators) {
    var indicators = getIndicators(indicators);
    return indicators.filter(function(indicator) {
      if (indicator.axis_name == undefined) {
        indicator = getIndicatorByName(indicator.name);
      }
      return indicator.axis_name == axis_name;
    });
  }

  function getIndicatorsByGroupName(group_name, indicators) {
    var indicators = getIndicators(indicators);
    return indicators.filter(function(indicator) {
      if (indicator.group_name == undefined) {
        indicator = getIndicatorByName(indicator.name);
      }
      return indicator.group_name == group_name;
    });
  }
  function getIndicatorsByAxisAndGroupName(axis_name, group_name, indicators) {
    var group_indicators = getIndicatorsByAxisName(axis_name, indicators);
    return group_indicators.filter(function(indicator) {
      return indicator.group_name == group_name;
    });
  }

  function getIndicators(indicators?) {
    if (indicators == undefined) {
      return ctrl.indicators;
    }
    return indicators;
  }

  function getLastIndicator() {
    if (angular.equals([], StrategyService.getResultStrategy())) {
      var last = getIndicatorsActive().filter(function(indicator) {
        return indicator.ultimo;
      });
      if (last.length > 0) {
        return last[0];
      }
    }
    return null;
  }

  function getIndicatorsActive(indicators?) {
    var indicators = getIndicators(indicators);
    return getIndicatorsByVisible(true, indicators);
  }

  function setLastIndicator(indicator) {
    var lasts_indicators = getIndicators().filter(function(ind) {
      return ind.ultimo && ind.name != indicator.name;
    });

    indicator.ultimo = true;

    lasts_indicators.forEach(function(ind) {
      ind.ultimo = false;
    });
  }

  function setListIndicatorsActive(listIndicators) {
    ctrl.listRangeIndicators = listIndicators;
  }

  // function getListIndicatorsActive(){
  //     return ctrl.listRangeIndicators || [];
  // };

  function closeAllEyeIndicator(listIndicators) {
    listIndicators.forEach(function(indicator) {
      indicator.ultimo = false;
    });
  }

  function lastViewEyeIndicator(indicator) {
    return indicator.ultimo;
  }

  function refreshSlider(scope) {
    $timeout(function() {
      // scope.$broadcast('reCalcViewDimensions');
      scope.$broadcast("rzSliderForceRender");
    });
  }

  function getOptionsSlider(indicador, reset) {
    if (!Boolean(indicador.options) || Boolean(reset)) {
      // Retirado o arredondamento a pedido da flavia.
      // indicador.minValue = Math.floor(indicador.minValue);
      // indicador.maxValue = Math.ceil(indicador.maxValue);
      indicador.step = 0.1;
      indicador.precision = 1;
      indicador.rounder = 1;
      indicador.maximun = false;
      if (indicador.maxValue <= 1) {
        indicador.maximun = true;
        indicador.step = 0.00005;
        indicador.precision = 5;
        indicador.rounder = 5;
      }
      indicador.minValue = Number(indicador.minValue.toFixed(indicador.rounder)) || 0;
      indicador.maxValue = Number(indicador.maxValue.toFixed(indicador.rounder)) || 0;
      indicador.options = {
        step: indicador.step,
        precision: indicador.precision,
        floor: indicador.minValue,
        ceil: indicador.maxValue,
        noSwitching: true,
        hideLimitLabels: false,
        translate: function(value) {
          var sigla = "";
          try {
            sigla = TranslateService.translate(indicador.unit.sigla);
          } catch (e) {}
          return $filter("number")(value.toFixed(indicador.rounder)) + " " + sigla;
        }
      };
    }
    return indicador.options;
  }

  function getLabelAndUnitIndicatorByLocale(indicator, titleUppercase) {
    var locale = MainService.getLocaleLanguageSystem();
    var label = titleUppercase ? indicator.label[locale].toUpperCase() : indicator.label[locale];
    var indicator = indicator.unit.sigla[locale];
    if (indicator.replace(/\s/g, "") !== "") {
      // Check empty string
      indicator = "(" + indicator + ")";
    }
    return label + " " + indicator;
  }

  return {
    setIndicadores: setIndicadores,
    getIndicadores: getIndicadores,
    setListIndicatorsActive: setListIndicatorsActive,
    getIndicatorsActiveByAxisName: getIndicatorsActiveByAxisName,
    getIndicatorsActiveByGroupName: getIndicatorsActiveByGroupName,
    closeAllEyeIndicator: closeAllEyeIndicator,
    getIndicatorByName: getIndicatorByName,
    getIndicators: getIndicators,
    getIndicatorsActive: getIndicatorsActive,
    getIndicatorsByAxisAndGroupName: getIndicatorsByAxisAndGroupName,
    getIndicatorsByAxisName: getIndicatorsByAxisName,
    getLastIndicator: getLastIndicator,
    getOptionsSlider: getOptionsSlider,
    lastViewEyeIndicator: lastViewEyeIndicator,
    loadIndicators: loadIndicators,
    refreshSlider: refreshSlider,
    setLastIndicator: setLastIndicator,
    setVisibleAllIndicators: setVisibleAllIndicators,
    getLabelAndUnitIndicatorByLocale: getLabelAndUnitIndicatorByLocale
  };
}

export const IndicatorServiceModule = new AppModule({
  name: "indicator.service",
  factories: {
    GetRegionIndicatorService,
    AxisService,
    GetAxisService,
    IndicatorService,
    NewGetIndicatorService,
    GetIndicatorsGroupsService,
    IndicatorsGroupsService
  }
});

// angular
//     .module('indicator.service', [])
//     .factory('GetRegionIndicatorService', GetRegionIndicatorService)
//     .factory('AxisService', AxisService)
//     .factory('GetAxisService', GetAxisService)
//     .factory('IndicatorService', IndicatorService)
//     .factory('NewGetIndicatorService', NewGetIndicatorService)
//     .factory('GetIndicatorsGroupsService', GetIndicatorsGroupsService)
//     .factory('IndicatorsGroupsService', IndicatorsGroupsService);
