import { AppModule } from "../../module-wrapper";
import { UserControllerModule } from "./user.controller";
import { UserRoutesModule } from "./user.routes";
import { UserServiceModule } from "./user.service";
import { UserAreaModule } from "./userArea/userArea.module";

// angular
//     .module('user.module', [
//         'userArea.module',
//         'user.controller',
//         'user.routes',
//         'user.service']);

export const UserModule = new AppModule({
  name: "user.module",
  modules: [UserAreaModule, UserControllerModule, UserRoutesModule, UserServiceModule]
});
