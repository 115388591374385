import { AppModule } from "../../../module-wrapper";
import { UserAreaDirectivesModule } from "./userArea.directives";

// angular
// 	.module('userArea.module', [
// 		'userArea.directives'
// 	]);

export const UserAreaModule = new AppModule({
  name: "userArea.module",
  modules: [UserAreaDirectivesModule]
});
