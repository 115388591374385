import { AppModule } from "../../../module-wrapper";
import angular from "angular";
import Plotly from "plotly.js";
import d3 from "d3";
import c3, { ChartConfiguration } from "c3";
import { reportLegendEs, reportLegendEn, reportLegendPt } from "./reportCharts.base64";

/* @ngInject */
function ReportChartsService(
  this: any,
  MapService,
  MainService,
  AppService,
  $q,
  IndicatorService,
  StrategyService,
  ReportService,
  LogService,
  TranslateService,
  $injector
) {
  var ctrl = this;

  function initCharts() {
    var deferred = $q.defer();
    angular.element(function () {
      var features = MapService.getFeaturesSelectionAreaInstance();
      initBarByIndicator().then(function () {
        generateFeaturesByIndice().then(function () {
          var forceGridInit = false;
          initScatterPolar(features, forceGridInit);
          initPie();
          addEventsResizeC3Charts();
          reloadDataUrlPie();
          deferred.resolve();
        });
      });
      ReportService.setReportCached(true);
    });
    return deferred.promise;
  }

  // Inicia gráfico relatório
  // Comparativo regional
  function initBarByIndicator() {
    var deferred = $q.defer();
    var indicatorsEconomico = IndicatorService.getIndicatorsActiveByAxisName("economico");
    var indicator = MainService.sortAscArrayObjectByKey(
      indicatorsEconomico,
      "order_presentation"
    )[0];
    var initChartBar = setInterval(function () {
      if (angular.element("#selectFeaturesByIndicator").length) {
        if ((angular.element("#selectFeaturesByIndicator option:eq(0)")[0] as any).label == "") {
          angular.element("#selectFeaturesByIndicator option:eq(0)").remove();
        }
        angular.element("#selectFeaturesByIndicator option:eq(0)").attr("selected", "selected");
        generateBarByIndicator(indicator).then(function () {
          deferred.resolve();
        });
        clearInterval(initChartBar);
      }
    }, 100);
    return deferred.promise;
  }

  // Gera gráfico relatório
  // Comparativo regional
  function generateBarByIndicator(indicator) {
    var deferred = $q.defer();
    if (angular.isDefined(indicator) && indicator != null) {
      var isGrid = StrategyService.getUnitPlanningActive().id === 2;
      var isUpperCase = true;
      var label = IndicatorService.getLabelAndUnitIndicatorByLocale(indicator, isUpperCase);
      var featuresByIndicator = getDataFeaturesByIndicator(
        "#featuresByIndicator",
        label,
        indicator,
        isGrid
      );
      if (isGrid) {
        addTooltipBarByIndicator(featuresByIndicator);
      }
      featuresByIndicator["onrendered"] = function () {
        deferred.resolve();
      };
      c3.generate(featuresByIndicator);
      addEventsResizeC3Charts();
    }
    return deferred.promise;
  }

  // Evento Tooltip - Comparativo regional
  function addTooltipBarByIndicator(dataFeaturesByInd) {
    ctrl.dataFeaturesByInd = dataFeaturesByInd.data.json;
    dataFeaturesByInd.tooltip = {
      format: {
        show: getStatusToolTip(true),
        title: function (d) {
          return getValueTextTooltip(ctrl.dataFeaturesByInd, d);
        },
      },
    };
  }

  // Gera gráfico relatório
  // Ranking de oportunidade econômica / Ranking de risco socioambiental
  function generateFeaturesByIndice() {
    var deferred = $q.defer();
    var isGrid = StrategyService.getUnitPlanningActive().id === 2;
    var featuresByIndiceOportunidade = getDataFeaturesByIndice(
      "#featuresByIndiceOportunidade",
      "reportcharts.maioresoportunidadeseconomicas",
      "reportcharts.indicedeoportunidadeeconomicalabel",
      "reportcharts.indicederiscosocioambientallabel",
      "indiceOportunidade",
      "indiceRisco",
      isGrid
    );
    var featuresByIndiceRisco = getDataFeaturesByIndice(
      "#featuresByIndiceRisco",
      "reportcharts.maioresriscossocioambientais",
      "reportcharts.indicederiscosocioambientallabel",
      "reportcharts.indicedeoportunidadeeconomicalabel",
      "indiceRisco",
      "indiceOportunidade",
      isGrid
    );

    if (isGrid) {
      addTooltipFeaturesByIndice(featuresByIndiceOportunidade, featuresByIndiceRisco);
    }

    featuresByIndiceOportunidade["onrendered"] = function () {
      featuresByIndiceRisco["onrendered"] = function () {
        deferred.resolve();
      };
      c3.generate(featuresByIndiceRisco);
    };
    c3.generate(featuresByIndiceOportunidade);
    c3.generate(featuresByIndiceRisco);

    var indiceOportunidadeDataURLsPromise = convertSvgToDataURL("featuresByIndiceOportunidade");
    indiceOportunidadeDataURLsPromise.then(function (dataURL) {
      ReportService.setDataURLFeaturesByIndiceOportunidade(dataURL);
    });

    var indiceRiscoDataURLsPromise = convertSvgToDataURL("featuresByIndiceRisco");
    indiceRiscoDataURLsPromise.then(function (dataURL) {
      ReportService.setDataURLFeaturesByIndiceRisco(dataURL);
    });
    attachStripes("#featuresByIndiceOportunidade", false);
    attachStripes("#featuresByIndiceRisco", true);
    attachLegends("#featuresByIndiceOportunidade", false);
    attachLegends("#featuresByIndiceRisco", true);
    adjustGraphPosition("#featuresByIndiceOportunidade");
    adjustGraphPosition("#featuresByIndiceRisco");
    return deferred.promise;
  }

  function adjustGraphPosition(idGraph) {
    var svg = d3.select(idGraph).select("svg");
    var minimumHeight: number = 450;
    var svgHeight: number = parseFloat(svg.attr("height"));
    if (svgHeight < minimumHeight) {
      var diffHeight = minimumHeight - svgHeight;
      var graphMiddle = parseFloat(svg.select("g").select("rect").attr("height")) / 2;
      var svgMiddle = svgHeight / 2;
      svg.attr("height", svgHeight + diffHeight);
      var graphTransform = svg.select("g").attr("transform");
      var transformValues = graphTransform
        .replace("translate", "")
        .replace("(", "")
        .replace(")", "")
        .split(",");
      var graphTranslateY = graphMiddle - svgMiddle;
      if (graphTranslateY < 0) {
        graphTranslateY = graphTranslateY * -1;
      }
      var xTransform = parseFloat(transformValues[0]);
      var yTransform = parseFloat(transformValues[1]);
      graphTranslateY = graphTranslateY + yTransform;
      svg.select("g").attr("transform", "translate(" + xTransform + ", " + graphTranslateY + ")");
    }
  }

  function attachLegends(idGraph, inverse) {
    var div = d3.select(idGraph);
    var svg = div.select("svg");

    if (!svg.select("#legend-color").empty()) {
      svg.selectAll("#legend-color").remove();
    }

    // Pega a posição que a nova legenda vai ser criada com base na posição
    // da legenda já existente
    try {
      var childrenNode = svg.select(".c3-legend-item-value").node().parentNode;
      var parentNode = d3.select(childrenNode);
      var translate = parentNode.attr("transform");
      var xNewLegend = parseFloat(
        translate.replace("translate", "").replace("(", "").replace(")", "").split(",")[0]
      );
      var yNewLegend =
        parseFloat(svg.select(".c3-legend-item-value2").select(".c3-legend-item-event").attr("y")) +
        20;

      var legendColor = svg
        .append("g")
        .attr("id", "legend-color")
        .attr("transform", "translate(" + (xNewLegend - 5) + ", " + yNewLegend * 1.1 + ")");

      var legendWidth = 196;
      var legendHeight = 189;

      var reportLegend = "";
      if (MainService.getLanguageSystem().locale === "pt-BR") {
        reportLegend = reportLegendPt;
      } else if (MainService.getLanguageSystem().locale === "en-US") {
        reportLegend = reportLegendEn;
      } else {
        reportLegend = reportLegendEs;
      }

      var legend = legendColor.append("g").attr("class", "custom-legend");
      legend
        .append("image")
        .attr("xlink:href", reportLegend)
        .attr("width", legendWidth)
        .attr("height", legendHeight)
        .attr("x", 2)
        .attr("y", 2);

      var legendsPath = [".c3-legend-item-value", ".c3-legend-item-value2"];
      var fill = ["url(#legend-stripe)", getRGB("grey")];
      if (inverse) {
        legendsPath.reverse();
      }
      legendsPath.forEach(function (l, i) {
        var currentLegend = svg.select(l);
        var x = 0;
        var y = 0;
        if (!currentLegend.empty()) {
          if (!currentLegend.select("line").empty()) {
            x = parseFloat(currentLegend.select("line").attr("x1"));
            y = parseFloat(currentLegend.select("line").attr("y1"));
          }
        }

        if (!currentLegend.select("line").empty()) {
          currentLegend.select("line").remove();
        }
        currentLegend
          .append("rect")
          .attr("x", x)
          .attr("y", y - 4)
          .attr("width", 10)
          .attr("height", 10)
          .attr("style", "fill: #000; stroke: #000; opacity: 1;");
        currentLegend
          .append("rect")
          .attr("x", x)
          .attr("y", y - 4)
          .attr("width", 10)
          .attr("height", 10)
          .attr("style", "fill: " + fill[i] + "; opacity: 1;");
      });
    } catch (err) {}
  }

  function attachStripes(idGraph, inverse) {
    var colors: string[] = ["green", "yellow", "orange", "red"];

    // Seleciona svg relacionado ao grafo a ser alterado
    var div = d3.select(idGraph);
    var svg = div.select("svg");

    // Gerar padrões svg
    var defs = svg.select("#defs");
    if (defs.empty() === true) {
      svg.select("defs").remove();
      defs = svg.append("defs").attr("id", "#defs");
      colors.forEach(function (c) {
        generatePatterns(defs, c, false);
      });
    }

    // Variáveis de caminhos
    var colorPaths: any[] = [];
    var colorPathsOther: any[] = [];
    var pathsStyleOne: string[] = [];
    var pathsStyleTwo: string[] = [];
    var pathsStyleThree: string[] = [];

    colors.forEach(function (c) {
      pathsStyleOne.push(
        "path[style='fill: " + getRGB(c) + "; opacity: 1; stroke: " + getRGB(c) + ";']"
      );
      pathsStyleTwo.push(
        "path[style='stroke: " + getRGB(c) + "; fill: " + getRGB(c) + "; opacity: 1;']"
      );
      pathsStyleThree.push(
        "path[style='fill: " + getRGB(c) + "; stroke: " + getRGB(c) + "; opacity: 1;']"
      );
    });

    var paths: any[] = [pathsStyleOne, pathsStyleTwo, pathsStyleThree];

    var flag: boolean = false;
    for (let p of paths) {
      var tempPaths: any[] = [];
      var otherPaths: any[] = [];
      p.forEach(function (style) {
        var colorElement;
        var otherBar;
        if (inverse) {
          colorElement = svg.select("g.c3-bars-value2").selectAll(style);
          otherBar = svg.select("g.c3-bars-value").selectAll(style);
        } else {
          colorElement = svg.select("g.c3-bars-value").selectAll(style);
          otherBar = svg.select("g.c3-bars-value2").selectAll(style);
        }

        if (colorElement.empty()) {
          tempPaths.push(null);
          otherPaths.push(null);
        } else {
          tempPaths.push(colorElement);
          otherPaths.push(otherBar);
        }
      });
      tempPaths.forEach(function (temp) {
        if (temp != null) {
          flag = true;
        }
      });
      if (flag) {
        tempPaths.forEach(function (c) {
          colorPaths.push(c);
        });
        otherPaths.forEach(function (c) {
          colorPathsOther.push(c);
        });
        break;
      } else {
        tempPaths = [];
        otherPaths = [];
      }
    }

    colorPaths.forEach(function (color, i) {
      if (color != null) {
        color.attr("style", "fill: url(#" + colors[i] + "-stripe); stroke: rgb(0, 0, 0);");
      }
    });
    colorPathsOther.forEach(function (color, i) {
      if (color != null) {
        color.attr("style", "fill: " + getRGB(colors[i]) + "; stroke: rgb(0, 0, 0); opacity: 1;");
      }
    });

    // Adição de rachuras negras
    var pathDescriptions: any[] = [];
    var colorBars;
    if (inverse) {
      colorBars = svg.select(".c3-bars-value2");
    } else {
      colorBars = svg.select(".c3-bars-value");
    }

    colorBars.selectAll("path").each(function (c) {
      var tempPath = d3.select(this);
      var description = {
        classPath: tempPath.attr("class"),
        dPath: tempPath.attr("d"),
        stylePath: tempPath.attr("style"),
      };
      pathDescriptions.push(description);
    });
    colorBars.selectAll("path").remove();

    pathDescriptions.forEach(function (pd) {
      colorBars
        .append("path")
        .attr("class", pd.classPath)
        .attr("d", pd.dPath)
        .attr("style", "fill: #000; stroke: #000; opacity: 1;");
      colorBars
        .append("path")
        .attr("class", pd.classPath)
        .attr("d", pd.dPath)
        .attr("style", pd.stylePath);
    });
  }

  function generatePatterns(defs, color, inverse) {
    var dictColors = {};
    dictColors = {
      "9EE360": "green",
      "FDE73A": "yellow",
      "FAB13D": "orange",
      "F94D3F": "red"
    }
    var idName: string = "";
    var legendClassName: string = "";
    var rotate: string = "rotate(45)";
    idName = color + "-stripe";
    legendClassName = "stripe-legend";
    if (inverse) {
      legendClassName = legendClassName + "-inverse";
      idName = idName + "-inverse";
      rotate = "rotate(135)";
    }

    var pattern = defs
      .append("pattern")
      .attr("id", idName)
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 4.5)
      .attr("height", 4.5)
      .attr("patternTransform", rotate);

    pattern
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 4.5)
      .attr("stroke", getRGB(color))
      .attr("stroke-width", 7)
      .attr("opacity", 1);

    var pattern = defs
      .append("pattern")
      .attr("id", "legend-stripe")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 4.5)
      .attr("height", 4.5)
      .attr("patternTransform", "rotate(45)");

    pattern
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 4.5)
      .attr("stroke", getRGB("grey"))
      .attr("stroke-width", 7);
  }

  // Evento Tooltip - Ranking de oportunidade econômica / Ranking de risco socioambiental
  function addTooltipFeaturesByIndice(dataIndOportunidade, dataIndRisco) {
    ctrl.dataIndOportunidade = dataIndOportunidade.data.json;
    ctrl.dataIndRisco = dataIndRisco.data.json;

    dataIndOportunidade.tooltip = {
      format: {
        show: getStatusToolTip(true),
        title: function (d) {
          return getValueTextTooltip(ctrl.dataIndOportunidade, d);
        },
      },
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        var $$ = this,
          config = $$.config,
          titleFormat = config.tooltip_format_title || defaultTitleFormat,
          nameFormat =
            config.tooltip_format_name ||
            function (name) {
              return name;
            },
          valueFormat = config.tooltip_format_value || defaultValueFormat,
          text,
          i,
          title,
          value,
          name,
          bgcolor;

        var tooltipSortFunction = this.getTooltipSortFunction();
        if (tooltipSortFunction) {
          d.sort(tooltipSortFunction);
        }

        for (i = 0; i < d.length; i++) {
          if (!(d[i] && (d[i].value || d[i].value === 0))) {
            continue;
          }

          if (!text) {
            title = $$.sanitise(titleFormat ? titleFormat(d[i].x) : d[i].x);
            text =
              "<table class='" +
              $$.CLASS.tooltip +
              "'>" +
              (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
          }

          value = $$.sanitise(valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index, d));
          if (value !== undefined) {
            // Skip elements when their name is set to null
            if (d[i].name === null) {
              continue;
            }
            name = $$.sanitise(nameFormat(d[i].name, d[i].ratio, d[i].id, d[i].index));
            bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

            text +=
              "<tr class='" +
              $$.CLASS.tooltipName +
              "-" +
              $$.getTargetSelectorSuffix(d[i].id) +
              "'>";
            if (i == 1) {
              text +=
                "<td class='name'><span style='background-color: " +
                AppService.getSystemColors().report.legendaRachurada.rgb +
                "'></span>" +
                name +
                "</td>";
            } else {
              text +=
                `<td class='name'>
                <span style='
                  background-image: linear-gradient(135deg, ${
                    AppService.getSystemColors().report.legendaRachurada.rgb
                  } 41.67%, #000000 41.67%, #000000 50%, ${
                  AppService.getSystemColors().report.legendaRachurada.rgb
                } 50%, ${
                  AppService.getSystemColors().report.legendaRachurada.rgb
                } 91.67%, #000000 91.67%, #000000 100%);
                  background-size: 8.49px 8.49px;
                '>` +
                "</span>" +
                name +
                "</td>";
            }

            text += "<td class='value'>" + value + "</td>";
            text += "</tr>";
          }
        }
        return text + "</table>";
      },
    };

    dataIndRisco.tooltip = {
      format: {
        show: getStatusToolTip(true),
        title: function (d) {
          return getValueTextTooltip(ctrl.dataIndRisco, d);
        },
      },
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        var $$ = this,
          config = $$.config,
          titleFormat = config.tooltip_format_title || defaultTitleFormat,
          nameFormat =
            config.tooltip_format_name ||
            function (name) {
              return name;
            },
          valueFormat = config.tooltip_format_value || defaultValueFormat,
          text,
          i,
          title,
          value,
          name,
          bgcolor;

        var tooltipSortFunction = this.getTooltipSortFunction();
        if (tooltipSortFunction) {
          d.sort(tooltipSortFunction);
        }

        for (i = 0; i < d.length; i++) {
          if (!(d[i] && (d[i].value || d[i].value === 0))) {
            continue;
          }

          if (!text) {
            title = $$.sanitise(titleFormat ? titleFormat(d[i].x) : d[i].x);
            text =
              "<table class='" +
              $$.CLASS.tooltip +
              "'>" +
              (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
          }

          value = $$.sanitise(valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index, d));
          if (value !== undefined) {
            // Skip elements when their name is set to null
            if (d[i].name === null) {
              continue;
            }
            name = $$.sanitise(nameFormat(d[i].name, d[i].ratio, d[i].id, d[i].index));
            bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

            text +=
              "<tr class='" +
              $$.CLASS.tooltipName +
              "-" +
              $$.getTargetSelectorSuffix(d[i].id) +
              "'>";
            if (i == 0) {
              text +=
                "<td class='name'><span style='background-color: " +
                AppService.getSystemColors().report.legendaRachurada.rgb +
                "'></span>" +
                name +
                "</td>";
            } else {
              text +=
                `<td class='name'>
                <span style='
                  background-image: linear-gradient(135deg, ${
                    AppService.getSystemColors().report.legendaRachurada.rgb
                  } 41.67%, #000000 41.67%, #000000 50%, ${
                  AppService.getSystemColors().report.legendaRachurada.rgb
                } 50%, ${
                  AppService.getSystemColors().report.legendaRachurada.rgb
                } 91.67%, #000000 91.67%, #000000 100%);
                  background-size: 8.49px 8.49px;
                '>` +
                "</span>" +
                name +
                "</td>";
            }

            text += "<td class='value'>" + value + "</td>";
            text += "</tr>";
          }
        }
        return text + "</table>";
      },
    };
  }

  // Inicializa relatório
  // Gráfico de Radar dos Indíces dos eixos de oportunidade econômica e risco socioambiental
  function initScatterPolar(features, forceGridInit) {
    var isGrid = StrategyService.getUnitPlanningActive().id === 2;
    if (Boolean(isGrid) && Boolean(forceGridInit) == false) {
      angular.element("#selectScatterPolarByCity option:eq(1)").attr("selected", "selected");
      angular.element("#selectScatterPolarByCity option:eq(0)").attr("selected", "selected");
    } else {
      angular.element("#selectScatterPolarByFeature option:eq(1)").attr("selected", "selected");
      if (angular.element("#selectScatterPolarByFeature option:eq(0)")[0].label == "") {
        angular.element("#selectScatterPolarByFeature option:eq(0)").remove();
      }
      angular.element("#selectScatterPolarByFeature option:eq(0)").attr("selected", "selected");
      var inputFeature = angular.element("#selectScatterPolarByFeature option:eq(0)")[0];
      if (inputFeature) {
        var featureName = inputFeature.label;
        var featureInit = features.filter(function (feature) {
          var descriptionFeature = getDescriptionFeature(feature);
          return descriptionFeature == featureName;
        })[0];
      }
      if (angular.isDefined(featureInit)) {
        ReportService.generateDataScatterPolarByFeature(featureInit);
      }
    }
  }

  function getGrids(features) {
    var deferred = $q.defer();
    var intervalLoadElement = setInterval(function () {
      deferred.resolve(features);
      clearInterval(intervalLoadElement);
    }, 100);
    return deferred.promise;
  }

  function isParaguaiReport() {
    return AppService.getActiveCountry() == "paraguai";
  }

  // Inicializa gráfico
  // Gráfico de Radar dos Indíces dos eixos de oportunidade econômica e risco socioambiental
  function initPie() {
    var reportData = ReportService.getReportData();
    if (isParaguaiReport()) {
      newPlotPie(
        "graphicPieEcorregioes",
        reportData.dataChartsEcorregioes,
        reportData.layoutPie,
        reportData.optionsCharts
      );
    } else {
      newPlotPie(
        "graphicPieBiomes",
        reportData.dataChartsBiomes,
        reportData.layoutPie,
        reportData.optionsCharts
      );
    }

    newPlotPie(
      "graphicPieStates",
      reportData.dataChartsStates,
      reportData.layoutPie,
      reportData.optionsCharts
    );
  }

  // Força reload dos gráficos
  // Gráfico de Radar dos Indíces dos eixos de oportunidade econômica e risco socioambiental
  function reloadDataUrlPie() {
    angular.element("#graphicPieBiomes").mouseleave(function () {
      var reportData = ReportService.getReportData();
      newPlotPie(
        "graphicPieBiomes",
        reportData.dataChartsBiomes,
        reportData.layoutPie,
        reportData.optionsCharts
      );
    });

    angular.element("#graphicPieStates").mouseleave(function () {
      var reportData = ReportService.getReportData();
      newPlotPie(
        "graphicPieStates",
        reportData.dataChartsStates,
        reportData.layoutPie,
        reportData.optionsCharts
      );
    });

    angular.element("#graphicPieEcorregioes").mouseleave(function () {
      var reportData = ReportService.getReportData();
      newPlotPie(
        "graphicPieEcorregioes",
        reportData.dataChartsEcorregioes,
        reportData.layoutPie,
        reportData.optionsCharts
      );
    });
  }

  // Datas
  // Ranking de oportunidade econômica / Ranking de risco socioambiental
  function getDataFeaturesByIndice(
    bindtoElem,
    title,
    label,
    label_2,
    mainIndice,
    subIndice,
    isGrid
  ) {
    var layoutBar = AppService.getBarChartsRankingIndiceLayout();
    var features = MapService.getFeaturesSelectionAreaInstance();
    var data = [] as any[];
    var obj = {};
    var title = MainService.getTextByKeyI18n(title);
    var axisYValues = ["feature", "value", "value2"];
    var axisYColors = [layoutBar.colors[mainIndice], layoutBar.colors[subIndice]];
    var labelAxisY = MainService.getTextByKeyI18n(label);
    var labelAxisY_2 = MainService.getTextByKeyI18n(label_2);
    var sizeByLine = layoutBar.sizeByLine; // Tamanho de cada linha do gráfico
    var ratioBar = layoutBar.ratioBar;
    var padding = layoutBar.padding;
    padding.left = 0;
    features.forEach(function (feature) {
      if (feature[mainIndice] >= 0) {
        var name = getDescriptionFeature(feature);
        if (name && name.length > padding.left) {
          padding.left = name.length;
        }
        obj = {
          feature: name,
          tooltipText: getToolTipText(feature),
          value: feature[mainIndice],
          value2: feature[subIndice],
          color: getColorFeature(feature),
        };
        data.push(obj);
      }
    });
    return getLayoutHorizontalBar(
      data,
      bindtoElem,
      title.toUpperCase(),
      axisYValues,
      axisYColors,
      labelAxisY,
      labelAxisY_2,
      isGrid,
      0,
      1,
      ratioBar,
      padding,
      sizeByLine,
      false,
      true,
      layoutBar.maxResults
    );
  }

  function getColorFeature(feature) {
    var OpportunityClass = feature.classeOportunidade;
    var riskClass = feature.classeRisco;
    var color: String = "";
    if (OpportunityClass === "alto" && riskClass === "baixo") {
      color = getRGB("green");
    } else if (OpportunityClass === "baixo" && riskClass === "baixo") {
      color = getRGB("yellow");
    } else if (OpportunityClass === "alto" && riskClass === "alto") {
      color = getRGB("orange");
    } else {
      color = getRGB("red");
    }

    return color;
  }

  // Comparativo regional
  function getDataFeaturesByIndicator(bindtoElem, title, indicator, isGrid): ChartConfiguration {
    var layoutBar = AppService.getBarChartsRegionalComparativeLayout();
    var features = MapService.getFeaturesSelectionAreaInstance();
    var data = [] as any[];
    var obj = {};
    var axisYValues = ["feature", "value"];
    var axisYColors = layoutBar.colors;
    var labelAxisY = title;
    var sizeByLine = layoutBar.sizeByLine; // Tamanho de cada linha do gráfico
    var hasPadding = true,
      hasLegend = false;
    var ratioBar = layoutBar.ratioBar;
    var padding = layoutBar.padding;
    padding.left = 0;

    features.forEach(function (feature) {
      feature.getProperties().indicators.some(function (indicatorFeature) {
        if (indicatorFeature.name == indicator.name) {
          var name = getDescriptionFeature(feature);
          if (name && name.length > padding.left) {
            padding.left = name.length;
          }
          obj = {
            feature: name,
            tooltipText: getToolTipText(feature),
            value: indicatorFeature["value"],
            color: layoutBar.colors,
          };
          data.push(obj);
          return true;
        }
      });
    });
    return getLayoutHorizontalBar(
      data,
      bindtoElem,
      title,
      axisYValues,
      axisYColors,
      labelAxisY,
      "",
      isGrid,
      indicator.minValue,
      indicator.maxValue,
      ratioBar,
      padding,
      sizeByLine,
      hasPadding,
      hasLegend,
      layoutBar.maxResults
    );
  }

  // Structure
  function getLayoutHorizontalBar(
    data,
    bindtoElem,
    title,
    axisYValues,
    axisYColors,
    labelAxisY,
    labelAxisY_2,
    isGrid,
    minValue,
    maxValue,
    ratioBar,
    padding,
    sizeByLine,
    hasPadding,
    hasLegend,
    maxResults
  ): ChartConfiguration {
    data = MainService.sortDescArrayObjectByKey(data, "value", maxResults || 0);
    var isRotated = true,
      isVisible = false;
    return {
      bindto: bindtoElem,
      size: getSize(data.length, sizeByLine),
      data: getDataByColumn(data, axisYValues, "bar", labelAxisY, labelAxisY_2),
      axis: getAxis(
        isRotated,
        minValue,
        maxValue,
        labelAxisY,
        MainService.getTextByKeyI18n("reportcharts.latlong"),
        isGrid,
        hasPadding
      ),
      bar: getRatioBar(ratioBar),
      color: getColor(axisYColors),
      legend: getLegend(hasLegend, "right"), //legenda oculta
      title: getTitle(title),
      padding: getPadding(padding),
      tooltip: getStatusToolTip(isVisible),
      onrendered: function () {
        setTimeout(function () {
          attachStripes("#featuresByIndiceOportunidade", false);
          attachStripes("#featuresByIndiceRisco", true);
        }, 1000);
      },
    };
  }

  function getSize(length, sizeByLine) {
    var sizeStructure = 107; // Tamanho referente a estrutura da tabela (header e footer)
    return {
      height: length * sizeByLine + sizeStructure,
    };
  }

  function getDataByColumn(data, arrayAxisY, typeChart, label, label_2) {
    return {
      x: "feature",
      y: "value",
      labels: {
        format: function (value) {
          return formatToFixed(value, 2);
        },
      },
      json: data,
      keys: {
        value: arrayAxisY,
      },
      names: {
        value: label,
        value2: label_2,
      },
      type: typeChart,
      color: function (color, d) {
        var instance = data[d.x];
        var correctColor = "";
        if (instance != undefined) {
          correctColor = instance.color;
        }
        return correctColor !== "" ? correctColor : color;
      },
    };
  }

  function getAxis(
    isRotated,
    minValue,
    maxValue,
    labelAxisY,
    labelAxisX,
    isGrid,
    hasPadding
  ): ChartConfiguration["axis"] {
    return {
      rotated: isRotated,
      x: {
        label: getLabel(isGrid, labelAxisX, "outer-middle") as any,
        type: "category",
        tick: {
          multiline: false,
        },
      },
      y: {
        label: getLabel(true, labelAxisY, "outer-center") as any,
        max: hasPadding ? maxValue * 1.11 : maxValue,
        min: 0,
        padding: {
          top: 0,
          bottom: 0,
        },
        tick: {
          format: function (value) {
            return formatToLocaleString(value);
          },
        },
      },
    };
  }

  function getLabel(isVisible, text, position) {
    return isVisible ? { text: text, position: position } : {};
  }

  function getLegend(isVisible, position) {
    return {
      position: position,
      show: isVisible,
      item: {
        onclick: function () {},
      },
    };
  }

  function getTitle(title) {
    return {
      text: title,
    };
  }

  function getColor(colors) {
    return {
      pattern: colors,
    };
  }

  function getRatioBar(value) {
    return {
      width: {
        ratio: value,
      },
    };
  }

  function getPadding(padding) {
    var valueFixed = 30;
    return {
      top: 20,
      bottom: 20,
      right: padding.right,
      left: padding.left * 7 + valueFixed,
    };
  }

  function getStatusToolTip(isVisible) {
    return {
      show: isVisible,
    };
  }

  function getValueTextTooltip(data, index) {
    return data[index].tooltipText;
  }

  function getFeatureGridCity(feature) {
    if (angular.isDefined(feature.getProperties().code)) {
      return feature.getProperties().city + " - " + feature.getProperties().state;
    } else {
      return "";
    }
  }

  function getDescriptionFeature(feature) {
    if (angular.isDefined(feature.getProperties().name)) {
      return feature.getProperties().name + " - " + feature.getProperties().uf;
    } else if (angular.isDefined(feature.getProperties().code)) {
      return feature.getProperties().code.toString();
      //return (
      //formatToFixed(feature.getProperties().centroid_y, 3) +
      //" / " +
      //formatToFixed(feature.getProperties().centroid_x, 3)
      //);
    }
  }

  //function getCodeFeature(feature) {
  //if (angular.isDefined(feature.getProperties().name)) {
  //return feature.getProperties().name + " - " + feature.getProperties().uf;
  //} else if (angular.isDefined(feature.getProperties().code)) {
  //return formatToFixed(feature.getProperties().code, 3);
  //}
  //}

  function getToolTipText(feature) {
    return getFeatureGridCity(feature);
  }

  function formatToFixed(value, toFixed) {
    return formatToLocaleString(parseFloat(Number(value).toFixed(toFixed)));
  }

  function formatToLocaleString(value) {
    var locale = MainService.getLocaleLanguageSystem();
    return value.toLocaleString(locale);
  }

  function convertSvgToDataURL(idChart) {
    var deferred = $q.defer();
    var svgChart = angular.element("#" + idChart + " > svg");

    //svgChart.find("defs").remove();
    svgChart.find("g.c3-grid").remove();
    svgChart.find("*").each(function (e) {
      if (Number(d3.select(this).style("fill-opacity")) == 0) d3.select(this).style("opacity", 0);
      d3.select(this).style("fill", d3.select(this).style("fill"));
      d3.select(this).style("stroke", d3.select(this).style("stroke"));
    });

    svgChart.find("image").each(function (e) {
      var imageWidth: number = +d3.select(this).attr("width");
      var imageHeight: number = +d3.select(this).attr("height");

      d3.select(this)
        .attr("width", imageWidth * 0.9)
        .attr("height", imageHeight * 0.9);
    });

    svgChart.find("text").each(function (e) {
      var fontSize = d3.select(this).style("font-size").substring(0, 2);
      var fontCalc;
      var isChrome = !!window.chrome && !!window.chrome.webstore;
      if (isChrome) {
        fontCalc = 2;
      } else {
        fontCalc = 3;
      }
      d3.select(this).style("font-size", Number(fontSize) - fontCalc + 2.5 + "px");
      d3.select(this).style("font-family", "Caecilia");
    });

    // html2canvas does not recognize dy
    svgChart.find("tspan").each(function (e) {
      // convert em to px
      if (
        d3.select(this).attr("dy").indexOf("em") !== -1 &&
        d3.select(this).style("font-size").indexOf("px") !== -1
      ) {
        d3.select(this).attr(
          "dy",
          // prettier-ignore
          Number(d3.select(this).attr("dy").replace("em", "")) *
          Number(d3.select(this).style("font-size").replace("px", ""))
        );
      }
      if (Number(d3.select(this).attr("dy")) != 0) {
        d3.select(this.parentNode!).attr(
          "y",
          Number(d3.select(this.parentNode!).attr("y")) + Number(d3.select(this).attr("dy"))
        );
        d3.select(this).attr("dy", 0);
      }
    });

    var scaleBy = 2;
    var canvasCharts = document.createElement("canvas")!;
    canvasCharts.width = scaleBy * svgChart.width()!;
    canvasCharts.height = scaleBy * svgChart.height()!;
    canvasCharts.style.width = svgChart.width()! + "px";
    canvasCharts.style.height = svgChart.height()! + "px";

    var svgString = new XMLSerializer().serializeToString(svgChart[0]);

    var ctx = canvasCharts.getContext("2d")!;
    ctx.scale(scaleBy, scaleBy);
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, svgChart.width()!, svgChart.height()!);
    var DOMURL = self.URL || self.webkitURL || self;
    var img = new Image();
    var svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    var url = DOMURL.createObjectURL(svg);
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      var dataURL = canvasCharts.toDataURL("image/png");
      deferred.resolve(dataURL);
    };
    img.src = url;
    return deferred.promise;
  }

  function createElementRect(width, height, x, y, fill, stroke?, strokeWidth?) {
    var svgns = "http://www.w3.org/2000/svg";
    var rect = document.createElementNS(svgns, "rect");
    if (angular.isDefined(x)) {
      rect.setAttributeNS(null, "x", x);
    }
    if (angular.isDefined(y)) {
      rect.setAttributeNS(null, "y", y);
    }
    if (angular.isDefined(width)) {
      rect.setAttributeNS(null, "width", width);
    }
    if (angular.isDefined(height)) {
      rect.setAttributeNS(null, "height", height);
    }
    if (angular.isDefined(fill)) {
      rect.setAttributeNS(null, "fill", fill);
    }
    if (angular.isDefined(stroke)) {
      rect.setAttributeNS(null, "stroke", stroke);
    }
    if (angular.isDefined(strokeWidth)) {
      rect.setAttributeNS(null, "stroke-width", strokeWidth);
    }
    return rect;
  }

  function addEventsResizeC3Charts() {
    addRectInTitle();
    updateElementLabel();
    resizeTitleChartsEvent();
    reAttachStripes();
    reAttachLegends();
    reAdjustSvg();
  }

  function addRectInTitle() {
    var widthTitle = angular.element(".c3 > svg").width();
    angular.element(".c3-title").each(function (i) {
      var rect = createElementRect(widthTitle, 34, 0, -3, "#8e8e8e");
      angular.element(this).attr("y", 22); // centralizar verticalmente titulo no element rect do svg
      angular.element(this).before(rect);
    });
  }

  function updateElementLabel() {
    angular.element(".c3-axis-x-label").each(function (i) {
      var heightSvg = angular.element(".c3 > svg").height()!;
      var textElement = angular.element(".c3-axis-x-label")[i];
      if (heightSvg < 150) {
        textElement.setAttributeNS(null, "x", "-20");
      }
      textElement.setAttributeNS(null, "dy", "-115");
    });
  }

  function resizeTitleChartsEvent() {
    window.addEventListener("resize", function (event) {
      setTimeout(function () {
        var widthTitle = angular.element(".c3 > svg").width();
        angular.element(".c3 > svg > rect").each(function (i) {
          angular.element(this)[0].setAttributeNS(null, "width", widthTitle as any);
        });
        updateElementLabel();
        addRectInTitle();
      }, 1000);
    });
  }

  function reAttachStripes() {
    window.addEventListener("resize", function (event) {
      setTimeout(function () {
        attachStripes("#featuresByIndiceOportunidade", false);
        attachStripes("#featuresByIndiceRisco", true);
      }, 1000);
    });
  }

  function reAttachLegends() {
    window.addEventListener("resize", function (event) {
      setTimeout(function () {
        attachLegends("#featuresByIndiceOportunidade", false);
        attachLegends("#featuresByIndiceRisco", true);
      }, 1000);
      setTimeout(function () {}, 1500);
    });
  }

  function reAdjustSvg() {
    window.addEventListener("resize", function (event) {
      setTimeout(function () {
        adjustGraphPosition("#featuresByIndiceOportunidade");
        adjustGraphPosition("#featuresByIndiceRisco");
      }, 1000);
    });
  }
  // c3charts end //

  // plotly.js //
  ctrl.plotlyCharts = [];

  function getDataPie(list, type) {
    // Mudar a precisão das porcentagens para duas casas decimais.
    // Evita alguns casos onde a soma das porcentagens dá 100.01% ou 99.99%.
    list.forEach(function (value) {
      value.percentage = Math.round(parseFloat(value.percentage) * 100) / 100;
    });

    var totalPie = 0;
    var othersPercentage: number = 0;
    var othersExist: boolean = false;
    var othersIndex: number = 0;
    list.forEach(function (legends, i) {
      // Verifica se já existe um valor pra Outros no gráfico
      if (legends.name === "report.outros") {
        othersExist = true;
        othersIndex = i;
      }
      totalPie = totalPie + legends.percentage;
    });
    if (totalPie < 100) {
      othersPercentage = 100 - totalPie;
      othersPercentage = Math.round(othersPercentage * 100) / 100;
    }

    // Garante que nenhum "Outro" com valor 0 será adicionado ao gráfico.
    if (Math.floor(othersPercentage * 100) > 0) {
      if (othersExist) {
        list[othersIndex].percentage = list[othersIndex].percentage + othersPercentage;
      } else {
        var others = { name: "report.outros", percentage: othersPercentage };
        list.push(others);
      }
    }

    totalPie = 0;
    list.forEach(function (legends) {
      totalPie = totalPie + legends.percentage;
    });

    // Caso a soma das porcentagens dê 100.01 remove-se 0.01
    if (totalPie > 100) {
      list[list.length - 1].percentage = list[list.length - 1].percentage - 0.01;
    }
    // Caso a soma das porcentagens dê 99.99 adiciona-se 0.01
    else if (totalPie < 100) {
      list[list.length - 1].percentage = list[list.length - 1].percentage + 0.01;
    }

    var ReportService = $injector.get("ReportService");
    var layout = getPieChartsLayout();
    var data = {
      marker: {
        colors: layout[type].colors,
      },
      hoverinfo: "label",
      textposition: "none",
      hole: layout.sizeHole,
      type: "pie",
      sort: false,
      domain: {
        x: [0, 0.6],
      },
      labels: [] as any,
      values: [] as any,
    };
    list.forEach(function (element) {
      data.labels.push(
        ReportService.getLabelProtectedAreas(element.name) +
          " <b>" +
          formatToFixed(element.percentage, 2) +
          "%</b>   "
      );
      data.values.push(parseFloat(element.percentage).toFixed(2));
    });
    return [data];
  }

  function getLayoutPie() {
    return {
      autosize: true,
      legend: {
        orientation: "v",
        y: 0.5, // vertical position
        x: 0.65, // horizontal position
        traceorder: "normal",
        bgcolor: "transparent",
      },
      font: {
        family: "TodaySHOP",
        size: 16,
        color: "#000",
      },
      margin: {
        t: 35,
        l: 60,
        r: 180,
        b: 30,
      },
      paper_bgcolor: "#eeeeee",
    };
  }

  function newPlotPie(div, data, layout, options) {
    var reportData = ReportService.getReportData();
    Plotly.purge(div);
    Plotly.newPlot(div, data, layout, options).then(function (gd) {
      Plotly.toImage(gd, { width: 800, height: 350, scale: 1.2 }).then(function (url) {
        if (gd.id == "graphicPieBiomes") {
          reportData.dataUrlPieBiomes = url;
        } else if (gd.id == "graphicPieStates") {
          reportData.dataUrlPieStates = url;
        } else if (gd.id == "graphicPieEcorregioes") {
          reportData.dataUrlPieEcorregions = url;
        }
        registerPlotChart(gd.id);
      });
    });
  }

  function getLayoutHistogram() {
    return {
      width: 550,
      height: 300,
      margin: {
        t: 30,
        b: 30,
      },
      font: {
        family: "TodaySHOP",
        size: 12,
        color: "#000",
      },
      xaxis: {
        title: MainService.getTextByKeyI18n("reportcharts.quantidadesdevaloresnoindicador"),
      },
      yaxis: {
        title: MainService.getTextByKeyI18n("reportcharts.quantidadesdevaloresnointervalo"),
      },
    };
  }

  function getDataHistogram(indicator) {
    var data = {
      x: indicator.values,
      type: "histogram",
    };

    return [data];
  }

  function newPlotHistogram(div, data, layout) {
    Plotly.newPlot(div, data, layout);
  }

  function getDataScatterPolar(feature) {
    var indicesByIndicators = MapService.getIndiceIndicadorsByFeature(feature);
    var layout = AppService.getScatterPolarIndicesByIndicator();
    var features = MapService.getFeaturesSelectionAreaInstance() || [];
    var indicatorsActive = IndicatorService.getIndicatorsActive();

    var data = {
      type: "scatterpolar",
      marker: {
        symbol: layout.marker.point.symbol || "circle", // data marker type
        size: layout.marker.point.size || 15,
        color: layout.marker.point.color || AppService.getSystemColors().report.radarIndicador.hex, // data marker color
      },
      line: {
        color: layout.marker.line.color || AppService.getSystemColors().report.radarLinhaPontos.hex, // data color line
        width: layout.marker.line.width || 6,
      },
      fill: "none",
      hoverinfo: "text",
      theta: [] as any[],
      text: [] as any[],
      r: [] as any[],
    };
    indicesByIndicators.forEach(function (indicatorFeature) {
      if (isNaN(indicatorFeature.indice)) {
        indicatorsActive.forEach(function (indicator) {
          if (indicatorFeature.name == indicator.name) {
            MapService.calcIndiceIndicadorByFeatures(indicator, features);
          }
        });
      }
      var indicator = IndicatorService.getIndicatorByName(indicatorFeature.name);
      var labelWrap = MainService.wordWrap(
        IndicatorService.getLabelAndUnitIndicatorByLocale(indicator),
        32
      ).join("<br>");
      data.theta.push(labelWrap);
      data.text.push(labelWrap + "<br>" + formatToFixed(indicatorFeature.indice, 2));
      data.r.push(parseFloat(indicatorFeature.indice).toFixed(2));
    });
    //close polygon
    data.theta.push(data.theta[0]);
    data.text.push(data.text[0]);
    data.r.push(data.r[0]);
    return [data];
  }

  function getLayoutScatterPolar(title, maxRange) {
    var layout = AppService.getScatterPolarIndicesByIndicator();
    return {
      annotations: [
        {
          visible: true,
          clicktoshow: false,
          showarrow: false,
          text:
            "<b><br>" +
            title +
            "</b><br>" +
            MainService.getTextByKeyI18n("reportcharts.oportunidadeeconomicaeriscosocioambiental"),
          align: layout.font.title.align || "left",
          x: layout.font.title.position.x || 0, // horizontal align
          y: layout.font.title.position.y || 1.25, // vertical align
          font: {
            family: layout.font.title.family || "TodaySHOP;",
            size: layout.font.title.size || 24,
            color: layout.font.title.color || "#6e6e6e",
          },
        },
      ],
      showlegend: false,
      autosize: true,
      height: 750,
      width: 1024 * 1.2,
      polar: {
        radialaxis: {
          visible: true,
          range: [0, maxRange * 1.1], // Adiciona mais um range
          angle: 90,
          gridcolor:
            layout.axis.grid.color || AppService.getSystemColors().report.radarLinhaGrade.hex, // color inside gridlines
          gridwidth: layout.axis.grid.width || 2, // width inside gridlines
          showline: false,
          tickfont: {
            size: layout.font.chart.size || 13,
            color: layout.font.chart.color || "#6e6e6e",
          },
          tickcolor: "#6e6e6e", // label tick anglelines
          ticklen: 10,
          tickwidth: 0,
          ticks: "outside",
          tickangle: 90,
        },
        angularaxis: {
          gridcolor:
            layout.axis.line.color || AppService.getSystemColors().report.radarIndicador.hex, //"#839bcb", // label anglelines
          gridwidth: layout.axis.line.width || 2,
          showline: false,
          ticklen: 30,
          ticks: "outside",
          tickcolor: layout.axis.lineOutside.color || "#ffffff",
          rotation: 40,
        },
      },
      font: {
        family: layout.font.chart.family || "TodaySHOP;",
        size: layout.font.chart.size || 13,
        color: layout.font.chart.color || "#6e6e6e",
      },
      margin: {
        t: 165,
        pad: 50,
      },
    };
  }

  function newPlotScatterPolar(div, data, layout, options) {
    var reportData = ReportService.getReportData();
    Plotly.newPlot(div, data, layout, options).then(function (gd) {
      registerPlotChart(div);
      Plotly.toImage(gd, { width: 1024, height: 768, scale: 2 }).then(function (url) {
        reportData.dataUrlScatterPolar = url;
      });
    });
  }

  function getPieChartsLayout() {
    return AppService.getPieChartsLayout();
  }

  function registerPlotChart(div) {
    var chartEl = d3.select("#" + div).style({
      width: "100%",
    });
    var overwriteChart = ctrl.plotlyCharts.filter(function (chart) {
      if (chart === null) {
        return false;
      } else {
        return chart.id == div;
      }
    });
    if (overwriteChart.length === 0) {
      ctrl.plotlyCharts.push(chartEl.node());
    }
    autoResizePlotCharts();
  }

  function clearListPlotCharts() {
    ctrl.plotlyCharts = [];
  }

  function autoResizePlotCharts() {
    window.onresize = function () {
      ctrl.plotlyCharts.forEach(function (chart) {
        Plotly.Plots.resize(chart);
      });
    };
  }

  function downloadDataUrlToPng(url, fileName, logName) {
    if (!angular.isUndefined(logName)) {
      LogService.sendButtonLog(logName, "click", "strategy");
    }

    var link = document.createElement("a");
    link.href = url;
    if (angular.isUndefined(fileName)) {
      fileName = angular.element("#selectScatterPolarByFeature option:selected")[0].label;
    } else {
      fileName = MainService.getTextByKeyI18n(fileName);
    }
    link.download = fileName + ".png";
    document.body.appendChild(link);
    link.click();
  }

  function getRGB(color) {
    var dictColors = {
      green: AppService.getSystemColors().indicator.green.rgb.replace(/,/g, ", "),
      yellow: AppService.getSystemColors().indicator.yellow.rgb.replace(/,/g, ", "),
      orange: AppService.getSystemColors().indicator.orange.rgb.replace(/,/g, ", "),
      red: AppService.getSystemColors().indicator.red.rgb.replace(/,/g, ", "),
      grey: AppService.getSystemColors().report.legendaRachurada.rgb.replace(/,/g, ", "),
    };
    return dictColors[color];
  }

  return {
    initCharts: initCharts,
    initBarByIndicator: initBarByIndicator,
    generateBarByIndicator: generateBarByIndicator,
    generateFeaturesByIndice: generateFeaturesByIndice,
    getDataFeaturesByIndice: getDataFeaturesByIndice,
    getDataFeaturesByIndicator: getDataFeaturesByIndicator,
    getFeatureGridCity: getFeatureGridCity,
    getDescriptionFeature: getDescriptionFeature,
    getStatusToolTip: getStatusToolTip,
    getValueTextTooltip: getValueTextTooltip,
    convertSvgToDataURL: convertSvgToDataURL,
    createElementRect: createElementRect,
    addRectInTitle: addRectInTitle,
    updateElementLabel: updateElementLabel,
    resizeTitleChartsEvent: resizeTitleChartsEvent,
    getLayoutHistogram: getLayoutHistogram,
    getDataPie: getDataPie,
    getLayoutPie: getLayoutPie,
    newPlotPie: newPlotPie,
    getDataHistogram: getDataHistogram,
    newPlotHistogram: newPlotHistogram,
    reloadDataUrlPie: reloadDataUrlPie,
    getDataScatterPolar: getDataScatterPolar,
    getLayoutScatterPolar: getLayoutScatterPolar,
    newPlotScatterPolar: newPlotScatterPolar,
    initScatterPolar: initScatterPolar,
    clearListPlotCharts: clearListPlotCharts,
    downloadDataUrlToPng: downloadDataUrlToPng,
    getGrids: getGrids,
    getRGB: getRGB,
    isParaguaiReport: isParaguaiReport,
    //getCodeFeature: getCodeFeature,
  };
}

// angular
//     .module('reportCharts.service', [])
//     .factory('ReportChartsService', ReportChartsService);

export const ReportChartsServiceModule = new AppModule({
  name: "reportCharts.service",
  factories: { ReportChartsService },
});
