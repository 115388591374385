import { AppModule } from "../module-wrapper";
import { AuthModule } from "./auth/auth.module";
import { ExporterModule } from "./exporter/exporter.module";
import { HttpServiceModule } from "./httpService/httpService.module";
import { LogModule } from "./log/log.module";
import { ModalModule } from "./modal/modal.module";
import { NotificationModule } from "./notification/notification.module";
import { TranslateModule } from "./translate/translate.module";
import { UserModule } from "./user/user.module";

/* @ngInject */
function run($rootScope, $templateCache, $http) {
  $rootScope.$on("$viewContentLoading", function() {
    $http.get("/app/core/views/error.messages.html").then(function(response) {
      $templateCache.put("error-messages", response.data);
    });
  });
}

export const CoreModule = new AppModule({
  name: "core.module",
  runner: run,
  modules: [
    AuthModule,
    ExporterModule,
    HttpServiceModule,
    LogModule,
    ModalModule,
    NotificationModule,
    TranslateModule,
    UserModule
  ]
});

// angular
//   .module("core.module", [
//     "auth.module",
//     "exporter.module",
//     "httpService.module",
//     "log.module",
//     "modal.module",
//     "notification.module",
//     "translate.module",
//     "user.module"
//   ])
//   .run(run);
