import { AppModule } from "../../../module-wrapper";

/* @ngInject */
function UserAreaFiles() {
  return {
    restrict: "E",
    templateUrl: "/app/core/user/userArea/views/user-area.files.html"
  };
}

/* @ngInject */
function UserAreaView() {
  return {
    restrict: "E",
    templateUrl: "/app/core/user/userArea/user-area.view.html"
  };
}

// angular
// 	.module('userArea.directives', [])
// 	.directive('userAreaFiles', UserAreaFiles)
// 	.directive('userAreaView', UserAreaView);
// })();

export const UserAreaDirectivesModule = new AppModule({
  name: "userArea.directives",
  directives: { userAreaFiles: UserAreaFiles, userAreaView: UserAreaView }
});
