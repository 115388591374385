import { AppModule } from "../module-wrapper";
import { saveAs } from "file-saver";

/* @ngInject */
function MainController(
  this: any,
  MainService,
  ReportService,
  $translate,
  LayerService,
  StrategyService,
  MapService,
  AppService
) {
  var ctrl = this;

  ctrl.setVisibilityMenu = function(visibility) {
    MainService.setVisibilityMenu(visibility);
  };

  ctrl.changeLanguage = function(language) {
    ReportService.setReportCachedExportPdf(false);
    MainService.setLanguageSystem(language);
    LayerService.setLanguageLayers(language);
    $translate.use(language);
    MapService.setTypeBaseTileMap();
    StrategyService.resetCollapsesSession();
  };

  ctrl.getLabelByLayout = function() {
    return MainService.getTextByKeyI18n("menu." + AppService.getLayout());
  };

  ctrl.getCurrentLanguage = function() {
    return MainService.getLanguageSystem().name;
  };

  ctrl.getLocaleCurrentLanguage = function() {
    return MainService.getLanguageSystem().locale;
  };

  ctrl.getLanguages = function() {
    ctrl.languages = MainService.getLanguages();
  };
  ctrl.getLanguages();

  ctrl.downloadPdf = function(nameFile) {
    var currentLang = MainService.getLanguageSystem().locale;
    var oReq = new XMLHttpRequest();
    var URLToPDF = "/assets/Manual-" + currentLang + ".pdf";
    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function() {
      var file = new Blob([oReq.response], {
        type: "application/pdf"
      });
      saveAs(file, nameFile + ".pdf");
    };
    oReq.send();
  };
}

export const MainControllerModule = new AppModule({
  name: "main.controller",
  controllers: { MainController }
});

// angular
//     .module('main.controller', [])
//     .controller('MainController', MainController, []);
