import { AppModule } from "../../module-wrapper";

/* @ngInject */
function UserService($resource, AuthService, AppService) {
  var url = AppService.getUrlApi() + "/register";
  var urlChangePassword = AppService.getUrlApi() + "/account/change-password";

  return $resource(
    url,
    { id: "@id" },
    {
      add: {
        method: "POST",
        isArray: false,
        headers: {
          authorization: AuthService.getToken()
        }
      },

      list: {
        method: "GET",
        isArray: false,
        headers: {
          authorization: AuthService.getToken()
        }
      },

      delete: {
        url: url + "/:id",
        method: "DELETE",
        isArray: false,
        headers: {
          authorization: AuthService.getToken()
        }
      },

      update: {
        url: urlChangePassword,
        method: "POST",
        isArray: false
      }
    }
  );
}

export const UserServiceModule = new AppModule({
  name: "user.service",
  factories: { UserService }
});

// angular
// 	.module('user.service', [])
// 	.factory('UserService', UserService);
