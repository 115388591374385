import { LayerService as ILayerService } from "../layer/layers.service";
import angular from "angular";
import { AppModule } from "../../module-wrapper";
import proj4 from "proj4";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";
import TileLayer from "ol/layer/Tile";
import TileWMSSource from "ol/source/TileWMS";
import LayerGroup from "ol/layer/Group";
import XYZSource from "ol/source/XYZ";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import ScaleLine from "ol/control/ScaleLine";
import Map from "ol/Map";
import { defaults as olControlDefaults } from "ol/control";
import View from "ol/View";
import Draw from "ol/interaction/Draw";
import Select from "ol/interaction/Select";
import Collection from "ol/Collection";
import { transform, fromLonLat } from "ol/proj";
import * as sphere from "ol/sphere";
import { register as proj4Register } from "ol/proj/proj4";

export type IMapService = ReturnType<typeof MapService>;

/* @ngInject */
function MapService(
  this: any,
  $localStorage,
  LayerService: ILayerService,
  ReportService,
  MainService,
  StrategyService,
  AppService,
  GetLayerService,
  $q,
  IndicatorService,
  $injector
) {
  var ctrl = this;

  var mapInitOptions = AppService.getMapViewInitOptions();

  ctrl.mapViewInit = {};
  ctrl.mapViewInit.coord = fromLonLat([mapInitOptions.mapMain.lon, mapInitOptions.mapMain.lat]);
  ctrl.mapViewInit.zoom = mapInitOptions.mapMain.zoom;

  ctrl.mapViewQuadrado = {};
  ctrl.mapViewQuadrado.coord = fromLonLat([
    mapInitOptions.mapQuadrado.lon,
    mapInitOptions.mapQuadrado.lat,
  ]);
  ctrl.mapViewQuadrado.zoom = mapInitOptions.mapQuadrado.zoom;
  ctrl.typebasemap = "m";

  function getTypeBaseTileMap() {
    return ctrl.typebasemap;
  }

  function getStyleByType(type) {
    if (type == "p") {
      return "&apistyle=s.t%3A2|s.e%3Ag|p.v%3Aoff,s.t%3A2|s.e%3Al|p.v%3Aoff";
    } else if (type == "m") {
      return "&apistyle=s.e%3Ag|p.c%3A%23fff5f5f5,s.e%3Al.i|p.v%3Aon,s.e%3Al.t.f|p.c%3A%23ff616161,s.e%3Al.t.s|p.c%3A%23fff5f5f5,s.t%3A21|s.e%3Al.t.f|p.c%3A%23ffbdbdbd,s.t%3A2|s.e%3Ag|p.c%3A%23ffeeeeee,s.t%3A2|s.e%3Al.t.f|p.c%3A%23ff757575,s.t%3A40|s.e%3Ag|p.c%3A%23ffe5e5e5,s.t%3A40|s.e%3Al.t.f|p.c%3A%23ff9e9e9e,s.t%3A3|p.v%3Aoff,s.t%3A3|s.e%3Ag|p.c%3A%23ffffffff,s.t%3A50|s.e%3Al.t.f|p.c%3A%23ff757575,s.t%3A49|s.e%3Ag|p.c%3A%23ffdadada,s.t%3A49|s.e%3Al.t.f|p.c%3A%23ff616161,s.t%3A51|s.e%3Al.t.f|p.c%3A%23ff9e9e9e,s.t%3A65|s.e%3Ag|p.c%3A%23ffe5e5e5,s.t%3A66|s.e%3Ag|p.c%3A%23ffeeeeee,s.t%3A6|s.e%3Ag|p.c%3A%23ffcee5e6,s.t%3A6|s.e%3Al.t.f|p.c%3A%23ff9e9e9e,s.t%3A19|p.v%3Aon";
    } else if (type == "h") {
      return "&apistyle=s.t%3A0|s.e%3A0|p.il%3Atrue"; // Para remover estradas, adicionar: |p.v%3Aon,s.t%3A3|p.v%3Aoff
    }
    return "";
  }

  function createUrlBaseTile(type?) {
    var locale = MainService.getLocaleLanguageSystem();
    var url = "https://mt.google.com/vt?x={x}&y={y}&z={z}&hl=";
    url += locale;
    url += "&s=Gal&src=app";

    if (angular.isUndefined(type)) {
      var type = getTypeBaseTileMap();
    }
    url += "&lyrs=" + type;
    url += getStyleByType(type);
    return url;
  }

  function setUrlInSource(url, source) {
    source.setUrl(url);
    source.refresh();
  }

  function setTypeBaseTileMap(type) {
    ctrl.typebasemap = type || ctrl.typebasemap;
    var url = createUrlBaseTile();
    var urlLabels = createUrlBaseTile("h");

    setUrlInSource(urlLabels, ctrl.layerLabels.getSource());
    setUrlInSource(url, ctrl.raster.getSource());
    if (angular.isDefined(ctrl.raster2) && angular.isDefined(ctrl.layerLabelsMapReport)) {
      setUrlInSource(urlLabels, ctrl.layerLabelsMapReport.getSource());
      setUrlInSource(url, ctrl.raster2.getSource());
    }
    setUrlInSource(urlLabels, ctrl.layerLabelsMapQuadrado.getSource());
    setUrlInSource(url, ctrl.rasterMapQuadrado.getSource());
  }

  function setIsDrawing(isDrawing) {
    ctrl.isDrawing = isDrawing;
  }

  function getIsDrawing() {
    return ctrl.isDrawing || false;
  }

  function getCalculeAreaByFeature(feature) {
    var features = getFeaturesDrawingAreaInstance();
    features.forEach(function (featureAux) {
      if (feature.getId() === featureAux.getProperties().idFather) {
        if (featureAux.getProperties().is_buffer) {
          feature = featureAux;
        }
      }
    });
    return feature;
  }

  function sortVectorDrawingFeaturesById(feature, addSource) {
    var features = getFeaturesDrawingAreaInstance();
    var id = 0;
    var idExist = true;
    while (Boolean(idExist)) {
      idExist = false;
      id++;
      features.forEach(function (featureAux) {
        if (featureAux.getId() === id) {
          idExist = true;
        }
      });
    }
    feature.setId(id);
    if (addSource) {
      ctrl.vectorDrawingArea.getSource().addFeature(feature);
    }
  }

  function getStyleText(value) {
    var styleDefault = getStyleDefault();
    var text = new Text({
      text: value,
      font: "14px TodaySHOP",
      fill: new Fill({ color: styleDefault.fillText.color }),
      stroke: new Stroke({
        color: styleDefault.strokeText.color,
        width: Number(styleDefault.strokeText.width),
      }),
    });
    return text;
  }

  function getStyle(colorFill, colorStroke, widthStroke, text?, lineDash?) {
    var style = new Style({
      fill: new Fill({
        color: colorFill || null,
      }),
      stroke: new Stroke({
        color: colorStroke,
        width: widthStroke,
        lineDash: lineDash,
      }),
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: colorStroke,
        }),
        stroke: new Stroke({
          color: colorStroke,
          width: widthStroke,
        }),
      }),
      text: text != undefined ? getStyleText(text) : undefined,
    });
    return style;
  }

  function getStyleOverlay(colorStroke, widthStroke, text) {
    var style = new Style({
      stroke: new Stroke({
        color: colorStroke,
        width: widthStroke,
      }),
      text: text != undefined ? getStyleText(text) : undefined,
    });
    return style;
  }

  function getStyleDefault() {
    return {
      stroke: {
        color: "#484848",
        width: "0.5",
      },
      strokeHover: {
        color: "#484848",
        width: "2",
      },
      strokeText: {
        color: "#ffffff",
        width: "4",
      },
      fillText: {
        color: "#404040",
      },
      fill: {
        color: "#dccbb5",
      },
    };
  }

  function getStyleMeasure() {
    return {
      stroke: {
        color: "#484848",
        lineDash: [8, 8],
        width: "2",
      },
      fill: {
        color: "rgba(255, 255, 255, 0.2)",
      },
    };
  }

  function getFeatureStyle(value, min, max, type) {
    var colorHex = getColorHex(value, min, max, type);
    var styleDefault = getStyleDefault();
    var style = getStyle("#" + colorHex, styleDefault.stroke.color, styleDefault.stroke.width);
    return style;
  }

  function getColorHex(value, min, max, relacao) {
    if (value < min || value > max) {
      return "";
    }
    // if (value < min) value = min;
    // else if (value > max) value = max;
    var colors = [
      {
        hex: AppService.getSystemColors().indicator.red.hex.replace("#", ""), //red
        value: 0,
        positionPercent: 0,
      },
      {
        hex: AppService.getSystemColors().indicator.orange.hex.replace("#", ""), //orange
        value: 0,
        positionPercent: 0,
      },
      {
        hex: AppService.getSystemColors().indicator.yellow.hex.replace("#", ""), //yellow
        value: 0,
        positionPercent: 0,
      },
      {
        hex: AppService.getSystemColors().indicator.green.hex.replace("#", ""), //green
        value: 0,
        positionPercent: 0,
      },
    ];
    if (relacao) {
      colors = colors.reverse();
    }
    colors.forEach(function (color, index) {
      color.positionPercent = index / (colors.length - 1);
      color.value = min + (max - min) * color.positionPercent;
    });

    var valuePercent = (value - min) / (max - min);
    if (isNaN(valuePercent)) {
      valuePercent = 0;
    }
    var colorMin = colors[0];
    var colorMax;

    colors.forEach(function (color, index) {
      if (valuePercent <= color.positionPercent) {
        colorMax = color;
        return;
      }
      colorMin = color;
    });
    var r = (value - colorMin.value) / (colorMax.value - colorMin.value);
    if (isNaN(r)) {
      r = 0;
    }
    var getColorHex = getColorHexFromGradient(colorMin.hex, colorMax.hex, r);
    return getColorHex;
  }

  function getColorHexFromGradient(color1, color2, radius) {
    var r = Math.round(
      parseInt(color2.substring(0, 2), 16) * radius +
        parseInt(color1.substring(0, 2), 16) * (1 - radius)
    );
    var g = Math.round(
      parseInt(color2.substring(2, 4), 16) * radius +
        parseInt(color1.substring(2, 4), 16) * (1 - radius)
    );
    var b = Math.round(
      parseInt(color2.substring(4, 6), 16) * radius +
        parseInt(color1.substring(4, 6), 16) * (1 - radius)
    );
    return toHex(r) + toHex(g) + toHex(b);
  }

  function toHex(value) {
    value = value.toString(16);
    return value.length == 1 ? "0" + value : value;
  }

  function colorMap(features) {
    features.forEach(function (feature) {
      var colorHex = getColorMapIndiceGeral(feature.classeOportunidade, feature.classeRisco);
      var styleDefault = getStyleDefault();
      feature.setStyle(
        getStyle("#" + colorHex, styleDefault.stroke.color, styleDefault.stroke.width)
      );
    });
  }

  function getColorMapIndiceGeral(indiceOportunidade, indiceRisco) {
    var colorHex = "";
    if (indiceOportunidade == "baixo") {
      if (indiceRisco == "baixo") {
        colorHex = AppService.getSystemColors().indicator.yellow.hex.replace("#", ""); //Amarelo
      } else {
        colorHex = AppService.getSystemColors().indicator.red.hex.replace("#", ""); //vermelho
      }
    } else {
      if (indiceRisco == "baixo") {
        colorHex = AppService.getSystemColors().indicator.green.hex.replace("#", ""); //verde
      } else {
        colorHex = AppService.getSystemColors().indicator.orange.hex.replace("#", ""); //laranja
      }
    }
    return colorHex;
  }

  function setAcceptTermsUse(isAcceptTermsUse) {
    $localStorage.isAcceptTermsUse = isAcceptTermsUse;
  }

  function getAcceptTermsUse() {
    return $localStorage.isAcceptTermsUse || false;
  }

  // Notificação provisória sobre necessidade da troca de senha pelos usuários
  function setNotifiedAboutPasswordChange(isNotifiedAboutPasswordChange) {
    $localStorage.isNotifiedAboutPasswordChange = isNotifiedAboutPasswordChange;
  }

  // Notificação provisória sobre necessidade da troca de senha pelos usuários
  function getNotifiedAboutPasswordChange() {
    return $localStorage.isNotifiedAboutPasswordChange || false;
  }

  function cloneFeaturesMap(layerOrigem, mapDestino, layerDestino) {
    if (layerDestino != undefined) {
      clearSource(layerDestino.getSource());
    }
    if (layerOrigem.getVisible()) {
      cloneFeatures(layerOrigem.getSource().getFeatures(), layerDestino.getSource());
    }
    setFeaturesStyleDefault(layerDestino.getSource().getFeatures());
    setVisibleVector(layerDestino, true);
    LayerService.setOpacityLayer(layerDestino, 100);
    renderMap(mapDestino);
  }

  function setVisibleVector(vector, visible) {
    vector.setVisible(visible);
  }

  function setFeaturesStyleDefault(features) {
    var styleDefault = getStyleDefault();
    var style = getStyle(
      styleDefault.fill.color,
      styleDefault.stroke.color,
      styleDefault.stroke.width
    );
    features.forEach(function (feature) {
      feature.setStyle(style);
    });
  }

  function refreshSource(source, features) {
    clearSource(source);
    cloneFeatures(features, source);
  }

  function cloneFeatures(features, source) {
    features.forEach(function (feature) {
      cloneFeature(feature, source);
    });
  }

  function cloneFeature(feature, source) {
    source.addFeature(feature.clone());
  }

  function clearSource(source) {
    source.clear();
  }

  function renderMap(map) {
    map.renderSync();
  }

  function hiddenComponentsToPrint() {
    angular.element("#mapQuadrado .ol-rotate").hide();
    angular.element("#mapQuadrado").attr("style", "visibility: hidden");
    angular.element("#cloneToDownloadPng").addClass("clone-indicator-hidden");
  }

  function getWmsLayers(layers) {
    var wmsLayers = layers.map(function (layer) {
      return new TileLayer({
        name: layer.mapserver_name,
        visible: false,
        zIndex: layer.z_index,
        source: new TileWMSSource({
          url: AppService.getUrlWmsApi(),
          params: {
            LAYERS: layer.mapserver_name,
            srs: "EPSG:3857",
          },
          crossOrigin: "anonymous",
        }),
      } as any);
    });
    return wmsLayers;
  }

  function newLayerGroup(nameGroup, wmsLayers) {
    var layerGroup = new LayerGroup({
      name: nameGroup,
      layers: wmsLayers,
    } as any);
    return layerGroup;
  }

  function newRaster(type?, zIndex?) {
    if (!zIndex) {
      zIndex = 0;
    }
    return new TileLayer({
      source: new XYZSource({
        url: createUrlBaseTile(type),
        tilePixelRatio: 2,
        crossOrigin: "anonymous",
      }),
      zIndex: zIndex,
    });
  }

  function newSource() {
    var source = new VectorSource();
    return source;
  }

  function newVector(source, zIndex, style) {
    var vector = new VectorLayer({
      style: getStyle(style.fill.color, style.stroke.color, style.stroke.width),
      source: source,
      zIndex: zIndex,
    });
    return vector;
  }

  function newScaleLine() {
    var scaleLineControl = new ScaleLine();
    return scaleLineControl;
  }

  function addScaleCanvasToMap(e) {
    var canvas = angular.element(".mapQuadrado canvas").get(0) as HTMLCanvasElement;
    //get the Scaleline div container the style-width property
    var olscale = angular.element(".mapQuadrado .ol-scale-line-inner");
    //Offset from the left
    var x_offset = 250;
    //offset from the bottom
    var y_offset = 30;
    var fontsize = 21;

    var ctx = e.context;
    var scalewidth = parseInt(olscale.css("width"), 10);
    var scale = olscale.text();
    var scalenumber = parseInt(scale, 10);
    var scaleunit = scale.match(/[Aa-zZ]{1,}/g);

    //Scale Text
    ctx.beginPath();
    ctx.textAlign = "left";
    ctx.strokeStyle = "#ffffff";
    ctx.fillStyle = "#000000";
    ctx.lineWidth = 2;
    ctx.font = fontsize + "px TodayShop";
    ctx.strokeText(
      [scalenumber + " " + scaleunit],
      x_offset - 135 / 2,
      canvas.height - y_offset + 40 / 2
    );
    ctx.fillText(
      [scalenumber + " " + scaleunit],
      x_offset - 135 / 2,
      canvas.height - y_offset + 40 / 2
    );

    //Scale Dimensions
    var xzero = scalewidth + x_offset;
    var yzero = canvas.height - 25;

    // Stroke Scale
    ctx.beginPath();
    ctx.strokeStyle = "#000000";

    ctx.lineWidth = 2;
    ctx.moveTo(x_offset, yzero + 5);
    ctx.lineTo(x_offset, yzero + 15);

    ctx.moveTo(x_offset + (xzero - x_offset), yzero + 5);
    ctx.lineTo(x_offset + (xzero - x_offset), yzero + 15);

    ctx.moveTo(x_offset, yzero + 15);
    ctx.lineTo(xzero, yzero + 15);

    ctx.stroke();
  }

  function newMap(scaleLineControl, layers, target, initCoord, initZoom) {
    var map = new Map({
      controls: olControlDefaults({
        zoom: false,
        attributionOptions: {
          collapsible: true,
        },
      }).extend([scaleLineControl]),
      layers: layers,
      target: target,
      view: new View({
        center: initCoord,
        zoom: initZoom,
        minResolution: 150,
        maxResolution: 20000,
        rotation: 0,
      }),
    });
    return map;
  }

  function getMapMainInstance() {
    return ctrl.map;
  }

  function getProjectionMapMain() {
    return getMapMainInstance().getView().getProjection();
  }

  function getFeaturesMeasureCollection() {
    return ctrl.featuresMeasureCollection;
  }

  function getVectorMeasureAreaInstance() {
    return ctrl.vectorMeasureArea;
  }

  function getFeaturesMeasureAreaInstance() {
    return ctrl.vectorMeasureArea.getSource().getFeatures();
  }

  function isVectorMeasureAreaHasFeatures() {
    return getFeaturesMeasureAreaInstance().length > 0;
  }

  function getFeaturesDrawingCollectionMapInstance() {
    return ctrl.featuresCollection;
  }

  function getVectorDrawingAreaInstance() {
    return ctrl.vectorDrawingArea;
  }

  function getFeaturesDrawingAreaInstance() {
    return ctrl.vectorDrawingArea.getSource().getFeatures();
  }

  function isVectorDrawingAreaHasFeatures() {
    return getFeaturesDrawingAreaInstance().length > 0;
  }

  function deleteFeatureVectorDrawingArea(feature) {
    deleteFeatureChild(feature);
    getVectorDrawingAreaInstance().getSource().removeFeature(feature);
  }

  function deleteFeatureChild(feature) {
    var features = getFeaturesDrawingAreaInstance();
    features.forEach(function (featureAux) {
      if (feature.getId() === featureAux.getProperties().idFather) {
        if (featureAux.getProperties().is_buffer || featureAux.getProperties().is_kml) {
          getVectorDrawingAreaInstance().getSource().removeFeature(featureAux);
        }
      }
    });
  }

  function getSourceSelectionAreaInstance() {
    return ctrl.vectorSelectionArea.getSource() || [];
  }

  function getVectorSelectionAreaInstance() {
    return ctrl.vectorSelectionArea;
  }

  function isVectorSelectionAreaHasFeatures() {
    return getFeaturesSelectionAreaInstance().length > 0;
  }

  function isInvalidSelectedArea() {
    var selectedAreaSize = getFeaturesSelectionAreaInstance() || [];
    return selectedAreaSize.length == 1;
  }

  function getFeaturesSelectionAreaInstance() {
    var IndicatorStrategyService = $injector.get("IndicatorStrategyService");
    return IndicatorStrategyService.getActiveFeatures();
  }

  function openWarning() {
    var ModalService = $injector.get("ModalService");
    ModalService.openModalWarningSelectionArea();
  }

  function getSourceCacheVectorSelectionArea(unitPlan) {
    if (unitPlan === "municipios") {
      return ctrl.sourceCacheMunicipios;
    } else if (unitPlan === "grades") {
      return ctrl.sourceCacheGrades;
    } else {
      return ctrl.vectorSelectionArea.getSource();
    }
  }

  function getSourceReportMap() {
    return ctrl.vectorMapReport.getSource() || undefined;
  }

  function getAtualSourceCacheVectorSelectionArea() {
    var unitPlan = StrategyService.getUnitPlanningActive();
    var unitPlanName = "";
    try {
      unitPlanName = unitPlan.name;
    } catch (e) {}
    return getSourceCacheVectorSelectionArea(unitPlanName);
  }

  function clearVectorMeasure() {
    ctrl.vectorMeasureArea.getSource().clear();
  }

  function clearVectorArea(clearDrawingArea, clearSourceCache) {
    ctrl.vectorSelectionArea.getSource().clear();
    if (clearSourceCache) {
      ctrl.sourceCacheMunicipios.clear();
      ctrl.sourceCacheGrades.clear();
    }
    if (clearDrawingArea) {
      ctrl.vectorDrawingArea.getSource().clear();
    }
  }

  function clearAllInteractionMapMain() {
    var map = getMapMainInstance();
    map.getInteractions().forEach(function (interaction) {
      if (interaction instanceof Draw) {
        map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        map.removeInteraction(interaction);
      }
    });
  }

  function hasInteractionMapMain(event) {
    var map = getMapMainInstance();
    var hasInteraction = false;
    map.getInteractions().forEach(function (interaction) {
      hasInteraction = interaction == event ? true : hasInteraction;
    });
    return hasInteraction;
  }

  function getMapViewInfo() {
    return ctrl.mapViewInit || {};
  }

  function initMapMain() {
    ctrl.raster = newRaster();
    ctrl.scaleLineControl = newScaleLine();

    var raster = [ctrl.raster];
    ctrl.map = newMap(
      ctrl.scaleLineControl,
      raster,
      "map",
      ctrl.mapViewInit.coord,
      ctrl.mapViewInit.zoom
    );
  }

  function moveScaleLineToCopyrightDiv() {
    var elementScaleLineInner = angular.element("#map .ol-scale-line");
    angular.element(".copyright").prepend(elementScaleLineInner);
  }

  function initVectorsMapMain() {
    ctrl.layerLabels = newRaster("h", 100);
    var styleDefault = getStyleDefault();

    ctrl.sourceCacheMunicipios = new VectorSource({});
    ctrl.sourceCacheGrades = new VectorSource({});
    var source = newSource();
    ctrl.vectorSelectionArea = newVector(source, 2, styleDefault);

    ctrl.featuresCollection = new Collection();
    var sourceCollection = new VectorSource({
      features: ctrl.featuresCollection,
      // crossOrigin: "anonymous"
    });
    ctrl.vectorDrawingArea = newVector(sourceCollection, 10, styleDefault);

    var styleMeasure = getStyleMeasure();
    ctrl.featuresMeasureCollection = new Collection();
    var sourceMeasureCollection = new VectorSource({
      features: ctrl.featuresMeasureCollection,
      // crossOrigin: "anonymous"
    });
    ctrl.vectorMeasureArea = newVector(sourceMeasureCollection, 10, styleMeasure);

    var collectionFeatureOverlay = new Collection<any>();
    var sourceCollectionFeatureOverlay = new VectorSource({
      features: collectionFeatureOverlay,
      // crossOrigin: "anonymous"
    });
    ctrl.featureOverlay = newVector(sourceCollectionFeatureOverlay, 101, styleDefault);

    var vectors = [
      ctrl.vectorSelectionArea,
      ctrl.vectorDrawingArea,
      ctrl.featureOverlay,
      ctrl.vectorMeasureArea,
      ctrl.layerLabels,
    ];
    vectors.forEach(function (vector) {
      ctrl.map.addLayer(vector);
    });
  }

  function getFeatureOverlay() {
    return ctrl.featureOverlay.getSource();
  }

  function initLayersWmsMapMain() {
    var deferred = $q.defer();
    GetLayerService.get().$promise.then(function (response) {
      LayerService.setGroupLayers(response.data);
      LayerService.setLanguageLayers(MainService.getLanguageSystem().locale);
      var layersWms = loadGroupsLayersWmsMapMain();
      ctrl.map.addLayer(layersWms);
      deferred.resolve();
    });
    return deferred.promise;
  }

  function loadGroupsLayersWmsMapMain() {
    var layersWms = LayerService.getLayers();
    ctrl.wmsLayers = getWmsLayers(layersWms);
    ctrl.layerGroupWms = newLayerGroup("wmsLayers", ctrl.wmsLayers);
    LayerService.setGroupLayerWms(ctrl.layerGroupWms);
    return ctrl.layerGroupWms;
  }

  function initMapSquare() {
    ctrl.rasterMapQuadrado = newRaster();
    ctrl.layerLabelsMapQuadrado = newRaster("h", 100);
    var layers = LayerService.getLayers();
    ctrl.wmsLayersMapQuadrado = getWmsLayers(layers);
    ctrl.layerGroupMapQuadrado = newLayerGroup("wmsLayersMapQuadrado", ctrl.wmsLayersMapQuadrado);
    LayerService.setGroupLayerWmsSquare(ctrl.layerGroupMapQuadrado);

    var styleDefault = getStyleDefault();
    var source = newSource();
    ctrl.vectorMapQuadrado = newVector(source, 2, styleDefault);
    ctrl.scaleLineControlMapQuadrado = newScaleLine();

    layers = [
      ctrl.rasterMapQuadrado,
      ctrl.layerGroupMapQuadrado,
      ctrl.vectorMapQuadrado,
      ctrl.layerLabelsMapQuadrado,
    ];
    ctrl.mapQuadrado = newMap(
      ctrl.scaleLineControlMapQuadrado,
      layers,
      "mapQuadrado",
      ctrl.mapViewQuadrado.coord,
      ctrl.mapViewQuadrado.zoom
    );

    var elementScaleLine = angular.element("#mapQuadrado .ol-scale-line");
    var elementScaleLineInner = angular.element("#mapQuadrado .ol-scale-line-inner");

    elementScaleLineInner.addClass("ol-scale-line-inner-float");
    elementScaleLine.addClass("ol-scale-line-quadrado");

    ctrl.mapQuadrado.render();
    hiddenComponentsToPrint();

    ctrl.mapQuadrado.on("postcompose", function (evt) {
      addScaleCanvasToMap(evt);
      ctrl.rasterMapQuadrado.on("tileloadstart", setStatusTileLoadFinish(false));
      ctrl.rasterMapQuadrado.on("tileloadend", setStatusTileLoadFinish(true));
    });
  }

  function setStatusTileLoadFinish(status) {
    ctrl.tileLoadFinish = status;
  }

  function mapViewLoaded() {
    return ctrl.tileLoadFinish;
  }

  function initMapReport() {
    ctrl.raster2 = newRaster();
    ctrl.layerLabelsMapReport = newRaster("h", 100);

    var styleDefault = getStyleDefault();
    var source = newSource();
    ctrl.vectorMapReport = newVector(source, 2, styleDefault);
    ctrl.scaleLineControlMapReport = newScaleLine();

    var layers = [
      ctrl.raster2,
      LayerService.getGroupLayerWms(),
      ctrl.vectorMapReport,
      ctrl.layerLabelsMapReport,
    ];
    ctrl.mapRelatorio = newMap(
      ctrl.scaleLineControlMapReport,
      layers,
      null,
      ctrl.mapViewInit.coord,
      ctrl.mapViewInit.zoom
    );
  }

  function setTargetMapReport() {
    var deferred = $q.defer();
    var intervalMapReport = setInterval(function () {
      if (angular.element("#mapRelatorio").length === 1) {
        var elementScaleLine = angular.element("#mapRelatorio .ol-scale-line");
        var elementScaleLineInner = angular.element("#mapRelatorio .ol-scale-line-inner");

        elementScaleLineInner.addClass("ol-scale-line-inner-float");
        elementScaleLine.addClass("ol-scale-line-report");

        ctrl.mapRelatorio.setTarget(angular.element("#mapRelatorio")[0]);
        printsToPdf().then(function () {
          deferred.resolve();
        });
        clearInterval(intervalMapReport);
      }
    }, 100);
    return deferred.promise;
  }

  function printsToPdf() {
    var deferred = $q.defer();
    ctrl.vectorMapReport.getSource().addFeatures(getFeaturesSelectionAreaInstance());

    if (Boolean(ReportService.getReportCached()) === false) {
      cloneFeaturesMap(getVectorSelectionAreaInstance(), ctrl.mapQuadrado, ctrl.vectorMapQuadrado);
      var elementCompass = "#mapQuadrado .ol-rotate";
      angular.element(elementCompass).removeAttr("style");
      angular.element("#mapQuadrado").removeAttr("style");
      //PRINT MAPA BIOMA
      var printDataURLBioma = getDataURLBioma(
        ctrl.rasterMapQuadrado,
        ctrl.mapQuadrado,
        ctrl.mapViewQuadrado,
        ctrl.layerGroupMapQuadrado
      );
      printDataURLBioma.then(function (dataURLMapBioma) {
        ReportService.setDataURLMapBioma(dataURLMapBioma);
        ctrl.layerGroupMapQuadrado.setVisible(false);
        //PRINT MAPA AREA SELECIONADA
        var printDataURLAreaSelecionada = getDataURL(
          ctrl.mapQuadrado,
          ctrl.rasterMapQuadrado,
          ctrl.vectorMapQuadrado.getSource(),
          3
        );
        printDataURLAreaSelecionada.then(function (dataURLMapAreaSelecionada) {
          ReportService.setDataURLMapAreaSelecionada(dataURLMapAreaSelecionada);
          ctrl.layerGroupMapQuadrado.setVisible(true);
          var valor = StrategyService.getStrategyInfo().opacidade.valor;
          //APLICA TRANSPARÊNCIA MAP QUADRADO E RELATÓRIO
          LayerService.setOpacityLayer(ctrl.vectorMapReport, valor / 100);
          LayerService.setOpacityLayer(ctrl.vectorMapQuadrado, valor / 100);
          ctrl.mapQuadrado.addLayer(ctrl.layerGroupWms);
          ctrl.layerGroupMapQuadrado.setVisible(false);
          clearSource(ctrl.vectorMapQuadrado.getSource());
          // calcularIndiceGeral - mapQuadrado
          ctrl.vectorMapQuadrado.getSource().addFeatures(getFeaturesSelectionAreaInstance());
          //PRINT MAPA INDICE GERAL
          var printDataURLMapIndiceGeral = getDataURL(
            ctrl.mapQuadrado,
            ctrl.rasterMapQuadrado,
            ctrl.vectorMapQuadrado.getSource(),
            3
          );
          printDataURLMapIndiceGeral.then(function (dataURLMapIndiceGeral) {
            ctrl.mapQuadrado.removeLayer(ctrl.layerGroupWms);
            ctrl.layerGroupMapQuadrado.setVisible(true);
            ReportService.setDataURLMapIndiceGeral(dataURLMapIndiceGeral);
            ReportService.setDataURLsElementMap(elementCompass, "elementCompass");
            ReportService.setDataURLElementInClasse();
            clearSource(ctrl.vectorMapQuadrado.getSource());
            deferred.resolve();
          });
        });
      });

      ReportService.getDataUrlLogo(
        "/assets/images/" +
          AppService.getLayout() +
          "/logo/logo-agroideal-" +
          MainService.getLanguageSystem().locale +
          ".png",
        function (dataUrl) {
          ReportService.setDataURLLogoAgroideal(dataUrl);
        }
      );

      ReportService.getDataUrlLogo("/assets/images/external-link.png", function (dataUrl) {
        ReportService.setDataURLIconLink(dataUrl);
      });
    } else {
      deferred.resolve();
    }
    viewBoundBox(ctrl.vectorMapReport.getSource().getExtent(), ctrl.mapRelatorio);
    return deferred.promise;
  }

  function cloneSourceCacheToSourceSelectionArea(sourceCache, source) {
    var features = [] as any[];
    if (sourceCache.getFeatures().length > 0) {
      if (source !== null) {
        source.clear();
      }
      cloneFeatures(sourceCache.getFeatures(), source);
      features = getFeaturesSelectionAreaInstance();
    }
    return features;
  }

  function featureInListByCodigo(feature, list) {
    return StrategyService.hasFeatureCode(feature.getProperties().code);
  }

  function getFeaturesByRegionsSelected(sourceCache) {
    var biomes = StrategyService.getRegionsActive();
    var featuresBiomes = [] as any[];
    if (isVectorSelectionAreaHasFeatures()) {
      setTimeout(() => {
        if (isInvalidSelectedArea()) {
          openWarning();
        }
      }, 1000);
      var features = sourceCache.getFeatures();
      features.forEach(function (feature) {
        var listBiomes = [];
        feature.getProperties().biomas.forEach(function (featureBiome) {
          listBiomes = listBiomes.concat(
            biomes.filter(function (biome) {
              return biome === featureBiome;
            })
          );
        });
        if (listBiomes.length !== 0) {
          featuresBiomes.push(feature);
        }
      });
    }
    return featuresBiomes;
  }

  function showFeaturesByRegionsSelected() {
    var biomes = StrategyService.getRegionsActive();
    var source = getVectorSelectionAreaInstance().getSource() || [];
    var features = source.getFeatures() || [];
    features.forEach(function (feature) {
      var listBiomes = [];
      feature.getProperties().biomas.forEach(function (featureBiome) {
        listBiomes = listBiomes.concat(
          biomes.filter(function (biome) {
            return biome === featureBiome;
          })
        );
      });
      if (listBiomes.length === 0) {
        source.removeFeature(feature);
      }
    });
  }

  // Verifica se a feature abrange as regiões selecionadas
  function isFeatureInRegionsActive(feature) {
    var biomes = StrategyService.getRegionsActive();
    var listBiomes = [];
    feature.getProperties().biomas.forEach(function (featureBiome) {
      listBiomes = listBiomes.concat(
        biomes.filter(function (biome) {
          return biome === featureBiome;
        })
      );
    });
    return listBiomes.length !== 0;
  }

  function hasIntersectionBetweenBiomes() {
    if (isVectorSelectionAreaHasFeatures()) {
      var features = getFeaturesSelectionAreaInstance();
      var biomes = StrategyService.getRegions();
      // var biomesActived = StrategyService.getRegionsActive();
      features.some(function (feature) {
        var listBiomes = [];
        feature.getProperties().biomas.forEach(function (featureBiome) {
          listBiomes = listBiomes.concat(
            biomes.filter(function (biome) {
              return biome.name === featureBiome;
            })
          );
        });
        // Retirado dia 26/06/2018 pois a FLAVIA pediu;
        // var sendNotification = listBiomes.length > biomesActived.length;
        // if(sendNotification){
        //     StrategyService.sendNotificationRegions();
        // }
        // return sendNotification;
        return true;
      });
    }
  }

  function hasFeatureInSelectionAreaByRegionsSelected() {
    var biomes = StrategyService.getRegionsActive();
    var unitPlan = StrategyService.getUnitPlanningActive();
    var hasFeature = false;
    var features = [] as any[];
    if (angular.isDefined(unitPlan)) {
      features = getSourceCacheVectorSelectionArea(unitPlan.name).getFeatures();
    }
    features.some(function (feature) {
      var listBiomes = [];
      feature.getProperties().biomas.forEach(function (featureBiome) {
        listBiomes = listBiomes.concat(
          biomes.filter(function (biome) {
            return biome === featureBiome;
          })
        );
      });
      if (listBiomes.length !== 0) {
        hasFeature = true;
        return true;
      }
    });
    return hasFeature;
  }

  function calcularIndiceGeral(features, listIndicators) {
    getBoundBoxSelectionArea();
    resetIndMax(listIndicators);
    listIndicators.forEach(function (indicator) {
      calcIndicadorMaxValueFeatures(indicator, features);
      // calcIndicadorMinValueFeatures(indicator, features)
      calcIndiceIndicadorByFeatures(indicator, features);
    });
    calcIndiceGeralByFeatures(listIndicators, features);
    colorMap(features);
  }

  function resetIndMax(listRangeIndicators) {
    listRangeIndicators.forEach(function (indicator) {
      indicator.indiceMax = 0;
    });
  }

  function calcIndicadorMaxValueFeatures(indicadorSelecionado, features) {
    var maxValueFeature = 0;
    features.forEach(function (feature) {
      if (feature.getProperties().indicators == undefined) {
        feature.setProperties({ indicators: [] });
      }
      feature.getProperties().indicators.forEach(function (indicador) {
        if (indicador.name == indicadorSelecionado.name) {
          if (indicador.value > maxValueFeature) {
            maxValueFeature = indicador.value;
          }
        }
      });
    });
    indicadorSelecionado.maxValueFeature = maxValueFeature;
  }

  // function newCalcIndicadorMinValueFeatures(indicadorSelecionado, features) {
  //   var minValueFeature = Number.MAX_SAFE_INTEGER; // valor alto para comparar com valores menores
  //   features.forEach(function(feature) {
  //     if (feature.getProperties().indicators == undefined) {
  //       feature.setProperties({ indicators: [] });
  //     }
  //     feature.getProperties().indicators.forEach(function(indicador) {
  //       if (indicador.name == indicadorSelecionado.name) {
  //         if (indicador.value < minValueFeature) {
  //           minValueFeature = indicador.value;
  //         }
  //       }
  //     });
  //   });
  //   indicadorSelecionado.minValueFeature = minValueFeature;
  // }

  // calculo do índice com a formula antiga, que levava em conta apenas o limite superior
  function calcIndiceIndicadorByFeatures(indicadorSelecionado, features) {
    features.forEach(function (feature) {
      feature.getProperties().indicators.forEach(function (indicador) {
        if (indicador.name == indicadorSelecionado.name) {
          indicador.indice = indicador.value / indicadorSelecionado.maxValueFeature;
          if (Number.isNaN(indicador.indice)) {
            indicador.indice = 0;
          }
          if (
            (indicadorSelecionado.axis_name == "economico" &&
              indicadorSelecionado.inverse_relationship) ||
            (indicadorSelecionado.axis_name == "socioambiental" &&
              !indicadorSelecionado.inverse_relationship)
          ) {
            indicador.indice = 1 - indicador.indice;
          }
          indicador.indice *= indicadorSelecionado.weight;
        }
      });
    });
  }

  // calculo do índice com a formula nova, levando em conta limite superior e inferior
  // function new_calcIndiceIndicadorByFeatures(indicadorSelecionado, features) {
  //   features.forEach(function(feature) {
  //     feature.getProperties().indicators.forEach(function(indicador) {
  //       if (indicador.name == indicadorSelecionado.name) {
  //         indicador.indice =
  //           (indicador.value - indicadorSelecionado.minValueFeature) /
  //           (indicadorSelecionado.maxValueFeature - indicadorSelecionado.minValueFeature);
  //         if (Number.isNaN(indicador.indice)) {
  //           indicador.indice = 0;
  //         }
  //         if (
  //           (indicadorSelecionado.axis_name == "economico" &&
  //             indicadorSelecionado.inverse_relationship) ||
  //           (indicadorSelecionado.axis_name == "socioambiental" &&
  //             !indicadorSelecionado.inverse_relationship)
  //         ) {
  //           indicador.indice = 1 - indicador.indice;
  //         }
  //         indicador.indice *= indicadorSelecionado.weight;
  //       }
  //     });
  //   });
  // }

  function getIndiceIndicadorsByFeature(feature) {
    var listIndicatorsActive = IndicatorService.getIndicatorsActive();
    var indicatorsActiveFeature = [];
    listIndicatorsActive.forEach(function (indicatorActive) {
      indicatorsActiveFeature = indicatorsActiveFeature.concat(
        feature.getProperties().indicators.filter(function (indicatorFeature) {
          return indicatorFeature.name === indicatorActive.name;
        })
      );
    });
    return indicatorsActiveFeature;
  }

  function calcIndiceGeralByFeatures(indicadoresSelecionados, features) {
    var indicators_economico = IndicatorService.getIndicatorsActiveByAxisName("economico");
    var indicators_socio = IndicatorService.getIndicatorsActiveByAxisName("socioambiental");

    // somatório dos pesos dos indicadores economicos para a média ponderada utilizada no calculo
    var somaIndiceMaxEconomico = indicators_economico.reduce(function (value, ind) {
      return value + ind.weight;
    }, 0);

    // somatório dos pesos dos indicares socioambientais para a média ponderada utilizada no calculo
    var somaIndiceMaxSocioambiental = indicators_socio.reduce(function (value, ind) {
      return value + ind.weight;
    }, 0);

    features.forEach(function (feature) {
      var indicators_economico = IndicatorService.getIndicatorsActiveByAxisName(
        "economico",
        feature.getProperties().indicators
      );
      var indicators_socio = IndicatorService.getIndicatorsActiveByAxisName(
        "socioambiental",
        feature.getProperties().indicators
      );

      var somaIndiceEcoFeature = indicators_economico.reduce(function (value, ind) {
        return value + (ind.indice || 0);
      }, 0);

      var somaIndiceSocioFeature = indicators_socio.reduce(function (value, ind) {
        return value + (ind.indice || 0);
      }, 0);

      feature.indiceOportunidade = somaIndiceEcoFeature / somaIndiceMaxEconomico || 0;
      if (feature.indiceOportunidade <= 0.5) {
        feature.classeOportunidade = "baixo";
      } else {
        feature.classeOportunidade = "alto";
      }

      feature.indiceRisco = somaIndiceSocioFeature / somaIndiceMaxSocioambiental || 0;
      if (feature.indiceRisco < 0.5) {
        feature.classeRisco = "baixo";
      } else {
        feature.classeRisco = "alto";
      }
    });
  }

  function getDataURLBioma(rasterMapQuadrado, mapQuadrado, mapViewQuadrado, layerGroupMapQuadrado) {
    var deferred = $q.defer();
    LayerService.changeOpacityLayerGroup(layerGroupMapQuadrado, 100);
    setViewMap(mapQuadrado, mapViewQuadrado.coord, mapViewQuadrado.zoom);
    var printDataURLBioma = setInterval(function () {
      if (mapViewLoaded()) {
        var dataURL;
        // var state = rasterMapQuadrado.getSource().getState();

        // if (state != 'ready'){
        //     var timeout = 3;
        //     for (i=0;i < time; i++){
        //         setTimeout(function(){
        //             state = layer.getSource().getState();

        //             if (state == 'ready'){
        //                 i = time; //break
        //             }
        //         }, 1000);
        //     }
        // };
        dataURL = getDataURLMapCanvas(mapQuadrado);
        LayerService.changeOpacityLayerGroup(layerGroupMapQuadrado, 0);
        clearInterval(printDataURLBioma);
        deferred.resolve(dataURL);
      }
    }, 1000);
    return deferred.promise;
  }

  function getDataURL(map, layerBase, source, timeout) {
    var deferred = $q.defer();
    var extent = getExtent(source);
    viewBoundBox(extent, map);
    var printDataURL = setInterval(function () {
      if (mapViewLoaded()) {
        // var state = layerBase.getSource().getState();

        // if (state != 'ready'){
        //     for (i=0;i < timeout; i++){
        //         setTimeout(function(){
        //             state = layer.getSource().getState();
        //             if (state == 'ready'){
        //                 i = time; //break
        //             }
        //         }, 1000);
        //     }
        // };
        clearInterval(printDataURL);
        deferred.resolve(getDataURLMapCanvas(map));
      }
    }, 2000);
    return deferred.promise;
  }

  function getExtent(source) {
    var extent = null;
    if (source) {
      extent = source.getExtent();
    }
    return extent;
  }

  function viewBoundBox(extent, map) {
    map.getView().fit(extent, map.getSize());
    map.renderSync();
  }

  function setViewMap(map, coordinates, zoom) {
    map.getView().setCenter(coordinates);
    map.getView().setZoom(zoom);
  }

  function getDataURLMapCanvas(map) {
    var dataURL = null;
    map.once("postcompose", function (event) {
      var canvas = event.context.canvas;
      dataURL = canvas.toDataURL();
    });
    map.renderSync();
    return dataURL;
  }

  function getBoundBoxSelectionArea() {
    if (isVectorSelectionAreaHasFeatures()) {
      viewBoundBox(getVectorSelectionAreaInstance().getSource().getExtent(), getMapMainInstance());
    }
  }

  ctrl.projBrasilAlbersEqualArea = {
    name: "SR-ORG:8056",
    proj4:
      "+proj=aea +lat_1=-2 +lat_2=-22 +lat_0=-12 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs",
  };

  proj4.defs(ctrl.projBrasilAlbersEqualArea.name, ctrl.projBrasilAlbersEqualArea.proj4);
  proj4Register(proj4);

  function calculateAreaFeature(feature) {
    return calculateAreaGeometry(
      feature.getGeometry(),
      getProjectionMapMain(),
      ctrl.projBrasilAlbersEqualArea.name
    );
  }

  function calculateAreaFeatureByGeom(geom) {
    return calculateAreaGeometry(geom, getProjectionMapMain(), ctrl.projBrasilAlbersEqualArea.name);
  }

  function calculateAreaGeometry(geometry, projGeometry, projCalculate) {
    if (geometry != null) {
      if (projGeometry != projCalculate) {
        geometry = olGeomTransformProjection(geometry, projGeometry, projCalculate);
      }
      var area = olGeomGetArea(geometry);
      return Math.round((area / 10000) * 100) / 100;
    } else {
      return 0;
    }
  }

  function calculateLengthMetersByCoordinates(coordinates, index /*, wgs84Sphere*/) {
    // var length = 0;
    var length = sphere.getDistance(
      // wgs84Sphere.haversineDistance(
      transform(coordinates[index], "EPSG:3857", "EPSG:4326"),
      transform(coordinates[index - 1], "EPSG:3857", "EPSG:4326")
    );
    return length;
  }

  function olGeomTransformProjection(olGeomPolygon, projOrigem, projDestino) {
    return olGeomPolygon.clone().transform(projOrigem, projDestino);
  }

  function olGeomGetArea(olGeomPolygon) {
    return Math.abs(olGeomPolygon.getArea());
  }

  function removeZCoordinate(coordinates) {
    return coordinates.map(function (coordinate) {
      if (coordinate.length == 3) {
        coordinate.pop();
      }
    });
  }

  function setNotifiedAboutNewIndicators(isNotifiedNewIndicators) {
    $localStorage.isNotifiedNewIndicators = isNotifiedNewIndicators;
  }

  function getNotifiedAboutNewIndicators() {
    return $localStorage.isNotifiedNewIndicators || false;
  }

  function browserDetectAgent() {
    var isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
    if (!isFirefox) {
      return sendNotificationBrowser();
    }
  }

  function sendNotificationBrowser() {
    var NotificationService = $injector.get("NotificationService");
    NotificationService.showNotification(
      "warning",
      MainService.getTextByKeyI18n("notification.browser"),
      MainService.getTextByKeyI18n("notification.browsermessage"),
      true,
      8000
    );
  }

  function sendNotificationAboutCoordinatesError() {
    var NotificationService = $injector.get("NotificationService");
    NotificationService.showNotification(
      "warning",
      MainService.getTextByKeyI18n("notification.browser"),
      MainService.getTextByKeyI18n("notification.coordinatesmessage"),
      true,
      8000
    );
  }

  return {
    setIsDrawing: setIsDrawing,
    getIsDrawing: getIsDrawing,
    getCalculeAreaByFeature: getCalculeAreaByFeature,
    setVisibleVector: setVisibleVector,
    sortVectorDrawingFeaturesById: sortVectorDrawingFeaturesById,
    getStyle: getStyle,
    getStyleDefault: getStyleDefault,
    getStyleMeasure: getStyleMeasure,
    getFeatureStyle: getFeatureStyle,
    setFeaturesStyleDefault: setFeaturesStyleDefault,
    viewBoundBox: viewBoundBox,
    setViewMap: setViewMap,
    cloneFeatures: cloneFeatures,
    cloneFeature: cloneFeature,
    clearSource: clearSource,
    colorMap: colorMap,
    setAcceptTermsUse: setAcceptTermsUse,
    getAcceptTermsUse: getAcceptTermsUse,
    setNotifiedAboutPasswordChange: setNotifiedAboutPasswordChange,
    getNotifiedAboutPasswordChange: getNotifiedAboutPasswordChange,
    hiddenComponentsToPrint: hiddenComponentsToPrint,
    getMapMainInstance: getMapMainInstance,
    getProjectionMapMain: getProjectionMapMain,
    getFeaturesMeasureCollection: getFeaturesMeasureCollection,
    getVectorMeasureAreaInstance: getVectorMeasureAreaInstance,
    isVectorMeasureAreaHasFeatures: isVectorMeasureAreaHasFeatures,
    getFeaturesDrawingCollectionMapInstance: getFeaturesDrawingCollectionMapInstance,
    getVectorDrawingAreaInstance: getVectorDrawingAreaInstance,
    getFeaturesDrawingAreaInstance: getFeaturesDrawingAreaInstance,
    isVectorDrawingAreaHasFeatures: isVectorDrawingAreaHasFeatures,
    deleteFeatureVectorDrawingArea: deleteFeatureVectorDrawingArea,
    deleteFeatureChild: deleteFeatureChild,
    getSourceSelectionAreaInstance: getSourceSelectionAreaInstance,
    getVectorSelectionAreaInstance: getVectorSelectionAreaInstance,
    getFeaturesSelectionAreaInstance: getFeaturesSelectionAreaInstance,
    getSourceCacheVectorSelectionArea: getSourceCacheVectorSelectionArea,
    getSourceReportMap: getSourceReportMap,
    getAtualSourceCacheVectorSelectionArea: getAtualSourceCacheVectorSelectionArea,
    clearVectorArea: clearVectorArea,
    clearVectorMeasure: clearVectorMeasure,
    clearAllInteractionMapMain: clearAllInteractionMapMain,
    hasInteractionMapMain: hasInteractionMapMain,
    getMapViewInfo: getMapViewInfo,
    initMapMain: initMapMain,
    moveScaleLineToCopyrightDiv: moveScaleLineToCopyrightDiv,
    initLayersWmsMapMain: initLayersWmsMapMain,
    initVectorsMapMain: initVectorsMapMain,
    initMapSquare: initMapSquare,
    initMapReport: initMapReport,
    setTargetMapReport: setTargetMapReport,
    cloneSourceCacheToSourceSelectionArea: cloneSourceCacheToSourceSelectionArea,
    featureInListByCodigo: featureInListByCodigo,
    getFeaturesByRegionsSelected: getFeaturesByRegionsSelected,
    showFeaturesByRegionsSelected: showFeaturesByRegionsSelected,
    isFeatureInRegionsActive: isFeatureInRegionsActive,
    hasIntersectionBetweenBiomes: hasIntersectionBetweenBiomes,
    hasFeatureInSelectionAreaByRegionsSelected: hasFeatureInSelectionAreaByRegionsSelected,
    calcularIndiceGeral: calcularIndiceGeral,
    getBoundBoxSelectionArea: getBoundBoxSelectionArea,
    calculateAreaFeature: calculateAreaFeature,
    calculateAreaFeatureByGeom: calculateAreaFeatureByGeom,
    calculateLengthMetersByCoordinates: calculateLengthMetersByCoordinates,
    olGeomTransformProjection: olGeomTransformProjection,
    calcIndiceGeralByFeatures: calcIndiceGeralByFeatures,
    getIndiceIndicadorsByFeature: getIndiceIndicadorsByFeature,
    getTypeBaseTileMap: getTypeBaseTileMap,
    setTypeBaseTileMap: setTypeBaseTileMap,
    removeZCoordinate: removeZCoordinate,
    getFeatureOverlay: getFeatureOverlay,
    getStyleOverlay: getStyleOverlay,
    refreshSource: refreshSource,
    openWarning: openWarning,
    isInvalidSelectedArea: isInvalidSelectedArea,
    getNotifiedAboutNewIndicators: getNotifiedAboutNewIndicators,
    setNotifiedAboutNewIndicators: setNotifiedAboutNewIndicators,
    calcIndiceIndicadorByFeatures: calcIndiceIndicadorByFeatures,
    sendNotificationBrowser: sendNotificationBrowser,
    sendNotificationAboutCoordinatesError: sendNotificationAboutCoordinatesError,
    browserDetectAgent: browserDetectAgent,
  };
}

/* @ngInject */
function EstrategiaService($resource, AppService) {
  var url = AppService.getUrlApi() + "/estrategia";

  return $resource(
    url,
    { id: "@id" },
    {
      post: {
        method: "POST",
        isArray: false,
      },
      patch: {
        url: url + "/:id",
        method: "PATCH",
        isArray: false,
      },
    }
  );
}

/* @ngInject */
function GetGeometriesService($resource) {
  return function (url) {
    return $resource(url, {
      get: {
        method: "GET",
        isArray: false,
      },
    });
  };
}

export const MapServiceModule = new AppModule({
  name: "map.service",
  factories: {
    MapService,
    EstrategiaService,
    GetGeometriesService,
  },
});

// angular
//     .module('map.service', [])
//     .factory('MapService', MapService)
//     .factory('EstrategiaService', EstrategiaService)
//     .factory('GetGeometriesService', GetGeometriesService);
