import { AppModule } from "../../module-wrapper";
import { LogControllerModule } from "./log.controller";
import { LogServiceModule } from "./log.service";

// angular
//     .module('log.module', [
//         'log.controller',
//         'log.directives',
//         'log.service'
//     ]);

export const LogModule = new AppModule({
  name: "log.module",
  modules: [LogControllerModule, LogServiceModule]
});
