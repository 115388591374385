import { AppModule } from "../module-wrapper";
import angular from "angular";

/* @ngInject */
function AccessLink(AuthService) {
  return {
    restrict: "A",
    link: function(element, attrs) {
      if (AuthService.isAuthenticated()) {
        var profiles = AuthService.getProfile();
        var pass = false;
        var services;

        angular.forEach(profiles, function(profile) {
          if (profile.services) {
            services = profile.services;
          }

          angular.forEach(services, function(service) {
            if (service.status && service.nameService === attrs.accessLink) {
              pass = true;
            }
          });
        });

        if (!pass) {
          var elementType = element.prop("tagName");
          if (elementType == "A") {
            element.attr("hidden", "hidden");
          }
        }
      }
    }
  };
}

/* @ngInject */
function AccessPermission(AuthService) {
  function AcessControl($scope) {
    var service = $scope.$parent.$resolve.$$controller;
    if (service == "UserController") {
      service = "User";
    } else if (service == "GroupController") {
      service = "Group";
    } else if (service == "UserProfileController") {
      service = "UserProfile";
    }

    return service;
  }

  return {
    restrict: "A",
    link: function($scope, element, attrs) {
      var profiles = AuthService.getProfile();
      var pass = false;
      var services;
      var permissions;

      var servicePermission = AcessControl($scope);

      angular.forEach(profiles, function(profile, key) {
        if (profile.services) {
          services = profile.services;
        }

        angular.forEach(services, function(service, key) {
          if (service.status && service.nameService === servicePermission) {
            permissions = service.permissions;
            angular.forEach(permissions, function(permission, key) {
              if (permission.status && permission.value === attrs.accessPermission) {
                pass = true;
              }
            });
          }
        });
      });

      if (!pass) {
        var elementType = element.prop("tagName");
        if (elementType == "BUTTON") {
          element.attr("disabled", "disabled");
        } else if (elementType == "TABLE") {
          element.attr("hidden", "hidden");
        } else if (elementType == "INPUT") {
          element.attr("hidden", "hidden");
        }
      }
    }
  };
}

/* @ngInject */
function ForDirective($compile) {
  return {
    restrict: "A",
    scope: true,
    link: function(scope, element, attrs) {
      angular.element(function() {
        var required = document.getElementsByName(attrs.for)[0].getAttribute("ng-required");

        if (required != "false") {
          var html = "<b>*</b>";
          var template = angular.element(html);
          element.after($compile(template)(scope));
        }
      });
    }
  };
}

/* @ngInject */
function FormValidation($compile, $templateRequest) {
  return {
    restrict: "AE",
    scope: true,
    link: function(scope, element, attrs) {
      var formName;

      if (!attrs.formname && !attrs.inputname) {
        formName = attrs.$$element[0].form.attributes[0].nodeValue;
        scope.form = formName + ".$submitted || " + formName + "." + attrs.name + ".$touched";
        scope.error = formName + "." + attrs.name + ".$error";
        scope.minlength = attrs.ngMinlength;
        scope.maxlength = attrs.ngMaxlength;
        scope.pattern = attrs.placeholder;
      }

      $templateRequest("/app/core/views/form.validation.html").then(function(html) {
        var template = angular.element(html);
        var elem = angular.element(".message-alert");
        elem.after($compile(template)(scope));
      });
    }
  };
}

/* @ngInject */
function LoadingMessage() {
  return {
    restrict: "E",
    scope: {},
    link: function(scope, element, attrs) {
      var html = "<div>" + attrs.message + "</div>";

      var template = angular.element(html);
      element.append(template);

      scope.$on("hideMessage", function(event, data) {
        if (element[0].id == data.elementId) {
          element.attr("hidden", "hidden");
        }
      });
    }
  };
}

var processList = [] as any[];

/* @ngInject */
function MessageDialog() {
  return {
    restrict: "E",
    scope: {},
    link: function(scope, element, attrs) {
      scope.changeMessage = function(data) {
        var time = parseInt(attrs.time);

        element[0].innerHTML = "";
        var html = element[0].innerHTML;

        angular.forEach(data.messages, function(data, key) {
          html = html + "<div>" + data.message + "</div>";
        });

        if (html != "") {
          var template = angular.element(html);
          element.append(template);
        }
        var clearTimeoutMessage: any;
        if (time != 0) {
          clearTimeoutMessage = setTimeout(function() {
            angular.forEach(processList, function(processRun, key) {
              if (processRun[key]) {
                element.attr("hidden", "hidden");
                var index = processList.indexOf(processRun[key]);
                processList.splice(index, 1);
              }
            });
          }, time);
        }

        var option = [{ elementId: data.elementId, timeoutId: clearTimeoutMessage }];
        processList.push(option);

        angular.forEach(processList, function(processRun, key) {
          if (processRun[key]) {
            if (
              processRun[key].elementId == data.elementId &&
              processRun[key].timeoutId != option[0].timeoutId
            ) {
              clearTimeout(processRun[key].timeoutId);
              var index = processList.indexOf(processRun);
              processList.splice(index, 1);
            }
          }
        });
      };

      scope.$on("callMessage", function(event, data) {
        if (element[0].id == data.elementId) {
          element.removeAttr("hidden");
          scope.changeMessage(data);
        }
      });
    }
  };
}

/* @ngInject */
function EnterKeyPressed() {
  return {
    restrict: "A",
    scope: true,
    link: function(scope, element, attrs) {
      element.bind("keydown keypress", function(event) {
        if (event.which === 13) {
          scope.$apply(function() {
            scope.$eval(attrs.enterKeyPressed);
          });
          event.preventDefault();
        }
      });
    }
  };
}

export const MainDirectivesModule = new AppModule({
  name: "main.directives",
  directives: {
    accessLink: AccessLink,
    accessPermission: AccessPermission,
    forDirective: ForDirective,
    formValidation: FormValidation,
    loadingMessage: LoadingMessage,
    messageDialog: MessageDialog,
    enterKeyPressed: EnterKeyPressed
  }
});

// angular
//     .module('main.directives', [])
//     .directive('accessLink', AccessLink)
//     .directive('accessPermission', AccessPermission)
//     .directive('forDirective', ForDirective)
//     .directive('formValidation', FormValidation)
//     .directive('loadingMessage', LoadingMessage)
//     .directive('messageDialog', MessageDialog)
//     .directive('enterKeyPressed', EnterKeyPressed);
