import { AppModule } from "../../module-wrapper";
import angular from "angular";
import { saveAs } from "file-saver";
import { Draw } from "ol/interaction";
import * as Condition from "ol/events/condition";
import Tile from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { Feature, Overlay } from "ol";
import * as Polygon from "ol/geom/Polygon";
import * as proj from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import KMLFormat from "ol/format/KML";
import GeoJSONFormat from "ol/format/GeoJSON";
// import _ from "lodash";
import _groupBy from "lodash/groupBy";
import _flatten from "lodash/flatten";
import turfBuffer from "@turf/buffer";
import WKT from "ol/format/WKT";
import * as olExtent from "ol/extent";
import { headers } from "./map.headers";

/* @ngInject */
function MapController(
  this: any,
  MapService,
  AuthService,
  ModalService,
  ReportService,
  LayerService,
  StrategyService,
  EstrategiaService,
  $scope,
  $rootScope,
  $http,
  $q,
  $location,
  Upload,
  GetGeometriesService,
  GeographicSearchService,
  AppService,
  MapInterationsService,
  IndicatorStrategyService,
  IndicatorService,
  AxisService,
  LogService,
  IndicatorsGroupsService,
  MainService
) {
  var ctrl = this;

  ctrl.maxValueBuffer = 7381675270;
  ctrl.drawInteractionMap;
  ctrl.has_strategy = false;
  ctrl.plano_negocio = {};
  ctrl.area = {};
  ctrl.isInfoFeatureActive = false;
  ctrl.temporaryOverlay = null;
  ctrl.headers = headers;

  setTimeout(function () {
    MapService.initMapMain();
    MapService.initVectorsMapMain();
    var promise = MapService.initLayersWmsMapMain();
    promise.then(function () {
      MapService.browserDetectAgent();
      MapService.initMapSquare();
      MapService.initMapReport();
      MapService.moveScaleLineToCopyrightDiv();
      AxisService.loadAxes();
      IndicatorsGroupsService.loadIndicatorsGroups();
      IndicatorService.loadIndicators().then(function () {
        var isInitLayers = ctrl.checkStrategyRecovery();
        if (!Boolean(isInitLayers)) {
          LayerService.initLayers();
        }
      });
      ctrl.addEventMouseFeature();
    });
  });

  StrategyService.setStrategyInfo();

  ctrl.zoomIn = function () {
    var view = MapService.getMapMainInstance().getView();
    var zoom = view.getZoom();
    view.setZoom(zoom + 1);
  };

  ctrl.zoomOut = function () {
    var view = MapService.getMapMainInstance().getView();
    var zoom = view.getZoom();
    view.setZoom(zoom - 1);
  };

  ctrl.changeBiome = function () {
    ModalService.openModalLoading();
    var unitName = StrategyService.getNameUnitPlanningActive();
    MapService.clearVectorArea(false, false);
    var canGenerateResult = false;
    ctrl.getSelectionAreaInCache(unitName, canGenerateResult);
  };

  ctrl.changeUnitPlanning = function (unitPlan) {
    var unitNameOld = StrategyService.getLastNameUnitPlanningActive();
    var status = true;
    StrategyService.setStatusUnitPlanning(unitPlan.id, status);
    if (unitPlan.checked && (unitPlan.name !== unitNameOld || unitPlan.first)) {
      StrategyService.setFirstAll();
      LogService.sendButtonLog(
        "selecionar_unidade_planejamento",
        "click",
        "strategy",
        unitPlan.name
      );
    }
    if (unitPlan.name !== unitNameOld) {
      var strategy = StrategyService.getStrategyInfo();
      var paramsGeographicSearch = StrategyService.getParamsGeographicSearch();
      if (
        MapService.isVectorDrawingAreaHasFeatures() ||
        angular.isDefined(strategy.id) ||
        angular.isDefined(paramsGeographicSearch)
      ) {
        var onlyDrawing = false;
        ctrl.getBackground(
          MapService.getFeaturesDrawingAreaInstance(),
          true,
          unitPlan.name,
          onlyDrawing,
          "change_unit"
        );
      }
      ctrl.selectTypeTool("removeInteraction");
      MapService.clearAllInteractionMapMain();
      StrategyService.clearResultStrategy();
    }
  };

  ctrl.orderBy = function (column) {
    ctrl.order = column;
  };

  ctrl.upModelSlider = function (indicator) {
    ctrl.indicator = indicator;
  };

  ctrl.getOptions = function (indicador, reset) {
    if (indicador.name == "sa8_riskex") {
      indicador.options = IndicatorStrategyService.getOptionsLogarithmicSlider(indicador);
    } else if (
      indicador.name == "sa5_n_emb" ||
      indicador.name == "sa6_confli" ||
      indicador.name == "sa4_te_cpt"
    ) {
      indicador.options = IndicatorStrategyService.getOptionsIntStepSlider(indicador);
    } else {
      indicador.options = IndicatorStrategyService.getOptionsSlider(indicador);
    }
    indicador.options.onEnd = function () {
      IndicatorStrategyService.setColorFeaturesByIndicator(ctrl.indicator);
    };
    return indicador.options;
  };

  ctrl.selectByEnterGeographicSearch = function (valueWithParams, valueInput) {
    if (angular.isDefined(valueInput)) {
      if (angular.isDefined(valueWithParams)) {
        var listParams = angular.copy(StrategyService.getParamsGeographicSearch());
        valueInput = valueInput.split(" -")[0];
        var lastParam = listParams.pop();
        if (valueInput !== lastParam.params.name) {
          ctrl.geographicSearch(valueInput, false);
        } else {
          angular
            .element("#inputGeographicSearch")
            .val(valueWithParams.name + " - " + valueWithParams.uf);
          ctrl.getBoundBoxSelectionArea();
        }
      } else {
        ctrl.geographicSearch(valueInput, false);
      }
    }
  };

  $scope.$on("howToUseSimulation", function (event, args) {
    var p = args.myParam;
    ctrl.selectGeographicSearch(p);
    ctrl.selectByEnterGeographicSearch(p.name + "-" + p.uf, p.name + "-" + p.uf);
  });

  ctrl.selectGeographicSearch = function (value) {
    var url = value.api;
    var params = {
      codigo: value.code,
      name: value.name,
    };
    LogService.sendButtonStrategyLog(
      "selecionar_por_procura_geografica",
      "click",
      undefined,
      value
    );
    ctrl.haveCacheMunicipios = false;
    ctrl.haveCacheGrades = false;
    StrategyService.setParamsGeographicSearch(url, params);
    var promise = ctrl.getBackgroundByGeographicSearch(url + "/:codigo", params);
    var canGenerateResult = false;
    promise.then(function () {
      ctrl.getBackgroundFinished(canGenerateResult);
      ctrl.getBoundBoxSelectionArea();
      ctrl.resultsGeographicSearch = value;
    });
  };

  ctrl.getInfoByPageGeographicSearch = function (page, params, url) {
    var deferred = $q.defer();
    params.page = page + 1;
    GeographicSearchService(url)
      .get(params)
      .$promise.then(
        function (response) {
          if (!$.isEmptyObject(response.data)) {
            response.data.features.forEach(function (feature) {
              LogService.addInCodesLog(feature.properties.code);
            });
          }
          var unitPlan = StrategyService.getUnitPlanningActive();
          var source = ctrl.createSourceByFeatures(response.data);
          var vectorDest = MapService.getVectorSelectionAreaInstance();
          ctrl.addSourceToVector(source, vectorDest, unitPlan.name);
          deferred.resolve();
        },
        function (error) {
          deferred.resolve();
          ModalService.closeModalLoading();
        }
      );
    return deferred.promise;
  };

  ctrl.coordinatesSearch = function (coordinates, type) {
    coordinates = coordinates.replace(";", " ");
    coordinates = coordinates.replace(/,+/g, ".");
    coordinates = coordinates.trim();
    try {
      var params = {
        coordinates: "POINT(" + coordinates + ")",
        type: type,
      };
      GeographicSearchService("search/:coordinates")
        .get(params)
        .$promise.then(function (response) {
          var extent;
          var wkt;
          if (type === "grid") {
            extent = response.data.grid_extent;
            wkt = response.data.grid_wkt;
          } else {
            extent = response.data.city_extent;
            wkt = response.data.city_wkt;
          }
          if (extent !== "") {
            MapService.viewBoundBox(extent, MapService.getMapMainInstance());
            ctrl.highlightExtent(wkt, 3000);
            ctrl.isTemporaryOverlayActive = true;
          } else {
            MapService.sendNotificationAboutCoordinatesError();
          }
        });
    } catch (error) {}
  };

  ctrl.boundBoxGeographicSearch = function (value) {
    if (value.groupLabel === "coordinates") {
      ctrl.coordinatesSearch(value.name, "grid");
    } else {
      var features = MapService.getFeaturesSelectionAreaInstance() || [];
      var geographicResult = features.filter(function (feature) {
        return feature.getProperties().code === value.code;
      });
      var timeout = 3000;
      if (geographicResult.length !== 0) {
        MapService.viewBoundBox(
          geographicResult[0].getGeometry().getExtent(),
          MapService.getMapMainInstance()
        );
        // Destaca features pesquisadas que estejam na estratégia
        ctrl.highlightFeature(geographicResult[0], timeout);
        ctrl.isTemporaryOverlayActive = true;
      } else {
        try {
          var params = {
            codigo: value.code,
            boundingbox: true,
          };
          GeographicSearchService(value.api + "/:codigo")
            .get(params)
            .$promise.then(
              function (response) {
                var extent = response.data.extent;
                // response.data.geojson está no formato WKT
                var wkt = response.data.geojson;
                MapService.viewBoundBox(extent, MapService.getMapMainInstance());
                // Destaca extent de features fora da estratégia
                ctrl.highlightExtent(wkt, timeout);
                ctrl.isTemporaryOverlayActive = true;
              },
              function (error) {}
            );
        } catch (error) {}
      }
    }
  };

  ctrl.highlightFeature = function (feature, timeout) {
    if (ctrl.temporaryOverlay != null) {
      ctrl.temporaryOverlay.setMap(null);
      ctrl.temporaryOverlay = null;
    }

    var featureCloned = feature.clone();
    var tempSource = new VectorSource({
      features: [featureCloned],
    });
    var styleDefault = MapService.getStyleDefault();
    featureCloned.setStyle(MapService.getStyle("rgba(0, 0, 0, 0)", styleDefault.stroke.color, 5));
    ctrl.temporaryOverlay = new VectorLayer({
      source: tempSource,
      style: MapService.getStyle(
        "rgba(0, 0, 0, 0)", // Estilo para deixar a feature transparente
        styleDefault.stroke.color,
        5
      ),
    });
    ctrl.temporaryOverlay.setMap(MapService.getMapMainInstance());
  };

  ctrl.highlightExtent = function (wkt, timeout) {
    if (ctrl.temporaryOverlay != null) {
      ctrl.temporaryOverlay.setMap(null);
      ctrl.temporaryOverlay = null;
    }
    var format = new WKT();
    var feature = format.readFeature(wkt, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    var tempSource = new VectorSource({
      features: [feature],
    });

    var styleDefault = MapService.getStyleDefault();
    // Deixar o estilo da feature igual ao do layer
    // Evita diferenças quando dispara o evento do mouse
    feature.setStyle(MapService.getStyle("rgba(0, 0, 0, 0)", styleDefault.stroke.color, 5));
    ctrl.temporaryOverlay = new VectorLayer({
      source: tempSource,
      style: MapService.getStyle(
        "rgba(0, 0, 0, 0)", // Estilo para deixar a feature transparente
        styleDefault.stroke.color,
        5
      ),
    });
    ctrl.temporaryOverlay.setMap(MapService.getMapMainInstance());
  };

  ctrl.turnOffHighlight = function () {
    ctrl.isTemporaryOverlayActive = false;
    if (ctrl.temporaryOverlay != null) {
      ctrl.temporaryOverlay.setMap(null);
      ctrl.temporaryOverlay = null;
    }
  };

  ctrl.geographicSearch = function (geographicText, onlyMunicipios) {
    var regx = new RegExp(
      /^[-+]?([1-8]?\d((\.|,)\d+)?|90((\.|,)0+)?)(;|\s)\s*[-+]?(180((\.|,)0+)?|((1[0-7]\d)|([1-9]?\d))((\.|,)\d+)?)$/,
      "i"
    );
    if (regx.test(geographicText)) {
      var coordinates = {
        groupLabel: "coordinates",
        api: "coordinates",
        name: geographicText,
        hifen: "",
      };
      return [coordinates];
    }
    var geographicId = Number(geographicText);
    if (!isNaN(geographicId) && onlyMunicipios) {
      var params = {
        name: geographicId,
      };
      return GeographicSearchService("search")
        .get(params)
        .$promise.then(function (response) {
          var geographicIdResult = response.data;
          var results = [] as any[];
          geographicIdResult.grades.forEach(function (grades) {
            grades.groupLabel = "grades";
            grades.api = "grades";
            grades.name = geographicId;
            grades["hifen"] = "";
            results.push(grades);
          });
          geographicIdResult.municipios.forEach(function (municipios) {
            municipios.groupLabel = "municipios";
            municipios.api = "municipios";
            municipios["hifen"] = "-";
            results.push(municipios);
          });
          const groups = Object.values(_groupBy(results, (x) => x.groupLabel)).map((group) => {
            group[0].firstInGroup = true;
            return group;
          });
          const out = _flatten(groups);
          return out;
        });
    } else {
      if (geographicText.length >= 3) {
        params = {
          name: geographicText,
        };
        return GeographicSearchService("search")
          .get(params)
          .$promise.then(
            function (response) {
              var geographicResult = response.data;
              var results = [] as any[];
              if (!onlyMunicipios) {
                geographicResult.estados.forEach(function (estados) {
                  estados.groupLabel = "estados";
                  estados.api = "estados";
                  estados["hifen"] = "-";
                  results.push(estados);
                });
                geographicResult.biomes.forEach(function (biomes) {
                  var biomesName = biomes.name;
                  biomes.name = biomes.label;
                  biomes.label = biomesName;
                  biomes.groupLabel = "biomas";
                  biomes.api = "biomes";
                  biomes["hifen"] = "";
                  results.push(biomes);
                });
                geographicResult.mesorregioes.forEach(function (mesorregioes) {
                  mesorregioes.groupLabel = "mesorregioes";
                  mesorregioes.api = "mesorregioes";
                  mesorregioes["hifen"] = "-";
                  results.push(mesorregioes);
                });
                geographicResult.microrregioes.forEach(function (microrregioes) {
                  microrregioes.groupLabel = "microrregioes";
                  microrregioes.api = "microrregioes";
                  microrregioes["hifen"] = "-";
                  results.push(microrregioes);
                });
              }
              geographicResult.municipios.forEach(function (municipios) {
                municipios.groupLabel = "municipios";
                municipios.api = "municipios";
                municipios["hifen"] = "-";
                results.push(municipios);
              });
              //usando biblioteca lodash;
              const groups = Object.values(_groupBy(results, (x) => x.groupLabel)).map((group) => {
                group[0].firstInGroup = true;
                return group;
              });
              const out = _flatten(groups);
              return out;
            },
            function (error) {}
          );
      }
    }
  };

  ctrl.selectTypeTool = function (event, kml) {
    MapService.clearAllInteractionMapMain();
    var features = MapService.getFeaturesSelectionAreaInstance() || [];
    var styleDefault = MapService.getStyleDefault();
    features.forEach(function (feature) {
      ctrl.toggleStrokeMouseHover(feature, styleDefault.stroke.width, false, false);
    });
    if (event === ctrl.typeTool || event === "removeInteraction") {
      if (kml) {
        LogService.sendButtonLog("selecionar_por_kml", "click", "strategy");
      }
      ctrl.typeTool = null;
    } else {
      ctrl.typeTool = event;
    }
    MapService.setIsDrawing(false);
    if (ctrl.typeTool === "Polygon" || ctrl.typeTool === "Point") {
      var name = "selecionar_por_" + ctrl.typeTool.toLowerCase();
      LogService.sendButtonLog(name, "click", "strategy");
      MapService.setIsDrawing(true);
      ctrl.addInteraction(ctrl.typeTool);
    }
  };

  ctrl.escapeEscDraw = function () {
    angular.element(document).keyup(function (e) {
      if (e.keyCode === 27) {
        if ((ctrl.typeTool && MapService.getIsDrawing()) || ctrl.typeTool == "measuring") {
          var map = MapService.getMapMainInstance();
          MapService.getVectorDrawingAreaInstance().setVisible(false);
          MapService.clearAllInteractionMapMain();
          MapService.clearVectorMeasure();
          map.getOverlays().clear();
          if (ctrl.typeTool != "measuring") {
            ctrl.addInteraction(ctrl.typeTool);
          }
        }
      }
    });
  };

  ctrl.removeInteraction = function () {
    ctrl.escapeEscDraw();
    MapService.getMapMainInstance().removeInteraction(ctrl.drawInteractionMap);
    ctrl.typeTool = "measuring";
    ctrl.drawInteractionMap = null;
  };

  ctrl.addInteraction = function (type) {
    var styleDefault = MapService.getStyleDefault();
    ctrl.escapeEscDraw();
    MapService.getMapMainInstance().getOverlays().clear();
    MapService.clearVectorMeasure();

    ctrl.drawInteractionMap = new Draw({
      features: MapService.getFeaturesDrawingCollectionMapInstance(),
      type: type,
      style: MapService.getStyle(
        styleDefault.fill.color,
        styleDefault.stroke.color,
        styleDefault.stroke.width
      ),
      freehandCondition: Condition.never, //ol.events.condition.never
    });

    ctrl.drawInteractionMap.on("drawstart", function (evt) {
      MapService.setIsDrawing(true);
      ctrl.haveCacheMunicipios = false;
      ctrl.haveCacheGrades = false;
      MapService.setVisibleVector(MapService.getVectorDrawingAreaInstance(), true);
    });

    ctrl.drawInteractionMap.on("drawend", function (evt) {
      var typeGeom = evt.feature.getGeometry().getType();
      ctrl.openModalDrawingFeature(evt.feature, false, typeGeom);
      if (typeGeom !== "Point") {
        MapService.viewBoundBox(
          evt.feature.getGeometry().getExtent(),
          MapService.getMapMainInstance()
        );
      }
      MapService.getMapMainInstance().getOverlays().clear();
    });

    MapService.getMapMainInstance().addInteraction(ctrl.drawInteractionMap);
    LayerService.setOpacityLayer(MapService.getVectorSelectionAreaInstance(), 100 / 100);
  };

  ctrl.openModalDrawingFeature = function (obj, isKml, type) {
    ModalService.openModalDrawingFeature(obj, isKml, type, $scope);
    if (isKml) {
      obj.forEach(function (feature) {
        MapService.sortVectorDrawingFeaturesById(feature, isKml);
      });
    } else {
      MapService.sortVectorDrawingFeaturesById(obj, isKml);
    }
  };

  ctrl.openModalGeographicSearch = function () {
    ModalService.openModalGeographicSearch($scope);
    ctrl.turnOffHighlight();
  };

  ctrl.addEventMouseFeature = function () {
    ctrl.typeTool = null;
    ctrl.mouseHoverEventFeature();
    ctrl.mouseClickEventFeature();
    ctrl.mouseClickInfoFeature();
    ctrl.mouseHoverInfoFeature();
  };

  ctrl.isInfoFeatureActivated = function () {
    if (!ctrl.isInfoFeatureActive) {
      ctrl.isInfoFeatureActive = true;
    } else {
      ctrl.isInfoFeatureActive = false;
    }
  };

  ctrl.getFeatureState = function () {
    return ctrl.isInfoFeatureActive;
  };

  ctrl.isTemporaryOverlayActivated = function () {
    ctrl.isTemporaryOverlayActive = !ctrl.isTemporaryOverlayActive;
  };

  ctrl.getTemporaryOverlayState = function () {
    return ctrl.isTemporaryOverlayActive;
  };

  ctrl.mouseClickEventFeature = function () {
    MapService.getMapMainInstance().on("click", function (e) {
      MapService.getMapMainInstance().forEachFeatureAtPixel(e.pixel, function (feature) {
        if (feature.getProperties().code) {
          if (!MapService.getIsDrawing()) {
            ModalService.openModalFeature(feature, $scope);
          }
        }
      });
    });
  };

  ctrl.mouseClickInfoFeature = function () {
    var map = MapService.getMapMainInstance();
    map.on("singleclick", function (evt) {
      var lang = MainService.getLocaleLanguageSystem();
      if (evt.dragging) {
        if (document.getElementById("tooltip")) {
          document.getElementById("tooltip")!.innerHTML = "";
        } else {
          $('<div id="tooltip" class="layers-tooltip bg-color-default-1"></div>').appendTo("#map");
        }
        map.getTargetElement().style.cursor = "";
        return;
      }
      if (
        !StrategyService.getIsInStrategyTab() &&
        !StrategyService.hasStrategy() &&
        ctrl.isInfoFeatureActive
      ) {
        var layerAtPixel = map.forEachLayerAtPixel(evt.pixel, function (layer) {
          if (layer instanceof Tile) {
            return LayerService.getLayerByMapserverName(layer.getProperties().name);
          }
          return null;
        });
        var viewResolution = /** @type {number} */ map.getView().getResolution();
        // Here all the layers in the wmsSource will be added to the URL
        if (
          StrategyService.isSelectedRegion &&
          layerAtPixel &&
          !LayerService.isMaskLayer(layerAtPixel.name) &&
          LayerService.isTooltipLayer(layerAtPixel.name)
        ) {
          map.getTargetElement().style.cursor = "pointer";
          var wmsSource = new TileWMS({
            url: AppService.getUrlWmsApi(),
            params: {
              LAYERS: layerAtPixel.mapserver_name,
              crs: "EPSG:3857",
            },
            crossOrigin: "anonymous",
          });

          var srcParams = wmsSource.getParams();

          if (srcParams["LAYERS"]) {
            var url = wmsSource.getGetFeatureInfoUrl(evt.coordinate, viewResolution, "EPSG:3857", {
              INFO_FORMAT: "geojson",
            });
            $http({
              method: "GET",
              url: url,
            }).then(function (response) {
              if (document.getElementById("tooltip")) {
                document.getElementById("tooltip")!.innerHTML = "";
              } else {
                $('<div id="tooltip" class="layers-tooltip bg-color-default-1"></div>').appendTo(
                  "#map"
                );
              }

              var data = response.data;
              if (data.features != undefined) {
                if (data.features.length > 0 && !MapService.getIsDrawing()) {
                  var info = new Overlay({});

                  var infoFeatures = data.features[0].properties;
                  var keys = Object.keys(infoFeatures);
                  $(
                    '<div class="modal-header header-default"><span class="tooltip-title">' +
                      layerAtPixel.label +
                      "</span></div>"
                  ).appendTo("#tooltip");

                  $('<div id="tooltip-body-content" class="tooltip-body"></div>').appendTo(
                    "#tooltip"
                  );

                  var propertiesList = Array();
                  for (var key in keys) {
                    var header = keys[key];
                    var properties = infoFeatures[header];

                    if (ctrl.headers[header] != undefined) {
                      var translated_header = ctrl.headers[header][lang];

                      if (properties) {
                        propertiesList.push(properties)
                        if( data.name == "assentamentos" ){
                          if( header != "capacidade" ){
                            $(
                              '<span class="tooltip-body-text">' +
                                translated_header +
                                ': </span><span class="textvalue-displayinfo">' +
                                properties +
                                "</span><br>"
                            ).appendTo("#tooltip-body-content");
                          }
                        } else {
                          $(
                            '<span class="tooltip-body-text">' +
                              translated_header +
                              ': </span><span class="textvalue-displayinfo">' +
                              properties +
                              "</span><br>"
                          ).appendTo("#tooltip-body-content");
                        }
                      }
                    }
                  }
                  if (propertiesList.length <= 0) {
                    $("#tooltip #tooltip-body-content").remove();
                  }

                  info.setElement(document.getElementById("tooltip")!);
                  info.setPosition(evt.coordinate);
                  map.addOverlay(info);
                }
              }
            });
          }
        }
      }
    });
  };

  ctrl.mouseHoverInfoFeature = function () {
    var map = MapService.getMapMainInstance();
    map.on("pointermove", function (evt) {
      if (ctrl.isInfoFeatureActive) {
        if (evt.dragging) {
          return;
        }
        if (Boolean(!MapService.getIsDrawing())) {
          var pixel = map.getEventPixel(evt.originalEvent);
          var layerAtPixel = map.forEachLayerAtPixel(pixel, function (layer) {
            return layer;
          });

          var layer = map.forEachLayerAtPixel(evt.pixel, function (layer) {
            if (layer instanceof Tile) {
              return LayerService.getLayerByMapserverName(layer.getProperties().name);
            }
            return null;
          });
          if (
            layerAtPixel != undefined &&
            !LayerService.isMaskLayer(layer.name) &&
            LayerService.isTooltipLayer(layer.name)
          ) {
            map.getTargetElement().style.cursor = "pointer";
          } else {
            if (document.getElementById("tooltip")) {
              document.getElementById("tooltip")!.innerHTML = "";
            } else {
              $('<div id="tooltip" class="layers-tooltip bg-color-default-1"></div>').appendTo(
                "#map"
              );
            }
            map.getTargetElement().style.cursor = "";
          }
        }
      }
    });
  };

  ctrl.mouseHoverEventFeature = function () {
    MapService.getMapMainInstance().on("pointermove", function (event) {
      if (event.dragging) {
        return;
      }
      if (Boolean(!MapService.getIsDrawing())) {
        changeMouseCursor(event.pixel);
      }
      MapService.getMapMainInstance().forEachFeatureAtPixel(event.pixel, function (feature, layer) {
        if (Boolean(!MapService.getIsDrawing())) {
          var styleDefault = MapService.getStyleDefault();
          var featuresOverlay = MapService.getFeatureOverlay().getFeatures();
          var codeCurrentFeatureOverlayed = null;
          var isOverlay = true;
          var isText = true;
          // volta ao style default sem o evento do mousehover
          if (featuresOverlay.length !== 0) {
            codeCurrentFeatureOverlayed = featuresOverlay[0].getProperties().code;
            var featureOriginal = StrategyService.getFeatureByCode(codeCurrentFeatureOverlayed);
            if (angular.isDefined(featureOriginal)) {
              ctrl.toggleStrokeMouseHover(
                featureOriginal,
                styleDefault.stroke.width,
                !isText,
                !isOverlay
              );
              MapService.getFeatureOverlay().clear();
            }
          }
          if (feature.getProperties().code !== codeCurrentFeatureOverlayed) {
            var featureCloned = feature.clone();
            MapService.getFeatureOverlay().addFeature(featureCloned);
            ctrl.toggleStrokeMouseHover(
              featureCloned,
              styleDefault.strokeHover.width,
              isText,
              isOverlay
            );
          }
        }
      });
    });
    MapService.getMapMainInstance()
      .getViewport()
      .addEventListener(
        "mouseout",
        function () {
          angular.element("#" + MapService.getMapMainInstance().getTarget())[0].style.cursor = "";
        },
        false
      );
  };

  ctrl.toggleStrokeMouseHover = function (feature, newWidth, isTextValue, isOverlay) {
    if (feature.getStyle() != null) {
      var style;
      var colorStroke = feature.getStyle().getStroke().getColor();
      var text;
      if (feature.get("name") != undefined) {
        text = isTextValue ? feature.get("name") + " - " + feature.get("uf") : "";
      }
      if (Boolean(isOverlay)) {
        style = MapService.getStyleOverlay(colorStroke, newWidth, text);
      } else {
        var colorFill = feature.getStyle().getFill().getColor();
        style = MapService.getStyle(colorFill, colorStroke, newWidth, text);
      }
      feature.setStyle(style);
    }
  };

  function changeMouseCursor(pixel) {
    var layer = MapService.getMapMainInstance().forEachLayerAtPixel(pixel, function (layer) {
      if (layer == MapService.getVectorSelectionAreaInstance()) {
        return layer;
      }
    });
    if (layer != undefined) {
      angular.element("#" + MapService.getMapMainInstance().getTarget())[0].style.cursor =
        "pointer";
    } else {
      angular.element("#" + MapService.getMapMainInstance().getTarget())[0].style.cursor = "";
      MapService.getFeatureOverlay().clear();
    }
  }

  ctrl.previewAreaBuffer = function (obj, bufferLength, isKml) {
    var features = [] as any[];
    if (Boolean(features)) {
      features = Boolean(isKml) ? obj : [obj];
    }
    if (bufferLength > 0) {
      features.forEach(function (feature) {
        var typeGeom = feature.getGeometry().getType();
        if (typeGeom !== "Polygon" || !isKml) {
          ctrl.addBufferit(feature, bufferLength, isKml);
        }
      });
      MapService.viewBoundBox(
        MapService.getVectorDrawingAreaInstance().getSource().getExtent(),
        MapService.getMapMainInstance()
      );
    } else {
      features.forEach(function (feature) {
        MapService.deleteFeatureChild(feature);
      });
    }
  };

  ctrl.getAreaFeature = function (obj, isKml) {
    var features = [] as any[];
    var areaFeature = 0;
    if (Boolean(features)) {
      features = Boolean(isKml) ? obj : [obj];
    }
    features.forEach(function (feature) {
      feature = MapService.getCalculeAreaByFeature(feature);
      if (feature.getGeometry().getType() !== "Point") {
        areaFeature += MapService.calculateAreaFeature(feature);
      }
    });
    return areaFeature;
  };

  ctrl.validCalcAreaFeature = function (obj, isKml) {
    var features = [] as any[];
    var valid = true;
    if (Boolean(features)) {
      features = Boolean(isKml) ? obj : [obj];
    }
    features.forEach(function (feature) {
      feature = MapService.getCalculeAreaByFeature(feature);
      if (feature.getGeometry().getType() === "Point") {
        valid = false;
      }
    });
    return !valid;
  };

  ctrl.addBufferit = function (feature, bufferLength, isKml) {
    if (bufferLength > 0) {
      var radiusInt = parseInt(bufferLength) * 1000;
      var bufferedFeature;

      if (feature.getGeometry().getType() == "Point") {
        bufferedFeature = ctrl.bufferitCircle(feature, radiusInt);
      } else if (feature.getGeometry().getType() == "Polygon") {
        bufferedFeature = ctrl.bufferitSquare(feature, radiusInt);
      }
      bufferedFeature.setProperties({
        idFather: feature.getId(),
        is_buffer: true,
      });
      MapService.deleteFeatureChild(feature);
      MapService.sortVectorDrawingFeaturesById(bufferedFeature, true);
      return bufferedFeature;
    }
  };

  ctrl.bufferitSquare = function (feature: Feature, radius: number) {
    const parser = new GeoJSONFormat();
    const geoJson = parser.writeFeatureObject(feature, {
      featureProjection: "EPSG:3857",
      dataProjection: "EPSG:4326",
    });
    const turfBuffered = turfBuffer(geoJson, radius / 1000);
    const olBuffered = parser.readFeature(turfBuffered, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
    return olBuffered;
    // var parser = new jsts.io.OL3Parser();
    // var jstsGeom = parser.read(feature.getGeometry()) as any;
    // var buffered = jstsGeom.buffer(radius);
    // var bufferedFeature = new Feature();
    // bufferedFeature.setGeometry(parser.write(buffered));
    // return bufferedFeature;
  };

  ctrl.bufferitCircle = function (feature, radius) {
    // var wgs84Sphere = new Sphere(6378137);
    // var pointExtent = feature.getGeometry().getExtent();
    // var bufferedExtent = new ol.extent.buffer(pointExtent, radius);
    var center = feature.getGeometry().getCoordinates();
    var circle = Polygon.circular(/*wgs84Sphere, */ proj.toLonLat(center), radius);
    circle.transform("EPSG:4326", "EPSG:3857");
    var bufferedFeature = new Feature(circle);
    return bufferedFeature;
  };

  ctrl.processSelectionArea = function (obj, isKml, type) {
    var features = [] as any[];
    if (Boolean(isKml)) {
      type = "kml";
    }
    if (Boolean(features)) {
      features = Boolean(isKml) ? obj : [obj];
    }
    var listFeatures = [] as any[];
    features.forEach(function (feature) {
      feature = ctrl.getFeatureBuffer(feature);
      listFeatures.push(feature);
    });
    var unitPlan = StrategyService.getUnitPlanningActive();
    var onlyDrawing = true;
    ctrl.getBackground(listFeatures, false, unitPlan.name, onlyDrawing, type);
    ctrl.selectTypeTool("removeInteraction");
  };

  ctrl.getFeatureBuffer = function (feature) {
    var features = MapService.getFeaturesDrawingAreaInstance();
    features.forEach(function (featureAux) {
      if (feature.getId() === featureAux.getProperties().idFather) {
        feature = featureAux;
      }
    });
    return feature;
  };

  ctrl.uploadFile = function (file) {
    ctrl.haveCacheMunicipios = false;
    ctrl.haveCacheGrades = false;
    if (file) {
      ModalService.openModalLoading();
      // var kml;
      var type = file.type;
      var kmzOrkmlOrcsv = file.name.substr(-3);
      if (type.match(".kmz") != null || kmzOrkmlOrcsv === "kmz") {
        ctrl.loadKmzFile(file);
      } else if (type.match(".kml") != null || kmzOrkmlOrcsv === "kml") {
        ctrl.loadKmlFile(file);
      } else if (
        type.match("application/vnd.ms-excel") != null ||
        type.match(".csv") != null ||
        kmzOrkmlOrcsv === "csv"
      ) {
        ctrl.loadcsv(file);
      }
    }
  };

  ctrl.loadcsv = function (csv) {
    var text;
    if (csv) {
      var fr = new FileReader();
      fr.onload = function (e) {
        text = fr.result;
        text = text.trim();
        text = text.replace(/\r?\n|\r/g, ",");
        var tokens = text.split(",");
        tokens.forEach(function (t) {
          var result = ctrl.geographicSearch(t, true);
          result.then(function (r) {
            ctrl.selectGeographicSearch(r[0]);
          });
        });
      };
      fr.readAsText(csv);
    }
    ModalService.closeModalLoading();
  };

  ctrl.loadKmlFile = function (kml) {
    if (kml) {
      var fr = new FileReader();
      fr.onload = function (event: any) {
        ctrl.processKmlMap(event.currentTarget.result, MapService.getMapMainInstance());
      };
      fr.readAsText(kml);
    }
  };

  ctrl.loadKmzFile = function (kmz) {
    if (kmz) {
      var upload = Upload.upload({
        url: AppService.getUrlApi() + "/convert_kmz",
        data: { file: kmz },
      });
      upload.then(
        function (resp) {
          ctrl.processKmlMap(resp.data, MapService.getMapMainInstance());
        },
        function (error) {
          ModalService.closeModalLoading();
        }
      );
    }
  };

  ctrl.processKmlMap = function (kml, map) {
    if (kml) {
      ModalService.closeModalLoading();
      ctrl.vectorKml = new VectorLayer({
        source: new VectorSource({
          features: new KMLFormat().readFeatures(kml),
        }),
      });
      var projection = new KMLFormat().readProjection(kml);
      var source = ctrl.transformProjectionFeature(ctrl.vectorKml.getSource(), projection);
      map.addLayer(ctrl.vectorKml);
      ctrl.processFeaturesKml(ctrl.setIdInitialKml(source));
    }
  };

  ctrl.transformProjectionFeature = function (source, projection) {
    source.getFeatures().forEach(function (feature) {
      feature.setGeometry(
        MapService.olGeomTransformProjection(
          feature.getGeometry(),
          projection.getCode(),
          MapService.getProjectionMapMain().getCode()
        )
      );
    });
    return source;
  };

  ctrl.setIdInitialKml = function (source) {
    source.getFeatures().forEach(function (feature, i) {
      feature.setId(i + 1);
    });
    return source;
  };

  ctrl.processFeaturesKml = function (source) {
    ctrl.haveCacheMunicipios = false;
    ctrl.haveCacheGrades = false;
    var havePointInKml = false;
    var features = source.getFeatures();
    ctrl.vectorKml.setVisible(false);
    features.forEach(function (feature) {
      if (feature.getGeometry().getType() == "Point") {
        havePointInKml = true;
      }
    });
    if (havePointInKml) {
      MapService.setVisibleVector(MapService.getVectorDrawingAreaInstance(), true);
      ctrl.openModalDrawingFeature(features, true);
    } else {
      features.forEach(function (feature) {
        MapService.sortVectorDrawingFeaturesById(feature, true);
      });
      var unitPlan = StrategyService.getUnitPlanningActive();
      var onlyDrawing = true;
      ctrl.getBackground(features, false, unitPlan.name, onlyDrawing, "kml");
    }
    MapService.clearAllInteractionMapMain();
  };

  ctrl.getInfoByCoordinates = function (coordinates, area, url, visible, type) {
    var deferred = $q.defer();
    var promises = [] as Promise<any>[];
    var params = {} as Record<string, any>;

    MapService.removeZCoordinate(coordinates);
    params.geom = JSON.stringify(coordinates);
    params.municipios = JSON.stringify(area.municipios_codigo);
    params.grades = JSON.stringify(area.grades_codigo);
    params.projection = MapService.getProjectionMapMain().getCode();

    var urlService = AppService.getUrlApi() + "/areas/" + url + "/count";
    GetGeometriesService(urlService)
      .get(params)
      .$promise.then(
        function (response) {
          var count = response.data.count;
          if (count > 0) {
            var pages = Array.apply(null, { length: count } as any).map(Function.call, Number);
            pages.forEach(function (page) {
              var promise = ctrl.getInfoByPage(page, params, url);
              promises.push(promise);
            });
            $q.all(promises).then(function () {
              LogService.sendAreaOfStudyLog(type, url);
              deferred.resolve();
            });
          } else {
            deferred.resolve();
          }
        },
        function (error) {
          deferred.resolve();
        }
      )
      .finally(function () {
        MapService.setVisibleVector(MapService.getVectorDrawingAreaInstance(), false);
      });
    return deferred.promise;
  };

  ctrl.getInfoByPage = function (page, params, url) {
    var deferred = $q.defer();
    var urlService = AppService.getUrlApi() + "/areas/" + url;
    params.page = page + 1;
    GetGeometriesService(urlService)
      .get(params)
      .$promise.then(
        function (response) {
          var unitPlan = StrategyService.getUnitPlanningActive();
          if (response.success && !$.isEmptyObject(response.data)) {
            response.data.features.forEach(function (feature) {
              LogService.addInCodesLog(feature.properties.code);
            });
            var source = ctrl.createSourceByFeatures(response.data);
            var vectorDest = MapService.getVectorSelectionAreaInstance();
            ctrl.addSourceToVector(source, vectorDest, unitPlan.name);
          }
          deferred.resolve();
        },
        function (error) {
          deferred.resolve();
          ModalService.closeModalLoading();
        }
      );
    return deferred.promise;
  };

  ctrl.createSourceByFeatures = function (features) {
    var source = new VectorSource({
      features: new GeoJSONFormat().readFeatures(features),
      format: new GeoJSONFormat(),
    });
    return source;
  };

  ctrl.addSourceToVector = function (source, vectorDest, unitPlan) {
    var sourceCache = MapService.getSourceCacheVectorSelectionArea(unitPlan);
    source.getFeatures().forEach(function (feature) {
      if (MapService.featureInListByCodigo(feature, sourceCache.getFeatures())) {
        source.removeFeature(feature);
      } else {
        feature.setGeometry(
          MapService.olGeomTransformProjection(
            feature.getGeometry(),
            feature.getProperties().epsg,
            MapService.getProjectionMapMain().getCode()
          )
        );
        StrategyService.addFeature(feature, unitPlan);
        vectorDest.getSource().addFeature(feature);
        MapService.getSourceCacheVectorSelectionArea(unitPlan).addFeature(feature);
      }
    });
  };

  ctrl.getBackground = function (features, clearSelectionArea, unitPlan, onlyDrawing, type) {
    ModalService.openModalLoading();
    angular.element(function () {
      ReportService.setReportCached(false);
      ReportService.setReportCachedExportPdf(false);

      var source = MapService.getSourceCacheVectorSelectionArea(unitPlan);
      var area = StrategyService.createArea(source.getFeatures());
      var strategy = StrategyService.getStrategyInfo();
      var paramsGeographicSearch = StrategyService.getParamsGeographicSearch();
      var canGenerateResult = false;

      if (clearSelectionArea) {
        MapService.getVectorSelectionAreaInstance().getSource().clear();
      }

      if (
        (Boolean(!ctrl.haveCacheMunicipios) && unitPlan == "municipios") ||
        (Boolean(!ctrl.haveCacheGrades) && unitPlan == "grades")
      ) {
        var promises = [] as Promise<any>[];
        var promise;
        features.forEach(function (feature) {
          var typeGeom = feature.getGeometry().getType();
          if (typeGeom !== "Point") {
            var coordinates = feature.getGeometry().getCoordinates();
            var coordinatesFormat =
              typeGeom === "MultiPolygon" ? coordinates[0][0] : coordinates[0];
            promise = ctrl.getInfoByCoordinates(coordinatesFormat, area, unitPlan, true, type);
            promises.push(promise);
          }
        });
        $q.all(promises).then(function () {
          promises = [];
          if (
            (angular.isUndefined(strategy.area) && angular.isUndefined(paramsGeographicSearch)) ||
            onlyDrawing
          ) {
            ctrl.getBackgroundFinished(canGenerateResult);
          } else {
            if (angular.isDefined(strategy.area) && !onlyDrawing) {
              promise = ctrl.getBackgroundStrategy(unitPlan, strategy);
              promises.push(promise);
            }
            if (angular.isDefined(paramsGeographicSearch) && !onlyDrawing) {
              paramsGeographicSearch.forEach(function (paramGeographicSearch) {
                promise = ctrl.getBackgroundByGeographicSearch(
                  paramGeographicSearch.url + "/:codigo",
                  paramGeographicSearch.params
                );
                promises.push(promise);
              });
            }
            $q.all(promises).then(function () {
              ctrl.getBackgroundFinished(canGenerateResult);
            });
          }
        });
        ctrl.setStatusCacheUnitPlanning(unitPlan, true);
      } else {
        ctrl.getSelectionAreaInCache(unitPlan, canGenerateResult);
      }
    });
  };

  ctrl.getBackgroundStrategy = function (unitPlan, strategy) {
    var deferred = $q.defer();
    var promises = [] as Promise<any>[];
    var promise;
    if (unitPlan === "municipios") {
      strategy.area.municipios.forEach(function (municipio_id) {
        promise = ctrl.getFeaturesSelectionArea(unitPlan, municipio_id, strategy);
        promises.push(promise);
      });
    } else if (unitPlan === "grades") {
      strategy.area.grades.forEach(function (grade_id) {
        promise = ctrl.getFeaturesSelectionArea(unitPlan, grade_id, strategy);
        promises.push(promise);
      });
    }
    $q.all(promises).then(function () {
      deferred.resolve();
    });
    return deferred.promise;
  };

  ctrl.getBackgroundByGeographicSearch = function (url, params) {
    var unitPlan = StrategyService.getNameUnitPlanningActive();
    var deferred = $q.defer();
    var promises = [] as Promise<any>[];
    GeographicSearchService(url + "/count")
      .get(params)
      .$promise.then(
        function (response) {
          var count = response.data.count;
          if (count > 0) {
            ModalService.openModalLoading();
            var pages = Array.apply(null, { length: count } as any).map(Function.call, Number);
            pages.forEach(function (page) {
              var promise = ctrl.getInfoByPageGeographicSearch(page, params, url);
              promises.push(promise);
            });
            $q.all(promises).then(function () {
              deferred.resolve();
              ctrl.setStatusCacheUnitPlanning(unitPlan, true);
              LogService.sendAreaOfStudyLog("procura_geografica", unitPlan, params);
            });
          } else {
            deferred.resolve();
          }
        },
        function (error) {
          deferred.resolve();
          ModalService.closeModalLoading();
        }
      );
    return deferred.promise;
  };

  ctrl.getBackgroundFinished = function (canGenerateResult) {
    var promises = [] as Promise<any>[];
    var promise;

    var unitPlan = StrategyService.getNameUnitPlanningActive();
    StrategyService.clearResultStrategy();
    if (Boolean(!StrategyService.getStatusNotificationRegions())) {
      MapService.hasIntersectionBetweenBiomes();
    }
    if (ctrl.vectorKml != undefined) {
      ctrl.vectorKml.setVisible(false);
    }

    var layout = AppService.getLayout();
    var systemCountry = AppService.getLayersNameUnits().municipios;

    if (
      !MapService.getNotifiedAboutNewIndicators() &&
      layout == "soja" &&
      systemCountry == "municipios_brasileiros_cerrado_amazonia"
    ) {
      ModalService.openTourNewIndicatorsSoy();
    }

    var features;
    var strategy = StrategyService.getStrategyInfo();
    var sourceCache = MapService.getSourceCacheVectorSelectionArea(unitPlan);
    var source = MapService.getSourceSelectionAreaInstance();

    if (Boolean(!canGenerateResult)) {
      if (source.getFeatures().length !== sourceCache.getFeatures().length) {
        MapService.cloneSourceCacheToSourceSelectionArea(sourceCache, source);
      }
      features = MapService.getFeaturesByRegionsSelected(source);
      MapService.showFeaturesByRegionsSelected();
      MapService.setFeaturesStyleDefault(features);

      promise = IndicatorStrategyService.updateValuesIndicators(features);
      promises.push(promise);
    } else {
      if (!Boolean(angular.equals(strategy.plano, []))) {
        if (!Boolean(angular.equals(strategy.plano.values, []))) {
          features = MapService.getFeaturesByRegionsSelected(source);
          promise = IndicatorStrategyService.updateValuesIndicators(features, strategy.plano);
          promises.push(promise);
          strategy.plano.values.forEach(function (value) {
            var indicator = IndicatorService.getIndicatorByName(value.indicador);
            if (indicator) {
              promise = IndicatorStrategyService.loadFeaturesValueIndicator(indicator);
              promises.push(promise);
            }
          });
          $q.all(promises).then(function () {
            var close_loading = true,
              send_log = true,
              is_function = true;
            StrategyService.generateResultStrategy(send_log, close_loading, is_function);
          });
        }
      }
    }
    if (MapService.hasFeatureInSelectionAreaByRegionsSelected()) {
      MapService.getBoundBoxSelectionArea();
    }
    $q.all(promises).then(function () {
      ModalService.closeModalLoading();
    });
    if (ctrl.isTemporaryOverlayActive) {
      var highlightExtent = ctrl.temporaryOverlay
        .getSource()
        .getFeatures()[0]
        .getGeometry()
        .getExtent();
      features.forEach(function (feature) {
        var featureExtent = feature.getGeometry().getExtent();
        if (
          olExtent.containsExtent(featureExtent, highlightExtent) ||
          olExtent.containsExtent(highlightExtent, featureExtent)
        ) {
          ctrl.turnOffHighlight();
        }
      });
    }
  };

  ctrl.setStatusCacheUnitPlanning = function (unitPlan, status) {
    if (unitPlan === "municipios") {
      ctrl.haveCacheMunicipios = status;
    } else if (unitPlan === "grades") {
      ctrl.haveCacheGrades = status;
    }
  };

  ctrl.getSelectionAreaInCache = function (unitPlan, canGenerateResult) {
    var source = MapService.getVectorSelectionAreaInstance().getSource();
    var sourceCache = MapService.getSourceCacheVectorSelectionArea(unitPlan);
    MapService.setFeaturesStyleDefault(sourceCache.getFeatures());
    MapService.cloneFeatures(sourceCache.getFeatures(), source);
    ctrl.getBackgroundFinished(canGenerateResult);
  };

  ctrl.clearStrategyPlan = function () {
    delete ctrl.plano_negocio.id;
    var indicators = IndicatorService.getIndicators(indicators);
    indicators.forEach(function (indicator) {
      if (angular.isDefined(indicator.plan_id)) {
        delete indicator.plan_id;
      }
    });
  };

  ctrl.salvarPlano = function () {
    ctrl.plano_negocio.values = [];
    var listIndicatorsActive = IndicatorService.getIndicatorsActive() || [];
    if (listIndicatorsActive) {
      listIndicatorsActive.forEach(function (indicador) {
        var plano_dict = {
          max_value: indicador.maxValue,
          min_value: indicador.minValue,
          weight: indicador.weight,
          relacao: indicador.inverse_relationship,
          indicador: indicador.name,
          id: indicador.plan_id != 0 ? indicador.plan_id : undefined,
        };
        ctrl.plano_negocio.values.push(plano_dict);
      });
    }
    return ctrl.plano_negocio;
  };

  ctrl.salvarEstrategia = function (
    hide_modal_link,
    send_log_report,
    open_modal_loading,
    close_modal_loading,
    is_function,
    override_strategy
  ) {
    var deferred = $q.defer();
    if (open_modal_loading) {
      ModalService.openModalLoading();
    }
    angular.element("#modalNameStrategy").modal("hide");
    ctrl.selectTypeTool("removeInteraction");
    $rootScope.isSavingStrategy = true;

    var format = is_function ? "function" : "click";

    AuthService.checkToken();

    var strategy = StrategyService.getStrategyInfo();

    if (!override_strategy) {
      ctrl.clearStrategyPlan();
    }

    strategy.plano = ctrl.salvarPlano();

    var features = MapService.getFeaturesSelectionAreaInstance();
    strategy.area = StrategyService.createArea(features);

    var unitPlan = StrategyService.getUnitPlanningActive();
    strategy.type = unitPlan.name;

    strategy.biomes = StrategyService.getRegionsActive();

    strategy.layers = LayerService.getLayers().reduce(function (layers, element) {
      if (element.is_visible) {
        layers.push(element.name);
      }
      return layers;
    }, []);

    strategy.opacity = strategy.opacidade.valor;

    if (strategy.id && override_strategy) {
      EstrategiaService.patch(strategy).$promise.then(
        function (response) {
          if (response.success) {
            savedStrategySuccess(
              response.data,
              hide_modal_link,
              send_log_report,
              close_modal_loading,
              format
            );
          }
          deferred.resolve();
        },
        function () {
          deferred.reject();
        }
      );
    } else {
      strategy.hash = "";
      EstrategiaService.post(strategy).$promise.then(
        function (response) {
          if (response.success) {
            savedStrategySuccess(
              response.data,
              hide_modal_link,
              send_log_report,
              close_modal_loading,
              format
            );
          }
          deferred.resolve();
        },
        function () {
          deferred.reject();
        }
      );
    }
    return deferred.promise;
  };

  function savedStrategySuccess(
    data,
    hide_modal_link,
    send_log_report,
    close_modal_loading,
    format
  ) {
    var listIndicatorsActive = IndicatorService.getIndicatorsActive() || [];
    var strategy = {} as Record<string, any>;
    strategy.opacidade = StrategyService.getOptionsOpacidade(strategy.opacity); // wtf?
    strategy.id = data.id;
    strategy.hash = data.hash;
    strategy.name = data.name;
    strategy.area = data.area;

    StrategyService.setStrategyInfo(strategy);

    ctrl.plano_negocio.id = data.plano.id;
    for (var i = 0; i < listIndicatorsActive.length; i++) {
      var indicador = listIndicatorsActive[i];
      for (var j = 0; j < data.plano.values.length; j++) {
        var valor = data.plano.values[j];
        if (indicador.indicador == valor.indicador) {
          indicador.id = valor.id;
          break;
        }
      }
    }

    StrategyService.setIdArea(data.area.id);
    StrategyService.loadStrategies();

    LogService.sendButtonLog("salvar_estrategia", format, "strategy");

    if (Boolean(send_log_report)) {
      LogService.sendButtonLog("gerar_relatorio_html", "click", "strategy");
    }
    if (Boolean(close_modal_loading)) {
      ModalService.closeModalLoading();
    }
    if (Boolean(hide_modal_link)) {
      angular.element("#salvarEstrategia").modal("hide");
    } else {
      angular.element("#salvarEstrategia").modal("show");
    }
  }

  ctrl.checkStrategyRecovery = function () {
    var hash_recuperacao;

    if (angular.isDefined($location.search().ESTRATEGIA)) {
      hash_recuperacao = $location.search().ESTRATEGIA;
    } else if (angular.isDefined($location.search().estrategia)) {
      hash_recuperacao = $location.search().estrategia;
    }
    if (angular.isDefined(hash_recuperacao)) {
      ctrl.recuperarEstrategia(hash_recuperacao);
      ctrl.has_strategy = true;
    }
    return ctrl.has_strategy;
  };

  ctrl.recuperarEstrategia = function (hash_recuperacao) {
    angular.element("#cloneToDownloadPng").addClass("clone-indicator-hidden");
    ctrl.haveCacheMunicipios = false;
    ctrl.haveCacheGrades = false;
    if (Boolean(hash_recuperacao)) {
      LogService.sendRecoveryStrategyLog();
      ModalService.openModalLoading();
      $http({
        method: "GET",
        url: AppService.getUrlApi() + "/estrategia/" + hash_recuperacao,
      }).then(
        function (response) {
          if (Boolean(response.data.success && angular.isDefined(response.data.data))) {
            var strategy = response.data.data;
            var last_indicators = strategy.plano.values[strategy.plano.values.length - 1];
            if (Boolean(last_indicators)) {
              last_indicators.ultimo = true;
            }
            strategy.opacidade = StrategyService.getOptionsOpacidade(strategy.opacity);
            LayerService.setOpacityLayer(
              MapService.getVectorSelectionAreaInstance(),
              strategy.opacidade.valor / 100
            );

            var layersWms = LayerService.getGroupLayerWms();
            var layersWmsSquare = LayerService.getGroupLayerWmsSquare();
            var layersList = LayerService.getLayers();
            var send_log = true,
              refreshList = true,
              isVisible = true,
              is_function = true;
            LayerService.resetAllCollapsesSession();

            layersList.forEach(function (layer) {
              if (!Boolean(layer.is_fixed)) {
                layer.is_visible = false;
                strategy.layers.forEach(function (layerName) {
                  var layer = LayerService.getLayerByName(layerName);
                  if (layer != -1) {
                    layer.is_visible = true;
                    layerName = layer.mapserver_name;
                    LayerService.setVisibleWmsLayerByName(
                      layersWmsSquare,
                      layerName,
                      isVisible,
                      !refreshList,
                      !send_log,
                      is_function
                    );
                    LayerService.setVisibleWmsLayerByName(
                      layersWms,
                      layerName,
                      isVisible,
                      refreshList,
                      send_log,
                      is_function
                    );
                  }
                });
              } else {
                LayerService.setVisibleWmsLayerByName(
                  layersWmsSquare,
                  layer.name,
                  isVisible,
                  refreshList,
                  !send_log,
                  is_function
                );
                LayerService.setVisibleWmsLayerByName(
                  layersWms,
                  layer.name,
                  isVisible,
                  refreshList,
                  send_log,
                  is_function
                );
              }
            });

            strategy.biomes.forEach(function (biome) {
              var status = true;
              StrategyService.setStatusRegionByName(biome, status);
            });

            var unitPlan = StrategyService.getUnitsPlanning()[Number(strategy.type == "grades")];

            var promises = [] as Promise<any>[];
            var promise;
            var canGenerateResult = true,
              send_log = true,
              is_function = true,
              status = true;

            StrategyService.setStatusUnitPlanning(unitPlan.id, status);

            if (unitPlan.name === "municipios") {
              strategy.area.municipios.forEach(function (municipio_id) {
                promise = ctrl.getFeaturesSelectionArea(unitPlan.name, municipio_id, strategy);
                promises.push(promise);
              });
            } else if (unitPlan.name === "grades") {
              strategy.area.grades.forEach(function (grade_id) {
                promise = ctrl.getFeaturesSelectionArea(unitPlan.name, grade_id, strategy);
                promises.push(promise);
              });
            }
            $q.all(promises).then(function () {
              ctrl.setStatusCacheUnitPlanning(unitPlan.name, true);
              ctrl.getBackgroundFinished(canGenerateResult, send_log, is_function);
            });

            StrategyService.setIdArea(strategy.area.id);
            StrategyService.setStrategyInfo(strategy);
          } else {
            ModalService.closeModalLoading();
          }
        },
        function (error) {}
      );
    }
  };

  ctrl.getFeaturesSelectionArea = function (unitPlan, id, strategy) {
    var deferred = $q.defer();
    $http({
      method: "GET",
      url: AppService.getUrlApi() + "/" + unitPlan + "/" + id,
    }).then(function (response) {
      var data = response.data;
      if (data.success) {
        var source = ctrl.createSourceByFeatures(response.data.data);
        var vectorDest = MapService.getVectorSelectionAreaInstance();
        ctrl.addSourceToVector(source, vectorDest, unitPlan);
      }
      deferred.resolve();
    });
    return deferred.promise;
  };

  ctrl.getBoundBoxSelectionArea = function () {
    MapService.getBoundBoxSelectionArea();
  };

  ctrl.getImageBoundBoxSelectionArea = function () {
    MapService.viewBoundBox(
      MapService.getVectorSelectionAreaInstance().getSource().getExtent(),
      MapService.getMapMainInstance()
    );

    MapService.getMapMainInstance().once("postcompose", function (event) {
      var canvas = event.context.canvas;
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(canvas.msToBlob(), "map.png");
      } else {
        canvas.toBlob(function (blob) {
          saveAs(blob, "map.png");
        });
      }
    });
    MapService.getMapMainInstance().renderSync();
  };

  $scope.$on("cleanMapSimulation", function () {
    ctrl.limparMapa();
  });

  ctrl.limparMapa = function () {
    // StrategyService.setStatusNotificationRegions(false);
    ctrl.selectTypeTool("removeInteraction");
    angular.element("#inputGeographicSearch").val("");
    MapService.setIsDrawing(false);
    MapService.clearVectorArea(true, true);
    MapService.clearVectorMeasure();
    StrategyService.clearParamsGeographicSearch();
    MapService.getMapMainInstance().renderSync();
    StrategyService.setStatusAllUnitsPlanning(false);
    StrategyService.setStrategyInfo();
    ctrl.clearStrategyPlan();
    StrategyService.setCollapseSession(1, false);
    // StrategyService.setStatusAllRegions(false);
    StrategyService.clearResultStrategy();
    StrategyService.clearStrategyInfo();
    StrategyService.clearFeatures();
    IndicatorService.setVisibleAllIndicators(false);
    MapInterationsService.expandMap();
    LogService.sendButtonLog("limpar_mapa_estrategia", "click", "strategy");
  };

  ctrl.isReadyToGenerateReport = function () {
    return IndicatorStrategyService.isReadyToGenerateReport();
  };

  ctrl.prepareReport = function () {
    var promises = [] as Promise<any>[];
    var promise;
    ctrl.selectTypeTool("removeInteraction");
    ModalService.openModalLoading();
    promise = ModalService.openModalReportStrategy();
    promises.push(promise);

    if (Boolean(ReportService.getReportCached()) === false) {
      var hide_modal_link = true,
        send_log_report = true,
        open_modal_loading = false,
        close_modal_loading = false,
        is_function = true,
        override_strategy = true;
      promise = ctrl.salvarEstrategia(
        hide_modal_link,
        send_log_report,
        open_modal_loading,
        close_modal_loading,
        is_function,
        override_strategy
      );
      promises.push(promise);
    } else {
      LogService.sendButtonLog("salvar_estrategia", "function", "strategy");
      LogService.sendButtonLog("gerar_relatorio_html", "click", "strategy");
    }

    angular.element("#salvarEstrategia").modal("hide");
    angular.element("#modalNameStrategy").modal("hide");

    $q.all(promises).then(function () {
      ModalService.closeModalLoading();
    });
  };

  ctrl.getListIndicatorsActive = function () {
    return IndicatorService.getIndicatorsActive();
  };

  ctrl.removeSelectionTypeTool = function () {
    ctrl.selectTypeTool("removeInteraction");
  };
}

export const MapControllerModule = new AppModule({
  name: "map.controller",
  controllers: { MapController },
});

// angular
//   .module('map.controller', [])
//   .controller('MapController', MapController, []);
