import { AppModule } from "../../../module-wrapper";
import angular from "angular";

/* @ngInject */
function ReportChartsController(
  this: any,
  ReportChartsService,
  StrategyService,
  MainService,
  ReportService,
  LogService,
  MapService,
  IndicatorService,
  ModalService
) {
  var ctrl = this;

  ctrl.generateChartScatterPolarByFeature = function(feature) {
    if (angular.isDefined(feature)) {
      ReportService.generateDataScatterPolarByFeature(feature);
    }
  };

  ctrl.generateChartBarByIndicator = function(indicator) {
    if (angular.isDefined(indicator)) {
      ReportChartsService.generateBarByIndicator(indicator);
    }
  };

  ctrl.downloadToPng = function(idChart) {
    var name = "exportar_png_ranking_indicadores_" + idChart.toString().toLowerCase();
    LogService.sendButtonLog(name, "click", "strategy");

    ReportChartsService.convertSvgToDataURL(idChart).then(function(uri) {
      var link = document.createElement("a");
      link.href = uri;
      var nameFile = angular
        .element("#" + idChart)
        .find("svg")
        .find(".c3-title")[0].textContent;
      link.download = nameFile + ".png";
      document.body.appendChild(link);
      link.click();
    });
  };

  ctrl.downloadDataUrlToPng = function(url, fileName, LogName) {
    ReportChartsService.downloadDataUrlToPng(url, fileName, LogName);
  };

  ctrl.isGridUnitPlanning = function() {
    return StrategyService.getUnitPlanningActive().id === 2;
  };

  ctrl.listFeatures = [];

  ctrl.getListFeatures = function (city?, state?) {
    ctrl.listFeatures = MapService.getFeaturesSelectionAreaInstance();
    if (Boolean(ctrl.isGridUnitPlanning())) {
      ReportChartsService.getGrids(ctrl.listFeatures).then(function (response) {
        ctrl.listFeatures = response;
        var forceGridInit = true;
        if (ctrl.listFeatures.length > 0) {
          angular.element(function () {
            if (angular.isDefined(city) && angular.isDefined(state)) {
              ModalService.openModalLoading();
            }
            ReportChartsService.initScatterPolar(ctrl.listFeatures, forceGridInit);
          });
        }
      });
    }
  };

  ctrl.getDescriptionFeature = function(feature) {
    return ReportChartsService.getDescriptionFeature(feature);
  };

  ctrl.getCitiesByListFeatures = function() {
    ctrl.listCities = [];
    var features = MapService.getFeaturesSelectionAreaInstance();
    features.forEach(function(feature) {
      var city = feature.getProperties().city;
      if (MainService.arrayObjectIndexOf(ctrl.listCities, city, "city") == -1) {
        ctrl.listCities.push({
          city: feature.getProperties().city,
          state: feature.getProperties().state
        });
      }
    });
  };

  ctrl.initChartScatterPolarByFeature = function() {
    if (Boolean(ctrl.isGridUnitPlanning())) {
      ctrl.getCitiesByListFeatures();
    }
    ctrl.getListFeatures();
  };

  ctrl.getGroupByFeature = function(feature) {
    if (angular.isDefined(feature.getProperties().city)) {
      return undefined;
    } else if (angular.isDefined(feature.getProperties().uf)) {
      return feature.getProperties().uf;
    }
  };

  ctrl.getListIndicators = function() {
    return IndicatorService.getIndicatorsActive();
  };

  ctrl.getDescriptionIndicator = function(indicator) {
    return IndicatorService.getLabelAndUnitIndicatorByLocale(indicator);
  };
}

export const ReportChartsControllerModule = new AppModule({
  name: "reportCharts.controller",
  controllers: { ReportChartsController }
});

// angular
//     .module('reportCharts.controller', [])
//     .controller('ReportChartsController', ReportChartsController)
