import { AppModule } from "../../module-wrapper";

/* @ngInject */
function HttpService($resource, AppService) {
  return function(endPoint) {
    return $resource(
      AppService.getUrlApi() + "/" + endPoint,
      {},
      {
        getXls: {
          method: "POST",
          responseType: "arraybuffer",
          headers: {
            "X-HTTP-Method-Override": "GET"
          },
          transformResponse: function(data, headers) {
            var response = {} as Record<string, any>;
            response.data = data;
            response.headers = headers();
            return response;
          },
          isArray: false
        }
      }
    );
  };
}

export const HttpService_ServiceModule = new AppModule({
  name: "httpService.service",
  factories: { HttpService }
});

// angular
//     .module('httpService.service', [])
//     .factory('HttpService', HttpService);
