import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function ModalService(
  this: any,
  StrategyService,
  MapService,
  ngDialog,
  HowToUseService,
  MainService,
  IndicatorService,
  GetRegionIndicatorService,
  $q,
  AppService,
  AxisService,
  LogService,
  ReportChartsService,
  TranslateService,
  ReportService
) {
  var ctrl = this;

  ctrl.modal;
  ctrl.is_opening = false;
  ctrl.obj = {};

  function openModalDrawingFeature(obj, isKml, type, $scope) {
    closeAllModal();
    var scope = $scope.$new();

    scope.obj = {};
    scope.obj.feature = obj;
    scope.obj.isKml = isKml;
    scope.obj.type = type;

    if (isKml) {
      scope.obj.label = "strategy.buffer";
    } else {
      scope.obj.label = StrategyService.getNameByTypeFeature(obj);
    }

    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.drawing-geometry.html",
      scope: scope,
      showClose: false,
      overlay: false,
      closeByEscape: false,
      closeByDocument: false,
      onOpenCallback: function () {
        ctrl.is_opening = false;
        MapService.setIsDrawing(false);
        angular.element("#tabmain").addClass("bufferActive");
      },
      preCloseCallback: function () {
        angular.element("#tabmain").removeClass("bufferActive");
        MapService.getFeatureOverlay().clear();
      },
    });
    return ctrl.modal;
  }

  function generateScopeObj(region) {
    var deferred = $q.defer();
    GetRegionIndicatorService.get(region).$promise.then(
      function (response) {
        ctrl.obj.indicators = response;
        deferred.resolve();
      },
      function (error) {
        ctrl.obj.indicators = [];
        deferred.resolve();
      }
    );
    return deferred.promise;
  }

  function openModalFeature(feature, $scope) {
    // closeAllModal();

    if (Boolean(!ctrl.is_opening)) {
      ctrl.is_opening = true;
      var scope = $scope.$new();
      var template;
      var region = {
        code: feature.getProperties().code,
        region: feature.getProperties().name ? "cities" : "grids",
      };
      ctrl.obj = {
        mesorregiao: feature.getProperties().mesorregiao,
        microrregiao: feature.getProperties().microrregiao,
        area: feature.getProperties().area,
        uf: feature.getProperties().uf,
        biomas: feature.getProperties().biomas,
        code: feature.getProperties().code,
        name: feature.getProperties().name || "strategy.grade",
      };
      scope.axisSer = AxisService;
      scope.indServ = IndicatorService;
      scope.translate = TranslateService.translate;

      var promises = [] as Promise<any>[];

      var promise = generateScopeObj(region);
      promises.push(promise);

      $q.all(promises).then(function () {
        scope.obj = ctrl.obj;
        scope.obj.indicators.forEach(function (indicator) {
          registerIndicatorFeatureInCollapseSession(indicator);
        });

        feature.setProperties({
          indicators: ctrl.obj.indicators,
        });
        // is Municipio
        if (feature.getProperties().name) {
          template = "municipality";
        } else {
          // is Grade
          template = "grid";
          scope.obj.city = feature.getProperties().city;
          scope.obj.state = feature.getProperties().state;
          scope.obj.centroid_x = feature.getProperties().centroid_x;
          scope.obj.centroid_y = feature.getProperties().centroid_y;
          scope.obj.code = feature.getProperties().code;
        }
        scope.strategyServ = StrategyService;
        ctrl.modal = ngDialog.open({
          template: "/app/core/modal/views/modal." + template + "-info-feature.html",
          className: "",
          scope: scope,
          controller: "ModalController",
          controllerAs: "modalCtrl",
          showClose: false,
          onOpenCallback: function () {
            ctrl.is_opening = false;
            angular.element(function () {
              angular.element(window).on("resize.eventResizeModalFeature", function () {
                resizePositionLateralMenu();
              });
              resizePositionLateralMenu();
            });
          },
          preCloseCallback: function () {
            angular.element(window).off("resize.eventResizeModalFeature");
          },
        });
        return ctrl.modal;
      });
    }
  }

  function forceResizePositionLateralMenu() {
    angular.element("div.modal-body.custom-margin-left-lateral-menu.nopadding-top").resize();
  }

  function resizePositionLateralMenu() {
    angular.element(function () {
      var extraPxHeight = 22;
      var lessPxHeight = 25;
      angular
        .element("div.modal-lateral-menu")
        .height(
          extraPxHeight +
            angular
              .element("div.modal-body.custom-margin-left-lateral-menu.nopadding-top")
              .height()!
        );
      angular.element("div.modal-bottom-lateral-menu").css({
        top:
          angular
            .element("div.modal-body.custom-margin-left-lateral-menu.nopadding-top")
            .height()! - lessPxHeight,
      });
    });
  }

  function registerIndicatorFeatureInCollapseSession(indicator) {
    indicator.collapse = true;
  }

  function openModalTermsUse() {
    // closeAllModal();
    if (!MapService.getAcceptTermsUse()) {
      ctrl.modal = ngDialog.open({
        template: "/app/core/modal/views/modal.terms-use.html",
        className: "ngdialog-theme-default custom-width-80",
        showClose: false,
        closeByEscape: false,
        closeByDocument: false,
        preCloseCallback: function () {
          MapService.setAcceptTermsUse(true);
        },
      });
      return ctrl.modal;
    }
  }

  function openModalDisclaimer() {
    if (
      AppService.getDynamicTranslations().disclaimer &&
      localStorage.getItem("modal_disclaimer_accept") !== "true"
    ) {
      ctrl.modal = ngDialog.open({
        template: "/app/core/modal/views/modal.disclaimer.html",
        className: "ngdialog-theme-default custom-width-80",
        showClose: false,
        closeByEscape: false,
        closeByDocument: false,
      });
      localStorage.setItem("modal_disclaimer_accept", "true");
      return ctrl.modal;
    }
  }

  function openModalTermsUseComplete() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.terms-use-complete.html",
      className: "ngdialog-theme-default custom-width-80",
      showClose: false,
    });
    return ctrl.modal;
  }

  function openModalPartners() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.partners.html",
      className: "ngdialog-theme-default custom-width-50",
      controller: "AppController",
      controllerAs: "appCtrl",
      showClose: false,
    });
    return ctrl.modal;
  }

  function openModalPrivacyPolicy() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.privacy-policy.html",
      className: "ngdialog-theme-default custom-width-80",
      showClose: false,
    });
    return ctrl.modal;
  }

  function openModalLayerMetadata(layer, $scope) {
    //closeAllModal();
    LogService.sendButtonLog("informacoes_camada", "click", "layer", layer.name);
    var scope = $scope.$new();
    $scope.service = JSON.parse(JSON.stringify(layer));
    if (Boolean(!ctrl.is_opening)) {
      ctrl.is_opening = true;
      ctrl.modal = ngDialog.open({
        template: "/app/core/modal/views/modal.info-layer.html",
        scope: scope,
        showClose: false,
        onOpenCallback: function () {
          ctrl.is_opening = false;
        },
      });
      return ctrl.modal;
    }
  }

  function openModalIndicatorInfo(indicator, $scope) {
    // closeAllModal();
    ctrl.histogram = {};
    ctrl.histogram.dataChartHistogram = ReportChartsService.getDataHistogram(indicator);
    ctrl.histogram.layoutChartsHistogram = ReportChartsService.getLayoutHistogram();

    LogService.sendButtonLog("informacoes_indicador", "click", "layer", indicator.name);
    var scope = $scope.$new();
    $scope.service = JSON.parse(JSON.stringify(indicator));
    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.info-indicator.html",
      scope: scope,
      showClose: false,
      onOpenCallback: function () {
        ReportChartsService.newPlotHistogram(
          "graphicHistogram",
          ctrl.histogram.dataChartHistogram,
          ctrl.histogram.layoutChartsHistogram
        );
      },
    });

    return ctrl.modal;
  }

  function openModalFeatureInfo(data, layer, $scope) {
    closeAllModal();
    var scope = $scope.$new();
    $scope.feature = data;
    $scope.layer = layer;
    $scope.properties = data.features[0].properties;
    $scope.keys = Object.keys($scope.properties);
    ctrl.modal = ngDialog.open({
      template: "/app/core/modal/views/modal.feature-info.html",
      scope: scope,
      showClose: false,
    });
    return ctrl.modal;
  }

  function openModalLoading() {
    angular.element("#loading").css({ display: "block" });
  }

  function closeModalLoading() {
    angular.element("#loading").css({ display: "none" });
  }

  function openModalContact() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/contact/contact.view.html",
      showClose: false,
    });
    return ctrl.modal;
  }

  function openModalLogin() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/auth/login/login.view.html",
      showClose: false,
      controller: "LoginController",
      controllerAs: "loginCtrl",
    });
    return ctrl.modal;
  }

  function openModalRecoveryPassword() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/auth/login/views/modal.recovery-password.html",
      showClose: false,
      controller: "LoginController",
      controllerAs: "loginCtrl",
    });
    return ctrl.modal;
  }

  function openModalChangePassword() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/user/changePassword/change-password.view.html",
      showClose: false,
      controller: "UserController",
      controllerAs: "userCtrl",
    });
    return ctrl.modal;
  }

  // function openModalDisclaimerChangePassword() {
  //   closeAllModal();
  //   if (!MapService.getNotifiedAboutPasswordChange()) {
  //     ctrl.modal = ngDialog.open({
  //       template: "/app/core/user/changePassword/disclaimer-change-password.view.html",
  //       className: "ngdialog-theme-default custom-width-80",
  //       showClose: false,
  //       closeByEscape: false,
  //       closeByDocument: false,
  //       preCloseCallback: function() {
  //         MapService.setNotifiedAboutPasswordChange(true);
  //       }
  //     });
  //     return ctrl.modal;
  //   }
  // }

  function openModalRegisterUser() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/user/user.view.html",
      showClose: false,
      controller: "UserController",
      controllerAs: "userCtrl",
    });
    return ctrl.modal;
  }

  function openModalMyStrategies() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/core/user/userArea/user-area.view.html",
      showClose: false,
      controller: "StrategyController",
      controllerAs: "strategyCtrl",
    });
    return ctrl.modal;
  }

  function openModalHowToCreateStrategies() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/howToUse/views/how-to-use.strategy.html",
      showClose: false,
      closeByEscape: false,
      overlay: false,
      controller: "HowToUseController",
      controllerAs: "howToUseCtrl",
      onOpenCallback: function () {
        HowToUseService.setInitialStepHowToCreateStrategies();
        angular.element("#tabmain").addClass("howToUseActive");
        angular.element("#main").append('<div class="ngdialog-overlay" style="z-index: 99"></div>');
      },
      preCloseCallback: function () {
        HowToUseService.setInitialStepHowToCreateStrategies();
        StrategyService.removeClassAllSession();
        angular.element("#tabmain").removeClass("howToUseActive");
        angular.element(".ngdialog-overlay").remove();
      },
    });
    return ctrl.modal;
  }

  function openModalHowToUseTools() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/howToUse/views/how-to-use.tools.html",
      showClose: false,
      overlay: false,
      controller: "HowToUseController",
      controllerAs: "howToUseCtrl",
      onOpenCallback: function () {
        angular.element("#tabDrawingtool").addClass("howToUseActive");
        angular.element("#main").append('<div class="ngdialog-overlay" style="z-index: 99"></div>');
      },
      preCloseCallback: function () {
        angular.element("#tabDrawingtool").removeClass("howToUseActive");
        angular.element(".ngdialog-overlay").remove();
      },
    });
    return ctrl.modal;
  }

  function openModalHowToUse() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/howToUse/views/how-to-use-summary.system.html",
      showClose: false,
      overlay: false,
      controller: "HowToUseController",
      controllerAs: "howToUseCtrl",
      onOpenCallback: function () {
        angular.element("#tabmain").addClass("howToUseActive");
        angular.element("#main").append('<div class="ngdialog-overlay" style="z-index: 99"></div>');
      },
      preCloseCallback: function () {
        angular.element("#tabmain").removeClass("howToUseActive");
        angular.element(".ngdialog-overlay").remove();
      },
    });
    return ctrl.modal;
  }

  function openModalWarningSelectionArea() {
    // closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/warning/views/warning.selection-area.html",
      showClose: false,
      className: "ngdialog-theme-default custom-position",
      onOpenCallback: function () {
        angular.element(".ngdialog-overlay").css({ "background-color": "transparent" });
      },
    });
    return ctrl.modal;
  }

  function openModalWarningMoratoriaDaSoja() {
    // closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/warning/views/warning.moratoria-area.html",
      showClose: false,
      className: "ngdialog-theme-default custom-position",
      onOpenCallback: function () {
        angular.element(".ngdialog-overlay").css({ "background-color": "transparent" });
      },
    });
    return ctrl.modal;
  }

  function openModalWarningNull() {
    // closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/warning/views/warning.null-areas.html",
      showClose: false,
      className: "ngdialog-theme-default custom-position",
      onOpenCallback: function () {
        angular.element(".ngdialog-overlay").css({ "background-color": "transparent" });
      },
    });
    return ctrl.modal;
  }

  function openModalWarningBiomeIntersection() {
    //closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/warning/views/warning.biomes-intersection.html",
      showClose: false,
      className: "ngdialog-theme-default custom-position",
      onOpenCallback: function () {
        angular.element(".ngdialog-overlay").css({ "background-color": "transparent" });
      },
    });
    return ctrl.modal;
  }

  function openModalGeographicSearch($scope) {
    // closeAllModal();
    var scope = $scope.$new();
    scope.translate = TranslateService.translate;
    if (Boolean(!ctrl.is_opening)) {
      ctrl.is_opening = true;
      ctrl.modal = ngDialog.open({
        template: "/app/core/modal/views/modal.geographic-search.html",
        showClose: false,
        scope: scope,
        onOpenCallback: function () {
          ctrl.is_opening = false;
        },
      });
      return ctrl.modal;
    }
  }

  function openModalReportStrategy() {
    var deferred = $q.defer();
    closeAllModal();
    ctrl.modal = ngDialog.open({
      className: "ngdialog-theme-default custom-width-80",
      template: "/app/modules/report/report.view.html",
      showClose: false,
      overlay: false,
      controller: "ReportController",
      controllerAs: "reportCtrl",
      onOpenCallback: function () {
        if (
          Boolean(ReportService.getReportCached()) === false &&
          angular.equals(StrategyService.getResultStrategy(), [])
        ) {
          // calcula resultado da estratégia
          var send_log = true,
            close_loading = false,
            is_function = true;
          StrategyService.generateResultStrategy(send_log, close_loading, is_function);
        }
        // carrega map report na div e inicia prints
        MapService.setTargetMapReport().then(function () {
          // busca informações de sobreposições da área
          var featuresActive = MapService.getFeaturesSelectionAreaInstance();
          ReportService.generateReport(featuresActive, IndicatorService.getIndicatorsActive()).then(
            function () {
              // inicializa gráficos
              ReportChartsService.initCharts().then(function () {
                // remove opacidade 0 da div clone para retirar prints do sliders
                angular.element("#cloneToDownloadPng").removeClass("clone-indicator-hidden");
                deferred.resolve();
              });
            }
          );
        });
        angular.element("#main").append('<div class="ngdialog-overlay" style="z-index: 99"></div>');
        angular.element(".estrategia-inner").addClass("bufferActive");
        angular.element(".rowstrategybuttons").addClass("bufferActive");
      },
      preCloseCallback: function () {
        MapService.clearSource(MapService.getSourceReportMap());
        MapService.hiddenComponentsToPrint();
        ReportChartsService.clearListPlotCharts();
        angular.element(".estrategia-inner").removeClass("bufferActive");
        angular.element(".rowstrategybuttons").removeClass("bufferActive");
        angular.element(".ngdialog-overlay").remove();
      },
    });
    return deferred.promise;
  }

  function openTourNewIndicatorsSoy() {
    closeAllModal();
    ctrl.modal = ngDialog.open({
      template: "/app/modules/tour/views/tour.new-indicators-soy.html",
      className: "ngdialog-theme-default custom-position",
      showClose: false,
      closeByEscape: false,
      closeByDocument: false,
      overlay: false,
      onOpenCallback: function () {
        angular.element("#main").append('<div class="ngdialog-overlay" style="z-index: 2"></div>');
        angular.element("#tabmain").addClass("bufferActive");
      },
      preCloseCallback: function () {
        MapService.setNotifiedAboutNewIndicators(true);
        angular.element(".ngdialog-overlay").remove();
        angular.element("#tabmain").removeClass("bufferActive");
      },
    });
    return ctrl.modal;
  }

  function closeAllModal() {
    MainService.setVisibilityMenu(false);
    ngDialog.close(ctrl.modal);
  }

  return {
    openModalDrawingFeature: openModalDrawingFeature,
    openModalFeature: openModalFeature,
    forceResizePositionLateralMenu: forceResizePositionLateralMenu,
    openModalTermsUse: openModalTermsUse,
    openModalTermsUseComplete: openModalTermsUseComplete,
    openModalPartners: openModalPartners,
    openModalPrivacyPolicy: openModalPrivacyPolicy,
    openModalLayerMetadata: openModalLayerMetadata,
    openModalIndicatorInfo: openModalIndicatorInfo,
    openModalFeatureInfo: openModalFeatureInfo,
    openModalLoading: openModalLoading,
    closeModalLoading: closeModalLoading,
    openModalContact: openModalContact,
    openModalLogin: openModalLogin,
    openModalRecoveryPassword: openModalRecoveryPassword,
    openModalChangePassword: openModalChangePassword,
    // openModalDisclaimerChangePassword: openModalDisclaimerChangePassword,
    openModalRegisterUser: openModalRegisterUser,
    openModalMyStrategies: openModalMyStrategies,
    openModalHowToCreateStrategies: openModalHowToCreateStrategies,
    openModalHowToUseTools: openModalHowToUseTools,
    openModalHowToUse: openModalHowToUse,
    openModalWarningSelectionArea: openModalWarningSelectionArea,
    openModalWarningBiomeIntersection: openModalWarningBiomeIntersection,
    openModalWarningMoratoriaDaSoja: openModalWarningMoratoriaDaSoja,
    openModalWarningNull: openModalWarningNull,
    openModalGeographicSearch: openModalGeographicSearch,
    openModalReportStrategy: openModalReportStrategy,
    openTourNewIndicatorsSoy: openTourNewIndicatorsSoy,
    openModalDisclaimer: openModalDisclaimer,
    closeAllModal: closeAllModal,
  };
}

export const ModalServiceModule = new AppModule({
  name: "modal.service",
  factories: { ModalService },
});

// angular.module("modal.service", []).factory("ModalService", ModalService);
