import { AppModule } from "../../module-wrapper";

/* @ngInject */
function NotificationService(toaster) {
  function showNotification(type, title, body, showCloseButton, timeout) {
    toaster.pop(getOptions(type, title, body, showCloseButton, timeout));
  }

  function getOptions(type, title, body, showCloseButton, timeout) {
    return {
      type: type,
      title: title,
      body: body,
      showCloseButton: true,
      timeout: timeout
    };
  }

  return {
    showNotification: showNotification
  };
}

export const NotificationServiceModule = new AppModule({
  name: "notification.service",
  factories: { NotificationService }
});

// angular.module("notification.service", []).factory("NotificationService", NotificationService);
