import { AppModule } from "../../module-wrapper";
import { saveAs } from "file-saver";

/* @ngInject */
function ExporterService(
  MainService,
  ModalService,
  LogService,
  StrategyService,
  HttpService,
  AppService,
  TranslateService
) {
  function createXls(params, filename) {
    ModalService.openModalLoading();
    HttpService("export")
      .getXls(params)
      .$promise.then(
        function(response) {
          var file = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          filename = filename + ".xlsx";
          ModalService.closeModalLoading();
          saveAs(file, decodeURIComponent(escape(filename)));
        },
        function() {}
      )
      .finally(function() {
        ModalService.closeModalLoading();
      });
  }

  function exportDetailFeatures(features, area) {
    LogService.sendButtonLog("exportar_todos_indicadores_xls", "click", "strategy");

    var unitPlan = StrategyService.getUnitPlanningActive();
    var indices = {};
    var file_name;

    for (var i = 0; i < features.length; i++) {
      var feature = features[i];
      indices[feature.getProperties().code] = {
        r: feature.indiceRisco,
        cr: feature.classeRisco,
        o: feature.indiceOportunidade,
        co: feature.classeOportunidade
      };
    }

    var params: Record<string, any> = {
      tipo: unitPlan.name,
      indices: JSON.stringify(indices)
    };
    var file_name;
    if (params.tipo == "grades") {
      params.grades = JSON.stringify(area.grades_codigo);
      file_name = MainService.getTextByKeyI18n("report.listade" + params.tipo + "csv");
    } else if (params.tipo == "municipios") {
      params.municipios = JSON.stringify(area.municipios_codigo);
      file_name = TranslateService.translate(
        AppService.getDynamicTranslations().report.listademunicipioscsv
      );
    }

    createXls(params, file_name);
  }

  function exportDetailFeature(feature) {
    LogService.sendButtonLog("exportar_indicadores_xls", "click", "strategy");

    var unitPlan = StrategyService.getUnitPlanningActive();

    var params = {
      tipo: unitPlan.name
    } as Record<string, any>;

    if (params.tipo == "grades") {
      params.grades = JSON.stringify([feature.code]);
    } else if (params.tipo == "municipios") {
      params.municipios = JSON.stringify([feature.code]);
    }

    createXls(params, String(feature.code));
  }

  function exportDetailProtectedArea(reportDict, filename) {
    // LogService.sendButtonLog("exportar_areas_protegidas_xls", "click", "strategy");
    var file_name = TranslateService.translate(AppService.getDynamicTranslations().protectedareasxls[filename])
    createXls(reportDict, file_name)
    }

  return {
    exportDetailFeatures: exportDetailFeatures,
    exportDetailFeature: exportDetailFeature,
    exportDetailProtectedArea: exportDetailProtectedArea
  };
}

export const ExporterServiceModule = new AppModule({
  name: "exporter.service",
  factories: { ExporterService }
});

// angular
//     .module('exporter.service', [])
//     .factory('ExporterService', ExporterService);
