import { AppModule } from "../../module-wrapper";
import { ReportControllerModule } from "./report.controller";
import { ReportDirectivesModule } from "./report.directives";
import { ReportServiceModule } from "./report.service";
import { ReportPdfModule } from "./reportPdf/reportPdf.module";
import { ReportChartsModule } from "./reportCharts/reportCharts.module";

// angular
//     .module('report.module', [
//         'report.controller',
//         'report.directives',
//         'report.service',
//         'reportPdf.module',
//         'reportCharts.module'
//     ]);

export const ReportModule = new AppModule({
  name: "report.module",
  modules: [
    ReportControllerModule,
    ReportDirectivesModule,
    ReportServiceModule,
    ReportPdfModule,
    ReportChartsModule
  ]
});
