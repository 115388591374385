import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function LayersController(this: any, LayerService, $timeout, $scope, ReportService) {
  var ctrl = this;

  ctrl.sliderLayersTransparency = {
    isVisible: false,
  };

  ctrl.getGroupLayers = function () {
    return LayerService.getGroupLayers();
  };

  ctrl.getLayers = function () {
    return LayerService.getLayers();
  };

  ctrl.registerCollapseSession = function (obj) {
    var index = LayerService.registerCollapseSession(obj);
    obj.collapse_index = index;
  };

  ctrl.toggleCollapseSession = function (elem) {
    LayerService.resetCollapsesSession(elem.collapse_index);
    elem.collapse = !elem.collapse;
  };

  ctrl.addEventToggle = function (layer?) {
    angular.element(document).on("click", function (evt) {
      var target = angular.element(evt.target)[0];
      ctrl.toggleSliderTransparency(target, "isSliderTransparencyPopUp", layer);
    });
  };

  ctrl.toggleGeneralSliderTransparency = function () {
    ctrl.addEventToggle();
    ctrl.sliderLayersTransparency.isVisible = !ctrl.sliderLayersTransparency.isVisible;

    if (ctrl.sliderLayersTransparency.isVisible) {
      angular.element("#isSliderTransparencyPopUp").show();
    } else {
      angular.element("#isSliderTransparencyPopUp").hide();
    }
  };

  ctrl.toggleLayerSliderTransparency = function (layer) {
    ctrl.addEventToggle(layer);
    var layers = LayerService.getGroupLayers() || [];
    layer.isVisibleTransparency = !layer.isVisibleTransparency;

    layers.forEach(function (layerItem) {
      if (layer.name != layerItem.name) {
        layerItem.isVisibleTransparency = false;
      }
    });
  };

  ctrl.toggleSliderTransparency = function (target, element, layer) {
    var layers = LayerService.getGroupLayers() || [];
    var isClickOut = false;

    if (target.id != "openSliderTransparencyPopUp") {
      while ((target = target.parentElement)) {
        if (target.id == element) {
          isClickOut = true;
        }
      }

      if (!isClickOut) {
        if (layers) {
          angular.element("." + layer.id).hide();
        } else {
          angular.element("#" + element).hide();
        }
        ctrl.sliderLayersTransparency.isVisible = false;

        $scope.$apply(function () {
          layers.forEach(function (layerItem) {
            layerItem.isVisibleTransparency = false;
          });
        });
      }
    }
  };

  ctrl.getOptionsLayer = function (layer) {
    if (!Boolean(layer.options)) {
      layer.transparency = 100;
      if (layer.isVisibleTransparency == undefined) {
        layer.isVisibleTransparency = false;
      }
      layer.options = {
        floor: 0,
        ceil: 100,
        noSwitching: true,
        hideLimitLabels: false,
        onChange: function () {
          ReportService.setReportCached(false);
          LayerService.changeOpacityLayer(layer, layer.transparency / 100);
        },
        translate: function (value) {
          return value + "%";
        },
      };
      $timeout(function () {
        $scope.$broadcast("reCalcViewDimensions");
        $scope.$broadcast("rzSliderForceRender");
      });
    }
    return layer.options;
  };

  ctrl.clearLayersMap = function () {
    var is_function = true;
    var layers = LayerService.getGroupLayers() || [];

    layers.forEach(function (layerItem) {
      layerItem.layers.forEach(element => {
        element.transparency = 100
      });
    });
    LayerService.clearLayersMap(is_function);
    LayerService.resetAllCollapsesSession();
  };

  ctrl.loadLayerWms = function (layer, is_function) {
    var send_log = true,
      reportCached = false;
    ReportService.setReportCached(reportCached);
    LayerService.loadLayerWms(layer, send_log, is_function);
  };

  ctrl.isOTBNLayer = function (layer) {
    if (layer.name == "cat_I_OTBN_hachurado" || layer.name == "cat_II_OTBN_hachurado") {
      return true;
    } else {
      return false;
    }
  };
}

export const LayersControllerModule = new AppModule({
  name: "layers.controller",
  controllers: { LayersController },
});

// angular
//     .module('layers.controller', [])
//     .controller('LayersController', LayersController)
