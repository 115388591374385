import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function ModalController(
  this: any,
  ModalService,
  MapService,
  $scope,
  AppService,
  MainService,
  ExporterService,
  $timeout
) {
  var ctrl = this;

  ctrl.getLayout = function () {
    return AppService.getLayout();
  };

  ctrl.openModalDisclaimer = function () {
    ModalService.openModalDisclaimer();
  };
  ctrl.openModalDisclaimer();

  ctrl.openModalTermsUse = function () {
    ModalService.openModalTermsUse();
  };
  ctrl.openModalTermsUse();

  ctrl.openModalTermsUseComplete = function () {
    ModalService.openModalTermsUseComplete();
  };

  ctrl.openModalPartners = function () {
    ModalService.openModalPartners();
  };

  ctrl.openModalPrivacyPolicy = function () {
    ModalService.openModalPrivacyPolicy();
  };

  ctrl.openModalLayerMetadata = function (layer) {
    ModalService.openModalLayerMetadata(layer, $scope);
  };

  ctrl.openModalIndicatorInfo = function (indicator) {
    ModalService.openModalIndicatorInfo(indicator, $scope);
  };

  ctrl.openModalContact = function () {
    ModalService.openModalContact();
  };

  ctrl.openModalLogin = function () {
    ModalService.openModalLogin();
  };

  ctrl.openModalRecoveryPassword = function () {
    ModalService.openModalRecoveryPassword();
  };

  ctrl.openModalChangePassword = function () {
    ModalService.openModalChangePassword();
  };

  ctrl.openModalRegisterUser = function () {
    ModalService.openModalRegisterUser();
  };

  // ctrl.openModalDisclaimerChangePassword = function() {
  //   ModalService.openModalDisclaimerChangePassword();
  // };

  ctrl.openModalMyStrategies = function () {
    ModalService.openModalMyStrategies();
  };

  ctrl.openModalHowToCreateStrategies = function () {
    ModalService.openModalHowToCreateStrategies();
  };

  ctrl.openModalHowToUseTools = function () {
    ModalService.openModalHowToUseTools();
  };

  ctrl.openModalHowToUse = function () {
    ModalService.openModalHowToUse();
  };

  ctrl.openModalWarningSelectionArea = function () {
    ModalService.openModalWarningSelectionArea();
  };

  ctrl.openModalWarningBiomeIntersection = function () {
    ModalService.openModalWarningBiomeIntersection();
  };

  ctrl.openModalWarningNull = function () {
    ModalService.openModalWarningNull();
  };

  ctrl.openModalWarningMoratoriaDaSoja = function () {
    ModalService.openModalWarningMoratoriaDaSoja();
  };

  ctrl.openSubmenuComoUsar = function () {
    MainService.removeClassesMenu("submenucomousar");
    angular.element("#submenucomousar").toggleClass("show");
    angular
      .element("#menu-language > span")
      .removeClass("flaticon-left-arrow")
      .addClass("flaticon-sort-down");
  };

  ctrl.openSubmenuUsuario = function () {
    MainService.removeClassesMenu("submenuusuario");
    angular.element("#submenuusuario").toggleClass("show");
    angular
      .element("#menu-language > span")
      .removeClass("flaticon-left-arrow")
      .addClass("flaticon-sort-down");
  };

  ctrl.openSubmenuLanguage = function () {
    MainService.removeClassesMenu("submenulang");
    angular.element("#submenulang").toggleClass("show");
    angular
      .element("#menu-language > span")
      .toggleClass("flaticon-left-arrow")
      .toggleClass("flaticon-sort-down");
  };

  ctrl.eventcloseAllModal = function (obj, isKml) {
    var features = Boolean(isKml) ? obj : [obj];
    features.forEach(function (feature) {
      MapService.deleteFeatureVectorDrawingArea(feature);
      MapService.setVisibleVector(MapService.getVectorDrawingAreaInstance(), false);
    });
    MapService.setIsDrawing(false);
  };

  ctrl.resetIndicatorsFeatureInCollapseSession = function (indicators, nameExcept) {
    ModalService.forceResizePositionLateralMenu();
    indicators.forEach(function (indicator) {
      if (nameExcept !== indicator.name) {
        indicator.collapse = true;
      }
    });
  };

  ctrl.exportExcel = function (featureCode) {
    ExporterService.exportDetailFeature(featureCode);
  };

  ctrl.strategyHowToUseView = function () {
    $(".modal-how-to-use-content").hide();
    $("#howToUseCarousel").show();
    $(".btn-voltar-menu").show();
  };

  ctrl.menuHowToUseView = function () {
    $("#howToUseCarousel").hide();
    $(".modal-how-to-use-content").show();
    $(".btn-voltar-menu").hide();
    $timeout(function () {
      $(".reset-ngswitch").triggerHandler("click");
    });
  };

  // ctrl.howToUseCarouselIndicator = function() {
  //   $("#howToUseCarousel").on("slid.bs.carousel", function() {
  //     const index = $("div.item.active").index() % 7;
  //     const carouselIndic = $('[data-slide-to="' + index + '"]');
  //     $(".carousel-indicators li").removeClass("active");
  //     carouselIndic.addClass("active");
  //   });

  //   $("#howToUseCarousel").on("slid.bs.carousel", "", function() {
  //     var $this = $(this);
  //     $this.children(".carousel-control").show();

  //     if ($(".carousel-inner .item:first").hasClass("active")) {
  //       $this.children(".left.carousel-control").hide();
  //     } else if ($(".carousel-inner .item:last").hasClass("active")) {
  //       $this.children(".right.carousel-control").hide();
  //     }
  //   });
  // };

  ctrl.howToUseCarouselIndicatorArrows = function () {
    $("#howToUseCarousel").on("slid.bs.carousel", "", function () {
      var $this = $(this);
      $this.children(".carousel-control").show();

      if ($(".carousel-inner .item:first").hasClass("active")) {
        $this.children(".left.carousel-control").hide();
      } else if ($(".carousel-inner .item:last").hasClass("active")) {
        $this.children(".right.carousel-control").hide();
      }
    });
  };
}

export const ModalControllerModule = new AppModule({
  name: "modal.controller",
  controllers: { ModalController },
});

// angular
//     .module('modal.controller', [])
//     .controller('ModalController', ModalController)
