import { AppModule } from "../../module-wrapper";

/* @ngInject */
function HowToUseController(this: any, HowToUseService) {
  var ctrl = this;

  ctrl.getListStepsHowToCreateStrategies = function () {
    return HowToUseService.getListStepsHowToCreateStrategies();
  };

  ctrl.setStepHowToCreateStrategies = function (step) {
    HowToUseService.setStepHowToCreateStrategies(step);
  };

  ctrl.getListStepsHowToUseTools = function () {
    return HowToUseService.getListStepsHowToUseTools();
  };

  ctrl.setStepHowToUseTools = function (step) {
    HowToUseService.setStepHowToUseTools(step);
  };

  ctrl.getHowToUseImage = function () {
    return HowToUseService.getHowToUseImage();
  };

  ctrl.getArrowIcons = function () {
    return HowToUseService.getArrowIcons();
  };

  ctrl.simulateHowToUseStep1 = function () {
    $("#howToUseCarousel").on("slid.bs.carousel", function () {
      if ($(".item").hasClass("active")) {
        var activeItem = $("div.item.active").index();
        switch (activeItem) {
          case 1:
            ctrl.setStepHowToCreateStrategies({ index: 1 });
            break;
          case 2:
            ctrl.setStepHowToCreateStrategies({ index: 2 });
            break;
          case 3:
            ctrl.setStepHowToCreateStrategies({ index: 8 });
            break;
          case 4:
            ctrl.setStepHowToCreateStrategies({ index: 3 });
            break;
        }
      }
    });
  };

  ctrl.simulateHowToUseStep2 = function () {
    $("#howToUseCarousel").on("slid.bs.carousel", function () {
      if ($(".item").hasClass("active")) {
        var activeItem = $("div.item.active").index();
        switch (activeItem) {
          case 1:
            break;
          case 2:
            ctrl.setStepHowToCreateStrategies({ index: 5 });
            break;
        }
      }
    });
  };
}

export const HowToUseControllerModule = new AppModule({
  name: "howToUse.controller",
  controllers: { HowToUseController: HowToUseController },
});

// angular
//     .module('howToUse.controller', [])
//     .controller('HowToUseController', HowToUseController)
