import { AppModule } from "../../module-wrapper";

/* @ngInject */
function config($urlRouterProvider, $stateProvider) {
  $urlRouterProvider.otherwise(location.pathname);

  $stateProvider.state("models/user", {
    title: "Users",
    url: "/models/user",
    templateUrl: "/app/modules/models/user/user.view.html",
    controller: "UserController",
    controllerAs: "userCtrl",
    authenticate: true
  });
}

export const UserRoutesModule = new AppModule({
  name: "user.routes",
  configurators: [config]
});

// angular
// 	.module('user.routes', [])
// 	.config(config);
