import { AppModule } from "../../../module-wrapper";

/* @ngInject */
function IndicatorValueService($resource, AppService) {
  var url = AppService.getUrlApi() + "/";

  return $resource(
    url,
    { region: "@region" },
    {
      get: {
        url: url + ":region/indicators",
        method: "POST",
        isArray: false,
        headers: {
          "X-HTTP-Method-Override": "GET",
        },
      },
    }
  );
}

/* @ngInject */
function IndicatorValueByNameService($resource, AppService) {
  var url = AppService.getUrlApi() + "/";

  return $resource(
    url,
    { region: "@region", name: "@name" },
    {
      get: {
        url: url + ":region/indicators/:name/",
        method: "POST",
        isArray: false,
        headers: {
          "X-HTTP-Method-Override": "GET",
        },
      },
    }
  );
}

export type IIndicatorStrategyService = ReturnType<typeof IndicatorStrategyService>;

/* @ngInject */
function IndicatorStrategyService(
  StrategyService,
  IndicatorService,
  IndicatorValueService,
  TranslateService,
  $filter,
  ModalService,
  IndicatorValueByNameService,
  $q,
  $injector,
  AxisService,
  ReportService,
  LogService
) {
  //   var ctrl = this;

  function getRegion() {
    var unitPlan = StrategyService.getUnitPlanningActive().name;
    return unitPlan == "grades" ? "grids" : "cities";
  }

  function setValueIndicatorInFeature(feature, indicator, colore) {
    var indicators = feature.getProperties().indicators || [];

    var has_indicator = false;

    for (var i = 0; i < indicators.length; i++) {
      var indicatorFor = indicators[i];
      if (indicator.name == indicatorFor.name) {
        indicatorFor.value = indicator.value;
        has_indicator = true;
        break;
      }
    }

    if (!has_indicator) {
      indicators.push(indicator);
    }

    feature.setProperties({ indicators: indicators });
    if (colore) {
      coloreFeature(feature, IndicatorService.getIndicatorByName(indicator.name), indicator.value);
    }
  }

  function getFeatureByCode(code) {
    var feature = StrategyService.getFeatureByCode(code);
    if (feature == undefined) {
      return false;
    }
    return feature;
  }

  function getValueFeaturesIndicator(features, indicator, colore) {
    var deferred = $q.defer();

    var codes = features.map(function (feature) {
      return feature.getProperties().code;
    });

    var params = {
      name: indicator.name,
      region: getRegion(),
    };

    IndicatorValueByNameService.get(params, {codes}).$promise.then(function (response) {
      var region_values = response.data;
      indicator.values = [];
      for (var i = 0; i < region_values.length; i++) {
        var region_value = region_values[i];
        indicator.values[i] = region_value.value;
        var feature = getFeatureByCode(region_value.code);
        if (feature) {
          setValueIndicatorInFeature(feature, region_value, colore);
        }
      }
      deferred.resolve();
    });

    return deferred.promise;
  }

  function getFeaturesNotNeedValuesIndicator(features, indicator_name) {
    var not_need_features = [] as any[];
    var i = 0;
    var len = features.length;
    for (; i < len; i++) {
      var feature = features[i];
      var indicators = feature.getProperties().indicators || [];
      var has_indicator = IndicatorService.getIndicatorByName(indicator_name, indicators, true);
      if (has_indicator) {
        not_need_features.push(feature);
      }
    }
    return not_need_features;
  }

  function getFeaturesNeedValuesIndicator(features, indicator_name) {
    var need_features = [] as any[];
    var i = 0;
    var len = features.length;
    for (; i < len; i++) {
      var feature = features[i];
      var indicators = feature.getProperties().indicators || [];
      var has_indicator = IndicatorService.getIndicatorByName(indicator_name, indicators, true);
      if (!has_indicator) {
        need_features.push(feature);
      }
    }
    return need_features;
  }

  function getDifferenceFeatures() {
    var all_features = getAllFeatures();
    var active_features = getActiveFeatures();

    var diff_features = [] as any[];
    var i = 0;
    var len = all_features.length;
    for (; i < len; i++) {
      var feature = all_features[i];
      if (active_features.indexOf(feature) < 0) {
        diff_features.push(feature);
      }
    }

    return diff_features;
  }

  function getAllFeatures() {
    var MapService = $injector.get("MapService");
    var features = MapService.getAtualSourceCacheVectorSelectionArea().getFeatures();
    return features as any[];
  }

  function removeFeaturesOutOfRange() {
    var diff_features = getDifferenceFeatures();
    var MapService = $injector.get("MapService");
    var source = MapService.getVectorSelectionAreaInstance().getSource();
    var i = 0;
    var len = diff_features.length;
    for (; i < len; i++) {
      var feature = diff_features[i];
      try {
        source.removeFeature(feature);
      } catch (e) {}
    }
  }

  function getActiveFeatures(indicator?) {
    var MapService = $injector.get("MapService");
    var indicators_active = IndicatorService.getIndicatorsActive();
    var features = getAllFeatures();
    var clear_features = [] as any[];

    var i = 0;
    var len = features.length;

    for (; i < len; i++) {
      var feature = features[i];
      var in_range = true;

      var j = 0;
      var jlen = indicators_active.length;

      for (; j < jlen; j++) {
        var indicator_active = indicators_active[j];
        if (!Boolean(indicator) || indicator_active.name != indicator.name) {
          if (feature.getProperties().indicators == undefined) {
            feature.setProperties({ indicators: [] });
          }
          var indicator_feature = IndicatorService.getIndicatorByName(
            indicator_active.name,
            feature.getProperties().indicators,
            false,
            true
          );
          if (indicator_feature) {
            var max = indicator_active.maxValue;
            var min = indicator_active.minValue;

            var value = indicator_feature.value;
            if (value !== null) {
              var value_fixed = Number(value.toFixed(indicator_active.precision));
              if (value_fixed == min || value_fixed == max) {
                value = value_fixed;
              }
              let diff = value - max;
              if (diff < 0) diff = diff * -1;
              if (diff < 0.0001) value = max;
              if (value > max || value < min) {
                in_range = false;
                break;
              }
            } else {
              in_range = false;
            }
          }
        }
      }

      if (in_range) {
        if (MapService.isFeatureInRegionsActive(feature)) {
          clear_features.push(feature);
        }
      }
    }
    return clear_features;
  }

  function resetInitialColor(features) {
    var MapService = $injector.get("MapService");
    MapService.setFeaturesStyleDefault(features);
  }

  function loadFeaturesValueIndicator(indicator, features, colore) {
    var deferred = $q.defer();
    var promises = [] as Promise<any>[];

    if (!Boolean(features)) {
      features = getActiveFeatures(indicator);
    }

    var features_need_indicator = getFeaturesNeedValuesIndicator(features, indicator.name);

    if (features_need_indicator.length > 0) {
      var promise = getValueFeaturesIndicator(features_need_indicator, indicator, colore);
      promises.push(promise);
    }

    $q.all(promises).then(function () {
      deferred.resolve();
    });

    return deferred.promise;
  }

  function coloreFeature(feature, indicator, value?) {
    var MapService = $injector.get("MapService");
    var min = indicator.minValue;
    var max = indicator.maxValue;
    if (value == undefined) {
      value = 0;
      var indicator_feature = IndicatorService.getIndicatorByName(
        indicator.name,
        feature.getProperties().indicators,
        false,
        true
      );
      if (indicator_feature) {
        value = indicator_feature.value;
      }
    }

    if (value !== null) {
      var value_fixed = Number(value.toFixed(indicator.precision));
      if (value_fixed == min || value_fixed == max) {
        value = value_fixed;
      }
      let diff = value - max;
      if (diff < 0) diff = diff * -1;
      if (diff < 0.0001) value = max;
      if (value >= min && value <= max) {
        var style = MapService.getFeatureStyle(value, min, max, indicator.inverse_relationship);
        feature.setStyle(style);
      }

      var style = MapService.getFeatureStyle(value, min, max, indicator.inverse_relationship);
      feature.setStyle(style);
    }
  }

  function setColorFeaturesByIndicator(indicator) {
    IndicatorService.setLastIndicator(indicator);
    var MapService = $injector.get("MapService");
    var features = getActiveFeatures(indicator);
    var source = MapService.getVectorSelectionAreaInstance().getSource();
    ReportService.setReportCached(false);
    ReportService.setReportCachedExportPdf(false);
    source.clear();
    StrategyService.clearResultStrategy();
    removeFeaturesOutOfRange();
    if (indicator.visible) {
      var features_not_need = getFeaturesNotNeedValuesIndicator(features, indicator.name);
      var promises = [] as Promise<any>[];
      var promise = loadFeaturesValueIndicator(indicator, features, true);
      promises.push(promise);
      $q.all(promises).then(function () {
        removeFeaturesOutOfRange();
        ModalService.closeModalLoading();
      });
      features_not_need.forEach(function (feature) {
        coloreFeature(feature, indicator);
      });
    } else {
      indicator.ultimo = false;
      resetInitialColor(features);
      ModalService.closeModalLoading();
    }
    source.addFeatures(features);
  }

  function clearIndicatorsStrategy() {
    var indicators = IndicatorService.getIndicators();
    var i = 0;
    var len = indicators.length;
    for (; i < len; i++) {
      var indicator = indicators[i];
      indicator.in_list = false;
      indicator.inverse_relationship = indicator.initial_relation;
      indicator.weight = 1;
      indicator.visible = false;
    }
  }

  function getOptionsSlider(indicador, reset) {
    if (!Boolean(indicador.options) || Boolean(reset)) {
      // Retirado o arredondamento a pedido da flavia.
      // indicador.minValue = Math.floor(indicador.minValue);
      // indicador.maxValue = Math.ceil(indicador.maxValue);
      indicador.step = 0.1;
      indicador.precision = 1;
      indicador.rounder = 1;
      indicador.maximun = false;
      if (indicador.maxValue <= 1) {
        indicador.maximun = true;
        indicador.step = 0.00005;
        indicador.precision = 5;
        indicador.rounder = 5;
      }

      indicador.minValue = indicador.minValue
        ? Number(indicador.minValue.toFixed(indicador.rounder))
        : 0;
      indicador.maxValue = indicador.maxValue
        ? Number(indicador.maxValue.toFixed(indicador.rounder))
        : 0;
      indicador.options = {
        step: indicador.step,
        precision: indicador.precision,
        floor: indicador.minValue,
        ceil: indicador.maxValue,
        noSwitching: true,
        hideLimitLabels: false,
        translate: function (value) {
          var sigla = "";
          try {
            sigla = TranslateService.translate(indicador.unit.sigla);
          } catch (e) {}
          return $filter("number")(value.toFixed(indicador.rounder)) + " " + sigla;
        },
      };
    }
    return indicador.options;
  }

  function getOptionsLogarithmicSlider(indicador, reset) {
    if (!Boolean(indicador.options) || Boolean(reset)) {
      indicador.step = 0.0000001;
      indicador.precision = 5;
      indicador.rounder = 5;

      indicador.minValue = indicador.minValue
        ? Number(indicador.minValue.toFixed(indicador.rounder))
        : 0;
      indicador.maxValue = indicador.maxValue
        ? Number(indicador.maxValue.toFixed(indicador.rounder))
        : 0;

      indicador.options = {
        step: indicador.step,
        precision: indicador.precision,
        floor: indicador.minValue,
        ceil: indicador.maxValue,
        customValueToPosition: function (val, minVal, maxVal) {
          val = Math.sqrt(val);
          minVal = Math.sqrt(minVal);
          maxVal = Math.sqrt(maxVal);
          var range = maxVal - minVal;
          return (val - minVal) / range;
        },
        customPositionToValue: function (percent, minVal, maxVal) {
          minVal = Math.sqrt(minVal);
          maxVal = Math.sqrt(maxVal);
          var value = percent * (maxVal - minVal) + minVal;
          return Math.pow(value, 2);
        },

        translate: function (value) {
          var sigla = "";
          try {
            sigla = TranslateService.translate(indicador.unit.sigla);
          } catch (e) {}
          return $filter("number")(value.toFixed(indicador.rounder)) + " " + sigla;
        },
      };
    }
    return indicador.options;
  }

  function getOptionsIntStepSlider(indicador, reset) {
    if (!Boolean(indicador.options) || Boolean(reset)) {
      indicador.step = 1;
      indicador.precision = 1;
      indicador.rounder = 1;
      indicador.maximun = false;
      indicador.minValue = Number(indicador.minValue.toFixed(indicador.rounder)) || 0;
      indicador.maxValue = Number(indicador.maxValue.toFixed(indicador.rounder)) || 0;
      indicador.options = {
        step: indicador.step,
        precision: indicador.precision,
        floor: indicador.minValue,
        ceil: indicador.maxValue,
        noSwitching: true,
        hideLimitLabels: false,
        translate: function (value) {
          var sigla = "";
          try {
            sigla = TranslateService.translate(indicador.unit.sigla);
          } catch (e) {}
          return $filter("number")(value.toFixed(indicador.rounder)) + " " + sigla;
        },
      };
    }
    return indicador.options;
  }

  function getCodesFeatures(features) {
    var i = 0;
    var len = features.length;
    var codes = [] as string[];
    for (; i < len; i++) {
      var feature = features[i];
      codes.push(feature.getProperties().code);
    }
    return codes;
  }

  function updateValuesIndicators(features, plan) {
    var deferred = $q.defer();
    if (features.length > 0) {
      var codes = getCodesFeatures(features);
      var params = {
        codes: codes,
        region: getRegion(),
      };
      clearIndicatorsStrategy();
      IndicatorService.setVisibleAllIndicators(false);
      var indicators = IndicatorService.getIndicators();
      IndicatorValueService.get(params).$promise.then(function (response) {
        var indicators_values = response.data;
        for (var i = 0; i < indicators_values.length; i++) {
          var indicator_value = indicators_values[i];
          var indicator = IndicatorService.getIndicatorByName(
            indicator_value.name,
            indicators,
            false,
            true
          );
          if (indicator) {
            indicator.maxValue = indicator_value.max_value;
            indicator.minValue = indicator_value.min_value;
            indicator.in_list = true;
            if (indicator.name == "sa8_riskex") {
              indicator.options = getOptionsLogarithmicSlider(indicator, true);
            } else if (
              indicator.name == "sa5_n_emb" ||
              indicator.name == "sa6_confli" ||
              indicator.name == "sa4_te_cpt"
            ) {
              indicator.options = getOptionsIntStepSlider(indicator, true);
            } else {
              indicator.options = getOptionsSlider(indicator, true);
            }
            if (!$.isEmptyObject(plan) && !$.isEmptyObject(plan.values)) {
              try {
                var value_indicator = plan.values.filter(function (value) {
                  return indicator.name == value.indicador;
                });
                if (value_indicator.length > 0) {
                  value_indicator = value_indicator[0];
                  indicator.plan_id = value_indicator.id;
                  indicator.inverse_relationship = value_indicator.relacao;
                  indicator.weight = value_indicator.weight;
                  indicator.ultimo = value_indicator.ultimo;
                  indicator.visible = true;
                  indicator.minValue = value_indicator.min_value;
                  indicator.maxValue = value_indicator.max_value;
                  LogService.sendButtonLog(
                    "ligar_indicador",
                    "function",
                    "strategy",
                    indicator.name
                  );
                }
              } catch (e) {}
            }
          }
        }
        deferred.resolve();
      });
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  function isReadyToGenerateReport() {
    var axes = AxisService.getAxes();
    var is_ready = true;

    if (axes.length > 0) {
      axes.forEach(function (axis) {
        var features = getActiveFeatures();
        var indicators = IndicatorService.getIndicatorsActiveByAxisName(axis.name);
        if (indicators.length <= 0 || features.length <= 1) {
          is_ready = false;
        }
      });
    } else {
      is_ready = false;
    }
    return is_ready;
  }

  return {
    getActiveFeatures: getActiveFeatures,
    getAllFeatures: getAllFeatures,
    getOptionsSlider: getOptionsSlider,
    getOptionsLogarithmicSlider: getOptionsLogarithmicSlider,
    getOptionsIntStepSlider: getOptionsIntStepSlider,
    isReadyToGenerateReport: isReadyToGenerateReport,
    loadFeaturesValueIndicator: loadFeaturesValueIndicator,
    removeFeaturesOutOfRange: removeFeaturesOutOfRange,
    setColorFeaturesByIndicator: setColorFeaturesByIndicator,
    updateValuesIndicators: updateValuesIndicators,
  };
}

export const IndicatorStrategyServiceModule = new AppModule({
  name: "indicatorstrategy.service",
  factories: {
    IndicatorStrategyService,
    IndicatorValueService,
    IndicatorValueByNameService,
  },
});

// angular
//     .module('indicatorstrategy.service', [])
//     .factory('IndicatorStrategyService', IndicatorStrategyService)
//     .factory('IndicatorValueService', IndicatorValueService)
//     .factory('IndicatorValueByNameService', IndicatorValueByNameService)
