import { AppModule } from "../../../module-wrapper";
import angular from "angular";

/* @ngInject */
function IndicatorStrategyController(
  this: any,
  IndicatorStrategyService,
  StrategyService,
  ModalService,
  LogService
) {
  var ctrl = this;

  ctrl.changeSlideIndicator = function (indicator, send_log, button) {
    ModalService.openModalLoading();
    if (send_log) {
      var button = button || (indicator.visible ? "ligar" : "desligar") + "_indicador";
      LogService.sendButtonLog(button, "click", "strategy", indicator.name);
    }
    angular.element(function () {
      IndicatorStrategyService.setColorFeaturesByIndicator(indicator);
      StrategyService.clearResultStrategy();
    });
    setTimeout(function () {
      var features = IndicatorStrategyService.getActiveFeatures();
      if (features.length <= 1) {
        ModalService.openModalWarningSelectionArea();
      }
    }, 500);
  };

  ctrl.isReadyToGenerateReport = function () {
    return IndicatorStrategyService.isReadyToGenerateReport();
  };
}

export const IndicatorStrategyControllerModule = new AppModule({
  name: "indicatorstrategy.controller",
  controllers: { IndicatorStrategyController },
});

// angular
//     .module('indicatorstrategy.controller', [])
//     .controller('IndicatorStrategyController', IndicatorStrategyController)
