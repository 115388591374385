import { AppModule } from "../../module-wrapper";

/* @ngInject */
function FeatureIndicatorInfo() {
  return {
    restrict: "E",
    templateUrl: "/app/core/modal/views/feature/feature.indicator-info.html"
  };
}

/* @ngInject */
function ModalLateralMenu() {
  return {
    restrict: "E",
    templateUrl: "/app/core/modal/views/feature/feature.lateral-menu.html"
  };
}

/* @ngInject */
function HowToUseStrategyView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/how-to-use-strategy.system.html"
  };
}

/* @ngInject */
function HowToUseLayersView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/how-to-use-layers.system.html"
  };
}

/* @ngInject */
function HowToUseLegendView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/how-to-use-legend.system.html"
  };
}

/* @ngInject */
function HowToUseMenuView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/how-to-use-menu.system.html"
  };
}

/* @ngInject */
function HowToUseToolsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/how-to-use-tools.system.html"
  };
}

/* @ngInject */
function HowToUseStrategyViewP1() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/strategyStepViews/how-to-use-strategy-p1.html"
  };
}

/* @ngInject */
function HowToUseStrategyViewP2() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/strategyStepViews/how-to-use-strategy-p2.html"
  };
}

/* @ngInject */
function HowToUseStrategyViewP3() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/strategyStepViews/how-to-use-strategy-p3.html"
  };
}

/* @ngInject */
function HowToUseStrategyViewP4() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/strategyStepViews/how-to-use-strategy-p4.html"
  };
}

/* @ngInject */
function HowToUseStrategyViewP5() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/howToUse/views/strategyStepViews/how-to-use-strategy-p5.html"
  };
}

export const ModalDirectivesModule = new AppModule({
  name: "modal.directives",
  directives: {
    featureIndicatorInfo: FeatureIndicatorInfo,
    modalLateralMenu: ModalLateralMenu,
    howToUseStrategyView: HowToUseStrategyView,
    howToUseLayersView: HowToUseLayersView,
    howToUseLegendView: HowToUseLegendView,
    howToUseMenuView: HowToUseMenuView,
    howToUseToolsView: HowToUseToolsView,
    howToUseStrategyViewP1: HowToUseStrategyViewP1,
    howToUseStrategyViewP2: HowToUseStrategyViewP2,
    howToUseStrategyViewP3: HowToUseStrategyViewP3,
    howToUseStrategyViewP4: HowToUseStrategyViewP4,
    howToUseStrategyViewP5: HowToUseStrategyViewP5
  }
});

// angular
//     .module('modal.directives', [])
//     .directive('featureIndicatorInfo', FeatureIndicatorInfo)
//     .directive('modalLateralMenu', ModalLateralMenu);
