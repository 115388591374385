import { AppModule } from "../../module-wrapper";
import angular from "angular";
import $ from "jquery";

/* @ngInject */
function GeographicSearchService(AppService, $resource) {
  return function(url) {
    return $resource(AppService.getUrlApi() + "/" + url, {
      get: {
        method: "GET",
        isArray: false
      }
    });
  };
}

/* @ngInject */
function StrategyServiceUser(AppService, $resource) {
  var url = AppService.getUrlApi() + "/my/estrategia";
  return $resource(
    url,
    {},
    {
      get: {
        method: "GET",
        isArray: false
      }
    }
  );
}

/* @ngInject */
function StrategyService(
  this: any,
  $localStorage,
  StrategyServiceUser,
  LayerService,
  $injector,
  AppService,
  NotificationService,
  MainService,
  LogService,
  $location,
  $timeout
) {
  var ctrl = this;

  ctrl.collapseSessions = [];

  ctrl.area = {};

  ctrl.features = {};

  ctrl.sentNotificationRegions = false;

  ctrl.isInStrategyTab = false;

  function getFeatures() {
    var unitName = getNameUnitPlanningActive();
    if (ctrl.features[unitName] == undefined) {
      ctrl.features[unitName] = {};
    }
    return ctrl.features[unitName];
  }

  function removeFeature(code) {
    var unitName = getNameUnitPlanningActive();
    ctrl.features[unitName][code] = undefined;
  }

  function setFeatures(features) {
    var unitName = getNameUnitPlanningActive();
    ctrl.features[unitName] = features;
  }

  function addFeature(feature, unitName) {
    var code = feature.getProperties().code;
    ctrl.features[unitName][code] = feature;
  }

  function getFeatureByCode(code) {
    return getFeatures()[code];
  }

  function hasFeatureCode(code) {
    return getFeatures().hasOwnProperty(code) && getFeatures()[code] != undefined;
    // return ctrl.features[code] != undefined;
  }

  function clearFeatures() {
    ctrl.features = {};
  }

  function sendNotificationRegions() {
    NotificationService.showNotification(
      "info",
      MainService.getTextByKeyI18n("strategy.regiaodeinteresse"),
      MainService.getTextByKeyI18n("strategy.notificacaointerseccao"),
      true,
      10000
    );
    setStatusNotificationRegions(true);
  }

  function setStatusNotificationRegions(status) {
    ctrl.sentNotificationRegions = status;
  }

  function getStatusNotificationRegions() {
    return ctrl.sentNotificationRegions;
  }

  function setIdArea(id) {
    ctrl.area.id = id;
  }

  function registerCollapseSession(obj, index) {
    obj.classDisabled = false;
    ctrl.collapseSessions.push(obj);
    // if (ctrl.collapseSessions.length < 1) {
    //   ctrl.collapseSessions.push(obj);
    // }
    // else {
    //   ctrl.collapseSessions.forEach(function(session, i) {
    //     if (session.name === obj.name) {
    //       ctrl.collapseSessions[i] = obj;
    //       index = session.collapse_index;
    //     }
    //   });
    // }
    return index || ctrl.collapseSessions.length;
  }

  // FIXME: if (except_index == undefined) impede os subgrupos fecharem os grupos; melhorar codigo
  function resetCollapsesSession(except_index) {
    if (except_index == undefined) {
      return 0;
    }
    ctrl.collapseSessions.forEach(function(obj) {
      if (obj.collapse_index !== except_index) {
        obj.collapse = false;
      }
    });
  }

  function removeClassAllSession() {
    ctrl.collapseSessions.forEach(function(obj) {
      obj.classDisabled = false;
    });
  }

  function setCollapseSession(index, disabled) {
    ctrl.collapseSessions.forEach(function(obj) {
      if (obj.collapse_index === index) {
        obj.collapse = true;
        obj.classDisabled = false;
      } else {
        obj.classDisabled = disabled;
      }
    });
    resetCollapsesSession(index);
  }

  function getCollapseSessions() {
    return ctrl.collapseSessions || [];
  }

  ctrl.regions = [
    {
      id: 3,
      name: "chaco",
      layerName: AppService.getLayersNameBiomes().chaco,
      label: "strategy.chaco",
      labelReport: "report.chaco",
      checked: false
    },
    {
      id: 2,
      name: "amazonia",
      layerName: AppService.getLayersNameBiomes().amazonia,
      label: "strategy.amazonia",
      labelReport: "report.amazonia",
      checked: false
    },
    {
      id: 1,
      name: "cerrado",
      layerName: AppService.getLayersNameBiomes().cerrado,
      label: "strategy.cerrado",
      labelReport: "report.cerrado",
      checked: false
    }
  ];

  ctrl.unitsPlanning = [
    {
      id: 1,
      name: "municipios",
      layerName: AppService.getLayersNameUnits().municipios,
      label: AppService.getDynamicTranslations().strategy.municipio,
      labelReport: AppService.getDynamicTranslations().report.municipio,
      labelReportPlural: AppService.getDynamicTranslations().report.municipios,
      checked: false,
      first: true
    },
    {
      id: 2,
      name: "grades",
      layerName: AppService.getLayersNameUnits().grades,
      label: AppService.getDynamicTranslations().strategy.grade,
      labelReport: AppService.getDynamicTranslations().report.grade,
      labelReportPlural: AppService.getDynamicTranslations().report.grades,
      checked: false,
      first: true
    }
  ];

  function setFirstAll() {
    ctrl.unitsPlanning.forEach(function(unit) {
      unit.first = false;
    });
  }

  function setStrategies(strategies) {
    $localStorage.strategies = strategies;
  }

  function setStrategie(strategie) {
    if ($.isEmptyObject($localStorage.strategies)) {
      $localStorage.strategies = [];
    }
    $localStorage.strategies.push(strategie);
  }

  function loadStrategies() {
    if (!$.isEmptyObject($localStorage.token)) {
      StrategyServiceUser.get().$promise.then(function(response) {
        if (response.success) {
          $localStorage.strategies = response.data;
        }
      });
    }
  }

  function getStrategies() {
    return $localStorage.strategies;
  }

  function getUnitsPlanning() {
    return ctrl.unitsPlanning;
  }

  function getUnitPlanningActive() {
    return ctrl.unitsPlanning.filter(function(unit) {
      return unit.checked;
    })[0];
  }

  function getNameUnitPlanningActive() {
    var unitName = "municipios";

    var unitPlan = getUnitPlanningActive();
    if (angular.isDefined(unitPlan)) {
      unitName = unitPlan.name;
    }

    return unitName;
  }

  function setLastNameUnitPlanningActive(unitName) {
    ctrl.lastNameUnitPlanningActive = unitName;
  }

  function getLastNameUnitPlanningActive() {
    return ctrl.lastNameUnitPlanningActive || getNameUnitPlanningActive();
  }

  function setStatusUnitPlanning(id, condition) {
    var layersWms = LayerService.getGroupLayerWms();
    ctrl.unitsPlanning.forEach(function(unitPlan) {
      if (unitPlan.id === id) {
        setLastNameUnitPlanningActive(unitPlan.name);
        unitPlan.checked = condition;
      } else {
        unitPlan.checked = !condition;
      }
      var send_log = true,
        refreshList = true,
        is_function = true;
      LayerService.setVisibleWmsLayerByName(
        layersWms,
        unitPlan.layerName,
        unitPlan.checked,
        refreshList,
        send_log,
        is_function
      );
    });
  }

  function setStatusAllUnitsPlanning(condition) {
    ctrl.unitsPlanning.forEach(function(unitPlan) {
      unitPlan.checked = condition;
    });
  }

  function getRegions() {
    var regionsActive = AppService.getActiveRegionsInterest();
    var regions = ctrl.regions.filter(function(region) {
      return regionsActive.indexOf(region.name) > -1;
    });
    return regions;
  }

  function isSelectedRegion() {
    var regionsSelected = ctrl.regions.filter(function(region) {
      return region.checked;
    });
    return regionsSelected.length !== 0;
  }

  function getRegionsActive() {
    var regionsActive = [] as any[];
    ctrl.regions.forEach(function(region) {
      if (region.checked) {
        regionsActive.push(region.name);
      }
    });
    return regionsActive;
  }

  function setStatusAllRegions(condition) {
    ctrl.regions.forEach(function(region) {
      region.checked = condition;
    });
  }

  function setStatusRegion(id, condition) {
    ctrl.regions.forEach(function(region) {
      if (region.id === id) {
        region.checked = condition;
      }
    });
  }

  function setStatusRegionByName(name, condition) {
    ctrl.regions.forEach(function(region) {
      if (region.name === name) {
        region.checked = condition;
        if (condition) {
          LogService.sendButtonLog(
            "selecionar_regiao_interesse",
            "function",
            "strategy",
            region.name
          );
        }
      }
    });
  }

  function getRegionByName(name) {
    var values = ctrl.regions.filter(function(region) {
      return region.name == name;
    });
    if (values.length > 0) {
      return values[0];
    } else {
      return -1;
    }
  }

  function setParamsGeographicSearch(url, params) {
    if (angular.isUndefined(ctrl.paramsGeographicSearch)) {
      ctrl.paramsGeographicSearch = [];
    }
    var _params = {
      url: url,
      params: params
    };
    ctrl.paramsGeographicSearch.push(_params);
  }

  function getParamsGeographicSearch() {
    return ctrl.paramsGeographicSearch;
  }

  function clearParamsGeographicSearch() {
    ctrl.paramsGeographicSearch = undefined;
  }

  function setStrategyInfo(obj) {
    var strategy = obj || {};
    var valueId = strategy.id || undefined;
    var valueNome = strategy.name || "";
    var valueHash = strategy.hash || "";
    var valuePlano = strategy.plano || [];
    var valueOpacidade = strategy.opacidade || getOptionsOpacidade();

    var area = angular.isDefined(strategy.area)
      ? {
          grades: strategy.area.grades || [],
          municipios: strategy.area.municipios || []
        }
      : undefined;

    ctrl.strategy = {
      id: valueId,
      name: valueNome,
      hash: valueHash,
      plano: valuePlano,
      opacidade: valueOpacidade,
      area: area
    };
  }

  function getStrategy() {
    return ctrl.strategy || {};
  }

  function getStrategyInfo() {
    return ctrl.strategy;
  }

  function clearStrategyInfo() {
    return ctrl.strategy;
  }

  function getOptionsOpacidade(valorOpacidade?) {
    var MapService = $injector.get("MapService");
    var ReportService = $injector.get("ReportService");
    return {
      valor: valorOpacidade || 100,
      options: {
        floor: 0,
        ceil: 100,
        step: 1,
        precision: 1,
        onChange: function() {
          ReportService.setReportCached(false);
          var valor = getStrategyInfo().opacidade.valor;
          LayerService.setOpacityLayer(MapService.getVectorSelectionAreaInstance(), valor / 100);
        },
        translate: function(valor) {
          return valor + "%";
        }
      }
    };
  }

  function hasStrategy() {
    return getStrategyHash() != undefined && getStrategyHash() != "";
  }

  function getStrategyName() {
    return getStrategy().name;
  }

  function getStrategyHash() {
    var hash_recuperacao = getStrategy().hash;
    if (hash_recuperacao == undefined || ctrl.strategy.hash == "") {
      if (angular.isDefined($location.search().ESTRATEGIA)) {
        hash_recuperacao = $location.search().ESTRATEGIA;
      } else if (angular.isDefined($location.search().estrategia)) {
        hash_recuperacao = $location.search().estrategia;
      }
    }
    return hash_recuperacao;
  }

  function setStrategyName(nome) {
    ctrl.strategy["nome"] = nome;
  }

  function getNameByTypeFeature(feature) {
    var type = feature.getGeometry().getType();
    if (type === "Point") {
      return "strategy.buffer";
    } else if (type === "Polygon") {
      return "strategy.poligono";
    }
  }

  function createArea(features) {
    var unitPlan = getUnitPlanningActive();
    ctrl.area = {};
    ctrl.area.municipios = [];
    ctrl.area.grades = [];
    ctrl.area.municipios_codigo = [];
    ctrl.area.grades_codigo = [];

    if (features.length > 0) {
      features.forEach(function(feature) {
        if (unitPlan.name == "municipios") {
          ctrl.area.municipios.push(feature.getProperties().code);
          ctrl.area.municipios_codigo.push(feature.getProperties().code);
        } else if (unitPlan.name == "grades") {
          ctrl.area.grades.push(feature.getProperties().code);
          ctrl.area.grades_codigo.push(feature.getProperties().code);
        }
      });
    }
    return ctrl.area;
  }

  function clearResultStrategy() {
    ctrl.classesLegend = [];
  }

  function getResultStrategy() {
    return ctrl.classesLegend || null;
  }

  function generateResultStrategy(send_log, close_loading, is_function) {
    var IndicatorService = $injector.get("IndicatorService");
    var IndicatorStrategyService = $injector.get("IndicatorStrategyService");
    var MapService = $injector.get("MapService");
    var ReportService = $injector.get("ReportService");
    var ModalService = $injector.get("ModalService");

    var features = MapService.getFeaturesSelectionAreaInstance() || [];
    var classes = ReportService.getClasses();
    var indicatorsActive = IndicatorService.getIndicatorsActive();

    if (
      angular.isDefined(IndicatorService.getIndicators()) &&
      IndicatorStrategyService.isReadyToGenerateReport()
    ) {
      if (send_log) {
        var format = is_function ? "function" : "click";
        LogService.sendButtonLog("visualizar_estrategia", format, "strategy");
      }
      IndicatorStrategyService.removeFeaturesOutOfRange();
      MapService.calcularIndiceGeral(features, indicatorsActive);
      ctrl.classesLegend = ReportService.calculateTotalFeaturesByClasse(classes, features);
    }
    if (close_loading) {
      ModalService.closeModalLoading();
    }
  }

  function getIsInStrategyTab() {
    return Boolean(ctrl.isInStrategyTab);
  }

  function setIsInStrategyTab(value) {
    ctrl.isInStrategyTab = value;
  }

  function getActiveBiomeByFeature(){
    var MapService = $injector.get("MapService");
    var features = MapService.getFeaturesSelectionAreaInstance() || [];
    ctrl.listBiomes = [];
    var isIntersectionActive = false;
    features.forEach(function (feature) {
      ctrl.listBiomes.push("" + feature.getProperties().biomas);
      if (
        ctrl.listBiomes.includes("amazonia") &&
        (ctrl.listBiomes.includes("cerrado,amazonia") ||
          ctrl.listBiomes.includes("amazonia,cerrado"))
      ) {
        isIntersectionActive = false;
      }
      if (ctrl.listBiomes.includes("cerrado")) {
          isIntersectionActive = true;
      }
      if (ctrl.listBiomes.includes("amazonia") & ctrl.listBiomes.includes("cerrado")) {
        isIntersectionActive = true;
      }
    });
    return isIntersectionActive;
  }

  function hasMoratoria() {
    var isSojaBR = AppService.getLayout();
    var isCerradoAndAmazonia = getActiveBiomeByFeature();
    var isCompromissosClicked = $("#group_compromissos span:first").hasClass("flaticon-sort-down");
    var isSocioambientalAxisOpen = $("#axis_2 > span > span").hasClass("flaticon-sort-down");

    if (
      isSojaBR == "soja" &&
      isCerradoAndAmazonia &&
      isSocioambientalAxisOpen &&
      isCompromissosClicked
    ) {
      $timeout(function () {
        $(".51").attr("disabled", "true");
      });
      return true;
    } else {
      return false;
    }
  }

  function hasNullCells() {
    var isNullIndicatorActive = false
    var nullIndicatorsList = ["prodmemb", "prod25emb", "prod75emb"];
    var IndicatorService = $injector.get("IndicatorService");
    var indicatorsActive = IndicatorService.getIndicatorsActive();
    indicatorsActive.forEach((indicator) => {
      isNullIndicatorActive = nullIndicatorsList.includes(indicator.name);
    });
    return isNullIndicatorActive
  }

  return {
    sendNotificationRegions: sendNotificationRegions,
    setStatusNotificationRegions: setStatusNotificationRegions,
    getStatusNotificationRegions: getStatusNotificationRegions,
    setIdArea: setIdArea,
    setStatusRegionByName: setStatusRegionByName,
    getUnitPlanningActive: getUnitPlanningActive,
    setStatusUnitPlanning: setStatusUnitPlanning,
    setStatusAllUnitsPlanning: setStatusAllUnitsPlanning,
    getNameUnitPlanningActive: getNameUnitPlanningActive,
    getRegions: getRegions,
    isSelectedRegion: isSelectedRegion,
    getRegionsActive: getRegionsActive,
    getLastNameUnitPlanningActive: getLastNameUnitPlanningActive,
    setStatusAllRegions: setStatusAllRegions,
    setStatusRegion: setStatusRegion,
    resetCollapsesSession: resetCollapsesSession,
    removeClassAllSession: removeClassAllSession,
    getUnitsPlanning: getUnitsPlanning,
    setParamsGeographicSearch: setParamsGeographicSearch,
    getParamsGeographicSearch: getParamsGeographicSearch,
    clearParamsGeographicSearch: clearParamsGeographicSearch,
    setStrategyInfo: setStrategyInfo,
    getStrategyInfo: getStrategyInfo,
    clearStrategyInfo: clearStrategyInfo,
    getOptionsOpacidade: getOptionsOpacidade,
    setStrategyName: setStrategyName,
    getStrategies: getStrategies,
    loadStrategies: loadStrategies,
    setStrategies: setStrategies,
    setStrategie: setStrategie,
    registerCollapseSession: registerCollapseSession,
    setCollapseSession: setCollapseSession,
    getCollapseSessions: getCollapseSessions,
    getNameByTypeFeature: getNameByTypeFeature,
    createArea: createArea,
    clearResultStrategy: clearResultStrategy,
    getResultStrategy: getResultStrategy,
    generateResultStrategy: generateResultStrategy,
    getRegionByName: getRegionByName,
    getFeatures: getFeatures,
    setFeatures: setFeatures,
    addFeature: addFeature,
    getFeatureByCode: getFeatureByCode,
    hasFeatureCode: hasFeatureCode,
    removeFeature: removeFeature,
    clearFeatures: clearFeatures,
    getStrategyName: getStrategyName,
    getStrategyHash: getStrategyHash,
    hasStrategy: hasStrategy,
    setFirstAll: setFirstAll,
    getIsInStrategyTab: getIsInStrategyTab,
    setIsInStrategyTab: setIsInStrategyTab,
    getActiveBiomeByFeature: getActiveBiomeByFeature,
    hasMoratoria: hasMoratoria,
    hasNullCells: hasNullCells
  };
}

export const StrategyServiceModule = new AppModule({
  name: "strategy.service",
  factories: { GeographicSearchService, StrategyServiceUser, StrategyService }
});

// angular
//   .module("strategy.service", [])
//   .factory("GeographicSearchService", GeographicSearchService)
//   .factory("StrategyServiceUser", StrategyServiceUser)
//   .factory("StrategyService", StrategyService);
