import { AppModule } from "../../module-wrapper";
import { LayersControllerModule } from "./layers.controller";
import { LayersDirectivesModule } from "./layers.directives";
import { LayersServiceModule } from "./layers.service";

// angular
//     .module('layers.module', [
//         'layers.controller',
//         'layers.directives',
//         'layers.service'
//     ]);

export const LayersModule = new AppModule({
  name: "layers.module",
  modules: [LayersControllerModule, LayersDirectivesModule, LayersServiceModule]
});
