import { AppModule } from "../../module-wrapper";
import angular from "angular";
import html2canvas from "html2canvas";

/* @ngInject */
function ReportInfoService($resource, AppService) {
  return function (url) {
    return $resource(
      AppService.getUrlApi() + "/data/" + url,
      {},
      {
        getInfo: {
          method: "POST",
          isArray: false,
          headers: {
            "X-HTTP-Method-Override": "GET",
          },
        },
      }
    );
  };
}

/* @ngInject */
function ReportService(
  this: any,
  $q,
  $sessionStorage,
  ReportInfoService,
  StrategyService,
  $injector,
  AppService,
  $anchorScroll,
  $location,
  MainService
) {
  var ctrl = this;

  ctrl.classes = [
    {
      id: 1,
      label: "report.baixoriscosocioambientalcomaltaoportunidadeeconomica",
      risco: "baixo",
      oportunidade: "alto",
      icon: "icon-grade-green",
    },
    {
      id: 2,
      label: "report.baixoriscosocioambientalcombaixaoportunidadeeconomica",
      risco: "baixo",
      oportunidade: "baixo",
      icon: "icon-grade-yellow",
    },
    {
      id: 3,
      label: "report.altoriscosocioambientalcomaltaoportunidadeeconomica",
      risco: "alto",
      oportunidade: "alto",
      icon: "icon-grade-orange",
    },
    {
      id: 4,
      label: "report.altoriscosocioambientalcombaixaoportunidadeeconomica",
      risco: "alto",
      oportunidade: "baixo",
      icon: "icon-grade-red",
    },
  ];
  ctrl.totalSuperpositionAreaList = [];

  function getReportData() {
    return ctrl.reportData;
  }

  function destroyCacheOnInit() {
    delete $sessionStorage.reportCached;
    delete $sessionStorage.reportCachedExportPdf;
  }

  destroyCacheOnInit();

  function setReportCached(cached) {
    $sessionStorage.reportCached = cached;
  }

  function getReportCached() {
    return $sessionStorage.reportCached || false;
  }

  function setReportCachedExportPdf(cached) {
    $sessionStorage.reportCachedExportPdf = cached;
  }

  function getReportCachedExportPdf() {
    return $sessionStorage.reportCachedExportPdf || false;
  }

  function getClasses() {
    return ctrl.classes;
  }

  // set colors in classes by colors classes in agroideal.json
  function setColorsClasses() {
    var reportScructure = AppService.getReportStructure();
    reportScructure.stratificationClasses.forEach(function (classesSettings) {
      getClasses().some(function (classe) {
        if (classesSettings.id === classe.id) {
          Object.assign(classe, classesSettings);
        }
      });
    });
  }

  setColorsClasses();

  function setDataURLElementInClasse() {
    StrategyService.getResultStrategy().forEach(function (featuresClasse) {
      getClasses().some(function (classe) {
        if (featuresClasse.id === classe.id) {
          getDataUrlLogo("/assets/icons/report/" + classe.icon + ".png", function (dataURL) {
            featuresClasse.dataURL = dataURL;
          });
          return true;
        }
      });
    });
  }

  function setDataURLElementCompass(dataURLElementCompass) {
    ctrl.dataURLElementCompass = dataURLElementCompass;
  }

  function setDataURLMapBioma(dataURLMapBioma) {
    ctrl.dataURLMapBioma = dataURLMapBioma;
  }

  function setDataURLMapAreaSelecionada(dataURLMapAreaSelecionada) {
    ctrl.dataURLMapAreaSelecionada = dataURLMapAreaSelecionada;
  }

  function setDataURLMapIndiceGeral(dataURLMapIndiceGeral) {
    ctrl.dataURLMapIndiceGeral = dataURLMapIndiceGeral;
  }

  function setDataURLLogoAgroideal(dataURLLogoAgroideal) {
    ctrl.dataURLLogoAgroideal = dataURLLogoAgroideal;
  }

  function setDataURLIconLink(dataURLIconLink) {
    ctrl.dataURLIconLink = dataURLIconLink;
  }

  function setDataURLSlidersIndicators(listIndicators) {
    var deferred = $q.defer();
    var contInterations = 0;
    listIndicators.forEach(function (indicator, i) {
      setTimeout(function () {
        scrollTo("indicator_" + indicator.id);
        getDataUrlById("#indicator_" + indicator.id).then(
          function (dataURL) {
            contInterations++;
            if (!isValidDataURL(dataURL)) {
              dataURL = getDataUrlBlankImage();
            }
            indicator.dataURL = dataURL;
            if (contInterations === listIndicators.length) {
              deferred.resolve();
            }
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }, 500 * i);
    });
    return deferred.promise;
  }

  function setDataURLFeaturesByIndiceOportunidade(dataURL) {
    ctrl.dataURLFeaturesByIndiceOportunidade = dataURL;
  }

  function setDataURLFeaturesByIndiceRisco(dataURL) {
    ctrl.dataURLFeaturesByIndiceRisco = dataURL;
  }

  function setDataURLFeaturesByIndicator(dataURL) {
    ctrl.dataURLFeaturesByIndicator = dataURL;
  }

  function setDataURLIndicesByIndicator(dataURL) {
    ctrl.dataURLIndicesByIndicator = dataURL;
  }

  function getDataURLsToPdf() {
    var dataUrlGroup = {
      dataURLElementCompass: ctrl.dataURLElementCompass,
      dataURLMapBioma: ctrl.dataURLMapBioma,
      dataURLMapAreaSelecionada: ctrl.dataURLMapAreaSelecionada,
      dataURLMapIndiceGeral: ctrl.dataURLMapIndiceGeral,
      dataURLLogoAgroideal: ctrl.dataURLLogoAgroideal,
      dataURLIconLink: ctrl.dataURLIconLink,
      dataURLFeaturesByIndiceOportunidade: ctrl.dataURLFeaturesByIndiceOportunidade,
      dataURLFeaturesByIndiceRisco: ctrl.dataURLFeaturesByIndiceRisco,
      dataURLFeaturesByIndicator: ctrl.dataURLFeaturesByIndicator,
      dataURLIndicesByIndicator: ctrl.dataURLIndicesByIndicator,
    };
    return dataUrlGroup;
  }

  function setDataURLsElementMap(elementMap, posElem) {
    getDataUrlById(elementMap).then(function (dataURL) {
      // var width = angular.element(elementMap).width();
      if (posElem === "elementCompass") {
        if (isValidDataURL(dataURL)) {
          setDataURLElementCompass(dataURL /*, width*/);
        } else {
          setDataURLElementCompass(getDataUrlBlankImage() /*, 1 */);
        }
      }
    });
  }

  function getDataUrlBlankImage() {
    return "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==";
  }

  function isValidDataURL(dataURL) {
    var value = dataURL || "";
    var mCheckMatchIsValidBase64 = value.match(/^(?:[data]{4}:(text|image|application)\/[a-z]*)/);
    if (mCheckMatchIsValidBase64 === null || mCheckMatchIsValidBase64.length <= 0) {
      return false;
    } else {
      return true;
    }
  }

  function scrollTo(id) {
    $location.hash(id);
    $anchorScroll();
    $location.hash("");
  }

  function calculateTotalFeaturesByClasse(classes, features) {
    var featuresByClasse = [] as any[];

    classes.forEach(function (classe) {
      var featuresClasse = getFeaturesByClasse(features, classe.oportunidade, classe.risco);
      var classeCopy = angular.copy(classe);

      classeCopy.total = featuresClasse.length;
      classeCopy.area = 0;

      featuresClasse.forEach(function (feature) {
        classeCopy.area += feature.getProperties().area;
      });
      featuresByClasse.push(classeCopy);
    });

    return featuresByClasse;
  }

  function calculateClassesByIndicator(classes, features, indicators) {
    var MapService = $injector.get("MapService");
    var indicatorsActive = indicators;
    indicatorsActive.forEach(function (indicator) {
      indicator.classes = [];
    });
    MapService.calcIndiceGeralByFeatures(indicatorsActive, features);
    classes.forEach(function (classe) {
      var featuresClasse = getFeaturesByClasse(features, classe.oportunidade, classe.risco);
      classe.valueTotal = 0;
      // Calcula valor das classes(faz média tabular das grades) por indicador da coluna - Indicadores selecionados | Valores consolidados da área de estudo
      getClassesByIndicator(indicatorsActive, featuresClasse, classe);
    });
    // Calcula valor total do indicador da coluna - Indicadores selecionados | Valores consolidados da área de estudo
    calculateValueTotalIndicatorColumn(features, indicators);
    return indicatorsActive;
  }

  function groupBy(array, func) {
    var groups = {};
    array.forEach(function (obj) {
      var group = JSON.stringify(func(obj));
      groups[group] = groups[group] || [];
      groups[group].push(obj);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  function hasInListTabularSum(name) {
    // Indicadores do tipo tabular que utilizam soma para calculos
    return (
      name == "sa6_confli" ||
      name == "sa4_te_cpt" ||
      name == "e5_lb_tot" ||
      name == "e6_lb_evo" ||
      name == "e7_lb_evo" ||
      name == "e8_contra" ||
      name == "sa1_comind" ||
      name == "e6_distp" ||
      name == "sa7_inai" ||
      name == "e8_contra" ||
      name == "e10_reba" ||
      name == "e8_lotacao" ||
      name == "e9_taxaano"
    );
  }

  function setCityValueOnGridFeaturesIndicators(features) {
    var is_grid = StrategyService.getNameUnitPlanningActive() == "grades";
    if (is_grid) {
      var features = features.filter(function (feature) {
        var indicators = feature.getProperties().indicators.filter(function (indicator) {
          var nameIndicator = indicator.name;
          return hasInListTabularSum(nameIndicator);
        });
        return indicators.length > 0;
      });
      var group_features_by_city = groupBy(features, function (item) {
        return [item.getProperties().city];
      });
      group_features_by_city.forEach(function (featuresCity) {
        var group_features_by_color = groupBy(featuresCity, function (item) {
          return [item.classeOportunidade, item.classeRisco];
        });
        group_features_by_color.forEach(function (featuresColor) {
          featuresColor.forEach(function (feature, i) {
            feature.getProperties().indicators.forEach(function (indicator) {
              if (hasInListTabularSum(indicator.name)) {
                indicator.valueCity = indicator.value / featuresColor.length;
              }
            });
          });
        });
      });
    }
  }

  function getClassesByIndicator(indicatorsActive, featuresClasse, classe) {
    var features = featuresClasse || [];
    // Calcula valor das features por municípios quando unidade de planejamento é grade
    setCityValueOnGridFeaturesIndicators(features);
    indicatorsActive.forEach(function (indicator) {
      indicator.classes.push(angular.copy(classe));
      indicator.classes
        .filter(function (classeIndicator) {
          return classeIndicator.id == classe.id;
        })
        .forEach(function (classeIndicator) {
          var features_filter = features.filter(function (feature) {
            var inds = feature.getProperties().indicators.filter(function (ind) {
              return ind.name == indicator.name;
            });
            return inds.length > 0;
          });
          classeIndicator.valueTotal = features_filter.reduce(function (value, feature) {
            var inds = feature.getProperties().indicators.filter(function (ind) {
              return ind.name == indicator.name;
            });
            if (inds.length > 0) {
              value += inds.reduce(function (value, ind) {
                if (ind.valueCity) {
                  value += ind.valueCity;
                } else {
                  value += ind.value;
                }
                return value;
              }, 0);
            }
            return value;
          }, 0);
          classeIndicator.valueTotal = Math.round(classeIndicator.valueTotal);
          // Retirado pois não há mais necessidade de media agrupada
          // var length = getLengthByCity(features, indicator.name);
          getValueCalculateIndicator(features.length, classeIndicator, indicator.name);
        });
    });

    return indicatorsActive;
  }

  // function getLengthByCity(features, nameIndicator) {
  //     var is_grid = StrategyService.getNameUnitPlanningActive() == "grades";
  //     if ((is_grid) && (nameIndicator == "e2_mprod" || nameIndicator == "e3_dprod" || nameIndicator == "prodmemb" || nameIndicator == "prod25emb" || nameIndicator == "prod75emb")) {
  //         var group_by_city = groupBy(features, function(item) {
  //             return [item.getProperties().city];
  //         });
  //         return group_by_city.length;
  //     } else {
  //         return features.length;
  //     }
  // }

  function getFeaturesByClasse(features, classeOportunidade, classeRisco) {
    var featuresClasse = [] as any[];
    features.forEach(function (feature) {
      if (classeOportunidade == feature.classeOportunidade && classeRisco == feature.classeRisco) {
        featuresClasse.push(feature);
      }
    });

    return featuresClasse;
  }

  function calculateValueTotalIndicatorColumn(features, indicators) {
    indicators.forEach(function (indicator) {
      var nameIndicador = indicator.name;
      indicator.valueTotal = 0;
      var length = 0;
      var is_grid = StrategyService.getNameUnitPlanningActive() == "grades";
      features.forEach(function (feature) {
        feature.getProperties().indicators.forEach(function (indicadorFeature) {
          if (nameIndicador == indicadorFeature.name) {
            indicator.valueTotal +=
              is_grid && hasInListTabularSum(nameIndicador)
                ? indicadorFeature.valueCity
                : indicadorFeature.value;
          }
        });
      });
      length = features.length;
      indicator.valueTotal = Math.round(indicator.valueTotal);
      getValueCalculateIndicator(length, indicator, indicator.name);
    });
  }

  function getValueCalculateIndicator(length, obj, name) {
    // indicadores que utilizam a média como o valor da tabela do relatório.
    if (
      name == "e2_mprod" ||
      name == "e3_dprod" ||
      name == "sa4_3p1" ||
      name == "sa7_cobcar" ||
      name == "sa8_riskex" ||
      name == "e7_inten" ||
      name == "prodmemb" ||
      name == "prod25emb" ||
      name == "prod75emb" ||
      name == "e5_mprod" ||
      name == "sa9_resbo" ||
      name == "sa3_prio" ||
      name == "sa6_respr" ||
      name == "sa8_rhpo" ||
      name == "sa1f_gf_hm" ||
      name == "sa7_gfw" ||
      name == "e6_pro_ati" ||
      name == "e7_pro_ati" ||
      name == "sa1e_mt_hm" ||
      name == "sa8_mb" ||
      name == "sa1d_ma_hm" ||
      name == "sa4_propw" ||
      name == "sa6_tipro" ||
      name == "sa3_propn" ||
      name == "sa7_natha" ||
      name == "sa10_riskd" ||
      name == "sa11_insca" ||
      name == "vegsatsoy" ||
      name == "e2_freqp" ||
      name == "sa17_droug" ||
      name == "sa13_sali" ||
      name == "e11_carga" ||
      name == "e12_txano1" ||
      name == "e13_txano2" ||
      name == "e18_ralcv" ||
      name == "e19_ralca" ||
      name == "e20_renpo" ||
      name == "sa15_renac" ||
      name == "sa16_alccv" ||
      name == "e8_gap_fra" ||
      name == "vegsatpec" ||
      name == "e1_csp_re" ||
      name == "vulcha" ||
      name == "tasact"
    ) {
      if (length > 0) {
        obj.valueTotal = obj.valueTotal / length;
      }
    }
  }

  function getDataUrlById(elem) {
    window.scrollTo(0, 0);
    var heightOriginal = angular.element(elem).height();
    if (angular.isDefined(angular.element(elem)[0].scrollHeight)) {
      heightOriginal = angular.element(elem)[0].scrollHeight;
    }
    return $q(function (success) {
      (window as any).devicePixelRatio = 1;
      html2canvas(angular.element(elem)[0], {
        letterRendering: true,
        background: "#FFFFFF",
        height: heightOriginal,
        dpi: 300,
      } as any).then(function (canvas) {
        window.scrollTo(0, 0);
        success(canvas.toDataURL());
      });
    });
  }

  function getDataUrlScaled(elem) {
    window.scrollTo(0, 0);
    var heightOriginal = angular.element(elem).height()!;
    var widthOriginal = angular.element(elem).width()!;
    if (angular.isDefined(angular.element(elem)[0].scrollHeight)) {
      heightOriginal = angular.element(elem)[0].scrollHeight;
    }

    var scaleBy = 4;
    var canvas = document.createElement("canvas")!;
    canvas.width = widthOriginal * scaleBy;
    canvas.height = heightOriginal * scaleBy;
    canvas.style.width = widthOriginal + "px";
    canvas.style.height = heightOriginal + "px";
    var context = canvas.getContext("2d")!;
    context.scale(scaleBy, scaleBy);

    return $q(function (success) {
      html2canvas(angular.element(elem)[0], {
        canvas: canvas,
        background: "#FFFFFF",
        height: heightOriginal,
        dpi: 300,
      } as any).then(function (canvas) {
        window.scrollTo(0, 0);
        success(canvas.toDataURL());
      });
    });
  }

  function getDataUrlLogo(url, callback) {
    var xhr = new XMLHttpRequest();
    var reader = new FileReader();

    xhr.onload = function () {
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };

    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  function generateReport(features, indicators) {
    var deferred = $q.defer();
    if (Boolean(getReportCached()) === false) {
      var unitPlan = StrategyService.getUnitPlanningActive();
      var area = StrategyService.createArea(features);
      var classes = getClasses();
      // Cálcula - Indicadores selecionados | Valores consolidados da área de estudo
      calculateClassesByIndicator(classes, features, indicators);
      getInfoSelectionAreaReport(unitPlan, area).then(function () {
        deferred.resolve();
      });
    } else {
      var ReportChartsService = $injector.get("ReportChartsService");
      // plota o gráfico caso estiver em cache pie states
      ctrl.reportData.dataChartsStates = ReportChartsService.getDataPie(
        ctrl.reportData.states,
        "states"
      );
      ReportChartsService.newPlotPie(
        "graphicPieStates",
        ctrl.reportData.dataChartsStates,
        ctrl.reportData.layoutPie,
        ctrl.reportData.optionsCharts
      );

      if (ReportChartsService.isParaguaiReport()) {
        ctrl.reportData.dataChartsEcorregioes = ReportChartsService.getDataPie(
          ctrl.reportData.ecorregioes,
          "ecorregioes"
        );
        ReportChartsService.newPlotPie(
          "graphicPieEcorregioes",
          ctrl.reportData.dataChartsEcorregioes,
          ctrl.reportData.layoutPie,
          ctrl.reportData.optionsCharts
        );
        deferred.resolve();
      } else {
        ctrl.reportData.dataChartsBiomes = ReportChartsService.getDataPie(
          ctrl.reportData.biomes,
          "biomes"
        );
        ReportChartsService.newPlotPie(
          "graphicPieBiomes",
          ctrl.reportData.dataChartsBiomes,
          ctrl.reportData.layoutPie,
          ctrl.reportData.optionsCharts
        );
        deferred.resolve();
      }
    }
    return deferred.promise;
  }

  function getInfoSelectionAreaReport(unitPlan, area) {
    var deferred = $q.defer();
    var ReportChartsService = $injector.get("ReportChartsService");
    var params = {
      tipo: unitPlan.name,
    } as any;
    ctrl.reportData = {};
    ctrl.reportData.layoutPie = ReportChartsService.getLayoutPie();
    ctrl.reportData.optionsCharts = {
      displayModeBar: false,
    };

    if (params.tipo == "grades") {
      params.grades = JSON.stringify(area.grades_codigo);
    } else if (params.tipo == "municipios") {
      params.municipios = JSON.stringify(area.municipios_codigo);
    }

    var promises = [] as Promise<any>[];
    var promise = ReportInfoService("area")
      .getInfo(params)
      .$promise.then(
        function (response) {
          ctrl.reportData.area = response.data.area;
        },
        function (error) {
          ctrl.reportData.area = 0;
        }
      );
    promises.push(promise);

    promise = ReportInfoService("states")
      .getInfo(params)
      .$promise.then(
        function (response) {
          ctrl.reportData.states = response.data.states;
          ctrl.reportData.dataChartsStates = ReportChartsService.getDataPie(
            ctrl.reportData.states,
            "states"
          );
        },
        function (error) {
          ctrl.reportData.states = [];
        }
      );
    promises.push(promise);

    var activeCountry = AppService.getActiveCountry();
    var ecorregiaoCountry = "paraguai";
    if (activeCountry == ecorregiaoCountry) {
      promise = ReportInfoService("ecorregioes")
        .getInfo(params)
        .$promise.then(
          function (response) {
            ctrl.reportData.ecorregioes = response.data.ecorregioes;
            ctrl.reportData.dataChartsEcorregioes = ReportChartsService.getDataPie(
              ctrl.reportData.ecorregioes,
              "ecorregioes"
            );
          },
          function (error) {
            ctrl.reportData.ecorregioes = [];
          }
        );
      promises.push(promise);
    }

    promise = ReportInfoService("biomes")
      .getInfo(params)
      .$promise.then(
        function (response) {
          ctrl.reportData.biomes = response.data.biomes;
          ctrl.reportData.dataChartsBiomes = ReportChartsService.getDataPie(
            ctrl.reportData.biomes,
            "biomes"
          );
        },
        function (error) {
          ctrl.reportData.biomes = [];
        }
      );
    promises.push(promise);

    var reportScructure = AppService.getReportStructure();
    if (reportScructure.protectedAreas) {
      promise = ReportInfoService("protected")
        .getInfo(params)
        .$promise.then(function (response) {
          reportScructure.protectedAreas.forEach(function (protectedArea) {
            ctrl.reportData[protectedArea.name] = response.data[protectedArea.name];
            ctrl.reportData.superposition_area_sum = getTotalSuperpositionAreaSum(
              ctrl.reportData[protectedArea.name]
            );
            if (response.data[protectedArea.name] != undefined) {
              ctrl.reportData[protectedArea.name].superposition_area_total =
                getSuperpositionAreaSum(ctrl.reportData[protectedArea.name]);
            }
          });
        });
      promises.push(promise);
    }

    $q.all(promises).then(function () {
      deferred.resolve();
    });

    return deferred.promise;
  }

  function getSuperpositionAreaSum(area) {
    var totalAreaPercentage = 0;
    var totalAreaPercentageHa;
    if (area != undefined) {
      for (var value in area) {
        totalAreaPercentage = totalAreaPercentage + area[value].percentage;
        totalAreaPercentageHa = (totalAreaPercentage * ctrl.reportData.area) / 100;
      }
    }

    return totalAreaPercentageHa;
  }

  function getTotalSuperpositionAreaSum(area) {
    var totalAreaPercentage = 0;
    if (area != undefined) {
      for (var value in area) {
        totalAreaPercentage = totalAreaPercentage + area[value].percentage;
      }
    }
    ctrl.totalSuperpositionAreaList.push(totalAreaPercentage);
    var totalSuperpositionAreaPercentage = ctrl.totalSuperpositionAreaList.reduce(
      (x, y) => x + y,
      0
    );

    // if (totalSuperpositionAreaPercentage.toFixed(2) < 0) {
    //   var totalSuperpositionAreaHa = 0;
    // } else {
    //   totalSuperpositionAreaHa = (totalSuperpositionAreaPercentage * ctrl.reportData.area) / 100;
    // }

    return totalSuperpositionAreaPercentage;
  }

  function generateDataScatterPolarByFeature(feature) {
    var ReportChartsService = $injector.get("ReportChartsService");
    var ModalService = $injector.get("ModalService");
    var hasReportData = setInterval(function () {
      if (
        angular.isDefined(ctrl.reportData) &&
        !angular.equals(ctrl.reportData, {}) &&
        feature !== null
      ) {
        ctrl.reportData.dataChartsScatterPolar = {};
        ctrl.reportData.dataChartsScatterPolar = ReportChartsService.getDataScatterPolar(feature);
        var maxRange = getMaxValueToScatterPolarByFeature(
          ctrl.reportData.dataChartsScatterPolar[0].r
        );
        var isGrid = StrategyService.getUnitPlanningActive().id === 2;
        var title = "";
        title = ReportChartsService.getDescriptionFeature(feature);
        if (isGrid) {
          title += "<br>" + ReportChartsService.getFeatureGridCity(feature);
        }
        ctrl.reportData.layoutScatterPolar = ReportChartsService.getLayoutScatterPolar(
          title,
          maxRange
        );
        ReportChartsService.newPlotScatterPolar(
          "graphicScatterPolar",
          ctrl.reportData.dataChartsScatterPolar,
          ctrl.reportData.layoutScatterPolar,
          ctrl.reportData.optionsCharts
        );
        clearInterval(hasReportData);
        ModalService.closeModalLoading();
      }
    }, 500);
  }

  function getMaxValueToScatterPolarByFeature(arrayOfStrings) {
    if (angular.isDefined(arrayOfStrings)) {
      var arrayOfNumbers = MainService.convertArrayStringToFloat(arrayOfStrings);
      return MainService.getMaxValueInArray(arrayOfNumbers);
    }
    return 0;
  }

  function getLabelProtectedAreas(label) {
    if (label == "report.outros") {
      return MainService.getTextByKeyI18n(label);
    }
    return label;
  }

  return {
    getReportData: getReportData,
    destroyCacheOnInit: destroyCacheOnInit,
    setReportCached: setReportCached,
    getReportCached: getReportCached,
    setReportCachedExportPdf: setReportCachedExportPdf,
    getReportCachedExportPdf: getReportCachedExportPdf,
    getClasses: getClasses,
    setDataURLElementCompass: setDataURLElementCompass,
    setDataURLMapBioma: setDataURLMapBioma,
    setDataURLMapAreaSelecionada: setDataURLMapAreaSelecionada,
    setDataURLMapIndiceGeral: setDataURLMapIndiceGeral,
    setDataURLLogoAgroideal: setDataURLLogoAgroideal,
    setDataURLIconLink: setDataURLIconLink,
    setDataURLSlidersIndicators: setDataURLSlidersIndicators,
    setDataURLFeaturesByIndiceOportunidade: setDataURLFeaturesByIndiceOportunidade,
    setDataURLFeaturesByIndiceRisco: setDataURLFeaturesByIndiceRisco,
    setDataURLFeaturesByIndicator: setDataURLFeaturesByIndicator,
    setDataURLIndicesByIndicator: setDataURLIndicesByIndicator,
    getDataURLsToPdf: getDataURLsToPdf,
    setDataURLsElementMap: setDataURLsElementMap,
    isValidDataURL: isValidDataURL,
    getDataUrlBlankImage: getDataUrlBlankImage,
    scrollTo: scrollTo,
    calculateClassesByIndicator: calculateClassesByIndicator,
    calculateTotalFeaturesByClasse: calculateTotalFeaturesByClasse,
    getFeaturesByClasse: getFeaturesByClasse,
    getValueCalculateIndicator: getValueCalculateIndicator,
    getDataUrlLogo: getDataUrlLogo,
    getDataUrlById: getDataUrlById,
    getDataUrlScaled: getDataUrlScaled,
    setDataURLElementInClasse: setDataURLElementInClasse,
    generateReport: generateReport,
    generateDataScatterPolarByFeature: generateDataScatterPolarByFeature,
    getLabelProtectedAreas: getLabelProtectedAreas,
    getSuperpositionAreaSum: getSuperpositionAreaSum,
    getTotalSuperpositionAreaSum: getTotalSuperpositionAreaSum,
  };
}

// angular
//   .module("report.service", [])
//   .factory("ReportInfoService", ReportInfoService)
//   .factory("ReportService", ReportService);

export const ReportServiceModule = new AppModule({
  name: "report.service",
  factories: { ReportService, ReportInfoService },
});
