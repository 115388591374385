import { AppModule } from "../../module-wrapper";

/* @ngInject */
function TranslateService(MainService) {
  function isObject(item) {
    return typeof item === "object" && !Array.isArray(item) && item !== null && item != undefined;
  }

  function translate(obj) {
    var lang = MainService.getLanguageSystem().locale;
    if (isObject(obj)) {
      return obj[lang];
    }

    return "";
  }

  return {
    translate: translate
  };
}

export type TranslateService = ReturnType<typeof TranslateService>;

// angular
//     .module('translate.service', [])
//     .factory('TranslateService', TranslateService);

export const TranslateServiceModule = new AppModule({
  name: "translate.service",
  factories: { TranslateService }
});
