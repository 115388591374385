import { AppModule } from "../../module-wrapper";
import { IReportCreatePdfService } from "./reportPdf/reportPdf.service";

/* @ngInject */
function ReportController(
  this: any,
  ReportService,
  StrategyService,
  MapService,
  ExporterService,
  ReportCreatePdfService: IReportCreatePdfService,
  ModalService,
  MainService,
  IndicatorService,
  $q,
  AppService,
  ReportChartsService,
  LogService,
  AxisService,
  IndicatorStrategyService
) {
  var ctrl = this;
  ctrl.report = {};

  ctrl.classes = ReportService.getClasses();

  ctrl.scrollTo = function (id) {
    ReportService.scrollTo(id);
  };

  ctrl.getDataURLs = function () {
    return ReportService.getDataURLsToPdf();
  };

  ctrl.getStrategyInfo = function () {
    return StrategyService.getStrategyInfo();
  };

  ctrl.getUnitPlanningActive = function (unitPlan) {
    return StrategyService.getUnitPlanningActive();
  };

  ctrl.getKeyListOf = function () {
    var unitPlan = StrategyService.getUnitPlanningActive() || "";
    if (unitPlan.name === "grades") {
      return "report.listadegrades";
    } else if (unitPlan.name === "municipios") {
      return "report.listademunicipios";
    }
  };

  ctrl.exportExcel = function () {
    var features = MapService.getFeaturesSelectionAreaInstance();
    var area = StrategyService.createArea(features);

    ExporterService.exportDetailFeatures(features, area);
  };

  ctrl.exportProtectedAreasExcel = function (type, unitNames) {
    var reportDict = {};
    var protectedAreaDict = {};
    var protectedAreaUnitDict = {};
    for (var unitName in unitNames) {
      var name = unitNames[unitName].name;
      var percentage = unitNames[unitName].percentage;
      if (name == undefined || percentage == undefined) {
        continue;
      }
      protectedAreaUnitDict[name] = percentage;
    }
    protectedAreaDict[type] = protectedAreaUnitDict;
    protectedAreaDict["total"] = unitNames.superposition_area_total;
    protectedAreaDict["report_type"] = "protected_area";

    reportDict["report"] = protectedAreaDict;

    ExporterService.exportDetailProtectedArea(reportDict, type);
  };

  ctrl.exportPdf = function () {
    ModalService.openModalLoading();
    LogService.sendButtonLog("exportar_relatorio_pdf", "click", "strategy");

    var currentLocale = MainService.getLanguageSystem().locale;
    var data = new Date();
    var defaultName = data.toLocaleDateString(currentLocale).replace(/\//g, "-");
    var strategy = StrategyService.getStrategyInfo() || "";
    var fileName = strategy.name || defaultName;
    var dataURLGroup = [];

    var report = {
      data: ReportService.getReportData(),
      name: strategy.name || "",
      indicators: IndicatorService.getIndicatorsActive(),
      unitPlan: StrategyService.getUnitPlanningActive().labelReport,
      classes: StrategyService.getResultStrategy(),
      protectedAreas: AppService.getReportStructure().protectedAreas,
    };
    var layout = AppService.getLayout();

    var promises = [] as any[];

    var indiceOportunidadeDataURLsPromise = ReportChartsService.convertSvgToDataURL(
      "featuresByIndiceOportunidade"
    );
    indiceOportunidadeDataURLsPromise.then(function (dataURL) {
      ReportService.setDataURLFeaturesByIndiceOportunidade(dataURL);
    });
    promises.push(indiceOportunidadeDataURLsPromise);

    var indiceRiscoDataURLsPromise = ReportChartsService.convertSvgToDataURL(
      "featuresByIndiceRisco"
    );
    indiceRiscoDataURLsPromise.then(function (dataURL) {
      ReportService.setDataURLFeaturesByIndiceRisco(dataURL);
    });
    promises.push(indiceRiscoDataURLsPromise);

    var featuresByIndicatorURLsPromise = ReportChartsService.convertSvgToDataURL(
      "featuresByIndicator"
    );
    featuresByIndicatorURLsPromise.then(function (dataURL) {
      ReportService.setDataURLFeaturesByIndicator(dataURL);
    });
    promises.push(featuresByIndicatorURLsPromise);

    ReportService.setDataURLIndicesByIndicator(ReportService.getReportData().dataUrlScatterPolar);

    if (Boolean(!ReportService.getReportCachedExportPdf())) {
      var dataURLsPromise = ReportService.setDataURLSlidersIndicators(
        IndicatorService.getIndicatorsActive()
      );
      dataURLsPromise.then(function () {
        ReportService.setReportCachedExportPdf(true);
        ModalService.closeModalLoading();
      });
      promises.push(dataURLsPromise);
    }

    $q.all(promises).then(function () {
      dataURLGroup = ReportService.getDataURLsToPdf();
      const docDefinition = ReportCreatePdfService.createDocDefinition(
        dataURLGroup,
        report,
        layout,
        currentLocale
      ) as any;
      setFonts();
      window.pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
      ModalService.closeModalLoading();
    });
  };

  function setFonts() {
    window.pdfMake.fonts = {
      Caecilia: {
        normal: "CaeciliaLTStd-Roman.ttf",
        bold: "CaeciliaLTStd-Bold.ttf",
        italics: "CaeciliaLTStd-Italic.ttf",
        bolditalics: "CaeciliaLTStd-BoldItalic.ttf",
      },
      TodaySHOP: {
        normal: "TodaySHOP-Light.ttf",
        bold: "TodaySHOP-Medium.ttf",
        italics: "TodaySHOP-BoldItalic.ttf",
        bolditalics: "fontFile4.ttf",
      },
    };
  }

  ctrl.getReportStructure = function () {
    return AppService.getReportStructure();
  };

  ctrl.getLabelProtectedAreas = function (label) {
    return ReportService.getLabelProtectedAreas(label);
  };

  ctrl.getReportData = function () {
    return ReportService.getReportData();
  };

  ctrl.getAxes = function () {
    return AxisService.getAxes();
  };

  ctrl.getIndicatorsActiveByAxisName = function (axis_name) {
    return IndicatorService.getIndicatorsActiveByAxisName(axis_name);
  };

  ctrl.getResultStrategy = function () {
    return StrategyService.getResultStrategy();
  };

  ctrl.getListIndicatorsActive = function () {
    return IndicatorService.getIndicatorsActive();
  };

  ctrl.getStrategyInfo = function () {
    return StrategyService.getStrategyInfo();
  };

  ctrl.getOptions = function (indicador) {
    return IndicatorStrategyService.getOptionsSlider(indicador);
  };

  ctrl.downloadDivToPng = function (div, fileName, logName) {
    ReportService.getDataUrlScaled(div).then(function (url) {
      ReportChartsService.downloadDataUrlToPng(url, fileName, logName);
    });
  };

  ctrl.downloadDataUrlToPng = function (url, fileName, logName) {
    ReportChartsService.downloadDataUrlToPng(url, fileName, logName);
  };

  ctrl.getDynamicTranslations = function () {
    return AppService.getDynamicTranslations();
  };

  ctrl.minimizeReportModal = function () {
    setTimeout(() => {
      $(".modal-report").hide();
      $(".modal-report-header-minimize").show();
      $(".ngdialog-overlay").remove();
    });
  };

  ctrl.maximizeReportModal = function () {
    setTimeout(() => {
      $(".modal-report").show();
      $(".modal-report-header-minimize").hide();
      $("#main").append('<div class="ngdialog-overlay" style="z-index: 99"></div>');
    });
  };

  ctrl.exitReportModal = function () {
    setTimeout(() => {
      $(".estrategia-inner").removeClass("bufferActive");
      $(".rowstrategybuttons").removeClass("bufferActive");
    });
  };

  ctrl.isParaguaiReport = function() {
    return AppService.getActiveCountry() == "paraguai";
  }
}

// angular
//     .module('report.controller', [])
//     .controller('ReportController', ReportController)

export const ReportControllerModule = new AppModule({
  name: "report.controller",
  controllers: { ReportController },
});
