import { AppModule, ExternalModule } from "./module-wrapper";
import "jquery";
import angular from "angular";

import "bootstrap";
import "@uirouter/angularjs";
import "angular-resource";
import "angular-messages";
import "angular-password";
import "angular-scroll";
import "angular-tooltips";
import "ngstorage";
import "angularjs-slider";
import "angular-filter";
import "angular-dynamic-locale";
import "ng-file-upload";
import "ng-dialog";
import "angular-translate";
import "angular-translate-loader-partial";
import "angular-ui-bootstrap";
import "angular-ui-tour";
import "angularjs-toaster";
import "default-passive-events";
// import "angular-i18n";

import "../node_modules/angularjs-slider/dist/rzslider.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/angular-tooltips/dist/angular-tooltips.css";

import "../assets/fonts/fontsstylesheet.css";
import "../assets/fonts/flaticon.css";
import "../assets/fonts/iconmoon.css";
import "../assets/css/modal-how-to-use.css";
import "../assets/css/normalize.css";
import "../assets/css/style.css";
import "../app/modules/report/report.css";
import "../app/core/modal/modal.css";
import "../app/core/user/user.css";
import "../assets/css/painelstyle.css";
import "../assets/css/loading.css";
import "../assets/css/layers.colors.css";
import "../assets/css/chaco.layers.colors.css";
import "../assets/css/paraguai.chaco.layers.colors.css";
import "../assets/css/reportCharts.style.css";
import "../assets/css/common-color-pallets.css";
import "../node_modules/ng-dialog/css/ngDialog.min.css";
import "../node_modules/ng-dialog/css/ngDialog-theme-default.min.css";
import "../node_modules/c3/c3.min.css";
import "../node_modules/angularjs-toaster/toaster.min.css";

import { ControllerModule } from "./app.controller";
import { ServiceModule } from "./app.service";
import { MainModule } from "./modules/main.module";
import { CoreModule } from "./core/core.module";

angular.lowercase = (text) => (text || "").toLowerCase();

var initInj = angular.injector(["ng"]);
var $http = initInj.get("$http");
// var data;

Promise.all([
  $http.get(location.pathname + "agroideal.json"),
  new Promise((resolve) => document.addEventListener("DOMContentLoaded", resolve)),
]).then(function ([response]: any[]) {
  const data = response.data as any;

  /* @ngInject */
  function run(AppService, MainService, AuthService, uiTourService) {
    MainService.initDictI18n();
    AppService.setLayout(data.layout);
    AppService.setUrlApi(data.url);
    AppService.setUrlSite(data.urlSite);
    AppService.setUrlLogApi(data.urlLogApi);
    AppService.setWmsConfigApi(data.wmsConfig);
    AppService.setActiveRegionsInterest(data.activeRegionsInterest);
    AppService.setActiveCountry(data.activeCountry);
    AppService.setLayersNamesMapServer(data.layersNamesMapServer);
    AppService.setMapViewInitOptions(data.mapViewInitOptions);
    AppService.setLogosPartners(data.logosPartners);

    // Report Structure
    AppService.setReportStructure(data.reportStructure);

    // Check Token Session
    AuthService.checkToken(true);

    // Setting google analytics id
    AppService.enableGoogleAnalytics(data.googleAnalyticsId);
    // Municipality Translations based on country's specific language
    AppService.setDynamicTranslations(data.dynamicTranslations);

    // Angular Tour
    AppService.uiTourService(uiTourService);
  }

  const app = new AppModule({
    name: "app.module",
    modules: [
      ControllerModule,
      ServiceModule,
      new ExternalModule("ngMessages"),
      new ExternalModule("ui.router"),
      new ExternalModule("ngResource"),
      new ExternalModule("ngStorage"),
      new ExternalModule("ngPassword"),
      new ExternalModule("rzModule"),
      new ExternalModule("angular.filter"),
      new ExternalModule("ngLocale"),
      new ExternalModule("ngFileUpload"),
      new ExternalModule("ngDialog"),
      new ExternalModule("720kb.tooltips"),
      new ExternalModule("duScroll"),
      new ExternalModule("pascalprecht.translate"),
      new ExternalModule("tmh.dynamicLocale"),
      new ExternalModule("ui.bootstrap"),
      new ExternalModule("bm.uiTour"),
      // new ExternalModule("ngLodash"),
      new ExternalModule("toaster"),
      MainModule,
      CoreModule,
    ],
    runner: run,
  });

  app.doRegister();

  angular.bootstrap(document, ["app.module"]);
});

// angular
//   .module("app.module", [
//     "app.controller",
//     "app.service",
//     "ngMessages",
//     "ui.router",
//     "ngResource",
//     "ngStorage",
//     "ngPassword",
//     "rzModule",
//     "angular.filter",
//     "ngLocale",
//     "ngFileUpload",
//     "ngDialog",
//     "720kb.tooltips",
//     "duScroll",
//     "pascalprecht.translate",
//     "tmh.dynamicLocale",
//     "ui.bootstrap",
//     "ngLodash",
//     "toaster",
//     "main.module",
//     "core.module"
//   ])
//   .run(run);

$(document).ready(function () {
  $("div.tabmain-icon").click(function (this: any) {
    $("div.tab-main").toggleClass("tabmain-hidden");
    $(this).find("span.filter-icon").toggleClass("flaticon-left-arrow");
    $(this).find("span.filter-icon").toggleClass("flaticon-arrowhead-pointing-to-the-right");
  });
});

if (module.hot) {
  module.hot.accept();
}

// console.log("carregou");
