import { element } from "angular";
import { AppModule } from "../../../module-wrapper";

export type IReportCreatePdfService = ReturnType<typeof ReportCreatePdfService>;

/* @ngInject */
function ReportCreatePdfService($filter, MainService, TranslateService, AppService) {
  var PT_IN_MILIMETERS = 2.83465;

  var _data = null as null | Date;
  var _colors = {};

  function milimetro(mm) {
    return mm * PT_IN_MILIMETERS;
  }

  function getLayout(layout) {
    _colors["gray_1"] = "#f8f8f8"; // Fundo da tabela
    _colors["gray_2"] = "#e5e7e8"; // Linha da tabela
    _colors["gray_3"] = "#ededee"; // Linha da tabela
    _colors["gray_4"] = "#eeeeee"; // Container das legendas
    _colors["gray_5"] = "#6d6d71"; // Ponto do separador && header tabelas

    // (Economicos/Socioambientais
    _colors["gray_6"] = "#6f6f73"; // Linha do separador
    _colors["gray_7"] = "#706e72"; // Borda legenda && Borda imagens
    _colors["white"] = "#FFFFFF"; // Texto header da tabela

    if (layout === "soja") {
      _colors["base_color"] = "#ffcb05";
    } else if (layout === "pecuaria") {
      _colors["base_color"] = "#cc7348";
    }
    return _colors;
  }

  function line(x, y, width, height, color) {
    return {
      lineWidth: height,
      type: "line",
      lineColor: color,
      x1: x,
      x2: x + width || 0,
      y1: y,
      y2: y,
    };
  }

  function point(x, y) {
    return {
      type: "ellipse",
      x: milimetro(x),
      y: milimetro(y),
      color: _colors["gray_5"],
      fillOpacity: 1,
      r1: milimetro(0.75),
      r2: milimetro(0.75),
    };
  }

  function tableInfoLayout() {
    return {
      hLineWidth: function () {
        return 0;
      },
      vLineWidth: function () {
        return 0;
      },
      fillColor: stripedRow,
    };
  }

  function tableInforLayoutWithHeader() {
    return {
      hLineWidth: function () {
        return 0;
      },
      vLineWidth: function () {
        return 0;
      },
      fillColor: stripedRow,
      header: {
        color: _colors["base_color"],
      },
    };
  }

  function stripedRow(i, node) {
    return i + 1 <= node.table.headerRows
      ? null
      : i % 2 === 0
      ? _colors["gray_3"]
      : _colors["gray_2"];
  }

  function separator(x, y, config) {
    var _config = config || {};
    var _lineWidth = _config.lineWidth || 0.5;
    var svg = {
      margin: [milimetro(1.4), 0, 0, 0],
      canvas: [
        {
          type: "rect",
          x: milimetro(x),
          y: milimetro(y),
          w: milimetro(config.length),
          h: 0,
          lineColor: _colors["gray_7"],
          lineWidth: _lineWidth,
          color: _colors["gray_7"],
        },
        point(x, y),
      ],
    };
    if (_config.rightPoint) svg.canvas.push(point(x + _config.length, y));
    return svg;
  }

  function header(strategyName, _colors) {
    var marginTopStrategyName = strategyName != "" ? 7 : 15.5;

    var header = [
      {
        canvas: [
          {
            type: "rect",
            x: milimetro(8),
            y: milimetro(0),
            w: milimetro(194),
            h: milimetro(4.88),
            lineWidth: 0,
            color: _colors["base_color"],
          },
        ],
      },
      {
        columnGap: milimetro(4.3),
        columns: [
          {
            width: milimetro(67),
            stack: [{ image: "dataURLLogoAgroideal", width: milimetro(61) }],
            margin: [milimetro(11), milimetro(5.8), 0, 0],
            alignment: "center",
          },
          {
            width: milimetro(128.56),
            stack: [
              {
                text: strategyName,
                bold: true,
                fontSize: 22,
                margin: [milimetro(6), milimetro(marginTopStrategyName), 0, milimetro(3)],
              },
              separator(0, 0, { length: 128.56 }),
              {
                text: $filter("date")(
                  _data,
                  MainService.getTextByKeyI18n("reportpdf.formatacaodatarelatorio")
                ).toUpperCase(),
                font: "TodaySHOP",
                fontSize: 9,
                margin: [milimetro(6), milimetro(3), 0, 0],
                characterSpacing: 1,
              },
            ],
          },
        ],
      },
    ];
    return header;
  }

  function titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  function createDocDefinition(dataUrlGroup, report, layout, locale) {
    _data = new Date();

    var isParaguaiReport = AppService.getActiveCountry() == "paraguai";
    var _colors = getLayout(layout);
    var strategyName = report.name;

    report.indicators = MainService.sortAscArrayObjectByKey(
      report.indicators,
      "order_presentation"
    );

    var tableIndicators = getTableIndicators(report.indicators, report.classes, locale);
    var areaTotal = getTotalAreaSelection(report.data.area);

    var tableBiomas = getTableBiomas(report.data.biomes);
    var tableEstados = getTableEstados(report.data.states);
    var tableHeaderProtectedAreas = getTableHeaderProtectedAreas();

    var protectedAreasStructure = report.protectedAreas;
    var protectedAreasDataPDF = [] as any[];

    protectedAreasStructure.forEach(function (area) {
      protectedAreasDataPDF.push(getTableContentProtectedAreas(report.data[area.name], area.key));
    });

    var protectedAreasStructurePDF = [] as any[];
    protectedAreasDataPDF.forEach(function (areaPDF, index) {
      areaPDF.forEach(function (item, index) {
        if (typeof item[0] !== "object") {
          item[0] = titleCase(item[0]);
        }
      });
      protectedAreasStructurePDF.push(getTableStructureProtectedAreas(areaPDF, index));
    });

    var tableClasses = getTableClasses(report.classes, report.unitPlan);

    var indicatorsEconomico = getIndicatorsByEixo(report.indicators, "economico");
    var indicatorsSocioambiental = getIndicatorsByEixo(report.indicators, "socioambiental");

    var slidersEconomico = getTableSliders(
      indicatorsEconomico,
      MainService.getTextByKeyI18n("reportpdf.oportunidadeeconomica")
    );
    var slidersSocioambiental = getTableSliders(
      indicatorsSocioambiental,
      MainService.getTextByKeyI18n("reportpdf.riscosocioambiental")
    );

    var tableIndicatorsSources = getTableIndicatorsSources(report.indicators, locale);

    if (isParaguaiReport) {
      var tableEcorregions = getTableEcorregions(report.data.ecorregioes);
      var columns = [
        {
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: ["*", "25%"],
            body: tableEcorregions,
          },
          layout: tableInforLayoutWithHeader(),
          style: "table-info",
        },
        {
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: ["*", "25%"],
            body: tableEstados,
          },
          layout: tableInforLayoutWithHeader(),
          style: "table-info",
        },
      ];
    } else {
      var columns = [
        {
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: ["*", "25%"],
            body: tableBiomas,
          },
          layout: tableInforLayoutWithHeader(),
          style: "table-info",
        },
        {
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: ["*", "25%"],
            body: tableEstados,
          },
          layout: tableInforLayoutWithHeader(),
          style: "table-info",
        },
      ];
    }

    var docDefinition = {
      info: {
        title: MainService.getTextByKeyI18n("reportpdf.relatorio"),
        author: "Agrosatélite",
        //subject : 'subject of document',
        //keywords : 'keywords for document'
      },
      pageMargins: [milimetro(8), milimetro(40), milimetro(8), milimetro(12.46)],
      defaultStyle: {
        fontSize: 10,
        font: "TodaySHOP",
        characterSpacing: 0.5,
      },
      header: header(strategyName, _colors),
      footer: {
        color: _colors["gray_7"],
        fontSize: 6,
        margin: [milimetro(8), 0, milimetro(8), milimetro(15)],
        stack: [
          {
            canvas: [
              {
                lineWidth: 0.2,
                type: "line",
                lineColor: "black",
                x1: 0,
                x2: milimetro(194),
                y1: 0,
                y2: 0,
              },
            ],
          },
          {
            margin: [5, 5, 0, 0],
            columns: [
              {
                text: MainService.getTextByKeyI18n("reportpdf.ferramentadeinteligenciaterritorial"),
                characterSpacing: 1,
              },
              {
                text: $filter("date")(_data, "d/M/yyyy H'h 'mm'm 's's'"),
                alignment: "right",
                characterSpacing: 1,
              },
            ],
          },
        ],
      },
      content: [
        separator(0, 0, {
          length: 191.04,
          rightPoint: true,
        }),
        {
          text: MainService.getTextByKeyI18n("reportpdf.areadeestudoselecionada"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          margin: [0, 10, 0, 50],
          width: milimetro(157.94),
          height: milimetro(157.94),
          image: "dataURLMapIndiceGeral",
          alignment: "center",
        },
        {
          pageBreak: "after",
          table: {
            widths: ["5%", "50%", "15%", "30%"], // number of columns
            body: tableClasses,
          },
          layout: {
            vLineWidth: function (i, node) {
              return 1;
            },
            hLineWidth: function (i, node) {
              return 1;
            },
            fillColor: stripedRow,
            hLineColor: "white",
            vLineColor: "white",
          },
        },

        // Segunda página
        separator(0, 0, {
          length: 191.04,
          rightPoint: true,
        }),
        {
          text: MainService.getTextByKeyI18n("reportpdf.areadeestudoselecionada"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          pageBreak: "after",
          columnGap: milimetro(4.3),
          columns: [
            [
              {
                margin: [0, milimetro(3)],
                fontSize: 11,
                table: {
                  headerRows: 0,
                  widths: ["*"],
                  body: [
                    [
                      {
                        border: [false, false, false, false],
                        text:
                          MainService.getTextByKeyI18n("reportpdf.totaldaareadeestudoselecionada") +
                          ":" +
                          "\n" +
                          $filter("number")(areaTotal, 1) +
                          " ha",
                        fillColor: _colors["base_color"],
                      },
                    ],
                  ],
                },
              },
              columns,
            ],
            [
              {
                fontSize: 11,
                table: {
                  headerRows: 1,
                  widths: ["*", "25%"],
                  body: tableHeaderProtectedAreas,
                },
                layout: tableInforLayoutWithHeader(),
                style: "table-info-only-margin-bottom",
              },
              protectedAreasStructurePDF,
            ],
          ],
        },

        // Terceira página
        separator(0, 0, {
          length: 191.04,
          rightPoint: true,
        }),
        {
          text: MainService.getTextByKeyI18n("reportpdf.indicadoresselecionadosconsolidado"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            widths: ["*", "12.8%", "12.8%", "12.8%", "12.8%", "12.8%"],
            headerRows: 2,
            fontSize: 30,
            body: tableIndicators,
          },
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function (i, node) {
              return 1;
            },
            fillColor: stripedRow,
            hLineColor: "white",
            vLineColor: "white",
          },
        },
        // sliders indicadores
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportpdf.indicadoresselecionados"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            headerRows: 1,
            widths: ["50%", "*"],
            body: slidersEconomico,
          },
          layout: "noBorders",
          style: "table-sliders",
        },
        {
          table: {
            headerRows: 1,
            widths: ["50%", "*"],
            body: slidersSocioambiental,
          },
          layout: "noBorders",
          style: "table-sliders",
        },
        // Ranking de oportunidade econômica
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportcharts.rankingdeoportunidadeeconomica"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            widths: ["100%", "*"],
            body: getTableCharts([dataUrlGroup.dataURLFeaturesByIndiceOportunidade]),
          },
          layout: "noBorders",
          style: "table-sliders",
        },
        // Ranking de risco socioambiental
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportcharts.rankingderiscosocioambiental"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            widths: ["100%", "*"],
            body: getTableCharts([dataUrlGroup.dataURLFeaturesByIndiceRisco]),
          },
          layout: "noBorders",
          style: "table-sliders",
        },
        // Ranking Comparativo Regional
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportcharts.comparativoregional"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            widths: ["100%", "*"],
            body: getTableCharts([dataUrlGroup.dataURLFeaturesByIndicator]),
          },
          layout: "noBorders",
          style: "table-sliders",
        },
        // Ranking Índices por indicador
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportcharts.indiceporindicador"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },
        {
          table: {
            widths: ["100%", "*"],
            body: getTableCharts([dataUrlGroup.dataURLIndicesByIndicator]),
          },
          layout: "noBorders",
          style: "table-sliders",
        },

        // Fontes dos indicadores selecionados
        {
          stack: [
            {
              text: "",
              pageBreak: "before",
            },
            separator(0, 0, {
              length: 191.04,
              rightPoint: true,
            }),
          ],
        },
        {
          text: MainService.getTextByKeyI18n("reportpdf.informacoesdosindicadoresselecionados"),
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, milimetro(3)],
          characterSpacing: 0.5,
        },

        tableIndicatorsSources,
      ],
      images: getDataURLs(dataUrlGroup),
      styles: {
        "table-sub-header": {
          font: "TodaySHOP",
          fontSize: 11,
          bold: true,
          color: _colors["white"],
          fillColor: _colors["gray_5"],
          characterSpacing: 1,
          margin: [0, 1, 0, -1],
        },
        "table-sub-header-sliders": {
          font: "TodaySHOP",
          fontSize: 11,
          bold: true,
          characterSpacing: 1,
          margin: [0, 1, 0, -1],
        },
        "table-header": {
          fontSize: 9,
          bold: true,
          font: "TodaySHOP",
          fillColor: _colors["base_color"],
          margin: [0, 9, 0, 4],
          characterSpacing: 1,
          alignment: "center",
        },
        "text-legend": {
          color: _colors["gray_7"],
          characterSpacing: 1,
          font: "TodaySHOP",
          bold: true,
          fontSize: 9,
          margin: [-9, 4, 0, 0],
        },
        "table-info": {
          font: "TodaySHOP",
          margin: [0, 6],
          fontSize: 11,
        },
        "table-sliders": {
          font: "TodaySHOP",
          margin: [0, 0],
          fontSize: 11,
        },
        "table-classes": {
          font: "TodaySHOP",
          margin: [-190, 203],
          fontSize: 11,
        },
        "table-info-only-margin-bottom": {
          font: "TodaySHOP",
          margin: [0, 6, 0, 0],
          fontSize: 11,
        },
        "table-info-only-margin-top": {
          font: "TodaySHOP",
          margin: [0, 0, 0, 6],
          fontSize: 11,
        },
        "indicador-style": {
          font: "TodaySHOP",
          fontSize: 11,
          margin: [0, 1, 0, 0],
          characterSpacing: 1,
        },
        "source-label-style": {
          font: "TodaySHOP",
          fontSize: 8,
          margin: [0, 4, 0, 0],
          characterSpacing: 1,
        },
        "source-value-style": {
          font: "TodaySHOP",
          fontSize: 9,
          margin: [0, 3, 0, 1],
          characterSpacing: 1,
          color: _colors["gray_5"],
        },
        "source-title-label-style": {
          font: "TodaySHOP",
          fontSize: 8,
          margin: [0, 4, 0, 0],
          characterSpacing: 1,
        },
        "source-title-value-style": {
          font: "TodaySHOP",
          fontSize: 12,
          margin: [0, 1, 0, 0],
          characterSpacing: 1,
          bold: true,
        },
        "source-spacer": {
          margin: [0, 0, 0, 20],
        },
        "header-classes-style": {
          font: "TodaySHOP",
          fontSize: 11,
          bold: true,
          fillColor: _colors["gray_1"],
          characterSpacing: 1,
          margin: [0, 0, 0, 0],
        },
        "classes-style": {
          font: "TodaySHOP",
          fillColor: _colors["gray_1"],
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        "municipio-style": {
          margin: [20, 0],
        },
      },

      pageBreakBefore: function (currentNode) {
        if (currentNode.id && currentNode.pageNumbers.length != 1) {
          return true;
        }
        return false;
      },
    };

    return docDefinition;
  }

  function getIndicatorsByEixo(indicators, eixoName) {
    var indicatorsByEixo = indicators.filter(function (elemento) {
      return elemento.axis_name === eixoName;
    });
    return indicatorsByEixo;
  }

  function getDataURLs(dataUrlGroup) {
    var dataURLs = {} as Record<string, string>;

    // LOGO
    dataURLs.dataURLLogoAgroideal = dataUrlGroup.dataURLLogoAgroideal;
    // MAPA BIOMA
    dataURLs.dataURLMapBioma = dataUrlGroup.dataURLMapBioma;
    // MAPA AREA SELECIONADA
    dataURLs.dataURLMapAreaSelecionada = dataUrlGroup.dataURLMapAreaSelecionada;
    // MAPA INDICE GERAL
    dataURLs.dataURLMapIndiceGeral = dataUrlGroup.dataURLMapIndiceGeral;
    // ELEMENTO COMPASS (NORTE)
    dataURLs.dataURLElementCompass = dataUrlGroup.dataURLElementCompass;
    // GRÁFICO ÍNDICE DE OPORTUNIDADE ECONÔMICA
    dataURLs.dataURLFeaturesByIndiceOportunidade = dataUrlGroup.dataURLFeaturesByIndiceOportunidade;
    // GRÁFICO ÍNDICE DE RISCO SOCIOAMBIENTAL
    dataURLs.dataURLFeaturesByIndiceRisco = dataUrlGroup.dataURLFeaturesByIndiceRisco;
    // GRÁFICO COMPARATIVO REGIONAL
    dataURLs.dataURLFeaturesByIndicator = dataUrlGroup.dataURLFeaturesByIndicator;
    // GRÁFICO ÍNDICES POR INDICADOR
    dataURLs.dataURLIndicesByIndicator = dataUrlGroup.dataURLIndicesByIndicator;
    // ICONE PARA ABRIR LINK EXTERNO
    dataURLs.dataURLIconLink = dataUrlGroup.dataURLIconLink;
    return dataURLs;
  }

  function getTableEstados(estados) {
    var listEstados = [] as any[];

    var headerEstados = [
      {
        text: TranslateService.translate(AppService.getDynamicTranslations().reportPdf.estados),
        colSpan: 2,
        bold: true,
        fillColor: _colors["base_color"],
      },
      [],
    ];

    listEstados.push(headerEstados);

    estados.forEach(function (estado) {
      var estadoStructure = [] as any[];
      var estadoInfo = {
        text: $filter("number")(estado.percentage, 1) + "%",
        bold: true,
        alignment: "center",
      };

      var labelEstado =
        estado.name == "report.outros" ? MainService.getTextByKeyI18n(estado.name) : estado.name;

      estadoStructure.push(labelEstado);
      estadoStructure.push(estadoInfo);

      listEstados.push(estadoStructure);
    });

    return listEstados;
  }

  function getTableBiomas(biomas) {
    var listBiomas = [] as any[];

    var headerBiomas = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.biomas"),
        colSpan: 2,
        bold: true,
        fillColor: _colors["base_color"],
      },
      [],
    ];

    listBiomas.push(headerBiomas);

    biomas.forEach(function (bioma) {
      var biomaData = [] as any[];
      var biomaInfo = {
        text: $filter("number")(bioma.percentage, 1) + "%",
        bold: true,
        alignment: "center",
      };

      var labelBioma =
        bioma.name == "report.outros" ? MainService.getTextByKeyI18n(bioma.name) : bioma.name;

      biomaData.push(labelBioma);
      biomaData.push(biomaInfo);

      listBiomas.push(biomaData);
    });

    return listBiomas;
  }

  function getTableEcorregions(ecorregions) {
    var listEcorregions = [] as any[];

    var headerEcorregion = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.ecorregions"),
        colSpan: 2,
        bold: true,
        fillColor: _colors["base_color"],
      },
      [],
    ];

    listEcorregions.push(headerEcorregion);

    ecorregions.forEach(function (ecorregions) {
      var ecorregionData = [] as any[];
      var ecorregionInfo = {
        text: $filter("number")(ecorregions.percentage, 1) + "%",
        bold: true,
        alignment: "center",
      };

      var labelEcorregions =
        ecorregions.name == "report.outros"
          ? MainService.getTextByKeyI18n(ecorregions.name)
          : ecorregions.name;

      ecorregionData.push(labelEcorregions);
      ecorregionData.push(ecorregionInfo);

      listEcorregions.push(ecorregionData);
    });

    return listEcorregions;
  }

  function getTableHeaderProtectedAreas() {
    var listAreasProtegidas = [] as any[];

    var headerAreasProtegidas = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.areasprotegidas"),
        fontSize: 14,
        colSpan: 2,
        bold: true,
        fillColor: _colors["base_color"],
      },
      [],
    ];
    listAreasProtegidas.push(headerAreasProtegidas);

    return listAreasProtegidas;
  }

  function getTableStructureProtectedAreas(data, index) {
    return {
      fontSize: 11,
      table: {
        headerRows: 1,
        widths: ["*", "25%"],
        body: data,
      },
      layout: tableInfoLayout(),
      style: index == 0 ? "table-info-only-margin-top" : "table-info",
    };
  }

  function getTableContentProtectedAreas(area = [] as any[], keyText) {
    var contentTable = [] as any[];

    var header = [
      {
        text:
          MainService.getTextByKeyI18n("report.sobreposicaocom") +
          " " +
          MainService.getTextByKeyI18n(keyText),
        colSpan: 2,
        bold: false,
        fillColor: _colors["gray_5"],
        color: _colors["white"],
      },
      [],
    ];
    contentTable.push(header);

    if (area.length == 0) {
      var contentNoValues = [
        {
          text:
            MainService.getTextByKeyI18n("report.nenhumainterseccaocom") +
            " " +
            MainService.getTextByKeyI18n(keyText),
          colSpan: 2,
          fillColor: _colors["gray_5"],
          color: _colors["white"],
        },
        [],
      ];
      contentTable.push(contentNoValues);
    }

    area.forEach(function (unit) {
      var areaData = [] as any[];
      var unitInfo = {
        text: $filter("number")(unit.percentage, 1) + "%",
        bold: true,
        alignment: "center",
      };

      var labelUnit =
        unit.name == "report.outros" ? MainService.getTextByKeyI18n(unit.name) : unit.name;

      areaData.push(labelUnit);
      areaData.push(unitInfo);
      contentTable.push(areaData);
    });

    return contentTable;
  }

  function getTotalAreaSelection(area) {
    return area;
  }

  function getIndicatorStyle(value, alignment?) {
    return {
      text: value,
      alignment: alignment || "center",
      style: "indicador-style",
    };
  }

  function getIndicatorSourceLabelStyle(value) {
    return {
      text: value,
      style: "source-label-style",
    };
  }

  function getIndicatorSourceValueStyle(value) {
    return {
      text: value,
      style: "source-value-style",
    };
  }

  function getIndicatorSourceValueStyleByArray(array) {
    return {
      stack: array,
      style: "source-value-style",
    };
  }

  function getTableIndicators(indicators, classes, locale) {
    var arrayTableIndicator = [] as any[];

    var headerTable = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.tipodeinformacao"),
        fontSize: 14,
        alignment: "left",
        style: "table-header",
      },
      {
        text: MainService.getTextByKeyI18n("reportpdf.total"),
        style: "table-header",
      },
    ] as any[];

    var borderBottom = true;
    var width = 10;

    classes.forEach(function (classe) {
      var columnValue = classesIconStyle(
        classe.dataURL,
        getClasseStyle(classe.hexColorBgIcon),
        width,
        borderBottom
      );
      headerTable.push(columnValue);
    });

    var headerEixoEconomico = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.oportunidadeeconomica"),
        colSpan: 6,
        style: "table-sub-header",
      },
    ];

    var headerEixoSocioambiental = [
      {
        text: MainService.getTextByKeyI18n("reportpdf.riscosocioambiental"),
        colSpan: 6,
        style: "table-sub-header",
      },
    ];

    arrayTableIndicator.push(headerTable);

    var addedHeaderEixoEco = false;
    var addedHeaderEixoSoc = false;

    indicators.forEach(function (indicator) {
      var arrayIndicator = [] as any[];
      var value = indicator.valueTotal;
      var classes = indicator.classes;

      arrayIndicator.push(
        getIndicatorStyle(indicator.label[locale] + " - " + indicator.unit.label[locale], "left")
      );
      arrayIndicator.push(getIndicatorStyle($filter("number")(value, 1)));

      classes.forEach(function (classe) {
        arrayIndicator.push(getIndicatorStyle($filter("number")(classe.valueTotal, 1)));
      });

      if (indicator.axis_name === "economico") {
        if (!addedHeaderEixoEco) {
          arrayTableIndicator.push(headerEixoEconomico);
        }
        addedHeaderEixoEco = true;
        arrayTableIndicator.push(arrayIndicator);
      }
    });

    indicators.forEach(function (indicator) {
      var arrayIndicator = [] as any[];
      var value = indicator.valueTotal;
      var classes = indicator.classes;

      arrayIndicator.push(
        getIndicatorStyle(indicator.label[locale] + " - " + indicator.unit.label[locale], "left")
      );
      arrayIndicator.push(getIndicatorStyle($filter("number")(value, 1)));

      classes.forEach(function (classe) {
        arrayIndicator.push(getIndicatorStyle($filter("number")(classe.valueTotal, 1)));
      });

      if (indicator.axis_name === "socioambiental") {
        if (!addedHeaderEixoSoc) {
          arrayTableIndicator.push(headerEixoSocioambiental);
        }
        addedHeaderEixoSoc = true;

        arrayTableIndicator.push(arrayIndicator);
      }
    });

    return arrayTableIndicator;
  }

  function getTableIndicatorsSources(indicators, locale) {
    var allTables = [] as any[];
    var table;

    indicators.forEach(function (indicator) {
      allTables.push({ text: "", style: "source-spacer" });
      table = {
        id: "table_" + indicator.name,
        table: {
          widths: ["12%", "*"],
          headerRows: 1,
          fontSize: 10,
          body: getTableIndicatorSource(indicator, locale),
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 ? 0 : 0.5;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
          fillColor: "white",
          hLineColor: "gray_5",
          vLineColor: "gray_5",
        },
      };
      allTables.push(table);
    });

    return allTables;
  }

  function getTableIndicatorSource(indicator, locale) {
    var table = [] as any[];

    var row = [] as any[];
    row.push({
      text: MainService.getTextByKeyI18n("strategy.nome").toUpperCase() + ":",
      style: "source-title-label-style",
    });
    row.push({ text: indicator.label[locale], style: "source-title-value-style" });
    table.push(row);

    row = [];
    row.push(
      getIndicatorSourceLabelStyle(
        MainService.getTextByKeyI18n("strategy.descricao").toUpperCase() + ":"
      )
    );
    row.push(getIndicatorSourceValueStyle(indicator.description[locale]));
    table.push(row);

    row = [];
    row.push(
      getIndicatorSourceLabelStyle(
        MainService.getTextByKeyI18n("strategy.unidade").toUpperCase() + ":"
      )
    );
    row.push(getIndicatorSourceValueStyle(indicator.unit.label[locale]));
    table.push(row);

    row = [];
    row.push(
      getIndicatorSourceLabelStyle(
        MainService.getTextByKeyI18n("strategy.ano_base").toUpperCase() + ":"
      )
    );
    row.push(getIndicatorSourceValueStyle(indicator.year_base[locale]));
    table.push(row);

    row = [];
    row.push(
      getIndicatorSourceLabelStyle(
        MainService.getTextByKeyI18n("strategy.fonte").toUpperCase() + ":"
      )
    );
    var sources = [] as any[];
    indicator.sources.forEach(function (source) {
      sources.push(getIndicatorSourceContent(source, locale));
    });
    indicator.projects.forEach(function (project) {
      sources.push(getIndicatorSourceContent(project, locale));
    });
    row.push(getIndicatorSourceValueStyleByArray(sources));
    table.push(row);

    row = [];
    row.push(
      getIndicatorSourceLabelStyle(
        MainService.getTextByKeyI18n("strategy.abrangencia").toUpperCase() + ":"
      )
    );
    var scopes = "";
    indicator.scopes.forEach(function (scope) {
      scopes += scope.label[locale] + " - ";
    });
    scopes = scopes.substr(0, scopes.length - 3);
    row.push(getIndicatorSourceValueStyle(scopes));
    table.push(row);

    return table;
  }

  function getIndicatorSourceContent(source, locale) {
    if (source.url) {
      return {
        columns: [
          {
            text: source.label[locale],
          },
          {
            width: milimetro(4),
            stack: [{ link: source.url, image: "dataURLIconLink", width: milimetro(2.7) }],
            margin: [0, 0, 0, milimetro(1)],
            alignment: "right",
          },
        ],
      };
    } else {
      return { text: source.label[locale] };
    }
  }

  function getTableClasses(classes, unitPlan) {
    var arrayTableClasses = [] as any[];
    var isBold = true;
    var width = 9;
    var borderBottom;

    classes.forEach(function (classe, index) {
      var arrayClasse = [] as any[];
      var unitPlanLabel = TranslateService.translate(unitPlan) + (classe.total > 1 ? "s" : "");
      borderBottom = index !== 3;
      arrayClasse.push(
        classesIconStyle(classe.dataURL, getClasseStyle(classe.hexColorBgIcon), width, borderBottom)
      );
      arrayClasse.push(
        classesTextStyle(MainService.getTextByKeyI18n(classe.label), !isBold, classe.hexColorBgText)
      );
      arrayClasse.push(classesTextStyle(classe.total + "  " + unitPlanLabel.toLowerCase()));
      arrayClasse.push(
        classesTextStyle(
          MainService.getTextByKeyI18n("reportpdf.areatotalde") +
            " " +
            $filter("number")(classe.area, 1) +
            " ha.",
          isBold
        )
      );
      arrayTableClasses.push(arrayClasse);
    });

    return arrayTableClasses;
  }

  function classesIconStyle(dataURL, style, mm, last) {
    return {
      border: [true, false, true, last],
      width: milimetro(mm),
      margin: [0, 0, 0, 0],
      style: style,
      image: dataURL,
    };
  }

  function classesTextStyle(value, isBold?, fillColor?) {
    return {
      text: value,
      bold: false || isBold,
      alignment: "left",
      margin: [5, 7, 0, 0], // centraliza o texto verticalmente
      border: [true, false, true, true],
      style: getClasseStyle(fillColor),
    };
  }

  function getClasseStyle(fillColor) {
    return {
      font: "TodaySHOP",
      fontSize: 10.5,
      fillColor: fillColor || _colors["gray_1"],
      alignment: "center",
    };
  }

  function getColumnImageStyleTable(dataURL, width) {
    return {
      image: dataURL,
      width: width,
    };
  }

  function getTableSliders(indicators, header) {
    var arrayTableSliders = [] as any[];

    var headerTable = [
      {
        text: header,
        colSpan: 2,
        alignment: "center",
        bold: true,
        style: "table-sub-header-sliders",
      },
      [],
    ];

    arrayTableSliders.push(headerTable);

    var arraySlider = [] as any[];
    indicators.forEach(function (indicator, i) {
      var isLastIndex = i == indicators.length - 1;

      arraySlider.push(getColumnImageStyleTable(indicator.dataURL, 260));

      if (arraySlider.length % 2 === 0) {
        arrayTableSliders.push(arraySlider);
        arraySlider = [];
      }

      if (isLastIndex && arraySlider.length % 2 !== 0) {
        arraySlider.push([]);
        arrayTableSliders.push(arraySlider);
      }
    });

    return arrayTableSliders;
  }

  function getTableCharts(listCharts) {
    var arrayTableSliders = [] as any[];

    listCharts.forEach(function (chart) {
      var arraySlider = [] as any[];
      arraySlider.push(getColumnImageStyleTable(chart, 550));
      arrayTableSliders.push(arraySlider);
    });

    return arrayTableSliders;
  }

  return {
    line: line,
    point: point,
    classesIconStyle: classesIconStyle,
    tableInfoLayout: tableInfoLayout,
    stripedRow: stripedRow,
    separator: separator,
    header: header,
    createDocDefinition: createDocDefinition,
    getDataURLs: getDataURLs,
    getIndicatorStyle: getIndicatorStyle,
    getTableIndicators: getTableIndicators,
    getTableIndicatorsSources: getTableIndicatorsSources,
    classesTextStyle: classesTextStyle,
    getTableClasses: getTableClasses,
    getTableSliders: getTableSliders,
    getTotalAreaSelection: getTotalAreaSelection,
    getTableHeaderProtectedAreas: getTableHeaderProtectedAreas,
    getTableBiomas: getTableBiomas,
    getTableEcorregions: getTableEcorregions,
    getTableEstados: getTableEstados,
    getTableCharts: getTableCharts,
  };
}

export const ReportPdfServiceModule = new AppModule({
  name: "reportPdf.service",
  factories: { ReportCreatePdfService },
});

// angular
//     .module('reportPdf.service', [])
//     .factory('ReportCreatePdfService', ReportCreatePdfService);
