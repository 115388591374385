import { AppModule } from "../../module-wrapper";
import { ContactServiceModule } from "./contact.service";
import { ContactControllerModule } from "./contact.controller";

// angular
//     .module('contact.module', [
//         'contact.controller',
//         'contact.service'
//     ]);

export const ContactModule = new AppModule({
  name: "contact.module",
  modules: [ContactControllerModule, ContactServiceModule]
});
