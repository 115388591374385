import { AppModule } from "../../module-wrapper";

/* @ngInject */
function StrategyView() {
  return {
    restrict: "E",
    controller: "StrategyController",
    controllerAs: "strategyCtrl",
    templateUrl: "/app/modules/strategy/strategy.view.html"
  };
}

/* @ngInject */
function StrategyRegions() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.regions.html"
  };
}

/* @ngInject */
function StrategyButtons() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.buttons.html"
  };
}

/* @ngInject */
function StrategyIndicators() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.indicators.html",
    controller: "IndicatorController",
    controllerAs: "indCtrl"
  };
}

/* @ngInject */
function StrategyResult() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.result.html"
  };
}

/* @ngInject */
function StrategySelectionArea() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.selection-area.html"
  };
}

/* @ngInject */
function StrategyTransparency() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.transparency.html"
  };
}

/* @ngInject */
function StrategyUnitPlanning() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/strategy.unit-planning.html"
  };
}

/* @ngInject */
function ButtonsSaveStrategy() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/modal/buttons.save-strategy.html"
  };
}

/* @ngInject */
function ModalSaveStrategy() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/modal/modal.save-strategy.html"
  };
}

/* @ngInject */
function ModalNameStrategy() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/strategy/views/modal/modal.strategy-name.html"
  };
}

export const StrategyDirectivesModule = new AppModule({
  name: "strategy.directives",
  directives: {
    strategyView: StrategyView,
    strategyRegions: StrategyRegions,
    strategyButtons: StrategyButtons,
    strategyIndicators: StrategyIndicators,
    strategyResult: StrategyResult,
    strategySelectionArea: StrategySelectionArea,
    strategyTransparency: StrategyTransparency,
    strategyUnitPlanning: StrategyUnitPlanning,
    buttonsSaveStrategy: ButtonsSaveStrategy,
    modalSaveStrategy: ModalSaveStrategy,
    modalNameStrategy: ModalNameStrategy
  }
});

// angular
//   .module("strategy.directives", [])
//   .directive("strategyView", StrategyView)
//   .directive("strategyRegions", StrategyRegions)
//   .directive("strategyButtons", StrategyButtons)
//   .directive("strategyIndicators", StrategyIndicators)
//   .directive("strategyResult", StrategyResult)
//   .directive("strategySelectionArea", StrategySelectionArea)
//   .directive("strategyTransparency", StrategyTransparency)
//   .directive("strategyUnitPlanning", StrategyUnitPlanning)
//   .directive("buttonsSaveStrategy", ButtonsSaveStrategy)
//   .directive("modalSaveStrategy", ModalSaveStrategy)
//   .directive("modalNameStrategy", ModalNameStrategy);
