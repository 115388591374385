export let headers = {
  atolegal: {
    "pt-BR": "Decreto de criação",
    "en-US": "Creation decree",
    "es-ES": "Decreto de creación",
  },
  label: {
    "pt-BR": "Categoria da unidade",
    "en-US": "Unit category",
    "es-ES": "Categoría de unidad",
  },
  nome_proje: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  tempo: {
    "pt-BR": "Tempo",
    "en-US": "Time",
    "es-ES": "Tiempo",
  },
  armazenado: {
    "pt-BR": "Empresa",
    "en-US": "Company",
    "es-ES": "Empresa",
  },
  capacidade: {
    "pt-BR": "Capacidade (ton)",
    "en-US": "Storage (ton)",
    "es-ES": "Capacidad (ton)",
  },
  nome: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  nm_mun: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  length: {
    "pt-BR": "Extensão",
    "en-US": "Length",
    "es-ES": "Extensión",
  },
  linha_fe_4: {
    "pt-BR": "Seção ferroviária",
    "en-US": "Railway section",
    "es-ES": "Tramo ferroviario",
  },
  fase_ti: {
    "pt-BR": "Status da unidade",
    "en-US": "Unit status",
    "es-ES": "Estado de la unidad",
  },
  cod_area: {
    "pt-BR": "Código da área (MMA)",
    "en-US": "Area code (MMA)",
    "es-ES": "Código de área (MMA)",
  },
  import_bio: {
    "pt-BR": "Importância biológica",
    "en-US": "Biological relevance",
    "es-ES": "Importancia biológica",
  },
  prior_acao: {
    "pt-BR": "Prioridade de ação",
    "en-US": "Priority of action",
    "es-ES": "Prioridad de acción",
  },
  etnia: {
    "pt-BR": "Etnia",
    "en-US": "Ethnicity",
    "es-ES": "Etnia",
  },
  nombre: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  name: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  etiqueta: {
    "pt-BR": "Etiqueta",
    "en-US": "Etiqueta",
    "es-ES": "Etiqueta",
  },
  provincia: {
    "pt-BR": "Províncias",
    "en-US": "Provinces",
    "es-ES": "Provincias",
  },
  departamen: {
    "pt-BR": "Departamento",
    "en-US": "Department",
    "es-ES": "Departamento",
  },
  terrai_nom: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  id_grade: {
    "pt-BR": "Identificador",
    "en-US": "Identifier",
    "es-ES": "Identificador",
  },
  nm_estado: {
    "pt-BR": "Departamento",
    "en-US": "Department",
    "es-ES": "Departamento",
  },
  nombre_com: {
    "pt-BR": "Comunidade",
    "en-US": "Territorie",
    "es-ES": "Comunidad",
  },
  situacion: {
    "pt-BR": "Situação",
    "en-US": "Situation",
    "es-ES": "Situación",
  },
  empresa: {
    "pt-BR": "Empresa habilitada",
    "en-US": "Qualified company",
    "es-ES": "Empresa calificada",
  },
  sitnationa: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  desig: {
    "pt-BR": "Nome",
    "en-US": "Name",
    "es-ES": "Nombre",
  },
  distrito: {
    "pt-BR": "Distrito",
    "en-US": "District",
    "es-ES": "Distrito",
  },
  dist_desc: {
    "pt-BR": "Localidades",
    "en-US": "Locality",
    "es-ES": "Localidades",
  },
  nm_meso: {
    "pt-BR": "Mesorregião",
    "en-US": "Mesoregion",
    "es-ES": "Mesorregión",
  },
  nm_micro: {
    "pt-BR": "Microrregião",
    "en-US": "Microregion",
    "es-ES": "Microrregión",
  },
  categoria: {
    "pt-BR": "Categoria",
    "en-US": "Category",
    "es-ES": "Categoría",
  }
};
