import { AppModule } from "../../module-wrapper";

/* @ngInject */
function LayersView() {
  return {
    restrict: "E",
    controller: "LayersController",
    controllerAs: "layersCtrl",
    templateUrl: "/app/modules/layer/layers.view.html"
  };
}

/* @ngInject */
function LayersButtonsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/layer/views/layers.buttons.html"
  };
}

/* @ngInject */
function LayersGroupsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/layer/views/layers.groups.html"
  };
}

/* @ngInject */
function LayersListView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/layer/views/layers.list.html"
  };
}

export const LayersDirectivesModule = new AppModule({
  name: "layers.directives",
  directives: {
    layersView: LayersView,
    layersButtonsView: LayersButtonsView,
    layersGroupsView: LayersGroupsView,
    layersListView: LayersListView
  }
});

// angular
//     .module('layers.directives', [])
//     .directive('layersView', LayersView)
//     .directive('layersButtonsView', LayersButtonsView)
//     .directive('layersGroupsView', LayersGroupsView)
//     .directive('layersListView', LayersListView);
