import { AppModule } from "../../module-wrapper";
import { ModalControllerModule } from "./modal.controller";
import { ModalDirectivesModule } from "./modal.directives";
import { ModalServiceModule } from "./modal.service";

// angular
// 	.module('modal.module', [
// 		'modal.controller',
// 		'modal.service',
// 		'modal.directives',
// 	]);

export const ModalModule = new AppModule({
  name: "modal.module",
  modules: [ModalControllerModule, ModalServiceModule, ModalDirectivesModule]
});
