import { AppModule } from "../../module-wrapper";
import { MapRoutesModule } from "./map.routes";
import { MapControllerModule } from "./map.controller";
import { MapServiceModule } from "./map.service";
import { MapDirectivesModule } from "./map.directives";
import { MapInteractionsModule } from "./mapInteractions/mapInteractions.module";

// angular
//     .module('map.module', [
//         'map.routes',
//         'map.controller',
//         'map.service',
//         'map.directives',
//         'mapInteractions.module'
//     ]);

export const MapModule = new AppModule({
  name: "map.module",
  modules: [
    MapRoutesModule,
    MapControllerModule,
    MapServiceModule,
    MapDirectivesModule,
    MapInteractionsModule
  ]
});
