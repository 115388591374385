import { AppModule } from "../../../module-wrapper";
import { ReportPdfServiceModule } from "./reportPdf.service";

// angular
//     .module('reportPdf.module', [
//         'reportPdf.service'
//     ]);

export const ReportPdfModule = new AppModule({
  name: "reportPdf.module",
  modules: [ReportPdfServiceModule]
});
