import { AppModule } from "../../module-wrapper";
import { StrategyControllerModule } from "./strategy.controller";
import { StrategyDirectivesModule } from "./strategy.directives";
import { IndicatorModule } from "./indicator/indicator.module";
import { StrategyServiceModule } from "./strategy.service";
import { IndicatorStrategyModule } from "./indicatorstrategy/indicatorstrategy.module";

// angular
//     .module('strategy.module', [
//         'strategy.controller',
//         'strategy.directives',
//         'strategy.service',
//         'indicator.module',
//         'indicatorstrategy.module',
//     ])

export const StrategyModule = new AppModule({
  name: "strategy.module",
  modules: [
    StrategyControllerModule,
    StrategyDirectivesModule,
    StrategyServiceModule,
    IndicatorModule,
    IndicatorStrategyModule
  ]
});
