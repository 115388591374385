import { AppModule } from "../../../module-wrapper";
import { ReportChartsControllerModule } from "./reportCharts.controller";
import { ReportChartsServiceModule } from "./reportCharts.service";

// angular
//     .module('reportCharts.module', [
//         'reportCharts.controller',
//         'reportCharts.service'
//     ]);

export const ReportChartsModule = new AppModule({
  name: "reportCharts.module",
  modules: [ReportChartsControllerModule, ReportChartsServiceModule]
});
