import { AppModule } from "../../../module-wrapper";
import { IndicatorControllerModule } from "./indicator.controller";
import { IndicatorDirectivesModule } from "./indicator.directives";
import { IndicatorServiceModule } from "./indicator.service";

// angular
//     .module('indicator.module', [
//         'indicator.controller',
//         'indicator.directives',
//         'indicator.service'
//     ]);

export const IndicatorModule = new AppModule({
  name: "indicator.module",
  modules: [IndicatorControllerModule, IndicatorDirectivesModule, IndicatorServiceModule]
});
