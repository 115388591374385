import { AppModule } from "../../../module-wrapper";

/* @ngInject */
function MapInterationsController(this: any, MapInterationsService, MapService, HowToUseService) {
  var ctrl = this;

  // Ferramenta de expandir o mapa para o zoom inicial
  ctrl.expandMap = function() {
    MapInterationsService.expandMap();
  };

  // Ferramenta de troca de estilo do mapa base
  ctrl.mapStyles = MapInterationsService.getMapStyles();
  ctrl.getMapStyles = function() {
    return ctrl.mapStyles;
  };

  ctrl.setMapStyle = function() {
    MapInterationsService.setMapStyle();
  };

  ctrl.setMapStyleIcon = function(){
    return MapInterationsService.setMapStyleIcon()
  }

  // Ferramenta de cálculo de área
  ctrl.addInteractionMeasureDraw = function() {
    MapInterationsService.offMeasure();
    var styleMeasure = MapService.getStyleMeasure();
    var featuresCollection = MapService.getFeaturesMeasureCollection();
    var type = "Polygon";
    MapInterationsService.addInteraction(styleMeasure, featuresCollection, type);
  };

  ctrl.isMeasureDraw = function() {
    return (MapInterationsService.isActiveMeasureDraw() || MapInterationsService.isMeasureDraw() )
  }
}

// angular
//     .module('mapInteractions.controller', [])
//     .controller('MapInterationsController', MapInterationsController)

export const MapInteractionsControllerModule = new AppModule({
  name: "mapInteractions.controller",
  controllers: { MapInterationsController }
});
