import { AppModule } from "../../module-wrapper";
import { HttpService_ServiceModule } from "./httpService.service";

// angular
//     .module('httpService.module', [
//         'httpService.service'
//     ]);

export const HttpServiceModule = new AppModule({
  name: "httpService.module",
  modules: [HttpService_ServiceModule]
});
