import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function UserController(
  this: any,
  UserService,
  AuthService,
  ModalService,
  $rootScope,
  NotificationService,
  MainService
) {
  var ctrl = this;

  ctrl.saveUser = function(user) {
    if (user) {
      if (user.id == null) {
        UserService.add(user).$promise.then(
          function(response) {
            if (response.success) {
              ModalService.openModalLogin();
            }
            angular.element(function() {
              $rootScope.$broadcast("callMessage", {
                messages: response.messages,
                elementId: "messageSave"
              });
            });
          },
          function() {
            $rootScope.$broadcast("callMessage", {
              messages: AuthService.getMsgErrorDefault(),
              elementId: "messageSave"
            });
          }
        );
      } else {
        UserService.update(user).$promise.then(
          function(response) {
            if (response.success) {
              ModalService.openModalLogin();
            }
            angular.element(function() {
              $rootScope.$broadcast("callMessage", {
                messages: response.messages,
                elementId: "messageSave"
              });
            });
          },
          function() {
            $rootScope.$broadcast("callMessage", {
              messages: AuthService.getMsgErrorDefault(),
              elementId: "messageSave"
            });
          }
        );
      }
    }
  };

  ctrl.changePasswordUser = function(user) {
    if (user) {
      UserService.update(user).$promise.then(
        function(response) {
          if (response.success) {
            ModalService.closeAllModal();
            NotificationService.showNotification(
              "success",
              MainService.getTextByKeyI18n("areapessoal.redefinirsuasenha"),
              MainService.getTextByKeyI18n("areapessoal.trocarsenhasucesso"),
              true,
              8000
            );
          }
          angular.element(function() {
            $rootScope.$broadcast("callMessage", {
              messages: response.messages,
              elementId: "messageSave"
            });
          });
        },
        function() {
          $rootScope.$broadcast("callMessage", {
            messages: AuthService.getMsgErrorDefault(),
            elementId: "messageSave"
          });
        }
      );
    }
  };
}

// angular
//     .module('user.controller', [])
//     .controller('UserController', UserController, []);

export const UserControllerModule = new AppModule({
  name: "user.controller",
  controllers: { UserController }
});
