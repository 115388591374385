import { AppModule } from "../module-wrapper";
import angular from "angular";

/* @ngInject */
function PropertiesService($resource) {
  return function (url) {
    return $resource(url, {
      get: {
        method: "GET",
        isArray: false,
      },
    });
  };
}

/* @ngInject */
function MainService(this: any, $localStorage, $q, PropertiesService, tmhDynamicLocale) {
  var ctrl = this;

  function sortAscArrayObjectByKey(arrayObj, key, slice) {
    arrayObj.sort(function (a, b) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });
    if (angular.isDefined(slice)) {
      arrayObj = arrayObj.slice(0, slice);
    }
    return arrayObj;
  }

  function sortDescArrayObjectByKey(arrayObj, key, slice) {
    arrayObj.sort(function (a, b) {
      if (a[key] < b[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    });
    if (angular.isDefined(slice)) {
      arrayObj = arrayObj.slice(0, slice);
    }
    return arrayObj;
  }

  function getDictProperties(urls) {
    var deferred = $q.defer();
    var listObj = [];
    var langSystem = getLanguageSystem().locale;
    var properties = [] as Record<string, any>[];
    var promises = [] as Promise<any>[];

    urls.forEach(function (url) {
      var promise = PropertiesService(url)
        .get()
        .$promise.then(
          function (response) {
            listObj = response.dict;
            listObj.forEach(function (obj) {
              var objOutput = {};
              for (var key in obj) {
                objOutput[key] = obj[key][langSystem];
                blankValueInProperties(key, objOutput[key], langSystem);
              }
              properties.push(objOutput);
            });
          },
          function (error) {}
        );
      promises.push(promise);
    });
    $q.all(promises).then(function () {
      properties.forEach(function (line) {
        var key = Object.keys(line)[0];
        duplicateKeysInProperties(key, properties);
      });
      deferred.resolve(properties);
    });
    return deferred.promise;
  }

  function duplicateKeysInProperties(key, properties) {
    var repeated = properties.filter(function (line) {
      var keyList = Object.keys(line)[0];
      return key === keyList;
    });
    if (repeated.length >= 2) {
      console.warn(key + " is duplicate.");
    }
  }

  function blankValueInProperties(key, value, language) {
    if (value === "") {
      console.warn(key + " in " + language + " is blank value.");
    }
  }

  function getLanguages() {
    ctrl.listLanguages = [
      { locale: "es-ES", site: "es", name: "menu.espanhol", default: false },
      { locale: "en-US", site: "en", name: "menu.ingles", default: true },
      { locale: "pt-BR", site: "", name: "menu.portugues", default: false },
    ];
    return ctrl.listLanguages;
  }

  function setLanguageSystem(langUser) {
    $localStorage.language = isLanguageAvailable(langUser);
    setLocaleSystem(langUser);
  }

  function setLocaleSystem(langUser) {
    tmhDynamicLocale.set(langUser.toLowerCase());
  }

  function getLanguageSystem() {
    if (!$localStorage.language) {
      var lang = navigator.language || navigator.userLanguage;
      setLanguageSystem(lang);
    }
    return $localStorage.language[0];
  }

  function getLocaleLanguageSystem() {
    return getLanguageSystem().locale;
  }

  function getOthersLanguages() {
    var currentLang = getLanguageSystem();
    var list = getLanguages();
    var avaibable = list.filter(function (language) {
      return currentLang.locale !== language.locale;
    });
    if (avaibable.length > 0) {
      return avaibable;
    }
  }

  function isLanguageAvailable(languageUser) {
    var list = getLanguages();
    var avaibable = list.filter(function (language) {
      return languageUser === language.locale;
    });
    if (avaibable.length > 0) {
      return avaibable;
    } else {
      return getLanguageDefault();
    }
  }

  function getLanguageDefault() {
    var list = getLanguages();
    var avaibable = list.filter(function (language) {
      return language.default === true;
    });
    return avaibable;
  }

  function getTextByKey(key, language) {
    var value;
    language.forEach(function (line) {
      if (Object.keys(line)[0] === key) {
        value = line.valueOf()[key];
      }
    });
    return value || key;
  }

  function initDictI18n() {
    $localStorage.dictTranslate = {};
  }

  function setDictI18n(dictTranslate) {
    var locale = getLocaleLanguageSystem();
    $localStorage.dictTranslate[locale] = dictTranslate;
  }

  function pushDictI18n(dictsTranslate, many) {
    if (!Boolean(many)) {
      dictsTranslate = [dictsTranslate];
    }
    var locale = getLocaleLanguageSystem();
    dictsTranslate.forEach(function (dictTranslate) {
      $localStorage.dictTranslate[locale].push(dictTranslate);
    });
  }

  function getTextByKeyI18n(key) {
    var locale = getLocaleLanguageSystem();
    var dict = $localStorage.dictTranslate[locale];
    var text = "";
    if (angular.isDefined(dict)) {
      var value = dict.filter(function (line) {
        var keyLine = Object.keys(line)[0];
        return key === keyLine;
      });
      if (angular.isDefined(value[0])) {
        text = value[0][key];
      }
    }
    return text || key;
  }

  function setVisibilityMenu(visibility) {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    if (visibility) {
      if (Boolean(isMobile)) {
        angular.element("div.menu-main-closed-for-mobile").addClass("menu-main-opened-for-mobile");
      } else {
        angular.element("div.menu-main-closed").addClass("menu-main-opened");
      }
      angular.element("div.menu-displayed").addClass("menu-animation-hidden");
    } else {
      if (Boolean(isMobile)) {
        angular
          .element("div.menu-main-closed-for-mobile")
          .removeClass("menu-main-opened-for-mobile");
      } else {
        angular.element("div.menu-main-closed").removeClass("menu-main-opened");
      }
      removeClassesMenu();
      angular
        .element("#menu-language > span")
        .removeClass("flaticon-left-arrow")
        .addClass("flaticon-sort-down");
      angular.element("div.menu-displayed").removeClass("menu-animation-hidden");
    }
  }

  function removeClassesMenu(scape?: string) {
    if (scape != "submenulang") {
      angular.element("#submenulang").removeClass("show");
    }
    if (scape != "submenucomousar") {
      angular.element("#submenucomousar").removeClass("show");
    }
    if (scape != "submenuusuario") {
      angular.element("#submenuusuario").removeClass("show");
    }
  }

  // Função realizar a quebra de linha
  function wordWrap(text, maxLength) {
    var result = [] as string[],
      line = [] as string[];
    var length = 0;
    text.split(" ").forEach(function (word) {
      if (length + word.length >= maxLength) {
        result.push(line.join(" "));
        line = [];
        length = 0;
      }
      length += word.length + 1;
      line.push(word);
    });
    if (line.length > 0) {
      result.push(line.join(" "));
    }
    return result;
  }

  function getMaxValueInArray(array) {
    return Math.max.apply(null, array);
  }

  function convertArrayStringToFloat(array) {
    return array.map(function (i) {
      return parseFloat(i);
    });
  }

  // Função para buscar valores duplicados em um array de objetos
  function arrayObjectIndexOf(myArray, searchTerm, property) {
    for (var i = 0, len = myArray.length; i < len; i++) {
      if (myArray[i][property] === searchTerm) return i;
    }
    return -1;
  }

  return {
    getDictProperties: getDictProperties,
    getLanguages: getLanguages,
    getLanguageSystem: getLanguageSystem,
    getLocaleLanguageSystem: getLocaleLanguageSystem,
    getOthersLanguages: getOthersLanguages,
    getTextByKey: getTextByKey,
    getTextByKeyI18n: getTextByKeyI18n,
    initDictI18n: initDictI18n,
    pushDictI18n: pushDictI18n,
    removeClassesMenu: removeClassesMenu,
    setDictI18n: setDictI18n,
    setLanguageSystem: setLanguageSystem,
    setVisibilityMenu: setVisibilityMenu,
    sortAscArrayObjectByKey: sortAscArrayObjectByKey,
    sortDescArrayObjectByKey: sortDescArrayObjectByKey,
    wordWrap: wordWrap,
    getMaxValueInArray: getMaxValueInArray,
    convertArrayStringToFloat: convertArrayStringToFloat,
    arrayObjectIndexOf: arrayObjectIndexOf,
  };
}

// angular
//     .module('main.service', [])
//     .factory('MainService', MainService)
//     .factory('PropertiesService', PropertiesService);

export const MainServiceModule = new AppModule({
  name: "main.service",
  factories: { MainService, PropertiesService },
});
