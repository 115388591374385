import angular from "angular";
import { AppModule } from "./module-wrapper";

/* @ngInject */
export function AppController(this: any, AppService, MainService) {
  var ctrl = this;

  ctrl.getLayoutAgroideal = function () {
    return AppService.getLayout();
  };

  ctrl.getUrlLogoAgroideal = function () {
    return AppService.getUrlLogoAgroideal(AppService.getLayout());
  };

  ctrl.getUrlSite = function () {
    if (angular.isUndefined(MainService.getLanguageSystem().site)) {
      MainService.setLanguageSystem(MainService.getLanguageSystem().locale);
    }
    if (MainService.getLanguageSystem().site === "en") {
      return AppService.getUrlSite() + "index-en.html";
    }
    if (MainService.getLanguageSystem().site === "es") {
      return AppService.getUrlSite() + "index-es.html";
    }
    return AppService.getUrlSite();
  };

  ctrl.getLogosPartners = function () {
    return AppService.getLogosPartners();
  };

  ctrl.getLinkByLocale = function (logo) {
    var locale = MainService.getLocaleLanguageSystem();
    return logo[locale] || logo["pt-BR"];
  };

  ctrl.getDynamicTranslations = function () {
    return AppService.getDynamicTranslations();
  };

  ctrl.tourReposition = function (tour) {
    setTimeout(function () {
      tour.reposition();
    });
  };
}

export const ControllerModule = new AppModule({
  name: "app.controller",
  controllers: { AppController },
});

// angular
//     .module('app.controller', [])
//     .controller('AppController', AppController);
