import { AppModule } from "../../../module-wrapper";

/* @ngInject */
function IndicatorController(
  this: any,
  AxisService,
  IndicatorService,
  TranslateService,
  IndicatorsGroupsService
) {
  var ctrl = this;

  ctrl.getIndicadores = function() {
    return IndicatorService.getIndicadores();
  };

  ctrl.getLastIndicator = function() {
    return IndicatorService.getLastIndicator();
  };

  ctrl.loadAxes = function() {
    AxisService.loadAxes();
  };

  ctrl.getAxes = function() {
    return AxisService.getAxes();
  };

  ctrl.loadIndicatorsGroups = function() {
    IndicatorsGroupsService.loadIndicatorsGroups();
  };

  ctrl.getIndicatorsGroupsByAxisName = function(axis_name) {
    return IndicatorsGroupsService.getIndicatorsGroupsByAxisName(axis_name);
  };

  ctrl.getIndicatorsByAxisName = function(axis_name) {
    return IndicatorService.getIndicatorsByAxisName(axis_name);
  };

  ctrl.getIndicatorsByAxisAndGroupName = function(axis_name, group_name) {
    return IndicatorService.getIndicatorsByAxisAndGroupName(axis_name, group_name);
  };

  ctrl.translate = TranslateService.translate;
}

export const IndicatorControllerModule = new AppModule({
  name: "indicator.controller",
  controllers: { IndicatorController }
});

// angular.module("indicator.controller", []).controller("IndicatorController", IndicatorController);
