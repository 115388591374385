import { AppModule } from "../../../module-wrapper";
import { IndicatorStrategyControllerModule } from "./indicatorstrategy.controller";
import { IndicatorStrategyServiceModule } from "./indicatorstrategy.service";

// angular
//     .module('indicatorstrategy.module', [
//         'indicatorstrategy.controller',
//         'indicatorstrategy.service'
//     ]);

export const IndicatorStrategyModule = new AppModule({
  name: "indicatorstrategy.module",
  modules: [IndicatorStrategyControllerModule, IndicatorStrategyServiceModule]
});
