import { AppModule } from "./module-wrapper";
import { colors } from "./app.colors";
import angular from "angular";

/* @ngInject */
export function AppService(this: any, MainService, $localStorage, $injector) {
  var ctrl = this;

  function setIdSessionLog(id) {
    ctrl.idSessionLog = id;
  }

  function getIdSessionLog() {
    return ctrl.idSessionLog;
  }

  function setLayout(layout) {
    ctrl.layout = layout;
  }

  function getLayout() {
    return ctrl.layout;
  }

  function setUrlApi(urlApi) {
    ctrl.urlApi = urlApi;
  }

  function getUrlApi() {
    return ctrl.urlApi;
  }

  function setUrlLogApi(urlLogApi) {
    ctrl.urlLogApi = urlLogApi;
  }

  function getUrlLogApi() {
    return ctrl.urlLogApi;
  }

  function setUrlSite(urlSite) {
    ctrl.urlSite = urlSite;
  }

  function getUrlSite() {
    return ctrl.urlSite;
  }

  function setWmsConfigApi(wmsConfig) {
    ctrl.wmsConfig = wmsConfig;
  }

  function getWmsConfigApi() {
    return ctrl.wmsConfig;
  }

  function getUrlWmsApi() {
    return ctrl.wmsConfig.urlWms;
  }

  function getUrlSldApi() {
    return ctrl.wmsConfig.urlSld;
  }

  function setActiveRegionsInterest(activeRegionsInterest) {
    ctrl.activeRegionsInterest = activeRegionsInterest;
  }

  function getActiveRegionsInterest() {
    return ctrl.activeRegionsInterest;
  }

  function setActiveCountry(activeCountry) {
    ctrl.activeCountry = activeCountry;
  }

  function getActiveCountry() {
    return ctrl.activeCountry;
  }

  function setLayersNamesMapServer(layersNamesMapServer) {
    ctrl.layersNamesMapServer = layersNamesMapServer;
  }

  function getLayersNameBiomes() {
    return ctrl.layersNamesMapServer.biomes;
  }

  function getLayersNameUnits() {
    return ctrl.layersNamesMapServer.units;
  }

  function setMapViewInitOptions(mapViewInitOptions) {
    ctrl.mapViewInitOptions = mapViewInitOptions;
  }

  function getMapViewInitOptions() {
    return ctrl.mapViewInitOptions;
  }

  function setLogosPartners(logosPartners) {
    ctrl.logosPartners = logosPartners;
  }

  function getLogosPartners() {
    return ctrl.logosPartners;
  }

  function getUrlLogoAgroideal(layout) {
    if (MainService.getLanguageSystem() === null) {
      return null;
    } else {
      return (
        "/assets/images/" +
        layout +
        "/logo/logo-agroideal-" +
        MainService.getLanguageSystem().locale +
        ".png"
      );
    }
  }

  function setReportStructure(reportStructure) {
    ctrl.reportStructure = reportStructure;
  }

  function getReportStructure() {
    return ctrl.reportStructure;
  }

  function getPieChartsLayout() {
    return ctrl.reportStructure.pieCharts || [];
  }

  function getBarChartsRankingIndiceLayout() {
    return ctrl.reportStructure.barChartsRankingIndice || [];
  }

  function getBarChartsRegionalComparativeLayout() {
    return ctrl.reportStructure.barChartsRegionalComparative || [];
  }

  function getScatterPolarIndicesByIndicator() {
    return ctrl.reportStructure.scatterPolarIndicesByIndicator || [];
  }

  function getTranslateAreas(language) {
    var language = language || "pt-BR";
    var areas = ctrl.reportStructure["protectedAreas"];
    var translates = [] as any[];
    if (areas) {
      areas.forEach(function (area) {
        if (area["translate"]) {
          var dict_translate = {};
          dict_translate[area["key"]] = area["translate"][language];
          translates.push(dict_translate);
        }
      });
    }
    return translates;
  }

  function enableGoogleAnalytics(gtagId) {
    if (angular.isDefined(gtagId)) {
      var scriptAsync = document.createElement("script");
      scriptAsync.async = true;
      scriptAsync.type = "text/javascript";
      scriptAsync.src = "https://www.googletagmanager.com/gtag/js?id=" + gtagId;
      document.body.appendChild(scriptAsync);
      window.dataLayer = window.dataLayer || [];
      gtag("js", new Date());
      gtag("config", gtagId);
    }
  }

  function gtag(...i: any[]) {
    window.dataLayer.push(arguments);
  }

  function setDynamicTranslations(dynamicTranslations) {
    ctrl.dynamicTranslations = dynamicTranslations;
  }
  function getDynamicTranslations() {
    return ctrl.dynamicTranslations;
  }

  function uiTourService(uiTourService) {
    if (!getNotifiedAboutNewFeatures()) {
      setTimeout(() => {
        var MapInterationsService = $injector.get("MapInterationsService");
        MapInterationsService.openStrategyTab();
      }, 1000);
    }
    uiTourService.createDetachedTour("general", {
      name: "general",
      backdrop: true,
      debug: true,
      templateUrl: "/app/modules/tour/views/tour-template.html",
    });

    var uitour = uiTourService.getTourByName("general");
    if (!getNotifiedAboutNewFeatures()) {
      setTimeout(() => {
        uitour.start();
      }, 2000);
      setTimeout(function () {
        uitour.reposition();
      }, 2500);
    }
    setNotifiedAboutNewFeatures(true);
  }

  function setNotifiedAboutNewFeatures(isNotifiedNewFeatures) {
    $localStorage.isNotifiedNewFeatures = isNotifiedNewFeatures;
  }

  function getNotifiedAboutNewFeatures() {
    return $localStorage.isNotifiedNewFeatures || false;
  }

  function getSystemColors() {
    var systemColors = colors;
    systemColors = updateSystemColors(systemColors);
    return systemColors;
  }

  function updateSystemColors(systemColors) {
    var reportStructure = getReportStructure();

    // Indicators colors
    reportStructure.stratificationClasses.forEach(function (classSetting) {
      if (classSetting.id == 1) {
        systemColors.indicator.green.hex = classSetting.hexColorBgIcon
          ? classSetting.hexColorBgIcon
          : systemColors.indicator.green.hex;
        systemColors.indicator.green.rgb = classSetting.rgbColorBgIcon
          ? classSetting.rgbColorBgIcon
          : systemColors.indicator.green.rgb;
      } else if (classSetting.id == 2) {
        systemColors.indicator.yellow.hex = classSetting.hexColorBgIcon
          ? classSetting.hexColorBgIcon
          : systemColors.indicator.yellow.hex;
        systemColors.indicator.yellow.rgb = classSetting.rgbColorBgIcon
          ? classSetting.rgbColorBgIcon
          : systemColors.indicator.yellow.rgb;
      } else if (classSetting.id == 3) {
        systemColors.indicator.orange.hex = classSetting.hexColorBgIcon
          ? classSetting.hexColorBgIcon
          : systemColors.indicator.orange.hex;
        systemColors.indicator.orange.rgb = classSetting.rgbColorBgIcon
          ? classSetting.rgbColorBgIcon
          : systemColors.indicator.orange.rgb;
      } else {
        systemColors.indicator.red.hex = classSetting.hexColorBgIcon
          ? classSetting.hexColorBgIcon
          : systemColors.indicator.red.hex;
        systemColors.indicator.red.rgb = classSetting.rgbColorBgIcon
          ? classSetting.rgbColorBgIcon
          : systemColors.indicator.red.rgb;
      }
    });

    return systemColors;
  }

  return {
    getIdSessionLog: getIdSessionLog,
    setIdSessionLog: setIdSessionLog,
    setLayout: setLayout,
    getLayout: getLayout,
    setUrlApi: setUrlApi,
    getUrlApi: getUrlApi,
    getUrlLogApi: getUrlLogApi,
    setUrlLogApi: setUrlLogApi,
    setUrlSite: setUrlSite,
    getUrlSite: getUrlSite,
    setWmsConfigApi: setWmsConfigApi,
    getWmsConfigApi: getWmsConfigApi,
    getUrlWmsApi: getUrlWmsApi,
    getUrlSldApi: getUrlSldApi,
    setActiveRegionsInterest: setActiveRegionsInterest,
    setActiveCountry: setActiveCountry,
    getActiveCountry: getActiveCountry,
    getActiveRegionsInterest: getActiveRegionsInterest,
    setLayersNamesMapServer: setLayersNamesMapServer,
    getLayersNameBiomes: getLayersNameBiomes,
    getLayersNameUnits: getLayersNameUnits,
    setMapViewInitOptions: setMapViewInitOptions,
    getMapViewInitOptions: getMapViewInitOptions,
    setReportStructure: setReportStructure,
    getReportStructure: getReportStructure,
    setLogosPartners: setLogosPartners,
    getLogosPartners: getLogosPartners,
    getUrlLogoAgroideal: getUrlLogoAgroideal,
    getPieChartsLayout: getPieChartsLayout,
    getBarChartsRankingIndiceLayout: getBarChartsRankingIndiceLayout,
    getBarChartsRegionalComparativeLayout: getBarChartsRegionalComparativeLayout,
    getScatterPolarIndicesByIndicator: getScatterPolarIndicesByIndicator,
    getTranslateAreas: getTranslateAreas,
    getSystemColors: getSystemColors,
    enableGoogleAnalytics: enableGoogleAnalytics,
    setDynamicTranslations: setDynamicTranslations,
    getDynamicTranslations: getDynamicTranslations,
    uiTourService: uiTourService,
    setNotifiedAboutNewFeatures: setNotifiedAboutNewFeatures,
  };
}

export const ServiceModule = new AppModule({
  name: "app.service",
  factories: { AppService },
});

// angular.module("app.service", []).factory("AppService", AppService);
