import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function ToolPanel() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.tool-panel.html"
  };
}

/* @ngInject */
function MapCopyright() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.copyright.html"
  };
}

/* @ngInject */
function PanelLegend() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.panel-legend.html",
    controller: "IndicatorController",
    controllerAs: "indCtrl",
    link: function(scope, element, attrs) {
      angular.element("#tablegend").on("click", function(event) {
        if (!angular.element("#tabmain").hasClass("tabmain-hidden")) {
          angular.element(".slide-bar").triggerHandler("click");
          angular.element(".modal-report-header-minimize").addClass("report-minimize-legend");
        }
      });

      angular.element(".slide-bar").on("click", function(event) {
        if (
          angular.element("#tablegend").hasClass("legend-arrow-open") &&
          angular.element("#tabmain").hasClass("tabmain-opened")
        ) {
          angular.element(".btn-close-legend").triggerHandler("click");
          angular.element(".modal-report-header-minimize").hide();
        }

        if (
          angular.element("#tabmain").hasClass("tabmain-hidden") &&
          !angular.element("#tablegend").hasClass("legend-arrow-open")
        ) {
          angular.element(".legend-content").hide();
          angular.element(".modal-report-header-minimize").hide();
        } else {
          angular.element(".legend-content").show();
          angular.element(".modal-report-header-minimize").show();
          angular.element(".modal-report-header-minimize").removeClass("report-minimize-legend");
        }
      });

      angular.element(".btn-close-legend").on("click", function(event) {
        if (angular.element("#tabmain").hasClass("tabmain-hidden")) {
          angular.element(".legend-content").hide();
          angular.element(".modal-report-header-minimize").hide();
        }
      });
    }
  };
}

/* @ngInject */
function PanelSubLegend() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.panel-sub-legend.html"
  };
}

/* @ngInject */
function MapTab(this: any, $rootScope) {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.panel.html",
    controller: [
      "$scope",
      "$timeout",
      function(this: any, $scope, $timeout) {
        var ctrl = this;
        $rootScope.tab = 1;

        ctrl.setSavingStrategy = function(isSavingStrategy) {
          $rootScope.isSavingStrategy = isSavingStrategy;
        };

        ctrl.isSet = function(checkTab) {
          return $rootScope.tab === checkTab;
        };

        ctrl.setTab = function(activeTab) {
          $rootScope.tab = activeTab;
          ctrl.refreshSliderTransparency();
          ctrl.toggleSliderTransparency();
        };

        ctrl.isUpperDiv = function(isUpper) {
          return $rootScope.isTutorialStrattegy === isUpper;
        };

        ctrl.refreshSliderTransparency = function() {
          $timeout(function() {
            $scope.$broadcast("reCalcViewDimensions");
            $scope.$broadcast("rzSliderForceRender");
          });
        };

        ctrl.toggleSliderTransparency = function() {
          angular.element(document).ready(function() {
            $scope.$on("$destroy", function() {
              $(document).off("click", "*");
            });
            angular.element("#isSliderTransparencyPopUp").hide();
            angular.element("#isSliderTransparencyPopUpStrategy").hide();
          });
        };

        ctrl.inTutorial = function() {
          return $rootScope.inTutorial;
        };

        ctrl.getTutorialStep = function() {
          return $rootScope.actualTutorialStep;
        };

        ctrl.tutorialStep = function(step) {
          if ($rootScope.inTutorial) {
            return $rootScope.actualTutorialStep === step;
          } else {
            return true;
          }
        };
      }
    ],
    controllerAs: "tab"
  };
}

/* @ngInject */
function TabLayer() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/panel/map.tab-layers.html"
  };
}

/* @ngInject */
function FileRead() {
  return {
    scope: { fileread: "=" },
    link: function(scope, element) {
      element.on("change", function() {
        scope.$apply();
      });
    }
  };
}

/* @ngInject */
function MapMenu() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/map/views/map.menu.html",
    link: function() {
      var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      if (isMobile) {
        angular
          .element("div.menu-main-closed")
          .removeClass("menu-main-closed")
          .addClass("menu-main-closed-for-mobile");
      }
    }
  };
}

export const MapDirectivesModule = new AppModule({
  name: "map.directives",
  directives: {
    toolPanel: ToolPanel,
    mapCopyright: MapCopyright,
    panelLegend: PanelLegend,
    panelSubLegend: PanelSubLegend,
    mapTab: MapTab,
    tabLayer: TabLayer,
    fileread: FileRead,
    mapMenu: MapMenu
  }
});

// angular
//     .module('map.directives', [])
//     .directive('toolPanel', ToolPanel)
//     .directive('mapCopyright', MapCopyright)
//     .directive('panelLegend', PanelLegend)
//     .directive('panelSubLegend', PanelSubLegend)
//     .directive('mapTab', MapTab)
//     .directive('tabLayer', TabLayer)
//     .directive("fileread", FileRead)
//     .directive("mapMenu", MapMenu);
