import { AppModule } from "../../module-wrapper";
import angular from "angular";
import { IIndicatorStrategyService } from "./indicatorstrategy/indicatorstrategy.service";

/* @ngInject */
function StrategyController(
  this: any,
  StrategyService,
  MapService,
  $scope,
  IndicatorService,
  LayerService,
  $anchorScroll,
  $location,
  LogService,
  ModalService,
  $timeout,
  IndicatorStrategyService: IIndicatorStrategyService
) {
  var ctrl = this;

  ctrl.host = window.location.host;
  ctrl.pathname = window.location.pathname === "/" ? "/" : window.location.pathname;
  ctrl.host_estrategia = ctrl.host + ctrl.pathname + "?estrategia=";

  ctrl.regions = StrategyService.getRegions();

  ctrl.getUnitsPlanning = function() {
    return StrategyService.getUnitsPlanning();
  };

  ctrl.initRegions = function() {
    ctrl.regions.forEach(function(region) {
      region.checked = true;
      // ctrl.selectRegion(region)
    });
  };

  ctrl.getUnitPlanningActive = function() {
    return StrategyService.getUnitPlanningActive();
  };

  ctrl.isSelectedUnitPlanning = function() {
    var listUnits = StrategyService.getUnitsPlanning().filter(function(unit) {
      return unit.checked;
    });
    return listUnits.length !== 0;
  };

  ctrl.selectUnitPlanning = function(id) {
    ctrl.toggleCollapseSession(ctrl.sanfArea);
  };

  ctrl.isSelectedRegion = function() {
    return Boolean(StrategyService.isSelectedRegion());
  };

  ctrl.getRegionByName = function(name) {
    return StrategyService.getRegionByName(name);
  };

  ctrl.selectRegion = function(region) {
    if (region.checked) {
      LogService.sendButtonLog("selecionar_regiao_interesse", "click", "strategy", region.name);
    }
    var layersWms = LayerService.getGroupLayerWms();
    var refreshList = true,
      send_log = true,
      is_function = true;
    ctrl.regions.forEach(function(region) {
      var layersWmsSquare = LayerService.getGroupLayerWmsSquare();
      LayerService.setVisibleWmsLayerByName(
        layersWmsSquare,
        region.layerName,
        region.checked,
        !refreshList,
        !send_log
      );
      LayerService.setVisibleWmsLayerByName(
        layersWms,
        region.layerName,
        region.checked,
        refreshList,
        send_log,
        is_function
      );
    });
  };

  ctrl.isSelectedSelectionArea = function() {
    var unitPlan = StrategyService.getUnitPlanningActive();
    var hasFeature = MapService.hasFeatureInSelectionAreaByRegionsSelected(unitPlan);
    return hasFeature;
  };

  ctrl.selectedAreaLenght = function() {
    // var selectedAreaSize = MapService.getFeaturesSelectionAreaInstance() || [];
    var selectedAreaSize = IndicatorStrategyService.getAllFeatures() || [];
    return selectedAreaSize;
  };

  ctrl.getActiveBiomeByFeature = function () {
    var features = MapService.getFeaturesSelectionAreaInstance() || [];
    ctrl.listBiomes = [];
    var isIntersectionActive = false;
    features.forEach(function (feature) {
      ctrl.listBiomes.push("" + feature.getProperties().biomas);
      if (
        ctrl.listBiomes.includes("amazonia") &&
        (ctrl.listBiomes.includes("cerrado,amazonia") ||
          ctrl.listBiomes.includes("amazonia,cerrado"))
      ) {
        isIntersectionActive = false;
      }
      if (ctrl.listBiomes.includes("cerrado")) {
          isIntersectionActive = true;
      }
      if (ctrl.listBiomes.includes("amazonia") & ctrl.listBiomes.includes("cerrado")) {
        isIntersectionActive = true;
      }
    });
    return isIntersectionActive;
  };

  ctrl.hasMoratoria = function() {
    return StrategyService.hasMoratoria()
  }

  ctrl.hasNullCells = function () {
    return StrategyService.hasNullCells();
  };

  ctrl.isSelectedIndicatorsByEixo = function(axis) {
    var listIndicatorsActive = IndicatorService.getIndicatorsActiveByAxisName(axis.name);
    return listIndicatorsActive.length != 0;
  };

  ctrl.isSelectedIndicatorsByGroup = function(group) {
    var listIndicatorsActive = IndicatorService.getIndicatorsActiveByGroupName(group.name);
    return listIndicatorsActive.length != 0;
  };

  ctrl.clearMap = function() {
    if (Boolean(ctrl.isSelectedUnitPlanning) && Boolean(!ctrl.sanfArea.collapse)) {
      ctrl.toggleCollapseSession(ctrl.sanfArea);
    } else if (Boolean(!ctrl.isSelectedUnitPlanning) && Boolean(!ctrl.sanfPlan.collapse)) {
      ctrl.toggleCollapseSession(ctrl.sanfPlan);
    }
  };

  ctrl.registerCollapseSession = function(obj, pos) {
    var index = StrategyService.registerCollapseSession(obj, pos);
    // não iniciar sessão collapsed no index (1) com estratégia aberta
    if (index == 1) {
      obj.collapse = window.location.search == "";
    }
    obj.collapse_index = index;
  };

  ctrl.toggleCollapseSession = function(elem) {
    StrategyService.resetCollapsesSession(elem.collapse_index);
    if (elem.collapse_index != 1) {
      elem.collapse = !elem.collapse;
      IndicatorService.refreshSlider($scope);
    }
    if (elem.collapse_index === 2 || elem.collapse_index === 3) {
      if (elem.collapse) {
        ctrl.scrollTo("axis_" + elem.id);
        IndicatorService.refreshSlider($scope);
      }
    }
  };

  ctrl.scrollTo = function(id) {
    setTimeout(function() {
      $location.hash(id);
      $anchorScroll();
      $location.hash("");
    });
  };

  ctrl.addEventToggleSliderTransparency = function() {
    angular.element(document).on("click", function(evt) {
      var target = angular.element(evt.target)[0];
      ctrl.toggleSliderTransparency(target, "isSliderTransparencyPopUpStrategy");
    });
  };

  ctrl.getVisibleSliderTransparency = function() {
    ctrl.isVisibleSliderStrategy = angular
      .element("#isSliderTransparencyPopUpStrategy")
      .is(":visible");
    return ctrl.isVisibleSliderStrategy;
  };

  ctrl.statusStrategySliderTransparency = function() {
    ctrl.addEventToggleSliderTransparency();
    IndicatorService.refreshSlider($scope);
    angular.element("#isSliderTransparencyPopUpStrategy").toggle();
    ctrl.toggleClassSliderTransparency();
  };

  ctrl.toggleClassSliderTransparency = function() {
    if (ctrl.getVisibleSliderTransparency()) {
      angular
        .element("strategy-view")
        .find("#openSliderTransparencyPopUp")
        .addClass("transparency-active");
    } else {
      angular
        .element("strategy-view")
        .find("#openSliderTransparencyPopUp")
        .removeClass("transparency-active");
    }
  };

  ctrl.toggleSliderTransparency = function(target, element) {
    var isClickOut = false;

    if (target.id != "openSliderTransparencyPopUp") {
      while ((target = target.parentElement)) {
        if (target.id == element) {
          isClickOut = true;
        }
      }
      if (!isClickOut) {
        angular.element("#" + element).hide();
        ctrl.toggleClassSliderTransparency();
      }
    }
  };

  ctrl.lastViewEyeIndicator = function(indicator) {
    return IndicatorService.lastViewEyeIndicator(indicator);
  };

  ctrl.getStrategies = function() {
    return StrategyService.getStrategies();
  };

  ctrl.loadStrategies = function() {
    StrategyService.loadStrategies();
  };

  ctrl.getStrategyInfo = function() {
    return StrategyService.getStrategyInfo();
  };

  ctrl.setStrategyName = function(strategyName) {
    StrategyService.setStrategyName(strategyName);
  };

  ctrl.getListIndicatorsActive = function() {
    return IndicatorService.getIndicatorsActive();
  };

  ctrl.getResultStrategy = function() {
    return StrategyService.getResultStrategy();
  };

  ctrl.generateResultStrategy = function(send_log, close_loading) {
    ModalService.openModalLoading();
    angular.element(function() {
      StrategyService.generateResultStrategy(send_log, close_loading);
    });
  };

  ctrl.getIsInStrategyTab = function() {
    return StrategyService.getIsInStrategyTab();
  };

  ctrl.setIsInStrategyTab = function(value) {
    StrategyService.setIsInStrategyTab(value);
  };

  ctrl.copy = function(attribute) {
    document.querySelector(attribute).select();
    document.execCommand("copy");
  };

  ctrl.openLinkStrategy = function(hash) {
    return ctrl.pathname + "?estrategia=" + hash;
  };

  ctrl.getIsInStrategyTab = function() {
    return StrategyService.getIsInStrategyTab();
  };

  ctrl.setIsInStrategyTab = function(value) {
    StrategyService.setIsInStrategyTab(value);
  };
}

// angular.module("strategy.controller", []).controller("StrategyController", StrategyController);

export const StrategyControllerModule = new AppModule({
  name: "strategy.controller",
  controllers: { StrategyController }
});
