import { AppModule } from "../../../module-wrapper";
import angular from "angular";
import Overlay from "ol/Overlay";
import OverlayPositioning from "ol/OverlayPositioning";
import { IMapService } from "../map.service";
import * as Conditions from "ol/events/condition";
import Draw from "ol/interaction/Draw";

/* @ngInject */
function MapInterationsService(this: any, $filter, MapService: IMapService, $timeout, HowToUseService) {
  var ctrl = this;

  ctrl.sketch = {};
  ctrl.listObjMeasureTooltip = [];
  ctrl.listener = {};
  ctrl.drawEvent;
  ctrl.measureDraw = false;

  ctrl.mapStyles = [
    {
      id: 1,
      style: "politico",
      name: "m",
      urlIcon: "mapa-estrada.png",
      textTooltip: "legenda.simples",
      visible: true
    },
    {
      id: 2,
      style: "satelite",
      name: "s",
      urlIcon: "mapa-satelite.png",
      textTooltip: "legenda.satelite",
      visible: true
    },
    {
      id: 3,
      style: "politicoRelevo",
      name: "p",
      urlIcon: "icon-map-3.png",
      textTooltip: "legenda.terreno",
      visible: false
    }
  ];

  function getMapStyles() {
    return ctrl.mapStyles;
  }

  function setMapStyle() {
    var currentStyleName = MapService.getTypeBaseTileMap();
    var visibleMapStyles = Array();

    ctrl.mapStyles.forEach(function (mapStyle) {
      if (mapStyle.visible === true) {
        visibleMapStyles.push(mapStyle.id);
      }
    });

    ctrl.mapStyles.forEach(function (mapStyle, index) {
      if (mapStyle.name === currentStyleName) {
        var nextId = index + 1;

        if (nextId < visibleMapStyles.length) {
          MapService.setTypeBaseTileMap(ctrl.mapStyles[nextId].name);
        } else {
          MapService.setTypeBaseTileMap(ctrl.mapStyles[0].name);
        }
      }
      mapStyle.active = false;
    });
  }

  function setMapStyleIcon() {
    var currentStyleName = MapService.getTypeBaseTileMap();
    ctrl.mapStyles.forEach(function (mapStyle) {
      if (mapStyle.name !== currentStyleName && mapStyle.visible === true) {
        ctrl.mapStyleIcon = mapStyle.urlIcon
    }})
    return ctrl.mapStyleIcon
  }

  function createMeasureTooltip(tooltipType) {
    if (tooltipType == "area") {
      ctrl.listObjMeasureTooltip = [];
    }
    var map = MapService.getMapMainInstance();
    var measureTooltip;
    var measureTooltipElement;
    if (measureTooltipElement) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement("div");
    measureTooltipElement.className =
      tooltipType == "length" ? "length-tooltip-type " : "area-tooltip-type ";
    measureTooltipElement.className += "tooltip tooltip-measure";
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -2],
      positioning: OverlayPositioning.BOTTOM_CENTER
    });
    var objToolTip = {
      tooltip: measureTooltip,
      element: measureTooltipElement,
      type: tooltipType
    };
    map.addOverlay(measureTooltip);
    angular.element(".length-tooltip-type").css("display", "block");
    ctrl.listObjMeasureTooltip.push(objToolTip);
  }

  function getMeasureToolTip(evt) {
    ctrl.sketch = evt.feature;
    var tooltipCoordHa = evt.coordinate;
    ctrl.listener = ctrl.sketch.getGeometry().on("change", function(evt) {
      var geom = evt.target;
      var tooltipTypeLength, tooltipTypeArea;
      var outputHa,
        index,
        totalLength = 0;

      // Verifica se a geometria ainda é uma 'linha'
      var coordinates = geom.getCoordinates()[0];

      if (coordinates.length === 3) {
        coordinates.pop();
      }

      // Criação do tooltip nas laterais (m)
      tooltipTypeLength = getSizeListMeasureTooltipByType("length");
      if (tooltipTypeLength.length < coordinates.length) {
        coordinates.forEach(function() {
          tooltipTypeLength = ctrl.listObjMeasureTooltip.filter(function(tooltip) {
            return tooltip.type == "length";
          });
          if (tooltipTypeLength.length != coordinates.length) {
            createMeasureTooltip("length");
          }
        });
      }

      // Cálculo do valor da medição (km)
      tooltipTypeLength = getSizeListMeasureTooltipByType("length");
      for (index = 1; index < coordinates.length; ++index) {
        var objOutputMLength = formatLength(coordinates, index);
        totalLength += objOutputMLength.value;
        tooltipTypeLength[index].element.innerHTML =
          $filter("number")(objOutputMLength.value.toFixed(1)) + " km";
        tooltipTypeLength[index].tooltip.setPosition(objOutputMLength.centerCoordinate);
      }

      // Criação do tooltip no centro (ha/m)
      var tooltipTypeArea = getSizeListMeasureTooltipByType("area");
      var objToolTip = tooltipTypeArea[0];
      outputHa = formatArea(geom);
      tooltipCoordHa = geom.getInteriorPoint().getCoordinates();
      if (parseFloat(outputHa) > 0) {
        objToolTip.element.innerHTML = "";
        objToolTip.element.innerHTML +=
          "<img class='tooltip-measure-icon' src='/assets/icons/icon-measure-ha.png'>";
        objToolTip.element.innerHTML += outputHa + "<br>";
        objToolTip.element.innerHTML +=
          "<img class='tooltip-measure-icon' src='/assets/icons/icon-measure-m.png'>";
        objToolTip.element.innerHTML += $filter("number")(totalLength.toFixed(1)) + " km";
        tooltipTypeLength[0].tooltip.setPosition(coordinates[0]);
        objToolTip.tooltip.setPosition(tooltipCoordHa);
      }
    });
  }

  function getSizeListMeasureTooltipByType(type) {
    var tooltipType = ctrl.listObjMeasureTooltip.filter(function(tooltip) {
      return tooltip.type == type;
    });
    return tooltipType;
  }

  function formatLength(coordinates, index) {
    // var wgs84Sphere = new Sphere(6378137);
    var output = 0;
    var centerCoordinateBetweenPoints;
    output = MapService.calculateLengthMetersByCoordinates(coordinates, index /*, wgs84Sphere*/);
    centerCoordinateBetweenPoints = getCenterCoordinateBetweenPoints(coordinates, index);
    var objFormatLength = {
      value: output / 1000, // meters -> kilometers
      centerCoordinate: centerCoordinateBetweenPoints
    };
    return objFormatLength;
  }

  function formatArea(polygon) {
    var area = MapService.calculateAreaFeatureByGeom(polygon);
    var output;
    output = $filter("number")(area.toFixed(1)) + " ha";
    return output;
  }

  function getCenterCoordinateBetweenPoints(coordinates, index) {
    var centerCoordinate = [] as any[];
    centerCoordinate.push((coordinates[index][0] + coordinates[index - 1][0]) / 2);
    centerCoordinate.push((coordinates[index][1] + coordinates[index - 1][1]) / 2);
    return centerCoordinate;
  }

  function isActiveMeasureDraw() {
    if (ctrl.drawEvent) {
      return ctrl.drawEvent.getActive();
    }
    return false;
  }

  function isMeasureDraw() {
    return ctrl.measureDraw;
  }

  function offMeasure() {
    ctrl.measureDraw = false;
  }

  function addInteraction(style, featuresCollection, type) {
    var mapInstance = MapService.getMapMainInstance();
    // Verifica se esta interação já esta ativa, caso sim, remove interação.
    if (Boolean(MapService.hasInteractionMapMain(ctrl.drawEvent))) {
      $timeout(function() {
        ctrl.drawEvent.setActive(false);
        mapInstance.removeInteraction(ctrl.drawEvent);
      });
      // Caso interação esteja inativa, adiciona interação.
    } else {
      if (ctrl.drawEvent) {
        mapInstance.removeInteraction(ctrl.drawEvent);
      }
      mapInstance.getOverlays().clear();
      MapService.clearVectorMeasure();
      ctrl.drawEvent = new Draw({
        features: featuresCollection,
        type: type,
        style: MapService.getStyle(
          style.fill.color,
          style.stroke.color,
          style.stroke.width,
          null,
          style.stroke.lineDash
        ),
        freehandCondition: Conditions.never
      });
      ctrl.drawEvent.on("drawstart", function(evt) {
        MapService.setIsDrawing(true);
        createMeasureTooltip("area");
        getMeasureToolTip(evt);
        ctrl.drawEvent.setActive(true);
      });
      ctrl.drawEvent.on("drawend", function(evt) {
        MapService.setIsDrawing(false);
        MapService.viewBoundBox(evt.feature.getGeometry().getExtent(), mapInstance);
        ctrl.measureDraw = true;
        $timeout(function() {
          ctrl.drawEvent.setActive(false);
        });
        mapInstance.removeInteraction(ctrl.drawEvent);
        angular.element(".ol-overlaycontainer-stopevent div:lt(1)").remove();
      });
      mapInstance.addInteraction(ctrl.drawEvent);
    }
  }

  function expandMap() {
    var mapViewInitial = MapService.getMapViewInfo();
    var mapInstance = MapService.getMapMainInstance();
    MapService.setViewMap(mapInstance, mapViewInitial.coord, mapViewInitial.zoom);
  }

  function openStrategyTab() {
    HowToUseService.setInitialStepHowToCreateStrategies();
  };

  return {
    getMapStyles: getMapStyles,
    setMapStyle: setMapStyle,
    createMeasureTooltip: createMeasureTooltip,
    getMeasureToolTip: getMeasureToolTip,
    getCenterCoordinateBetweenPoints: getCenterCoordinateBetweenPoints,
    isActiveMeasureDraw: isActiveMeasureDraw,
    isMeasureDraw: isMeasureDraw,
    offMeasure: offMeasure,
    addInteraction: addInteraction,
    expandMap: expandMap,
    openStrategyTab: openStrategyTab,
    setMapStyleIcon: setMapStyleIcon
  };
}

// angular
//     .module('mapInteractions.service', [])
//     .factory('MapInterationsService', MapInterationsService);

export const MapInteractionsServiceModule = new AppModule({
  name: "mapInteractions.service",
  factories: { MapInterationsService }
});
