import { AppModule } from "../../../module-wrapper";
import angular from "angular";

/* @ngInject */
function LoginController(
  this: any,
  AuthService,
  AuthLoginService,
  ModalService,
  $state,
  $rootScope,
  StrategyService,
  AppService,
  NotificationService,
  MainService,
  LogService
) {
  var ctrl = this;
  $rootScope.isSavingStrategy = false;

  ctrl.url = AppService.getUrlApi() + "/session";
  ctrl.urlRecovery = AppService.getUrlApi() + "/account/request-recovery-password";

  ctrl.signIn = function(user) {
    if (user) {
      AuthLoginService(ctrl.url)
        .login(user)
        .$promise.then(
          function(response) {
            if (response.success) {
              response = response.data;
              AuthService.setUsername(response.username);
              AuthService.setName(response.name);
              AuthService.setToken(response.token);
              StrategyService.loadStrategies();
              $state.go("map");
              ModalService.closeAllModal();
              // ModalService.openModalDisclaimerChangePassword();
              NotificationService.showNotification(
                "success",
                MainService.getTextByKeyI18n("acesso.acesso"),
                ctrl.getName() + " " + MainService.getTextByKeyI18n("acesso.bemvindo"),
                true,
                8000
              );
              LogService.sendAuthLog(false, false);
            } else {
              $rootScope.$broadcast("callMessage", {
                messages: response.messages,
                elementId: "messageSignIn"
              });
            }
          },
          function(error) {
            $rootScope.$broadcast("callMessage", {
              messages: AuthService.getMsgErrorDefault(),
              elementId: "messageSignIn"
            });
          }
        );
    }
  };

  ctrl.signInStrategyRoute = function(user) {
    if (user) {
      AuthLoginService(ctrl.url)
        .login(user)
        .$promise.then(
          function(response) {
            if (response.success) {
              response = response.data;
              AuthService.setUsername(response.username);
              AuthService.setToken(response.token);
              $state.go("map");
            }
            $rootScope.$broadcast("callMessage", {
              messages: response.messages,
              elementId: "messageSignIn"
            });
          },
          function(error) {
            $rootScope.$broadcast("callMessage", {
              messages: AuthService.getMsgErrorDefault(),
              elementId: "messageSignIn"
            });
          }
        );
    }
  };

  ctrl.logout = function() {
    AuthLoginService(ctrl.url)
      .logout()
      .$promise.then(
        function(response) {
          if (response.success) {
            AuthService.logout();
          }
        },
        function(error) {}
      )
      .finally(function() {
        angular.element("div.menu-main-closed").removeClass("menu-main-opened");
        angular.element("div.menu-displayed").removeClass("menu-animation-hidden");
      });
  };

  ctrl.isAuthenticated = function() {
    return AuthService.isAuthenticated();
  };

  ctrl.getUsername = function() {
    return AuthService.getUsername();
  };

  ctrl.getName = function() {
    return AuthService.getName();
  };

  ctrl.getToken = function() {
    var token = AuthService.getToken();
    return token;
  };

  ctrl.savingStrategy = function() {
    return $rootScope.isSavingStrategy;
  };

  ctrl.closeIsSaving = function() {
    $rootScope.isSavingStrategy = false;
  };

  ctrl.openModalMyStrategies = function() {
    ModalService.openModalMyStrategies();
  };

  ctrl.openModalRecoveryPassword = function() {
    ModalService.openModalRecoveryPassword();
  };

  ctrl.sendRecoveryPassword = function(email) {
    AuthLoginService(ctrl.urlRecovery)
      .recoveryPassword(email)
      .$promise.then(function(response) {
        if (response.success) {
          ModalService.closeAllModal();
          NotificationService.showNotification(
            "success",
            MainService.getTextByKeyI18n("areapessoal.redefinirsuasenha"),
            MainService.getTextByKeyI18n("areapessoal.enviaremailsucesso"),
            true,
            8000
          );
        } else {
          $rootScope.$broadcast("callMessage", {
            messages: response.messages,
            elementId: "messageSignIn"
          });
        }
      });
  };
}

export const LoginControllerModule = new AppModule({
  name: "login.controller",
  controllers: { LoginController }
});

// angular
//     .module('login.controller', [])
//     .controller('LoginController', LoginController);
