import { AppModule } from "../../../module-wrapper";
import { LoginControllerModule } from "./login.controller";

export const LoginModule = new AppModule({
  name: "login.module",
  modules: [LoginControllerModule]
});

// angular
//     .module('login.module', [
//         'login.controller'
//     ])
