import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function EmailController(this: any, EmailService, ModalService, $rootScope) {
  var ctrl = this;
  ctrl.form = {};

  this.enviarEmail = function() {
    ModalService.openModalLoading();
    EmailService.post(ctrl.form).$promise.then(function(response) {
      ModalService.closeModalLoading();
      $rootScope.$broadcast("callMessage", {
        messages: response.messages,
        elementId: "contactMessage"
      });
      if (response.success) {
        angular.element("#contactMessage").addClass("message-success");
        angular.element("#contactMessage").removeClass("message-error");
      } else {
        angular.element("#contactMessage").addClass("message-error");
        angular.element("#contactMessage").removeClass("message-success");
      }
    });
  };
}

export const ContactControllerModule = new AppModule({
  name: "contact.controller",
  controllers: { EmailController }
});

// angular
//     .module('contact.controller', [])
//     .controller('EmailController', EmailController)
