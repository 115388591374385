import { AppModule } from "../../module-wrapper";

/* @ngInject */
function PostLogService($resource, AppService) {
  return function(url) {
    var url = url || AppService.getUrlLogApi();
    return $resource(
      url,
      {},
      {
        post: {
          url: url,
          method: "POST",
          isArray: true
        }
      }
    );
  };
}

/* @ngInject */
function LogService(this: any, PostLogService, $injector) {
  var ctrl = this;

  ctrl.codes_log = [];

  function addInCodesLog(code) {
    ctrl.codes_log.push(code);
  }

  function concatInCodesLog(codes) {
    ctrl.codes_log = ctrl.codes_log.concat(codes);
  }

  function clearCodesLog() {
    ctrl.codes_log = [];
  }

  function sendLog(log: any, url?: any, function_success?: Function, function_error?: Function) {
    function_success = function_success || function(response) {};
    function_error =
      function_error ||
      function(error) {
        console.log(error);
      };
    log = JSON.stringify(log);
    PostLogService(url)
      .post(log)
      .$promise.then(function_success, function_error);
  }

  function getStrategyLog() {
    var StrategyService = $injector.get("StrategyService");
    if (StrategyService.hasStrategy()) {
      var strategy = {};
      strategy["hash"] = StrategyService.getStrategyHash();
      return strategy;
    }
    return {};
  }

  function getAuthLog() {
    var AuthService = $injector.get("AuthService");
    var auth = {};
    auth["is_logged"] = AuthService.isAuthenticated();
    return auth;
  }

  function getSessionLog() {
    var AppService = $injector.get("AppService");
    var session = {};
    session["id"] = AppService.getIdSessionLog();
    return session;
  }

  function getBaseLog() {
    var log = {};
    log["strategy"] = getStrategyLog();
    log["auth"] = getAuthLog();
    log["session"] = getSessionLog();
    var date = new Date();
    log["date"] = date.toISOString();
    return log;
  }

  function sendButtonStrategyLog(name, format, value, value_strategy) {
    var log = getBaseLog();
    log["action"] = {
      name: name,
      module: "strategy",
      format: format
    };
    if (!Boolean(value)) {
      value = name;
    }
    log["action"].value = value;
    if (Boolean(value_strategy)) {
      log["strategy"].value = value_strategy;
    }
    sendLog(log);
  }

  function sendButtonLog(name, format, module, value) {
    var log = getBaseLog();
    log["action"] = {
      name: name,
      module: module,
      format: format
    };
    if (!Boolean(value)) {
      value = name;
    }
    log["action"].value = value;
    sendLog(log);
  }

  function sendAreaOfStudyLog(type, planning_unit, params) {
    var log = getBaseLog();
    var area_of_study = {};
    area_of_study["type"] = type;
    area_of_study["codes"] = ctrl.codes_log;
    area_of_study["planning_unit"] = planning_unit;

    if (Boolean(params)) {
      area_of_study["params"] = params;
    }

    log["strategy"]["area_of_study"] = area_of_study;
    sendLog(log);
    clearCodesLog();
  }

  function sendRecoveryStrategyLog() {
    var log = getBaseLog();
    log["strategy"]["recovery"] = true;
    sendLog(log);
  }

  function sendAuthLog(initial, saveId) {
    var function_success = function(response) {
      if (response.length > 0) {
        response = response[0];
        var AppService = $injector.get("AppService");
        AppService.setIdSessionLog(response._id["$oid"]);
      }
    };

    var log = getBaseLog();
    log["auth"]["initial"] = initial;

    var AppService = $injector.get("AppService");
    sendLog(log, AppService.getUrlLogApi(), saveId ? function_success : undefined);
  }

  return {
    addInCodesLog: addInCodesLog,
    concatInCodesLog: concatInCodesLog,
    sendAreaOfStudyLog: sendAreaOfStudyLog,
    sendAuthLog: sendAuthLog,
    sendButtonLog: sendButtonLog,
    sendRecoveryStrategyLog: sendRecoveryStrategyLog,
    sendButtonStrategyLog: sendButtonStrategyLog
  };
}

export const LogServiceModule = new AppModule({
  name: "log.service",
  factories: { LogService, PostLogService }
});

// angular
//     .module('log.service', [])
//     .factory('LogService', LogService)
//     .factory('PostLogService', PostLogService);
