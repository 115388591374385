import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function config($urlRouterProvider, $stateProvider, $locationProvider) {
  $urlRouterProvider.otherwise(location.pathname);
  $locationProvider.hashPrefix("");
  $locationProvider.html5Mode(true);

  $stateProvider.state("map", {
    title: "Agroideal",
    url: location.pathname,
    templateUrl: "/app/modules/map/map.view.html",
    controller: "MapController",
    controllerAs: "mapCtrl",
    resolve: {
      MainService: "MainService",
      translatePartialLoader: [
        "$translate",
        "MainService",
        "tmhDynamicLocale",
        "$location",
        function loadPartialLoader($translate, MainService, tmhDynamicLocale, $location) {
          if (angular.isDefined($location.search().lang)) {
            MainService.setLanguageSystem($location.search().lang);
            $location.url("");
          }
          // var lang = MainService.getLanguageSystem();
          $translate.use(MainService.getLanguageSystem().locale);
          tmhDynamicLocale.set(MainService.getLanguageSystem().locale.toLowerCase());
          $translate.refresh();
        }
      ]
    }
  });
}

export const MapRoutesModule = new AppModule({
  name: "map.routes",
  configurators: [config]
});

// angular
//     .module('map.routes', [])
//     .config(config);
