import { AppModule } from "../../module-wrapper";

/* @ngInject */
function ReportView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/report.view.html"
  };
}

/* @ngInject */
function NewReportView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/new.report-view.html"
  };
}

/* @ngInject */
function ReportLateralMenuView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.lateral-menu-view.html"
  };
}

/* @ngInject */
function ReportSessionMapView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-map-view.html"
  };
}

/* @ngInject */
function ReportSessionStudyAreaView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-study-area-view.html"
  };
}

/* @ngInject */
function ReportSessionConsolidatedIndicatorsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-consolidated-indicators-view.html"
  };
}

/* @ngInject */
function ReportSessionSlidersIndicatorsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-sliders-indicators-view.html"
  };
}

/* @ngInject */
function ReportSessionChartsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-charts-view.html"
  };
}

/* @ngInject */
function ReportSessionInformationView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-information-view.html"
  };
}

/* @ngInject */
function ReportProtectedAreasView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.protected-areas-view.html"
  };
}

/* @ngInject */
function ReportChartsView() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/reportCharts/reportCharts.view.html"
  };
}

/* @ngInject */
function ReportSessionDownloadToPng() {
  return {
    restrict: "E",
    templateUrl: "/app/modules/report/views/report.session-download-to-png.html",
    controller: "ReportController",
    controllerAs: "reportCtrl"
  };
}

export const ReportDirectivesModule = new AppModule({
  name: "report.directives",
  directives: {
    reportView: ReportView,
    newReportView: NewReportView,
    reportLateralMenuView: ReportLateralMenuView,
    reportSessionMapView: ReportSessionMapView,
    reportSessionStudyAreaView: ReportSessionStudyAreaView,
    reportSessionConsolidatedIndicatorsView: ReportSessionConsolidatedIndicatorsView,
    reportSessionSlidersIndicatorsView: ReportSessionSlidersIndicatorsView,
    reportSessionChartsView: ReportSessionChartsView,
    reportSessionInformationView: ReportSessionInformationView,
    reportProtectedAreasView: ReportProtectedAreasView,
    reportChartsView: ReportChartsView,
    reportSessionDownloadToPng: ReportSessionDownloadToPng
  }
});

// angular
//     .module('report.directives', [])
//     .directive('reportView', ReportView)
//     .directive('newReportView', NewReportView)
//     .directive('reportLateralMenuView', ReportLateralMenuView)
//     .directive('reportSessionMapView', ReportSessionMapView)
//     .directive('reportSessionStudyAreaView', ReportSessionStudyAreaView)
//     .directive('reportSessionConsolidatedIndicatorsView', ReportSessionConsolidatedIndicatorsView)
//     .directive('reportSessionSlidersIndicatorsView', ReportSessionSlidersIndicatorsView)
//     .directive('reportSessionChartsView', ReportSessionChartsView)
//     .directive('reportSessionInformationView', ReportSessionInformationView)
//     .directive('reportProtectedAreasView', ReportProtectedAreasView)
//     .directive('reportChartsView', ReportChartsView)
//     .directive('reportSessionDownloadToPng', ReportSessionDownloadToPng)
