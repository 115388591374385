import angular, { IControllerConstructor, IDirectiveFactory } from "angular";

interface AppModuleArgs {
  name: string;
  modules?: AppModule[];
  controllers?: Record<string, IControllerConstructor>;
  factories?: Record<string, Function>;
  directives?: Record<string, IDirectiveFactory>;
  runner?: Function;
  configurators?: Function[];
}

/**
 * O objetivo desse wrapper é criar uma estrutura onde não seja possível
 * requisitar um módulo sem tê-lo realmente importado.
 */
export class AppModule {
  __tag__ = "AppModule" as const;
  _ngModule?: angular.IModule;

  constructor(public args: AppModuleArgs) {}

  get modules() {
    return this.args.modules || [];
  }

  get controllers() {
    return this.args.controllers || {};
  }

  get factories() {
    return this.args.factories || {};
  }

  get directives() {
    return this.args.directives || {};
  }

  get configurators() {
    return this.args.configurators || [];
  }

  doRegister() {
    if (this._ngModule) return;
    const depNames = this.modules.map(x => x.args.name);
    this.modules.forEach(module => module.doRegister());
    // console.log("doRegister", this.args.name);
    this._ngModule = angular.module(this.args.name, depNames);
    Object.entries(this.controllers).forEach(([key, ctrl]) => {
      this._ngModule = this._ngModule!.controller(key, ctrl);
    });
    Object.entries(this.factories).forEach(([key, fac]) => {
      if (fac.length && fac.$inject === undefined) {
        console.warn(`${key} factory has no $inject.`);
      }
      this._ngModule = this._ngModule!.factory(key, fac);
    });
    Object.entries(this.directives).forEach(([key, directive]) => {
      this._ngModule = this._ngModule?.directive(key, directive);
    });
    if (this.args.runner) {
      this._ngModule.run(this.args.runner);
    }
    this.configurators.forEach(fn => {
      this._ngModule = this._ngModule?.config(fn);
    });
  }
}

export class ExternalModule extends AppModule {
  constructor(name: string) {
    super({ name });
  }

  doRegister() {}
}
