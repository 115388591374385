import { AppModule } from "../../module-wrapper";
import angular from "angular";

/* @ngInject */
function GetLayerService($resource, AppService) {
  var url = AppService.getUrlApi() + "/layers-group";

  return $resource(url, {
    get: {
      method: "GET",
      isArray: false,
    },
  });
}

export type LayerService = ReturnType<typeof LayerService>;

/* @ngInject */
function LayerService(this: any, LogService) {
  var ctrl = this;

  ctrl.collapseSessions = [];

  function registerCollapseSession(obj) {
    ctrl.collapseSessions.push(obj);
    return ctrl.collapseSessions.length;
  }

  function resetCollapsesSession(except_index) {
    ctrl.collapseSessions.forEach(function (obj) {
      if (obj.collapse_index !== except_index) {
        obj.collapse = false;
      }
    });
  }

  function resetAllCollapsesSession() {
    ctrl.collapseSessions.forEach(function (obj) {
      obj.collapse = false;
    });
  }

  function setGroupLayerWms(groupLayersWms) {
    ctrl.groupLayersWms = groupLayersWms;
  }

  function getGroupLayerWms() {
    return ctrl.groupLayersWms;
  }

  function setGroupLayerWmsSquare(groupLayerWmsSquare) {
    ctrl.groupLayerWmsSquare = groupLayerWmsSquare;
  }

  function getGroupLayerWmsSquare() {
    return ctrl.groupLayerWmsSquare;
  }

  function setGroupLayers(groupLayers) {
    ctrl.groupLayers = groupLayers;
  }

  function getGroupLayers() {
    return ctrl.groupLayers;
  }

  function getLayers() {
    var layers = [] as any[];
    if (angular.isDefined(ctrl.groupLayers)) {
      ctrl.groupLayers.forEach(function (groupLayer) {
        groupLayer.layers.forEach(function (layer) {
          //cria uma entrada para silos e armazens que adiciona a informacao de fonte de informacao da agrosatelite
          if (layer.id == 27 && layer.projects.length < 2) {
            layer.projects.push({
              label: "Agrosatélite Geotecnologia Aplicada",
              source: 2,
              url: "https://agrosatelite.com.br/",
              translate_label: {
                "en-US": "Agrosatélite Applied Geotechnology",
                "es-ES": "Agrosatélite Geotecnología Aplicada",
                "pt-BR": "Agrosatélite Geotecnologia Aplicada",
              },
            });
          }
          layer["order_group"] = groupLayer.order;
        });
        layers = layers.concat(groupLayer.layers);
      });
    }
    return layers;
  }

  function getLayerByName(name) {
    var layer = getLayers().filter(function (layer) {
      return layer.name == name;
    });
    if (layer.length > 0) {
      return layer[0];
    }
    return -1;
  }

  function getLayerByMapserverName(mapserver_name) {
    var layer = getLayers().filter(function (layer) {
      return layer.mapserver_name == mapserver_name;
    });
    if (layer.length > 0) {
      return layer[0];
    }
    return -1;
  }

  function setLanguageLayers(lang) {
    ctrl.groupLayers.forEach(function (layerGroup) {
      if (!Boolean(layerGroup.translate_label)) {
        layerGroup.translate_label = layerGroup.label;
      }
      try {
        layerGroup.label = layerGroup.translate_label[lang];
      } catch (e) {
        layerGroup.label = "";
      }
      layerGroup.layers.forEach(function (layer) {
        if (!Boolean(layer.translate_label)) {
          layer.translate_label = layer.label;
        }
        if (!Boolean(layer.translate_description)) {
          layer.translate_description = layer.description;
        }
        if (!Boolean(layer.translate_year_base)) {
          layer.translate_year_base = layer.year_base;
        }
        layer.sources.forEach(function (source) {
          if (!Boolean(source.translate_label)) {
            source.translate_label = source.label;
          }

          try {
            source.label = source.translate_label[lang];
          } catch (e) {
            source.label = "";
          }
        });

        if (Boolean(layer.scopes)) {
          layer.scopes.forEach(function (scope) {
            if (!Boolean(scope.translate_label)) {
              scope.translate_label = scope.label;
            }

            try {
              scope.label = scope.translate_label[lang];
            } catch (e) {
              scope.label = "";
            }
          });
        }

        if (Boolean(layer.projects)) {
          layer.projects.forEach(function (project) {
            if (!Boolean(project.translate_label)) {
              project.translate_label = project.label;
            }

            try {
              project.label = project.translate_label[lang];
            } catch (e) {
              project.label = "";
            }
          });
        }

        try {
          layer.label = layer.translate_label[lang];
        } catch (e) {
          layer.label = "";
        }
        try {
          layer.description = layer.translate_description[lang];
        } catch (e) {
          layer.description = "";
        }
        try {
          layer.year_base = layer.translate_year_base[lang];
        } catch (e) {
          layer.year_base = "";
        }
      });
    });
  }

  function changeOpacityLayer(layer, opacidade) {
    var layersMap = getGroupLayerWms();
    layersMap.getLayers().forEach(function (layerMap) {
      if (layer.mapserver_name == layerMap.get("name")) {
        if (layer.is_fixed == false) {
          setOpacityLayer(layerMap, opacidade);
        }
      }
    });
  }

  function changeOpacityLayerGroup(layerGroup, opacidade) {
    if (layerGroup) {
      var layers = getGroupLayers();
      layerGroup.getLayers().forEach(function (layerMap) {
        layers.forEach(function (layer) {
          if (layer.mapserver_name == layerMap.get("name")) {
            if (layer.is_fixed == false) {
              setOpacityLayer(layerMap, opacidade);
            }
          }
        });
      });
    }
  }

  function setOpacityLayer(layer, opacidade) {
    if (layer) {
      layer.setOpacity(opacidade);
    }
  }

  function initLayers() {
    var send_log = true,
      is_function = true;
    ctrl.groupLayers.forEach(function (layerGroup) {
      layerGroup.layers.forEach(function (layer) {
        if (layer.is_visible) {
          loadLayerWms(layer, send_log, is_function);
        }
      });
    });
  }

  function loadLayerWms(layer, send_log, is_function) {
    var layers = getGroupLayerWms();
    var refreshList = false;
    setVisibleWmsLayerByName(
      layers,
      layer.mapserver_name,
      layer.is_visible,
      refreshList,
      send_log,
      is_function
    );
  }

  function setVisibleWmsLayerByName(
    layersMap,
    layerName,
    visibility,
    refreshList,
    send_log,
    is_function
  ) {
    layersMap.getLayers().forEach(function (layer) {
      var name = layer.get("name");
      if (name === layerName) {
        if (layer.getVisible() != visibility && Boolean(send_log != false)) {
          name = (visibility ? "ligar" : "desligar") + "_camada";
          var format = is_function ? "function" : "click";
          LogService.sendButtonLog(name, format, "layer", layerName);
        }
        layer.setVisible(visibility);
        layer.is_visible = visibility;
      }
      if (refreshList) {
        setVisibilityMainListLayersByName(layerName, visibility);
      }
    });
  }

  function setVisibilityMainListLayersByName(layerName, visibility) {
    ctrl.groupLayers.forEach(function (layerGroup) {
      layerGroup.layers.forEach(function (layer) {
        if (layer.mapserver_name === layerName) {
          layer.is_visible = visibility;
        }
      });
    });
  }

  function clearLayersMap(is_function) {
    var send_log = true;
    LogService.sendButtonLog("limpar_mapa_camada", is_function ? "function" : "click", "layer");
    ctrl.groupLayers.forEach(function (layerGroup) {
      layerGroup.layers.forEach(function (layer) {
        if (layer.is_fixed === false) {
          changeOpacityLayer(layer, 100);
          layer.is_visible = false;
          loadLayerWms(layer, send_log, is_function);
        }
      });
    });
  }

  function isMaskLayer(layerName) {
    return (
      layerName == "capa_mundo_amz_cer" ||
      layerName == "capa_mundo_chaco_paraguai" ||
      layerName == "capa_mundo_chaco"
    );
  }

  function isTooltipLayer(layerName) {
    var allowedTooltipLayers = [
      "unidade_de_conservacao-apa",
      "assentamentos",
      "grade",
      "hidrovias",
      "armazenamento",
      "tis",
      "unidade_de_conservacao-protecao_integral",
      "unidade_de_conservacao-uso_sustentavel",
      "areas_prioritarias",
      "ferrovias",
      "etnias",
      "prot_inter",
      "prot_nac",
      "provincias_chaco",
      "acopios",
      "municipios_argentina_chaco",
      "grades",
      "microrregiao",
      "mesorregiao",
      "municipio",
      "gradep",
      "estados_paraguai_chaco",
      "comunidades_py",
      "localidades_py",
      "servicoamb_py",
      "aprotegidas_py",
      "ibas_py",
      "reservab_py",
      "senacsa_chaco",
      "ecorregioescp",
      "municipios_paraguai_chaco",
    ];
    return allowedTooltipLayers.includes(layerName);
  }

  return {
    registerCollapseSession: registerCollapseSession,
    resetCollapsesSession: resetCollapsesSession,
    resetAllCollapsesSession: resetAllCollapsesSession,
    setGroupLayerWmsSquare: setGroupLayerWmsSquare,
    getGroupLayerWmsSquare: getGroupLayerWmsSquare,
    setGroupLayerWms: setGroupLayerWms,
    getGroupLayerWms: getGroupLayerWms,
    setGroupLayers: setGroupLayers,
    getGroupLayers: getGroupLayers,
    getLayers: getLayers,
    changeOpacityLayer: changeOpacityLayer,
    changeOpacityLayerGroup: changeOpacityLayerGroup,
    setOpacityLayer: setOpacityLayer,
    setLanguageLayers: setLanguageLayers,
    initLayers: initLayers,
    loadLayerWms: loadLayerWms,
    setVisibleWmsLayerByName: setVisibleWmsLayerByName,
    clearLayersMap: clearLayersMap,
    getLayerByName: getLayerByName,
    getLayerByMapserverName: getLayerByMapserverName,
    isMaskLayer: isMaskLayer,
    isTooltipLayer: isTooltipLayer,
  };
}

export const LayersServiceModule = new AppModule({
  name: "layers.service",
  factories: { LayerService, GetLayerService },
});

// angular
//     .module('layers.service', [])
//     .factory('LayerService', LayerService)
//     .factory('GetLayerService', GetLayerService);
