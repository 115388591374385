import { AppModule } from "../../module-wrapper";
import { AuthServiceModule } from "./auth.service";
import { LoginModule } from "./login/login.module";

export const AuthModule = new AppModule({
  name: "auth.module",
  modules: [AuthServiceModule, LoginModule]
});

// angular
//     .module('auth.module', [
//         'auth.service',
//         'login.module'
//     ])
