import { AppModule } from "../../../module-wrapper";
import { MapInteractionsControllerModule } from "./mapInteractions.controller";
import { MapInteractionsServiceModule } from "./mapInteractions.service";

// angular
//     .module('mapInteractions.module', [
//         'mapInteractions.controller',
//         'mapInteractions.service'
//     ])

export const MapInteractionsModule = new AppModule({
  name: "mapInteractions.module",
  modules: [MapInteractionsControllerModule, MapInteractionsServiceModule]
});
