import { AppModule } from "../../module-wrapper";

/* @ngInject */
function TranslateController(this: any, TranslateService) {
  var ctrl = this;

  ctrl.translate = function(obj) {
    return TranslateService.translate(obj);
  };
}

export const TranslateControllerModule = new AppModule({
  name: "translate.controller",
  controllers: { TranslateController }
});

// angular
//     .module('translate.controller', [])
//     .controller('TranslateController', TranslateController)
