import { AppModule } from "../../module-wrapper";

/* @ngInject */
function NotificationConfig() {
  return {
    restrict: "E",
    template: "<toaster-container toaster-options=\"{ 'limit':3 }\"></toaster-container>"
  };
}

// angular
//     .module('notification.directives', [])
//     .directive('notificationConfig', NotificationConfig);

export const NotificationDirectivesModule = new AppModule({
  name: "notification.directives",
  directives: { notificationConfig: NotificationConfig }
});
