import { AppModule } from "../../module-wrapper";

/* @ngInject */
function LogController(this: any, LogService) {
  var ctrl = this;

  ctrl.getIndicadores = function() {
    return LogService.getIndicadores();
  };
}

export const LogControllerModule = new AppModule({
  name: "log.controller",
  controllers: { LogController }
});

// angular
//     .module('log.controller', [])
//     .controller('LogController', LogController)
