import { AppModule } from "../../module-wrapper";
import { NotificationServiceModule } from "./notification.service";
import { NotificationDirectivesModule } from "./notification.directives";

// angular
//     .module('notification.module', [
//         'notification.service',
//         'notification.directives'
//     ]);

export const NotificationModule = new AppModule({
  name: "notification.module",
  modules: [NotificationServiceModule, NotificationDirectivesModule]
});
