import { AppModule } from "../../module-wrapper";

/* @ngInject */
function EmailService($resource, AppService) {
  var url = AppService.getUrlApi() + "/contact";

  return $resource(
    url,
    {},
    {
      post: {
        method: "POST",
        isArray: false
      }
    }
  );
}

/* @ngInject */
function ContactDialogService() {
  function getDialog(this: any) {
    return this.dialog;
  }

  function setDialog(this: any, dialog) {
    this.dialog = dialog;
  }

  return {
    getDialog: getDialog,
    setDialog: setDialog
  };
}

// angular
//     .module('contact.service', [])
//     .factory('EmailService', EmailService)
//     .factory('ContactDialogService', ContactDialogService);

export const ContactServiceModule = new AppModule({
  name: "contact.service",
  factories: { EmailService, ContactDialogService }
});
