import { AppModule } from "../../module-wrapper";
import { ExporterServiceModule } from "./exporter.service";

export const ExporterModule = new AppModule({
  name: "exporter.module",
  modules: [ExporterServiceModule]
});

// angular
//     .module('exporter.module', [
//         'exporter.service'
//     ]);
